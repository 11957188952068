import React from 'react';
import PropTypes from 'prop-types';

import './couponField.css';

/**
 * CouponField.
 * Displays and handles a cart coupon.
 *
 * @author  Miguel
 * @param   {String}      props.coupon              Coupon code.
 * @param   {Object}      props.couponEntity        Coupon object from the CartItems component.
 * @param   {Number}      props.monthsDiscount      Number of months for the coupon discount.
 * @param   {Function}    props.onCouponApply       Function from the Cart Page to to apply a coupon code.
 * @param   {Function}    props.onCouponInputChange Function from the Cart Page to handle coupon code onInputChange.
 * @param   {Function}    props.onDeleteCoupon      Function from the Cart Page to to remove a coupon code.
 * @returns {JSX.Element}                           The component markup.
 */

const CouponField = ({
  coupon,
  couponEntity,
  monthsDiscount,
  onCouponApply,
  onCouponInputChange,
  onDeleteCoupon
}) => {
  return (
    <React.Fragment>
      <div className='row form-group'>
        <div className='col-sm-12'>
          <div className='input-group'>
            <input
              id='coupon_input'
              type='text'
              className='form-control'
              placeholder='Promo Code'
              onChange={(e) => {
                onCouponInputChange(e);
              }}
              value={coupon === null ? '' : coupon}
            />
            <span className='input-group-btn'>
              <button
                className='btn validate-btn cart-btn'
                style={{ height: '44px' }}
                type='button'
                id='btn_apply_coupon'
                onClick={() => {
                  onCouponApply(coupon);
                }}
              >
                Apply
              </button>
            </span>
          </div>
        </div>
      </div>
      {couponEntity &&
        Object.keys(couponEntity).length > 0 &&
        couponEntity.discountServiceConfig &&
        couponEntity.discountServiceConfig.couponVisibility === 'Y' && (
          <div className='row'>
            <div className='col-sm-12'>
              <div className='font-16 success-text'>
                Coupon Applied
                {couponEntity.discountType === 'MONTHS' && monthsDiscount > 0 && (
                  <span className='success-text'>
                    - {monthsDiscount} {monthsDiscount === 1 ? 'Month' : 'Months'} free
                  </span>
                )}
                {couponEntity && couponEntity.code !== 'UEMTQT' && (
                  <i
                    id='btn_delete_coupon'
                    data-testid="delete-coupon"
                    className='fa fa-times-circle close-icon margin-left-5'
                    aria-hidden='true'
                    onClick={() => {
                      onDeleteCoupon();
                    }}
                  ></i>
                )}
              </div>
            </div>
          </div>
        )}
      {couponEntity && couponEntity.code !== 'UEMTQT' && (
        <div className='font-16 text-left margin-top-10'>
          The offer of &#39;10% off when purchasing two or more
          products&#39; can not be used in conjunction with any other offer.
        </div>
      )}
    </React.Fragment>
  );
};

CouponField.propTypes = {
  coupon: PropTypes.string,
  couponEntity: PropTypes.object,
  monthsDiscount: PropTypes.number,
  onCouponApply: PropTypes.func.isRequired,
  onCouponInputChange: PropTypes.func.isRequired,
  onDeleteCoupon: PropTypes.func.isRequired
};

export default CouponField;
