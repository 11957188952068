import React from "react";
import {FormGroup, ControlLabel, FormControl, Col, Checkbox, Tooltip, OverlayTrigger, Button} from 'react-bootstrap';
import TncTrial from './tncTrial'
import TncOneMonth from './tncOneMonth'
import TncGeneral from './tncGeneral'
import ErrorMessages from './checkoutErrorMessages';
import IndustrySelector from './industrySelector';
import AccountSelector from './accountSelector';


export default class CustomerForm extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      mobile: false
    }
  }

  componentDidMount() {
    if(/Android|webOS|iPhone|iPod|ipad|BlackBerry/i.test(navigator.userAgent) ){
      this.setState({
        mobile:true
      })
    }
  }

  /* istanbul ignore next*/
  orderSummaryFocus() {
    window.scroll({
      top: document.body.offsetHeight,
      left: 0, 
      behavior: 'smooth',
    });
  }
  
  render(props){
    let tooltip = (
      <Tooltip id="tooltip">
        Please choose your preferred contact method in the event we need to contact you about your order.
      </Tooltip>
    )

    let tooltipStreetAddress = (
      <Tooltip id="tooltip">
        Example unit address format "UNIT 1, 5 Street Name"
      </Tooltip>
    )

    let tooltipNZBN = (
      <Tooltip id="tooltip">
        This is your NZBN
      </Tooltip>
    )



    let classNames = require('classnames');
    let creditCardTileClass = classNames({
      'col-sm-4 btn btn-radio btn-active': this.props.cardType === 'credit',
      'col-sm-4 btn btn-radio': this.props.cardType !== 'credit'
    });
    let debitCardTileClass = classNames({
      'col-sm-4 btn btn-radio btn-active': this.props.cardType === 'debit',
      'col-sm-4 btn btn-radio': this.props.cardType !== 'debit'
    });
    let titleClass= classNames({
      'form-error-border':this.props.errorMessages.title,
      '': !this.props.errorMessages.title
    })
    let nameClass= classNames({
      'form-error-border':this.props.errorMessages.name,
      '': !this.props.errorMessages.name
    })
    let mobileNumberClass= classNames({
      'form-error-border':this.props.errorMessages.mobileNumber,
      '': !this.props.errorMessages.mobileNumber
    })
    let emailClass= classNames({
      'form-error-border':this.props.errorMessages.email,
      '': !this.props.errorMessages.email
    })
    let singleAddressLineClass= classNames({
      'form-error-border':this.props.errorMessages.singleAddressLine,
      '': !this.props.errorMessages.singleAddressLine
    })
    let streetAddressClass= classNames({
      'form-error-border':this.props.errorMessages.streetAddress || this.props.errorMessages.streetAddressFormat,
      '': !this.props.errorMessages.streetAddress
    })
    let suburbCityClass= classNames({
      'form-error-border':this.props.errorMessages.suburbCity,
      '': !this.props.errorMessages.suburbCity
    })
    let stateClass= classNames({
      'form-error-border':this.props.errorMessages.state,
      '': !this.props.errorMessages.state
    })
    let postcodeClass= classNames({
      'form-error-border':this.props.errorMessages.postcode,
      '': !this.props.errorMessages.postcode
    })
    let physicalSingleAddressLineClass= classNames({
      'form-error-border':this.props.errorMessages.physicalSingleAddressLine,
      '': !this.props.errorMessages.physicalSingleAddressLine
    })
    let physicalStreetAddressClass= classNames({
      'form-error-border':this.props.errorMessages.physicalStreetAddress || this.props.errorMessages.physicalStreetAddressFormat,
      '': !this.props.errorMessages.physicalStreetAddress
    })
    let physicalSuburbCityClass= classNames({
      'form-error-border':this.props.errorMessages.physicalSuburbCity,
      '': !this.props.errorMessages.physicalSuburbCity
    })
    let physicalStateClass= classNames({
      'form-error-border':this.props.errorMessages.physicalState,
      '': !this.props.errorMessages.physicalState
    })
    let physicalPostcodeClass= classNames({
      'form-error-border':this.props.errorMessages.physicalPostcode,
      '': !this.props.errorMessages.physicalPostcode
    })
    let roleClass= classNames({
      'form-error-border':this.props.errorMessages.role,
      '': !this.props.errorMessages.role
    })
    let abnClass= classNames({
      'form-error-border':this.props.errorMessages.abn,
      '': !this.props.errorMessages.abn
    })
    let abnManualClass= classNames({
      'form-error-border':this.props.errorMessages.abnManual,
      '': !this.props.errorMessages.abnManual
    })
    let legalEntityNameClass= classNames({
      'form-error-border':this.props.errorMessages.legalEntityName,
      '': !this.props.errorMessages.legalEntityName
    })
    let businessNameClass= classNames({
      'form-error-border':this.props.errorMessages.businessName,
      '': !this.props.errorMessages.businessName
    })
    let accountNumberClass= classNames({
      'form-error-border':this.props.errorMessages.accountNumber,
      '': !this.props.errorMessages.accountNumber
    })
    let accountNameClass= classNames({
      'form-error-border':this.props.errorMessages.accountName,
      '': !this.props.errorMessages.accountName
    })
    let businessTermsClass= classNames({
      'red-text body':this.props.errorMessages.businessTerms,
      'body': !this.props.errorMessages.businessTerms
    })
    let physicalAddressClass= classNames({
      'red-text body':this.props.errorMessages.physicalAddress,
      'body': !this.props.errorMessages.physicalAddress
    })
    let termsClass= classNames({
      'red-text':this.props.errorMessages.terms,
      '': !this.props.errorMessages.terms
    })

    let paymentSectionClass = classNames({
      'col-sm-12 margin-top-10': this.state.mobile === false,
      'col-sm-12 margin-top-10 no-padding': this.state.mobile
    })

    let businessCheckbox = classNames({
      'col-sm-6 col-sm-offset-4 body': this.state.mobile === false,
      'col-sm-6 col-sm-offset-4 no-padding body': this.state.mobile
    })

    let backToCartBtnClass = classNames({
      'btn btn-outline-20 float-right checkoutpage-btn':this.state.mobile === false,
      'btn btn-outline-20 float-right margin-left-10 checkoutpage-btn':this.state.mobile
    })

    const getCustomerFormValue = (field, defaultValue) => field === null ? defaultValue : field;

    return (
      <div className="row white-bg padding-20 no-margin">
        <form componentclass="fieldset">
          <div className="registration-h6">
            <i className="form-error-messages body-semibold">*</i> Mandatory field unless stated otherwise
          </div>
          <br/>
          <div className="row no-margin">
            <div className="col-sm-12 no-padding">
              <div className="row no-margin">
                <div className="col-xs-12 no-padding">
                  <div className="section-header checkout-h6">
                    <i className="far fa-address-card margin-right-5" aria-hidden="true"></i>
                    Account Details
                  </div>
                </div>
              </div>
              <AccountSelector
                onInputChange={this.props.onInputChange}
                customerDetails={this.props.customerDetails}
                errorMessages={this.props.errorMessages}
                accounts = {this.props.login.accounts}
                accountsLoader = {this.props.login.accountsLoader}
                onAccountSelectorChange = {this.props.onAccountSelectorChange}
                onManualAccountInputChange = {this.props.onManualAccountInputChange}
                onAccountTypeSelectorChange = {this.props.onAccountTypeSelectorChange}
              />
            </div>
          </div>
          <hr/>
          <div className="row no-margin">
            <div className="col-sm-12 no-padding">
              <div className="row no-margin">
                <div className="col-xs-12 no-padding">
                  <div className="section-header checkout-h6">
                    <i className="far fa-address-card margin-right-5" aria-hidden="true"></i>
                    Customer Details
                  </div>
                </div>
              </div>
              <FormGroup controlId="title" className="row">
                <Col sm={4}>
                  <ControlLabel className='body-semibold'>
                    Title
                    <i className="form-mandatory-fields">*</i>
                  </ControlLabel>
                </Col>
                <Col sm={6}>
                  <FormControl 
                    className={titleClass} 
                    componentClass="select" 
                    disabled = {this.props.disabled.title}
                    name="title" 
                    value={this.props.customerDetails.title} 
                    onChange={(e)=>{this.props.onInputChange(e)}}
                  >
                    <option value="">--Select Title--</option>
                    <option value="Mr.">Mr.</option>
                    <option value="Ms.">Ms.</option>
                    <option value="Mrs.">Mrs.</option>
                    <option value="Dr.">Dr.</option>
                    <option value="Prof.">Prof.</option>
                    <option value="Mx.">Mx.</option>
                  </FormControl>
                  {this.props.errorMessages.title && (
                    <div className="form-error-messages captions">Please select Title.</div>
                  )}
                </Col>
              </FormGroup>
              <FormGroup controlId="fullName" className="row">
                <Col sm={4}>
                  <ControlLabel className="body-semibold">
                    Full Name
                    <i className="form-mandatory-fields">*</i>
                  </ControlLabel>
                </Col>
                <Col sm={6}>
                  <FormControl type="text"
                    placeholder="John Smith"
                    name="name"
                    disabled = {this.props.disabled.name}
                    className={nameClass}
                    value={this.props.customerDetails.name || ""}
                    onChange={(e)=>{this.props.onInputChange(e)}}
                  />
                  {
                    this.props.errorMessages.name &&
                    <div className="form-error-messages captions">Please enter your first and last name.</div>
                  }
                </Col>
              </FormGroup>
              <FormGroup controlId="contactNumber" className="row">
                <Col sm={4}>
                  <ControlLabel className="body-semibold">
                    Contact Phone No.
                    <i className="form-mandatory-fields">*</i>
                  </ControlLabel>
                </Col>
                <Col sm={6}>
                  <FormControl type="text" className={mobileNumberClass} disabled = {this.props.disabled.mobileNumber}
                  placeholder="0200 000 000" name="mobileNumber" value={getCustomerFormValue(this.props.customerDetails.mobileNumber, '')} onChange={(e)=>{this.props.onInputChange(e)}}/>
                  {
                    this.props.errorMessages.mobileNumber &&
                    <div className="form-error-messages captions">Please enter your phone number.</div>
                  }
                </Col>
              </FormGroup>
              <FormGroup controlId="email" className="row">
                <Col sm={4}>
                  <ControlLabel className="body-semibold">
                    Email Address
                    <i className="form-mandatory-fields">*</i>
                  </ControlLabel>
                </Col>
                <Col sm={6}>
                  <FormControl type="email" placeholder="email@email.com" disabled = {this.props.disabled.email}
                  className={emailClass} name="email" value={getCustomerFormValue(this.props.customerDetails.email, '')} onChange={(e)=>{this.props.onInputChange(e)}}/>
                  {
                    this.props.errorMessages.email &&
                    <div className="form-error-messages captions">Please enter your email address.</div>
                  }
                </Col>
              </FormGroup>
              <FormGroup controlId="preferredContact" className="row">
                <Col sm={4}>
                  <ControlLabel>
                    <span className="margin-right-5 body-semibold title-column">
                      Preferred Contact
                      <i className="form-mandatory-fields">*</i>
                    </span>
                    <OverlayTrigger placement="top" overlay={tooltip} trigger={['click', 'hover', 'focus']}>
                      <span>
                        <i className="far fa-question-circle tooltip-icon-new tooltip-column" aria-hidden="true"></i>
                      </span>
                    </OverlayTrigger>
                  </ControlLabel>
                </Col>
                <Col sm={6}>
                    <FormControl componentClass="select"  name="preferredContact" value={getCustomerFormValue(this.props.customerDetails.preferredContact, 'email')} onChange={(e)=>{this.props.onInputChange(e)}}>
                      <option value="email">Email</option>
                      <option value="phone">Phone</option>
                    </FormControl>
                </Col>
              </FormGroup>
              {
                this.props.manualEntry === false &&
                <FormGroup controlId="mailingAddress" className="row">
                  <Col sm={4}>
                    <ControlLabel className="body-semibold">
                      Mailing Address
                      <i className="form-mandatory-fields">*</i>
                  </ControlLabel>
                  </Col>
                  <Col sm={6}>
                    <FormControl type="text" autoComplete="new-singleAddressLine" disabled = {this.props.disabled.singleAddressLine}
                      placeholder="Enter Address" className={singleAddressLineClass} name="singleAddressLine" value={getCustomerFormValue(this.props.customerDetails.singleAddressLine, '')}
                      onChange={(e)=>{this.props.onAddressInputChange(e)}} onFocus={()=>{this.props.onAddressFocus()}} />
                      {
                        this.props.errorMessages.singleAddressLine &&
                        <div className="form-error-messages captions">Please select your address or click 'Enter address manually'</div>
                      }
                      {
                        (!this.props.disabled.singleAddressLine && !this.props.forceAddressManualEntry()) &&
                        <p className="grey-link text-left no-padding" style={{margin:'5px 0 0 0'}} onClick={()=>{this.props.onAddressManualEntry()}}>Enter address manually</p>
                      }
                      {
                        this.props.addressOption && this.props.address && this.props.address.length>0 &&
                        <div className="custom-autocomplete text-left">
                          {
                            this.props.address.map(function(line, index){
                              return(
                                  <div key={line.propertyId} className="custom-autocomplete-row" onClick={()=>{this.props.onAddressSelection(line, "singleAddressLine")}}>
                                    {line.suggestion.replace(/\//g, ", ")}
                                  </div>
                              )
                            },this)
                          }
                        </div>
                      }
                  </Col>
                </FormGroup>
              }

              {
                this.props.manualEntry &&
                <div>
                  <FormGroup controlId="street" className="row">
                    <Col sm={4}>
                      <ControlLabel>
                          <span className="margin-right-5 body-semibold title-column">
                            Street Address
                            <i className="form-mandatory-fields">*</i>
                          </span>
                          <OverlayTrigger placement="top" overlay={tooltipStreetAddress} trigger={['click', 'hover', 'focus']}>
                            <span>
                              <i className="far fa-question-circle tooltip-icon-new tooltip-column" aria-hidden="true"></i>
                            </span>
                          </OverlayTrigger>
                        </ControlLabel>
                    </Col>
                    <Col sm={6}>
                      <FormControl
                        type="text"
                        name="streetAddress"
                        className={streetAddressClass}
                        value={getCustomerFormValue(this.props.customerDetails.streetAddress, '')}
                        onChange={(e)=>{this.props.onInputChange(e)}}/>
                      {
                        this.props.errorMessages.streetAddress &&
                        <div className="form-error-messages captions">Please enter your street address.</div>
                      }
                      {
                        this.props.errorMessages.streetAddressFormat &&
                        <div className="form-error-messages captions">Only special characters "," and "-" are accepted in Street Address.</div>
                      }
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="suburb" className="row">
                    <Col sm={4}>
                      <ControlLabel className="body-semibold">
                        Suburb
                        <i className="form-mandatory-fields">*</i>
                      </ControlLabel>
                    </Col>
                    <Col sm={6}>
                      <FormControl
                        type="text"
                        name="suburbCity"
                        className={suburbCityClass}
                        value={getCustomerFormValue(this.props.customerDetails.suburbCity, '')}
                        onChange={(e)=>{this.props.onInputChange(e)}}/>
                      {
                        this.props.errorMessages.suburbCity &&
                        <div className="form-error-messages captions">Please enter your suburb.</div>
                      }
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="state" className="row">
                    <Col sm={4}>
                      <ControlLabel className="body-semibold">
                        State
                        <i className="form-mandatory-fields">*</i>
                      </ControlLabel>
                    </Col>
                    <Col sm={6}>
                      <FormControl
                        type="text"
                        name="state"
                        className={stateClass}
                        value={getCustomerFormValue(this.props.customerDetails.state, '')}
                        onChange={(e)=>{this.props.onInputChange(e)}}/>
                      {
                        this.props.errorMessages.state &&
                        <div className="form-error-messages captions">Please enter your state.</div>
                      }
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="postcode" className="row">
                    <Col sm={4}>
                      <ControlLabel className="body-semibold">
                        Postcode
                        <i className="form-mandatory-fields">*</i>
                      </ControlLabel>
                    </Col>
                    <Col sm={6}>
                      <FormControl
                        type="text"
                        name="postcode"
                        className={postcodeClass}
                        value={getCustomerFormValue(this.props.customerDetails.postcode, '')}
                        onChange={(e)=>{this.props.onInputChange(e)}}/>
                      {
                        this.props.errorMessages.postcode &&
                        <div className="form-error-messages captions">Please enter your postcode.</div>
                      }
                      {
                        !this.props.forceAddressManualEntry() &&
                        <p className="grey-link text-left no-padding" style={{margin:'5px 0 0 0'}} onClick={()=>{this.props.onAddressCancelManualEntry()}}>Cancel manual address entry</p>
                      }
                    </Col>
                  </FormGroup>
                </div>
              }
              <FormGroup>
                <Col className={businessCheckbox}>
                  <Checkbox name="physicalAddress" checked={this.props.physicalAddressSelector} onClick={(e)=>{this.props.onInputChange(e)}}><span className={physicalAddressClass}>Physical Address Different?</span></Checkbox>
                </Col>
              </FormGroup>
              {
                this.props.physicalAddress && this.props.physicalManualEntry === false &&
                <FormGroup controlId="physicalMailingAddress" className="row">
                  <Col sm={4}>
                    <ControlLabel className="body-semibold">
                      Physical Address
                      <i className="form-mandatory-fields">*</i>
                    </ControlLabel>
                  </Col>
                  <Col sm={6}>
                    <FormControl type="text" autoComplete="new-singleAddressLine" 
                    placeholder="Enter Address" className={physicalSingleAddressLineClass} name="physicalSingleAddressLine" value={getCustomerFormValue(this.props.customerDetails.physicalSingleAddressLine, '')}
                      onChange={(e)=>{this.props.onAddressInputChange(e)}} onFocus={()=>{this.props.onPhysicalAddressFocus()}}/>
                      {
                        this.props.errorMessages.physicalSingleAddressLine &&
                        <div className="form-error-messages captions">Please select your physical address or click 'Enter physical address manually'</div>
                      }
                      {
                        (!this.props.disabled.singleAddressLine && !this.props.forcePhysicalAddressManualEntry()) &&         
                        <p className="grey-link text-left no-padding" style={{margin:'5px 0 0 0'}} onClick={()=>{this.props.onPhysicalAddressManualEntry()}}>Enter physical address manually</p>               
                      }
                      {
                        this.props.physicalAddressOption && this.props.address && this.props.address.length>0 &&
                        <div className="custom-autocomplete text-left">
                          {
                            this.props.address.map(function(line, index){
                              return(
                                  <div key={line.propertyId} className="custom-autocomplete-row" onClick={()=>{this.props.onAddressSelection(line, "physicalSingleAddressLine")}}>
                                    {line.suggestion.replace(/\//g, ", ")}
                                  </div>
                              )
                            },this)
                          }
                        </div>
                      }
                  </Col>
                </FormGroup>
              }

              {
                this.props.physicalAddress && this.props.physicalManualEntry &&
                <div>
                  <FormGroup controlId="physicalStreet" className="row">
                    <Col sm={4}>
                      <ControlLabel>
                        <span className="margin-right-5 body-semibold title-column">
                          Physical Address
                          <i className="form-mandatory-fields">*</i>
                        </span>
                        <OverlayTrigger placement="top" overlay={tooltipStreetAddress} trigger={['click', 'hover', 'focus']}>
                          <span>
                            <i className="far fa-question-circle tooltip-icon-new tooltip-column" aria-hidden="true"></i>
                          </span>
                        </OverlayTrigger>
                      </ControlLabel>
                    </Col>
                    <Col sm={6}>
                      <FormControl
                        type="text"
                        name="physicalStreetAddress"
                        className={physicalStreetAddressClass}
                        value={getCustomerFormValue(this.props.customerDetails.physicalStreetAddress, '')}
                        onChange={(e)=>{this.props.onInputChange(e)}}/>
                      {
                        this.props.errorMessages.physicalStreetAddress &&
                        <div className="form-error-messages captions">Please enter your physical street address.</div>
                      }
                      {
                        this.props.errorMessages.physicalStreetAddressFormat &&
                        <div className="form-error-messages captions">Only special characters "," and "-" are accepted in Physical Street Address.</div>
                      }
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="physicalSuburb" className="row">
                    <Col sm={4}>
                      <ControlLabel className="body-semibold">
                        Suburb
                        <i className="form-mandatory-fields">*</i>
                      </ControlLabel>
                    </Col>
                    <Col sm={6}>
                      <FormControl
                        type="text"
                        name="physicalSuburbCity"
                        className={physicalSuburbCityClass}
                        value={getCustomerFormValue(this.props.customerDetails.physicalSuburbCity, '')}
                        onChange={(e)=>{this.props.onInputChange(e)}}/>
                      {
                        this.props.errorMessages.physicalSuburbCity &&
                        <div className="form-error-messages captions">Please enter your suburb.</div>
                      }
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="physicalState" className="row">
                    <Col sm={4}>
                      <ControlLabel className="body-semibold">
                        State
                        <i className="form-mandatory-fields">*</i>
                      </ControlLabel>
                    </Col>
                    <Col sm={6}>
                      <FormControl
                        type="text"
                        name="physicalState"
                        className={physicalStateClass}
                        value={getCustomerFormValue(this.props.customerDetails.physicalState, '')}
                        onChange={(e)=>{this.props.onInputChange(e)}}/>
                      {
                        this.props.errorMessages.physicalState &&
                        <div className="form-error-messages captions">Please enter your state.</div>
                      }
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="physicalPostcode" className="row">
                    <Col sm={4}>
                      <ControlLabel className="body-semibold">
                        Postcode
                        <i className="form-mandatory-fields">*</i>
                      </ControlLabel>
                    </Col>
                    <Col sm={6}>
                      <FormControl
                        type="text"
                        name="physicalPostcode"
                        className={physicalPostcodeClass}
                        value={getCustomerFormValue(this.props.customerDetails.physicalPostcode, '')}
                        onChange={(e)=>{this.props.onInputChange(e)}}/>
                      {
                        this.props.errorMessages.physicalPostcode &&
                        <div className="form-error-messages captions">Please enter your postcode.</div>
                      }
                      {
                       !this.props.forcePhysicalAddressManualEntry() &&
                      <p className="grey-link text-left no-padding" style={{margin:'5px 0 0 0'}} onClick={()=>{this.props.onPhysicalAddressCancelManualEntry()}}>Cancel manual address entry</p>
                      }
                    </Col>
                  </FormGroup>
                </div>
              }
              {
                this.props.showBusinessOption &&
                <FormGroup controlId="preferredContact" className="row">
                  <Col sm={4}>
                    <ControlLabel>
                      <span className="margin-right-5 body-semibold">
                        Purchase Reason
                        <i className="form-mandatory-fields">*</i>
                      </span>
                    </ControlLabel>
                  </Col>
                  <Col sm={6}>
                      <FormControl
                        componentClass="select"
                        name="business"
                        value={this.props.business===true?"Business":"Personal"}
                        onChange={(e)=>{this.props.onChangeBusinessOption(e)}}>
                        <option value="Business">Business Use</option>
                        <option value="Personal">Personal Use</option>
                      </FormControl>
                  </Col>
                </FormGroup>
              }
              {
                this.props.business === false &&
                <IndustrySelector
                  onInputChange={this.props.onInputChange}
                  customerDetails={this.props.customerDetails}
                  errorMessages={this.props.errorMessages}
                />
              }

            </div>
          </div>
          <hr/>
          {
            this.props.business &&
            <div className="row no-margin">
              <div className="col-sm-12 no-padding">
                <div className="row no-margin">
                  <div className="col-xs-12 no-padding">
                    <div className="section-header checkout-h6">
                      <i className="far fa-building font-20 margin-right-5" aria-hidden="true"></i>
                      Business / Office
                    </div>
                  </div>
                </div>
                <FormGroup controlId="role" className="row">
                  <Col sm={4}>
                    <ControlLabel>
                      <span className="margin-right-5 body-semibold">
                        Position / Role
                        <i className="form-mandatory-fields">*</i>
                      </span>
                    </ControlLabel>
                  </Col>
                  <Col sm={6}>
                      <FormControl
                        className={roleClass}
                        name="role"
                        componentClass="select"
                        disabled = {this.props.disabled.role}
                        value={getCustomerFormValue(this.props.customerDetails.role, '')}
                        onChange={(e)=>{this.props.onInputChange(e)}}>
                        <option value="null">--Select Role--</option>
                        <option value="ACCOUNTANT">Accountant</option>
                        <option value="ACCOUNTS_PAYABLE_OFFICER">Accounts Payable Officer</option>
                        <option value="ADMINISTRATOR">Administrator</option>
                        <option value="BUSINESS_OWNER">Business Owner</option>
                        <option value="CONSULTANT">Consultant</option>
                        <option value="DIRECTOR">Director</option>
                        <option value="MANAGER">Manager</option>
                        <option value="OWNER">Owner</option>
                        <option value="STAFF">Staff</option>
                        <option value="USER">User</option>
                      </FormControl>
                      {
                        this.props.errorMessages.role &&
                        <div className="form-error-messages captions">Please select your position or role.</div>
                      }
                  </Col>
                </FormGroup>
                {
                  this.props.abnManualEntry === false &&
                  <FormGroup controlId="abn" className="row">
                    <Col sm={4}>
                      <ControlLabel className="body-semibold">
                        ABN / ACN
                        <i className="form-mandatory-fields">*</i>
                      </ControlLabel>
                    </Col>
                    <Col sm={6}>
                      <FormControl type="text" name="abn" className={abnClass} value={getCustomerFormValue(this.props.customerDetails.abn, '')} disabled = {this.props.disabled.abn}
                         onChange={(e)=>{this.props.onInputChange(e)}} onBlur={(e)=>{this.props.onAbnChange(e)}}/>
                         {
                           this.props.errorMessages.abn &&
                          <div className="form-error-messages captions">Please enter a valid ABN / ACN or click 'Enter business details manually'</div>
                        }
                        {
                          this.props.errorMessages.abnFormat &&
                          <div className="form-error-messages captions">ABN format: 12345678901 or 12 123 456 789.<br/> ACN format: 123456789 or 123 456 789</div>
                        }
                        {
                          this.props.errorMessages.abnInvalid &&
                          <div className="form-error-messages captions">Please enter a valid ABN / ACN or click 'Enter business details manually'</div>
                        }
                    </Col>
                  </FormGroup>
                }
                {
                  this.props.abnManualEntry &&
                  <div>
                  <FormGroup controlId="abnManual" className="row">
                    <Col sm={4}>
                      <ControlLabel className="body-semibold title-column">
                        Company Number
                        <i className="form-mandatory-fields">*</i>
                      </ControlLabel>
                        <OverlayTrigger placement="top" overlay={tooltipNZBN} trigger={['click', 'hover', 'focus']}>
                          <span>
                            <i className="far fa-question-circle tooltip-icon-new tooltip-column" aria-hidden="true"></i>
                          </span>
                        </OverlayTrigger>
                    </Col>
                    <Col sm={6}>
                      <FormControl type="text" name="abnManual" className={abnManualClass} disabled = {this.props.disabled.abnManual}
                        value={getCustomerFormValue(this.props.customerDetails.abn, '')} onChange={(e)=>{this.props.onInputChange(e)}}/>
                      {
                        this.props.errorMessages.abnManual &&
                        <div className="form-error-messages captions">Please enter your company number/NZBN.</div>
                      }
                      {
                        this.props.errorMessages.abnManualFormat &&
                        <div className="form-error-messages captions">Format: 1234567890123</div>
                      }
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="legalEntityName" className="row">
                    <Col sm={4}>
                      <ControlLabel className="body-semibold">
                        Company Name
                        <i className="form-mandatory-fields">*</i>
                      </ControlLabel>
                    </Col>
                    <Col sm={6}>
                      <FormControl type="text" name="legalEntityName" className={legalEntityNameClass} disabled = {this.props.disabled.legalEntityName}
                        value={getCustomerFormValue(this.props.customerDetails.legalEntityName, '')} onChange={(e)=>{this.props.onInputChange(e)}}/>
                      {
                        this.props.errorMessages.legalEntityName &&
                        <div className="form-error-messages captions">Please enter your company name.</div>
                      }
                    </Col>
                  </FormGroup>
                  <FormGroup controlId="businessName" className="row">
                    <Col sm={4}>
                      <ControlLabel className="body-semibold">
                        Trading Name
                        <i className="form-mandatory-fields">*</i>
                      </ControlLabel>
                    </Col>
                    <Col sm={6}>
                      <FormControl type="text" name="businessName" className={businessNameClass} disabled = {this.props.disabled.businessName}
                         value={getCustomerFormValue(this.props.customerDetails.businessName, '')} onChange={(e)=>{this.props.onInputChange(e)}}/>
                      {
                        this.props.errorMessages.businessName &&
                        <div className="form-error-messages captions">Please enter your trading name.</div>
                      }
                    </Col>
                  </FormGroup>
                </div>
                }
                {
                  this.props.business === true &&
                  <IndustrySelector
                    onInputChange={this.props.onInputChange}
                    customerDetails={this.props.customerDetails}
                    errorMessages={this.props.errorMessages}
                  />
                }

                <FormGroup>
                  <Col className={businessCheckbox}>
                    <Checkbox name="businessTerms" onClick={(e)=>{this.props.onInputChange(e)}}><span className={businessTermsClass}>I'm authorised to enter this contract on behalf of this business</span></Checkbox>
                  </Col>
                </FormGroup>
              </div>
            </div>
          }
          {
            this.props.business &&
            <hr/>
          }
          {
            this.props.totalPrice > 0 &&
            <div className="row no-margin">
              <div className="col-sm-12 no-padding">
                <div className="row no-margin">
                  <div className="section-header checkout-h6">
                    <i className="far fa-credit-card font-20 margin-right-5" aria-hidden="true"></i>
                    Confirm Order
                  </div>
                </div>
                <div className="row no-margin">
                  <div className={paymentSectionClass}>
                    {
                      this.props.isDirectDebit &&
                      <div className="row btn-group display-block no-margin" data-toggle="buttons">
                        <div className={creditCardTileClass}  onClick={()=>{this.props.onCardChange('credit')}} style={{padding:'15px 12px'}}>
                          <input type="radio"/>
                          <div>Credit Card</div>
                        </div>
                        <div className={debitCardTileClass} onClick={()=>{this.props.onCardChange('debit')}} style={{padding:'15px 12px'}}>
                          <input type="radio"/>
                          <div>Direct Debit</div>
                        </div>
                      </div>
                    }
                    <br/>
                    {
                      !this.props.isDirectDebit && 
                        <div className="col-sm-12 no-padding padding-bottom-10">
                          <div className="text-align-center">
                            <div className="section-header">
                              Payment Method: Credit Card
                            </div>
                          </div>
                        </div>
                    }
                    {
                      
                      this.props.cardType === 'debit' &&
                      <div className="row no-margin">
                        <div className="col-sm-12 no-padding">
                          <FormGroup controlId="accNumb" className="row">
                            <Col sm={4}>
                              <ControlLabel>
                                Account Number
                                <i className="form-mandatory-fields">*</i>
                              </ControlLabel>
                            </Col>
                            <Col sm={6}>
                              <FormControl type="text"  name="accountNumber" className={accountNumberClass}
                                 onChange={(e)=>{this.props.onDirectDebitInputChange(e)}}/>
                              {
                                this.props.errorMessages.accountNumber &&
                                <div className="form-error-messages">Please enter your account number.</div>
                              }
                            </Col>
                          </FormGroup>
                          <FormGroup controlId="accName" className="row">
                            <Col sm={4}>
                              <ControlLabel>
                                Account Name
                                <i className="form-mandatory-fields">*</i>
                              </ControlLabel>
                            </Col>
                            <Col sm={6}>
                              <FormControl type="text"  name="accountName" className={accountNameClass}
                                 onChange={(e)=>{this.props.onDirectDebitInputChange(e)}}/>
                              {
                                this.props.errorMessages.accountName &&
                                <div className="form-error-messages">Please enter your name.</div>
                              }
                            </Col>
                          </FormGroup>
                        </div>
                      </div>
                    }
                    {
                      this.props.conditionalTC === true && this.props.quotes !== undefined && this.props.quotes.length > 0 &&
                      <div>
                        {
                          this.props.quotes.map(function(item, index){
                            return(
                              <div key={item.headline}>
                                {
                                  item.contractMonths >= 6 &&
                                  <TncGeneral
                                    productType={item.type}
                                    conditionalTC={this.props.conditionalTC}
                                    headline={item.headline}
                                    totalMonths={item.userContractMonths}
                                    totalDays={item.contractMonths>=12?'90':'30'}
                                    noticeDays={item.type === "CordellEstimatorNZ" ? '90' : '30'}
                                  />
                                }
                                {
                                  item.contractMonths === 0  &&
                                  <TncTrial conditionalTC={this.props.conditionalTC} headline={item.headline}/>
                                }
                                {
                                  item.contractMonths >= 1  && item.contractMonths < 6 &&
                                  <TncOneMonth conditionalTC={this.props.conditionalTC} headline={item.headline} totalMonths={item.userContractMonths} totalDays='30'/>
                                }
                              </div>
                            )
                          },this)
                        }
                      </div>
                    }
                    {
                      this.props.conditionalTC === false && (this.props.userTotalMonths >= 6) && this.props.quotes !== undefined && this.props.quotes.length > 0 &&
                      <TncGeneral
                      productType={this.props.quotes[0]?.type}
                      totalMonths={this.props.userTotalMonths}
                      totalDays={this.props.totalDays}
                      noticeDays={this.props.quotes[0].type === "CordellEstimatorNZ" ? '90' : '30'}
                      />
                    }
                    {
                      this.props.conditionalTC === false && this.props.userTotalMonths >= 1 && this.props.userTotalMonths < 6 &&
                      <TncOneMonth totalMonths={this.props.userTotalMonths} totalDays='30'/>
                    }
                    <FormGroup className="row no-margin">
                      <Col sm={12}>
                        <Checkbox name="terms" onClick={(e)=>{this.props.onInputChange(e)}}><span className={termsClass}>I've read and accept the <a className="terms-link" href="https://www.corelogic.co.nz/sites/default/files/2018-06/2018-06--v150201_CLNZ%20Standard%20Terms%20and%20Conditions.pdf" target="_blank" rel="noopener noreferrer">terms and conditions</a></span></Checkbox>
                      </Col>
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
          }

          <hr/>
          {
            this.props.cardType==='credit' && this.props.totalPrice > 0 &&
            <div>
            <div className="row no-margin">
              { this.state.mobile===true &&
                <button type="button" id="view-order-summary-btn" className={'btn btn-outline-20 float-right checkoutpage-btn'} onClick={this.orderSummaryFocus} style={{padding:'5px 12px',marginLeft:'7px'}}>View Order Summary</button>
              }
              <button type="button"
                className={this.props.submitLoader ? 'btn btn-primary float-right disabled checkoutpage-btn' : 'btn btn-primary float-right checkoutpage-btn'}
                onClick={()=>{this.props.onSubmitCustomerDetails("credit")}}>
                Proceed to Payment
                {
                  (this.props.submitLoader) &&
                  <span>
                      <i className="fa fa-spinner fa-pulse fa-fw"></i>
                      <span className="sr-only">Loading...</span>
                    </span>
                }
              </button>
              <Button id="btn_cart" type="button" className={backToCartBtnClass} style={{padding:'5px 12px'}} onClick={()=>{this.props.backToCart()}}>
                Back to Cart
              </Button>

            </div>
            {
              this.props.errors &&
              <div>
                <ErrorMessages errorMessages={this.props.errorMessages}/>
              </div>
            }
            </div>
          }
          {
            this.props.cardType==='debit' && this.props.totalPrice > 0 &&
            <div>
            <div className="row no-margin">
              <button
                type="button"
                className={this.props.submitLoader ? "btn btn-primary float-right btn-20 disabled" : "btn btn-primary float-right btn-20"}
                onClick={()=>{this.props.onSubmitCustomerDetails("debit")}}>
                Place Order
                {
                  this.props.submitLoader &&
                  <span>
                      <i className="fa fa-spinner fa-pulse fa-fw"></i>
                      <span className="sr-only">Loading...</span>
                    </span>
                }
              </button>
              <Button id="btn_cart" className={backToCartBtnClass} style={{padding:'5px 12px'}} onClick={()=>{this.props.backToCart()}}>
                Back to Cart
              </Button>
            </div>
            {
              this.props.errors &&
              <ErrorMessages errorMessages={this.props.errorMessages}/>
            }
          </div>
          }
        </form>
      </div>
    )
  }
}
