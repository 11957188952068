export default class ProductItemsService {

  getItemsCount() {
    let cartItemsCount = 0;

    if(this.props.cart && this.props.cart.cartItems){
      cartItemsCount = this.props.cart.cartItems.length
    }else if(this.props.order && this.props.order.orderItems){
      cartItemsCount = this.props.order.orderItems.length
    }
    return cartItemsCount;
  }

}
