import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import RPNZProductPackageConfiguration from './components/products/rpnz/rpnzProductPackageConfiguration';
import GuruProductPackageConfiguration from './components/products/guru/GuruProductPackageConfiguration';
import CordellEstimatorProductPackageConfiguration from './components/products/cordellEstimator/cordellEstimatorProductPackageConfiguration';
import ProductConfigSummary from './components/products/productConfigSummary';

import history from '../history';

import { fetchQuote } from '../actions/packageConfiguration';
import { saveCartItem, deleteOrder, getUserSession, checkout } from '../actions/cart';
import { getContactInfo, getContactUsername } from '../actions/checkout';

import { verifyEmailValidated } from '../services/v2/customerRegistration/registrationService';

// Services
// import { setCustomerDetails } from '../services/OrderService';

import {
  analyticsPageView,
  trackAddToCart,
  trackGenericPageInfo
} from '../services/v2/segmentAnalytics';

const PackageConfigurationPage = (props) => {
  const [friendlyName, setFriendlyName] = useState(null);

  const product = useSelector((state) => state.packageSelection);
  const coupon = useSelector((state) => state.packageSelection.coupon);

  const productQuote = useSelector((state) => state.packageConfiguration);
  const quoteLoading = useSelector((state) => state.packageConfiguration.loading);

  const cart = useSelector((state) => state.cart.cart);
  const order = useSelector((state) => state.cart.order);
  const loading = useSelector((state) => state.cart.loading);
  const loggedIn = useSelector((state) => state.cart.loggedIn);
  const checkoutErrors = useSelector((state) => state.cart.checkoutErrors);
  const [prevCheckoutErrors, setPrevCheckoutErrors] = useState(checkoutErrors);

  const contactInfo = useSelector((state) => state.checkout.contactInfo);
  const contactUsernameDetails = useSelector((state) => state.checkout.contactUsernameDetails);
  const registration = useSelector((state) => state.registration);

  const PRODUCTS_CONFIG = product.productsConfig[friendlyName];
  const PRODUCT_ID = parseInt(props.match.params.product_id);

  const dispatch = useDispatch();

  const setConfigurationBlob = (product_id = null, products_config = null) => {
    let id = product_id ? product_id : PRODUCT_ID;
    let config = products_config ? products_config : PRODUCTS_CONFIG;
    let quoteConfig = config.quoteConfigurationTemplate.configuration;
    // Checks if the config for the quotes persists and sets them accordingly
    if (
      (!quoteConfig.packageName && config.quoteServiceType === 'RPNZ') ||
      (!quoteConfig.packageName && config.quoteServiceType === 'CordellEstimatorNZ')
    ) {
      return productQuote.quotesConfigById[id];
    } else {
      return config.quoteConfigurationTemplate;
    }
  };

  const onCheckout = () => {
    dispatch(getUserSession()).then(function () {
      if (loggedIn) {
        setCustomerDetails();
      } else {
        history.push('/register');
      }
    });
  };

  const setCategory = (categoryKey) => {
    let configurationBlob = setConfigurationBlob();
    configurationBlob.configuration.category[categoryKey] =
      !configurationBlob.configuration.category[categoryKey];
    configurationBlob.product_id = PRODUCT_ID;
    dispatch(fetchQuote(configurationBlob, PRODUCT_ID));
  };

  const onSelectingUser = (user) => {
    let configurationBlob = setConfigurationBlob();
    if (configurationBlob.configuration.number_of_users !== undefined) {
      configurationBlob.configuration.number_of_users = user;
    } else if (configurationBlob.configuration.numberOfUsers !== undefined) {
      configurationBlob.configuration.numberOfUsers = user;
    }
    configurationBlob.product_id = PRODUCT_ID;
    dispatch(fetchQuote(configurationBlob, PRODUCT_ID));
  };

  const onRemoveAddon = (addon) => {
    let configurationBlob = setConfigurationBlob();
    let index = configurationBlob.configuration.addons.indexOf(addon);
    if (index > -1) {
      configurationBlob.configuration.addons.splice(index, 1);
    }
    configurationBlob.product_id = PRODUCT_ID;
    dispatch(fetchQuote(configurationBlob, PRODUCT_ID));
  };

  const trackAddItemToCartAnalytics = (action, quote) => {
    let cta = action === 'buy' ? 'Buy it Now' : 'Add to Cart';
    trackAddToCart(cta, quote);
  };

  const onAddToCart = (action) => {
    let configurationBlob = setConfigurationBlob();
    // let productId = productId;

    //Segment Analytics
    trackAddItemToCartAnalytics(action, productQuote.quoteById[PRODUCT_ID]);

    let cartItem = {
      configuration: configurationBlob.configuration,
      product_id: PRODUCT_ID
    };

    if (order != null && Object.keys(order).length > 0) {
      dispatch(deleteOrder(cartItem)).then(function () {
        dispatch(saveCartItem(cartItem, action));
      });
    } else {
      dispatch(saveCartItem(cartItem, action)).then(function () {
        if (action === 'buy') {
          onCheckout();
        }
      });
    }
  };

  const changePackage = () => {
    trackGenericPageInfo('Back to package selection page');
    history.push(`/product/${friendlyName}`);
  };

  //temporary function from service
  const setCustomerDetails = () => {
    dispatch(getContactInfo()).then(function () {
      let products = cart.cartItems;
      // let contactInfo = contactInfo;
      let customer = {
        industry: 'NONE'
      };
      if (contactInfo) {
        if (contactInfo.emailAddress) {
          customer.email = contactInfo.emailAddress;
        }
        if (contactInfo.firstName) {
          customer.name = `${contactInfo.firstName} `;
        }
        if (contactInfo.lastName) {
          customer.name += contactInfo.lastName;
        }
        if (contactInfo.mobileNumber) {
          customer.mobileNumber = contactInfo.mobileNumber;
        }

        if (customer.email) {
          dispatch(getContactUsername(customer.email)).then(function () {
            // let contactUsernameDetails = contactUsernameDetails;
            if (contactUsernameDetails.content && contactUsernameDetails.content.length > 0) {
              customer.username = contactUsernameDetails.content[0].username
                ? contactUsernameDetails.content[0].username
                : '';
            }

            customer.claudId = registration.emailValidation.guid;

            const newOrder = {
              processStatus: 'AUTO',
              currency: 'NZD',
              customer: customer,
              quoteConfiguration: {
                products: products
              },
              transactionType: 'PRE_AUTH'
            };
            dispatch(checkout(newOrder));
          });
        }
      }
    });
  };

  useEffect(() => {
    analyticsPageView('Customisation');
    window.scrollTo(0, 0);
    if (PRODUCT_ID) {
      let newFriendlyName = null;
      for (let config in product.productsConfig) {
        if (product.productsConfig[config].id === PRODUCT_ID) {
          newFriendlyName = product.productsConfig[config].productUrl;
        }
      }
      setFriendlyName(newFriendlyName);
    }
  }, [product, PRODUCT_ID]);

  useEffect(() => {
    if (friendlyName) {
      if (product.productsConfig[friendlyName]) {
        let configurationBlob = setConfigurationBlob(
          PRODUCT_ID,
          product.productsConfig[friendlyName]
        );
        configurationBlob.product_id = PRODUCT_ID;
        if (configurationBlob.configuration.number_of_users !== undefined) {
          configurationBlob.configuration.number_of_users =
            configurationBlob.configuration.number_of_users !== null
              ? configurationBlob.configuration.number_of_users
              : 1;
        } else if (configurationBlob.configuration.numberOfUsers !== undefined) {
          configurationBlob.configuration.numberOfUsers =
            configurationBlob.configuration.numberOfUsers !== null
              ? configurationBlob.configuration.numberOfUsers
              : 1;
        }
        dispatch(fetchQuote(configurationBlob, PRODUCT_ID));
      } else {
        history.push('/cart');
      }
    }
  }, [friendlyName]);

  useEffect(() => {
    setPrevCheckoutErrors(checkoutErrors);

    const options = {
      history: history,
      prevCheckoutErrors: prevCheckoutErrors,
      checkoutErrors: checkoutErrors
    }
    verifyEmailValidated(options);
  }, [checkoutErrors]);

  return (
    <div className='off-white-bg'>
      <div className='container'>
        {PRODUCTS_CONFIG && (
          <div className='row row-eq-height margin-bottom-100'>
            <div className='col-sm-8'>
              {PRODUCTS_CONFIG.quoteServiceType === 'RPNZ' && (
                <RPNZProductPackageConfiguration
                  onSelectingUser={onSelectingUser}
                  productPackageConfig={PRODUCTS_CONFIG.configuration}
                  productPackageDefaultConfig={setConfigurationBlob()}
                />
              )}
              {PRODUCTS_CONFIG.quoteServiceType === 'Guru' && (
                <GuruProductPackageConfiguration
                  onSelectingUser={onSelectingUser}
                  productPackageConfig={PRODUCTS_CONFIG.configuration}
                  productPackageDefaultConfig={setConfigurationBlob()}
                  selectedPackageKey={
                    setConfigurationBlob().configuration.packageName
                      ? setConfigurationBlob().configuration.packageName
                      : setConfigurationBlob().configuration.package
                  }
                />
              )}
              {PRODUCTS_CONFIG.quoteServiceType === 'CordellEstimatorNZ' && (
                <CordellEstimatorProductPackageConfiguration
                  onSelectingUser={onSelectingUser}
                  packageKey={setConfigurationBlob().configuration.packageName}
                  productPackageConfig={PRODUCTS_CONFIG.configuration}
                  productPackageDefaultConfig={setConfigurationBlob()}
                  setCategory={setCategory}
                />
              )}
            </div>
            <div className='col-sm-4'>
              <ProductConfigSummary
                cart={cart}
                changePackage={changePackage}
                coupon={coupon}
                loading={loading}
                onAddToCart={onAddToCart}
                onRemoveAddon={onRemoveAddon}
                packageKey={
                  setConfigurationBlob().configuration.packageName
                    ? setConfigurationBlob().configuration.packageName
                    : setConfigurationBlob().configuration.package
                }
                productQuote={productQuote.quoteById[PRODUCT_ID]}
                quoteLoading={quoteLoading}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PackageConfigurationPage;
