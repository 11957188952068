import {
  REDIRECT_TO_PAYMENT_PORTAL_FAILURE,
  REDIRECT_TO_PAYMENT_PORTAL_REQUEST,
  REDIRECT_TO_PAYMENT_PORTAL_SUCCESS,
  FETCH_PAYMENT_DETAILS_REQUEST,
  FETCH_PAYMENT_DETAILS_SUCCESS,
  FETCH_PAYMENT_DETAILS_FAILURE,
  DIRECT_DEBIT_PAYMENT_REQUEST,
  DIRECT_DEBIT_PAYMENT_SUCCESS,
  DIRECT_DEBIT_PAYMENT_FAILURE,
  TRIAL_PURCHASE_REQUEST,
  TRIAL_PURCHASE_SUCCESS,
  TRIAL_PURCHASE_FAILURE,
  FETCH_ADDRESS_LINES_REQUEST,
  FETCH_ADDRESS_LINES_SUCCESS,
  FETCH_ADDRESS_LINES_FAILURE,
  RESET_ADDRESS_LOOKUP_PROPS,
  FETCH_ABN_DETAILS_FAILURE,
  FETCH_ABN_DETAILS_REQUEST,
  FETCH_ABN_DETAILS_SUCCESS,
  RESET_ABN_LOOKUP_PROPS,
  SET_CUSTOMER_DETAILS,
  GET_ROLES_REQUEST,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAILURE,
  GET_CONTACT_INFO_REQUEST,
  GET_CONTACT_INFO_SUCCESS,
  GET_CONTACT_INFO_FAILURE,
  GET_CONTACT_USERNAME_REQUEST,
  GET_CONTACT_USERNAME_SUCCESS,
  GET_CONTACT_USERNAME_FAILURE,
  GET_ACCOUNT_INFO_REQUEST,
  GET_ACCOUNT_INFO_SUCCESS,
  GET_ACCOUNT_INFO_FAILURE,
  SAVE_CUSTOMER_DETAILS_REQUEST,
  SAVE_CUSTOMER_DETAILS_SUCCESS,
  SAVE_CUSTOMER_DETAILS_FAILURE,
  GET_PROPERTY_DETAILS_REQUEST,
  GET_PROPERTY_DETAILS_SUCCESS,
  GET_PROPERTY_DETAILS_FAILURE,
  RESET_CHECKOUT_LOADERS,
  VALIDATE_ACCOUNT_REFERENCE_NUMBER_REQUEST,
  VALIDATE_ACCOUNT_REFERENCE_NUMBER_SUCCESS,
  VALIDATE_ACCOUNT_REFERENCE_NUMBER_FAILURE
} from '../constants';

const initialProductState = {
  payment: {},
  directDebit: {},
  trial: {},
  address: [],
  addressError: false,
  abn: {},
  abnError: false,
  coupon: {},
  paymentLoader: false,
  customer: {
    firstName: '',
    lastName: '',
    emailAddress: '',
    username: '',
    password: '',
    confirmPassword: ''
  },
  contactUsernameDetails: {},
  contactInfo: {},
  accountsLoader: false,
  accountDetails: {},
  propertyDetails: {},
  submitLoader: false
};

export default (state = initialProductState, action = {}) => {
  //This is to ensure immutability of state
  let newState = { ...state };

  switch (action.type) {
    case SAVE_CUSTOMER_DETAILS_REQUEST:
      newState.customerLoader = true;
      newState.submitLoader = true;
      return newState;
    case SAVE_CUSTOMER_DETAILS_SUCCESS:
      newState.customerLoader = false;
      return newState;
    case SAVE_CUSTOMER_DETAILS_FAILURE:
      newState.customerLoader = false;
      newState.submitLoader = false;
      return newState;
    case RESET_CHECKOUT_LOADERS:
      newState.contactsLoader = false;
      newState.paymentLoader = false;
      newState.submitLoader = false;
      return newState;
    case REDIRECT_TO_PAYMENT_PORTAL_REQUEST:
      newState.paymentLoader = true;
      newState.submitLoader = true;
      return newState;
    case REDIRECT_TO_PAYMENT_PORTAL_FAILURE:
      newState.submitLoader = false;
      newState.paymentLoader = false;
      return newState;
    case FETCH_PAYMENT_DETAILS_REQUEST:
      return newState;
    case FETCH_PAYMENT_DETAILS_SUCCESS:
      newState.paymentLoader = false;
      newState.payment = action.body;
      return newState;
    case DIRECT_DEBIT_PAYMENT_REQUEST:
      newState.paymentLoader = true;
      newState.submitLoader = true;
      newState.trial = {};
      return newState;
    case DIRECT_DEBIT_PAYMENT_SUCCESS:
      newState.paymentLoader = false;
      newState.directDebit = action.body;
      return newState;
    case DIRECT_DEBIT_PAYMENT_FAILURE:
      newState.paymentLoader = false;
      newState.submitLoader = false;
      return newState;
    case TRIAL_PURCHASE_REQUEST:
      newState.paymentLoader = true;
      newState.directDebit = {};
      return newState;
    case TRIAL_PURCHASE_SUCCESS:
      newState.paymentLoader = false;
      newState.trial = action.body;
      return newState;
    case TRIAL_PURCHASE_FAILURE:
      newState.paymentLoader = false;
      return newState;
    case FETCH_ADDRESS_LINES_REQUEST:
      newState.address = [];
      newState.addressError = false;
      newState.errorMessages = [];
      return newState;
    case FETCH_ADDRESS_LINES_SUCCESS:
      newState.address = action.body;
      return newState;
    case FETCH_ADDRESS_LINES_FAILURE:
      newState.address = [];
      newState.addressError = true;
      return newState;
    case RESET_ADDRESS_LOOKUP_PROPS:
      newState.addressError = false;
      newState.address = [];
      return newState;
    case FETCH_ABN_DETAILS_REQUEST:
      newState.abn = {};
      newState.abnError = false;
      newState.errorMessages = [];
      return newState;
    case FETCH_ABN_DETAILS_SUCCESS:
      newState.abnError = false;
      newState.abn = action.body;
      return newState;
    case FETCH_ABN_DETAILS_FAILURE:
      newState.abn = {};
      newState.abnError = true;
      return newState;
    case RESET_ABN_LOOKUP_PROPS:
      newState.abnError = false;
      newState.abn = {};
      return newState;
    case SET_CUSTOMER_DETAILS:
      newState.customer = action.data;
      return newState;
    case GET_ROLES_REQUEST:
      newState.roles = [];
      newState.rolesLoader = true;
      return newState;
    case GET_ROLES_SUCCESS:
      newState.roles = action.data;
      newState.rolesLoader = false;
      return newState;
    case GET_ROLES_FAILURE:
      newState.roles = [];
      newState.rolesLoader = false;
      return newState;
    case GET_CONTACT_INFO_REQUEST:
      newState.contactsLoader = true;
      return newState;
    case GET_CONTACT_INFO_SUCCESS:
      newState.contactInfo = action.data;
      newState.contactsLoader = false;
      return newState;
    case GET_CONTACT_INFO_FAILURE:
      newState.contactsLoader = false;
      return newState;
    case GET_CONTACT_USERNAME_REQUEST:
      newState.contactsLoader = true;
      newState.submitLoader = true;
      return newState;
    case GET_CONTACT_USERNAME_SUCCESS:
      newState.contactUsernameDetails = action.data;
      newState.contactsLoader = false;
      return newState;
    case GET_CONTACT_USERNAME_FAILURE:
      newState.contactsLoader = false;
      newState.submitLoader = false;
      return newState;
    case GET_ACCOUNT_INFO_REQUEST:
      newState.accountsLoader = true;
      return newState;
    case GET_ACCOUNT_INFO_SUCCESS:
      newState.accountDetails = action.data;
      newState.accountsLoader = false;
      return newState;
    case GET_ACCOUNT_INFO_FAILURE:
      newState.accountsLoader = false;
      return newState;
    case GET_PROPERTY_DETAILS_SUCCESS:
      newState.addressError = false;
      newState.propertyDetails = action.body;
      return newState;
    case VALIDATE_ACCOUNT_REFERENCE_NUMBER_SUCCESS:
      newState.isValidAccountReferenceNumber = action.data;
      return newState;
    case FETCH_PAYMENT_DETAILS_FAILURE:
    case GET_PROPERTY_DETAILS_REQUEST:
      newState.propertyDetails = {};
      newState.addressError = false;
      newState.errorMessages = [];
      return newState;
    case GET_PROPERTY_DETAILS_FAILURE:
      newState.propertyDetails = {};
      newState.addressError = true;
      return newState;
    case REDIRECT_TO_PAYMENT_PORTAL_SUCCESS:
    case VALIDATE_ACCOUNT_REFERENCE_NUMBER_REQUEST:
    case VALIDATE_ACCOUNT_REFERENCE_NUMBER_FAILURE:
      return newState;
    default:
      return state;
  }
};
