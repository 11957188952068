import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import PropTypes from 'prop-types';

import tick from '../../../../image/Icons/tick-ef4400.svg';
import clLogo from '../../../../image/CL_Logo_RGB.png';

import './PackageCard.css'

/**
 * PackageCard.
 * Component for handling product package selection.
 *
 * @author  Manny
 * @param   {String}      props.method            Optional industry params.
 * @param   {Function}    props.onSelectPackage   Handles onClick for selecting a package.
 * @param   {Number}      props.packageIndex      Number of package index.
 * @param   {String}      props.selectedIndustry  Name of selected industry.
 * @param   {Object}      props.singlePackage     Name of selected package.
 * @param   {Boolean}     props.isMobile          Determines if tables are for mobile format.
 * @returns {JSX.Element} The component markup.
 */

const PackageCard = ({
  method,
  onSelectPackage,
  packageIndex,
  selectedIndustry,
  singlePackage,
  isMobile
}) => {
  const isPremium = singlePackage.key === 'premium';
  let classNames = require('classnames');
  let colSize = classNames({
    'col-sm-3 no-padding card-column': !isMobile,
    'col-sm-4 no-right-padding package-col-mobile mobile-card-column': isMobile
  });

  return (
    <div id={isPremium ? "best-value-column" : ""} className={colSize} key={packageIndex}>
      <div id={isPremium ? "best-value-card" : ""} className={`panel-container package-card ${isPremium ? "best-value-card" : ""}`}>
        {isPremium && <div className='best-value-tag captions'><b>BEST VALUE</b></div>}
        <p
          id={`package_title_${singlePackage.key}`}
          className='no-margin package-card-title'
        >
          {singlePackage.title}
        </p>
        <p className="package-blurb">
          {singlePackage.blurb}
        </p>
        <p className='package-selection-h4 no-margin'>
          <span className='superscript captions' style={{margin: "0" }}>FROM</span>
          <br/>
          <span><b style={{fontWeight: 700, fontSize: "28px", lineHeight: "28px"}}>${singlePackage.base_price.toFixed(2)}</b></span>
          <span className='captions'>/MTH</span>
        </p>
        <p className='sub-text padding-top-5 footnote-text' style={{color: "#a5a4a7", margin:'0px'}}>{singlePackage.sub_text_1}</p>
        <p className='sub-text margin-bottom-10 footnote-text'style={{color: "#a5a4a7"}}>{singlePackage.sub_text_2}</p>
        <button
          id={`select_package_btn_${singlePackage.key}`}
          type='button'
          className={`btn full-width no-margin ${isPremium ? "best-value-btn" : "btn-primary"}`}
          onClick={() => {
            onSelectPackage(selectedIndustry, singlePackage.key);
          }}
        >
          Select Package
        </button>
        <br/>
        {singlePackage?.features?.filteredFeatures && (
          <div className='features-list'>
            {singlePackage.features.filteredFeatures.map((feature, featureKey) => {
              if (feature) {
                let tooltip = <Tooltip id='tooltip'>
                                <strong>{feature.descriptionHeader && <>{feature.descriptionHeader}<br/></>}</strong>
                                {feature.description}
                              </Tooltip>;
                return (
                  <div className='features-container body' key={featureKey}>
                    <div>
                      <img src={tick} alt='yes' className='margin-right-5' />
                    </div>
                    <div className='column title-column'>{feature.title}</div>
                    <div className="column tooltip-column text-right">
                      {feature.description && (
                        <OverlayTrigger
                          placement='top'
                          overlay={tooltip}
                          trigger={['click', 'hover', 'focus']}
                        >
                          <span style={{
                            alignItems: "center",
                            border: "1px solid #79848b",
                            borderRadius: "50%",
                            cursor: "pointer",
                            display: "flex",
                            fontFamily: "Source Sans Pro, sans-serif",
                            fontSize: "13px",
                            height: "15px",
                            justifyContent: "center",
                            marginTop: "5px",
                            width: "15px"
                          }}>
                            i
                          </span>
                        </OverlayTrigger>
                      )}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        )}
      </div>
    </div>
  );
};

PackageCard.propTypes = {
  method: PropTypes.string,
  onSelectPackage: PropTypes.func,
  packageIndex: PropTypes.number.isRequired,
  selectedIndustry: PropTypes.string.isRequired,
  singlePackage: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired
};

export default PackageCard;
