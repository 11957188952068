import React from 'react';
import ObjectRepeat from './objectRepeat';
import PropTypes from 'prop-types';

/**
 * Banner
 *
 * @author  Miguel
 * @param   {Object}      props.industryKey          Industry Key.
 * @param   {Object}      props.industryPackage      Product Config Packages.
 * @param   {Function}    props.onSelectPackage      React onSelectPackage.
 * @param   {String}      props.product              Product Name as String.
 * @returns {JSX.Element}                                   The component markup.
 */

const Banner = ({ industryKey, industryPackage, onSelectPackage, product }) => {
  return (
    <div className='feature-wrapper'>
      <div id='rp-features-table' className='row rpp-features'>
        <div className='col-sm-12' style={{paddingLeft:'10px',paddingRight:'10px'}}>
          <div className='row feature-card'>
            <ObjectRepeat
              industryKey={industryKey}
              onSelectPackage={onSelectPackage}
              packages={industryPackage}
              product={product}
            />
          </div>
          <div className='row margin-top-20'>
          </div>
        </div>
      </div>
    </div>
  );
};

Banner.propTypes = {
  industryKey: PropTypes.object,
  industryPackage: PropTypes.object.isRequired,
  onSelectPackage: PropTypes.func.isRequired,
  product: PropTypes.string.isRequired
};

export default Banner;
