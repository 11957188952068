import React from "react";

//IMAGES
import CorelogicLogo from '../../../image/CorelogicLogo-footer.svg'

import './footer.css'

class Footer extends React.Component{

  constructor(props){
    super(props);

    //SERVICES
    this.trackCompanyInfo = this.props.SegmentAnalytics.trackCompanyInfo.bind(this)
  }

  redirectToURL(link, pageName) {
    this.trackCompanyInfo(pageName);
    window.open(link, "_blank", "noopener noreferrer");
  }

  render(){
    return (
      <footer className="footer" id="footer">
        <div className="container padding-30-0">
        <div className="footer-row row">
          <div id="footer-container" className="footer-column">
            <div className="row footer-terms margin-10">
              <p className="no-margin footer-links">
                &copy; 2022. CoreLogic NZ Limited. <br className="line-break-768"/> All rights reserved.<br className="line-break-768" />
                <br className="line-break-992 line-break-574" />
                
              </p>
              <p className="no-margin footer-links">
                <a
                  className="footer-link"
                  id="privacy-policy"
                  onClick={() => {this.redirectToURL("https://www.corelogic.co.nz/legals/privacy-policy", "Privacy Policy")}}
                >
                  Privacy Policy
                </a>
                <br className="line-break-464" />
                <span className="divider-464">|</span>
                <a
                  className="footer-link"
                  id="product-terms"
                  onClick={() => {this.redirectToURL("https://www.corelogic.co.nz/legals/product-terms-and-conditions-nz", "Product Terms & Conditions")}}
                >
                  Product Terms & Conditions
                </a>
                <br className="line-break-768 line-break-464 line-break-465 " />
                <span className="divider-768 divider-464 divider-465">|</span>
                <a
                  className="footer-link"
                  id="website-terms"
                  onClick={() => {this.redirectToURL("https://www.corelogic.co.nz/legals/website-terms-of-use-nz", "Website Terms of Use")}}
                >
                  Website Terms of Use
                </a>
                <br className="line-break-992  line-break-618 line-break-464" />
                <span className="divider-992  divider-618 divider-464">|</span>
                <a
                  className="footer-link"
                  id="data-code-of-conduct"
                  onClick={() => {this.redirectToURL("https://www.propertydatacodeofconduct.com.au/ttsvr/n/qvas-195", "Data Code of Conduct")}}
                >
                  Data Code of Conduct
                </a>
                <br className="line-break-768 line-break-465 line-break-464" />
                <span className="divider-768 divider-465 divider-464">|</span>
                <a
                  className="footer-link"
                  id="disclaimer"
                  onClick={() => {this.redirectToURL("https://www.corelogic.co.nz/legals/disclaimers-nz", "Disclaimer")}}
                >
                  Disclaimer
                </a>
                <br className="line-break-464 line-break-464" />
                <span className="divider-464">|</span>
                <a
                  className="footer-link"
                  id="sitemap"
                  onClick={() => {this.redirectToURL("https://www.corelogic.co.nz/sitemap", "Sitemap")}}
                >
                  Sitemap
                </a>
              </p>
            </div>
          </div>
          <div id="footer-logo-column" className="footer-column">
            <img src={CorelogicLogo} alt="Core Logic" id="footer-logo"/>
          </div>
        </div>
      </div>
      </footer>
    )
  }
}

export default Footer;
