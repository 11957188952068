export default class OrderService {

    setCustomerDetails() {
      
      this.props.getContactInfo().then(function () {
        let products = this.props.cart.cartItems
        let contactInfo = this.props.contactInfo
        let customer = {
          industry: "NONE"
        }
        if (contactInfo) {
          if (contactInfo.emailAddress) {
            customer.email = contactInfo.emailAddress
          }
          if (contactInfo.firstName) {
            customer.name = contactInfo.firstName + " "
          }
          if (contactInfo.lastName) {
            customer.name += contactInfo.lastName
          }
          if(contactInfo.mobileNumber){
            customer.mobileNumber = contactInfo.mobileNumber
          }
  
          if (customer.email) {
            this.props.getContactUsername(customer.email).then(function () {
              let contactUsernameDetails = this.props.contactUsernameDetails
              if(contactUsernameDetails.content && contactUsernameDetails.content.length > 0){
                customer.username = contactUsernameDetails.content[0].username ? contactUsernameDetails.content[0].username : ''
              }
    
              customer.claudId = this.props.registration.emailValidation.guid

              let order = {
                processStatus: "AUTO",
                currency: "NZD",
                customer: customer,
                quoteConfiguration: {
                  products: products
                },
                transactionType: "PRE_AUTH"
              }
              this.props.checkout(order)
            }.bind(this))
          }
        }
      }.bind(this))
    }
  
  
  }