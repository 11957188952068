import React, { useState } from 'react';
import PropTypes from 'prop-types';

import history from '../../../../history';

import { PanelGroup, Panel } from 'react-bootstrap';

import UsersActive from '../../../../image/Icons/Users-active.svg';
import Users from '../../users';

/**
 * GuruProductPackageConfiguration.
 *
 * @author  Linton
 * @param   {Function}    props.onSelectingUser              Function to call on selecting users.
 * @param   {Object}      props.productPackageConfig         Product Package Object.
 * @param   {Object}      props.productPackageDefaultConfig  Product Package Default Object.
 * @param   {String}      props.selectedPackageKey           Selected package key
 * @returns {JSX.Element}                                    The component markup.
 */

const GuruProductPackageConfiguration = ({
  onSelectingUser,
  productPackageConfig,
  productPackageDefaultConfig,
  selectedPackageKey
}) => {
  const [activeKey, setActiveKey] = useState('1');
  const number_of_users = 1;

  const handleSelect = (newActiveKey) => {
    setActiveKey(newActiveKey);
  };

  const onUserClickAction = () => {
    setActiveKey('2');
  };

  const customizedBack = () => {
    const previousUrl = document.referrer;
    if (previousUrl.includes('package')){
      history.go(-3)
    } else {
      history.go(-1);
    }
  };

  return (
    <div>
      <div className='row  no-margin'>
        <div className='col-sm-12'>
          <div className='row'>
            <div className='back-btn' onClick={customizedBack}>
              {' '}
              &lt; Back{' '}
            </div>
            <hr />
          </div>
          <div className='row'>
            <div className='section-no-pad-title text-center'>Customise Your Subscription</div>
          </div>
        </div>
      </div>
      <div className='row white-bg padding-20'>
        <div className='col-sm-12 no-padding'>
          <PanelGroup
            accordion
            id='report-bundle-config-accordion'
            activeKey={activeKey}
            onSelect={handleSelect}
          >
            <Panel eventKey='1'>
              <Panel.Heading className='white-bg'>
                <Panel.Title toggle>
                  <div type='button' className='row panel-title panel-button no-margin'>
                    <div className='col-xs-8 text-left no-padding'>
                      <span>
                        <img src={UsersActive} alt='' className='margin-right-5' />
                        <span className='text-success body'>Users</span>
                      </span>
                    </div>
                    <div style={{"lineHeight": "30px"}} className='col-xs-4 text-success text-right no-padding'>
                      {number_of_users !== '' && (
                        <span className='margin-right-5 body'>
                          {productPackageDefaultConfig.configuration.number_of_users === null
                            ? 1
                            : productPackageDefaultConfig.configuration.number_of_users}
                          {productPackageDefaultConfig.configuration.number_of_users === 1 ||
                          productPackageDefaultConfig.configuration.number_of_users === null
                            ? ' User'
                            : ' Users'}
                        </span>
                      )}
                      {activeKey !== '1' && (
                        <span className='float-right carat-down text-success' aria-hidden='true'></span>
                      )}
                      {activeKey === '1' && (
                        <span className='float-right carat-up text-success' aria-hidden='true'></span>
                      )}
                    </div>
                  </div>
                </Panel.Title>
              </Panel.Heading>
              <Panel.Body collapsible>
                {productPackageConfig.packages[selectedPackageKey] && (
                  <Users
                    productPackageConfig={productPackageConfig}
                    productPackageDefaultConfig={productPackageDefaultConfig}
                    users={productPackageConfig.packages[selectedPackageKey].users}
                    onSelectingUser={onSelectingUser}
                    selectedUser={productPackageDefaultConfig.configuration.number_of_users}
                    onUserClickAction={onUserClickAction}
                  />
                )}
              </Panel.Body>
            </Panel>
          </PanelGroup>
        </div>
      </div>
    </div>
  );
};

GuruProductPackageConfiguration.propTypes = {
  productPackageConfig: PropTypes.object.isRequired,
  productPackageDefaultConfig: PropTypes.object.isRequired,
  onSelectingUser: PropTypes.func.isRequired,
  selectedPackageKey: PropTypes.string.isRequired
};

export default GuruProductPackageConfiguration;
