import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import tick from "../../image/Icons/tick-ef4400.svg"

export default class dataComparisonTableMobile extends React.Component{
  render(){
    let data = this.props.data
    let packageData =  this.props.industryPackage
    let type = this.props.type
    let packageKey = this.props.packageKey
    if(data !== undefined){
      var dataContent = Object.keys(data).map(function (singlePackage) {
        var item = data[singlePackage]
        if((item.conditional=== true &&
           this.props.industryKey !== undefined &&
          (
            (this.props.industryKey === 'mortgage_brokers_and_financial_planners' && (item.key === 'homeprezzo_agent_lite' || item.key === 'homeprezzo_agent')) ||
            (this.props.industryKey === 'real_estate_agencies_and_valuers' &&
              (item.key === 'smart_move' || item.key === 'homeprezzo_agent' || item.key === 'homeprezzo_small_agency' ||  item.key === 'homeprezzo_agency' || item.key === 'homeprezzo_agency_large' ))
          ))|| item.conditional === undefined
        ){
          var subItem = packageData[packageKey]
          var tooltip = (
            <Tooltip id="tooltip">
              {item.description}
            </Tooltip>
          );
          return (
            <div className="row table-padding" key={item.key}>
              <div className="col-xs-8">
                <p className="semi-bold no-margin text-left">
                  <span 
                  className="margin-right-5"
                  id={`item_title_${item.key}`}
                  >
                    {item.title}
                  </span>
                  {item.description!=null && <OverlayTrigger placement="top" overlay={tooltip} trigger={['click', 'hover', 'focus']}>
                    <span>
                      <i className="far fa-question-circle tooltip-icon" aria-hidden="true"></i>
                    </span>
                  </OverlayTrigger>}
                  {
                    item.sticker === true &&
                    <span className="new-inline-tag-mobile" style={{padding:'5px'}}><i className="fa fa-star" style={{fontSize:'7px'}} aria-hidden="true"></i>
                      <span> New</span>
                    </span>
                  }
                  {
                    item.popular_sticker === true &&
                    <span className="new-inline-tag" style={{padding:'5px'}}><i className="fa fa-star" style={{fontSize:'7px'}} aria-hidden="true"></i>
                      <span> Popular</span>
                    </span>
                  }
                </p>
              </div>
              <div className="col-xs-4 comparison-table-align" key={subItem.key}>
                {
                  subItem.features[type] && subItem.features[type][item.key]!=='N/A' && subItem.features[type][item.key]!=='Metrolpolitan Area' &&
                  <span>{subItem.features[type][item.key]}</span>
                }
                {
                  subItem.features[type] && subItem.features[type][item.key]===true &&
                  <img src={tick} alt="yes"/>
                }
                {
                  subItem.features[type] && subItem.features[type][item.key]==='Metrolpolitan Area' &&
                  <span><span>{subItem.features[type][item.key]}</span>
                  <p className="sub-text">Enquire for national</p></span>
                }
                {subItem.features[type] && subItem.features[type][item.key]==='N/A' && <span><i className="fa fa-minus tan-text" aria-hidden="true"></i></span>}
              </div>
            </div>
          );
        }else{
          return(
            <div key={item.key}></div>
          )
        }

      }, this);
    }

    return (
      <div>
        {dataContent}
      </div>
    );
  }
}
