import React, { Component } from 'react';
import { connect } from 'react-redux'
import {fetchWindcavePaymentByToken} from '../actions/paymentToken'
import {
  analyticsPageView,
  trackAction
} from '../services/v2/segmentAnalytics';

class PaymentPxPay extends Component {

  constructor(props){
    super(props);

  }

  componentDidMount() {
    analyticsPageView("Payment PxPay");
    if(fetchWindcavePaymentByToken !== null) {
      this.props.fetchWindcavePaymentByToken(this.props.match.params.payment_token).then(function(){
        trackAction("Start Payment process", this.props.paymentToken.success ? "Success": "Failed")
      }.bind(this))
    }
  }

  render(){
    return(
      <div>
        <div className="row off-white-bg no-margin">
          <div className="col-sm-12">
            <div className="row no-margin">
              {
                this.props.paymentToken.loader &&
                <div className="col-sm-12 section-title heading1 text-center" style={{padding:'120px'}}>
                  Processing
                  <span>
                      <i className="fa fa-spinner fa-pulse fa-fw"></i>
                      <span className="sr-only">Loading...</span>
                    </span>
                </div>
              }
              {
                this.props.paymentToken.failed &&
                <div className="col-sm-12 section-title heading1 text-center" style={{padding:'120px'}}>
                  Process failed
                </div>
              }
              {
                this.props.paymentToken.success === true &&
                <div className="col-sm-12 section-title heading1 text-center" style={{padding:'120px'}}>
                  Redirection to payment portal
                  <span>
                      <i className="fa fa-spinner fa-pulse fa-fw"></i>
                      <span className="sr-only">Loading...</span>
                    </span>
                </div>
              }

            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    paymentToken: state.paymentToken
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchWindcavePaymentByToken:(token) => dispatch(fetchWindcavePaymentByToken(token))
  }
}

let PaymentPxPayPage = connect(mapStateToProps, mapDispatchToProps)(PaymentPxPay)

export default PaymentPxPayPage;
