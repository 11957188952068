import Api from '../services/api.js';
import history from '../history';
import DataLayerService from '../services/dataLayerService';

export function saveCartItemRequest() {
  return {
    type: 'SAVE_CART_ITEM_REQUEST'
  };
}

export function saveCartItemSuccess(data) {
  return {
    type: 'SAVE_CART_ITEM_SUCCESS',
    data: data
  };
}

export function saveCartItemFailure(exception) {
  return {
    type: 'SAVE_CART_ITEM_FAILURE',
    exception: exception
  };
}

export function deleteCartItemRequest() {
  return {
    type: 'DELETE_CART_ITEM_REQUEST'
  };
}

export function deleteCartItemSuccess(data) {
  return {
    type: 'DELETE_CART_ITEM_SUCCESS',
    data: data
  };
}

export function deleteCartItemFailure(exception) {
  return {
    type: 'DELETE_CART_ITEM_FAILURE',
    exception: exception
  };
}

export function checkoutRequest() {
  return {
    type: 'CHECKOUT_REQUEST'
  };
}

export function checkoutSuccess(data) {
  return {
    type: 'CHECKOUT_SUCCESS',
    data: data
  };
}

export function checkoutFailure(exception) {
  return {
    type: 'CHECKOUT_FAILURE',
    exception: exception
  };
}

export function deleteOrderRequest() {
  return {
    type: 'DELETE_ORDER_REQUEST'
  };
}

export function deleteOrderSuccess(data) {
  return {
    type: 'DELETE_ORDER_SUCCESS',
    data: data
  };
}

export function deleteOrderFailure(exception) {
  return {
    type: 'DELETE_ORDER_FAILURE',
    exception: exception
  };
}

export function fetchCartRequest() {
  return {
    type: 'FETCH_CART_REQUEST'
  };
}

export function fetchCartSuccess(data) {
  return {
    type: 'FETCH_CART_SUCCESS',
    data: data
  };
}

export function fetchCartFailure(exception) {
  return {
    type: 'FETCH_CART_FAILURE',
    exception: exception
  };
}

export function refreshCoupon(data) {
  return {
    type: 'REFRESH_COUPON',
    data: data
  };
}

export function refreshCartCoupon(data) {
  return {
    type: 'REFRESH_CART_COUPON',
    data: data
  };
}

export function deleteCouponRequest() {
  return {
    type: 'DELETE_COUPON_REQUEST'
  };
}

export function deleteCouponFailure(exception) {
  return {
    type: 'DELETE_COUPON_FAILURE',
    exception: exception
  };
}

export function applyCouponRequest() {
  return {
    type: 'APPLY_COUPON_REQUEST'
  };
}

export function applyCouponSuccess(body) {
  return {
    type: 'APPLY_COUPON_SUCCESS',
    body: body
  };
}

export function applyCouponFailure(exception) {
  return {
    type: 'APPLY_COUPON_FAILURE',
    exception: exception
  };
}

export function removeCoupon() {
  return {
    type: 'REMOVE_COUPON'
  };
}

export function getUserSessionRequest() {
  return {
    type: 'USER_SESSION_REQUEST'
  };
}

export function getUserSessionSuccess(body) {
  return {
    type: 'USER_SESSION_SUCCESS',
    body: body
  };
}

export function getUserSessionFailure(exception) {
  return {
    type: 'USER_SESSION_FAILURE',
    exception: exception
  };
}

export function saveCartItem(cartItem, action) {
  return (dispatch) => {
    dispatch(saveCartItemRequest());
    const api = new Api();
    return api
      .post('/__api/cart/items', cartItem, { headers: { Region: 'NZ' } })
      .then((response) => {
        Promise.all([dispatch(saveCartItemSuccess(response.data))]).then(() => {
          const dataLayerService = new DataLayerService();

          dataLayerService.notifyOnNewCartItem(response.data, cartItem);
          dispatch(refreshCartCoupon(response.data));
          if (action === 'buy') {
            return;
          } else {
            history.push('/cart');
          }
        });
      })
      .catch((err) => dispatch(saveCartItemFailure(err)));
  };
}

export function deleteCartItem(cartItemId, productId, item) {
  return (dispatch) => {
    dispatch(deleteCartItemRequest());
    const api = new Api();
    return api
      .delete(`/__api/cart/items/${cartItemId}`, { headers: { Region: 'NZ' } })
      .then((response) => {
        Promise.all([dispatch(deleteCartItemSuccess(response.data))]).then(() => {
          const dataLayerService = new DataLayerService();
          dataLayerService.notifyOnDeleteCartItem(item);
          dispatch(refreshCartCoupon(response.data));
        });
      })
      .catch((err) => dispatch(deleteCartItemFailure(err)));
  };
}

export function checkout(order) {
  return (dispatch) => {
    dispatch(checkoutRequest());
    const api = new Api();
    return api
      .post('/__api/orders', order, { headers: { Region: 'NZ' } })
      .then((response) => {
        Promise.all([dispatch(checkoutSuccess(response.data))]).then(() => {
          history.push('/checkout');
          const dataLayerService = new DataLayerService();
          dataLayerService.notifyOnCheckout(response.data);
        });
      })
      .catch((err) => dispatch(checkoutFailure(err)));
  };
}

export function fetchCart(item) {
  return (dispatch) => {
    dispatch(fetchCartRequest());
    const api = new Api();
    return api
      .get('/__api/cart', { headers: { Region: 'NZ' } })
      .then((response) => {
        dispatch(fetchCartSuccess(response.data));
        dispatch(refreshCartCoupon(response.data));
        if (item != null) {
          dispatch(saveCartItem(item));
        }
      })
      .catch((err) => dispatch(fetchCartFailure(err)));
  };
}

export function deleteOrder() {
  return (dispatch) => {
    dispatch(deleteOrderRequest());
    const api = new Api();
    return api
      .delete('/__api/order/current', {
        headers: {
          Region: 'NZ',
          Pragma: 'no-cache',
          'Cache-Control': 'no-store, no-cache, must-revalidate',
          Expires: -1
        }
      })
      .then((response) => {
        dispatch(deleteOrderSuccess(response.data));
        dispatch(refreshCartCoupon(response.data));
      })
      .catch((err) => dispatch(deleteOrderFailure(err)));
  };
}

export function applyCoupon(coupon) {
  return (dispatch) => {
    dispatch(applyCouponRequest());
    const api = new Api();
    return api
      .post('/__api/coupon', coupon, { headers: { 'Content-Type': 'text/plain', Region: 'NZ' } })
      .then((response) => {
        dispatch(fetchCartSuccess(response.data));
        dispatch(refreshCartCoupon(response.data));
      })
      .catch((err) => dispatch(applyCouponFailure(err)));
  };
}

export function deleteCoupon() {
  return (dispatch) => {
    dispatch(deleteCouponRequest());
    const api = new Api();
    return api
      .delete('/__api/coupon', { headers: { Region: 'NZ' } })
      .then((response) => {
        dispatch(fetchCartSuccess(response.data));
        dispatch(refreshCartCoupon(response.data));
      })
      .catch((err) => dispatch(deleteCouponFailure(err)));
  };
}

export function getUserSession() {
  return (dispatch) => {
    dispatch(getUserSessionRequest());
    const api = new Api();
    return api
      .get('/__api/user/active', { headers: { Region: 'NZ' } })
      .then((response) => dispatch(getUserSessionSuccess(response.data)))
      .catch((err) => dispatch(getUserSessionFailure(err)));
  };
}

export function clearCart() {
  return {
    type: 'CLEAR_ORDER'
  };
}

export function userLogoutRequest() {
  return {
    type: 'USER_LOGOUT_REQUEST'
  };
}

export function userLogoutSuccess(data) {
  return {
    type: 'USER_LOGOUT_SUCCESS',
    data: data
  };
}

export function userLogoutFailure(exception) {
  return {
    type: 'USER_LOGOUT_FAILURE',
    exception: exception
  };
}

export function userLogout(isClearCart) {
  return (dispatch) => {
    dispatch(userLogoutRequest());
    const api = new Api();
    return api
      .get(`/__api/user/logout?clearCart=${isClearCart}`, {
        headers: {
          Region: 'NZ',
          Pragma: 'no-cache',
          'Cache-Control': 'no-store, no-cache, must-revalidate',
          Expires: -1
        }
      })
      .then((response) => {
        dispatch(userLogoutSuccess(response.data));
        window.location.href = response.data;
      })
      .catch((err) => dispatch(userLogoutFailure(err)));
  };
}

 /* istanbul ignore next */
export function resetState() {
  return (dispatch) => {
    dispatch(userLogout(true));
    dispatch(deleteOrder());
    dispatch(clearCart());
  };
}
