import React, { Component } from 'react';
import { connect } from 'react-redux'
import {validateEmail} from '../actions/registration'
import {redirectToLoginPage, resetLoaders} from '../actions/login'
import {getAccounts, getContactInfo, getContactUsername} from '../actions/checkout'
import {checkout} from '../actions/cart'
import qs from 'query-string';
import {
  analyticsIdentity,
  analyticsPageView,
  trackContinueShoppingFromEmailValidation,
  trackEmailValidation,
  trackSignUp
} from '../services/v2/segmentAnalytics';

import './EmailValidationPage.css'

class EmailValidation extends Component {

  constructor(props){
    super(props);
    this.state = {
      emptyCart: false
    }

    this.trackSignUp = trackSignUp;

    //SERVICES
    this.validateEmail =  this.props.LoginService.validateEmail.bind(this);
    this.redirectToLoginPage =  this.props.LoginService.redirectToLoginPage.bind(this);

    this.getItemsCount = this.props.ProductItemsService.getItemsCount.bind(this)

    this.setCustomerDetails = this.props.OrderService.setCustomerDetails.bind(this)
  }

  continueShopping(link) {
    trackContinueShoppingFromEmailValidation("Continue Shopping");
    window.location.href = link;
  }

  componentDidMount() {
    analyticsPageView("Email Validation");

    if(this.props.login?.userLoggedIn){
      analyticsIdentity()
    }
  
    let codeParam = null
    let validationParam = null
    if (this.props.location?.search && this.props.location?.search.length > 0) {
      codeParam = qs.parse(this.props.location.search).code ? qs.parse(this.props.location.search).code : null
      validationParam = qs.parse(this.props.location.search).validated ? qs.parse(this.props.location.search).validated : null
    }
    if (codeParam) {
      this.props.getAccounts(codeParam).then(function() {
        this.props.validateEmail().then(function() {
          if (this.props.registration.emailValidation.isEmailValidated) {
            analyticsIdentity(this.props.registration.emailValidation)
            this.setCustomerDetails()
          }
          trackEmailValidation(this.props.registration.emailValidation.isEmailValidated)
        }.bind(this))
      }.bind(this))
    }
    if (this.getItemsCount() === 0) {
      this.setState({
        emptyCart: true
      })
    }
    else {
      this.props.resetLoaders()
      if (validationParam) this.redirectToLoginPage()
    }
  }

  render() {
    return(
      <div className="row off-white-bg padding-20 no-margin">
        <form componentclass="fieldset">
          <div className="row no-margin">
            <div className="col-sm-3"></div>
            <div className="col-sm-6">
              {
                this.state.emptyCart &&
                <div className="row no-margin">
                  <div className="col-xs-12 padding-20 white-bg">
                    <div className="section-header email-validation-h5" style={{textAlign:'center'}}>
                      Where are your cart items?
                    </div>
                    <div className="body" style={{padding:"7% 15%"}}>
                      Thank you for validating your email. You have reached this page because you may have validated your email on a different device or a different browser.
                      <br/><br/>
                      Please navigate to your original browser to checkout or please continue shopping from within this browser by re-adding the product(s) to your cart.
                    </div>
                    <div style={{textAlign:'center'}}>
                      <a onClick={()=>{this.continueShopping("https://www.corelogic.co.nz/products")}}>
                        <button type="button" className="btn cont-btn email-validation-btn" style={{float:'none', marginTop:'0'}}>Continue Shopping</button>
                      </a>
                    </div>
                  </div>

                </div>
              }
              {
                !this.state.emptyCart &&
                !this.props.registration?.emailValidationLoader &&
                !this.props.login?.accountsLoader &&
                !this.props.checkoutLoader &&
                !this.props.contactsLoader &&
                !this.props.login?.loader &&
                <div className="row no-margin">
                  <div className="col-xs-12 padding-20">
                    <div className="section-header email-validation-h5" style={{textAlign:'center'}}>
                      Validate your email
                    </div>
                  </div>
                  <div className="white-bg body" style={{padding:"13% 23%"}}>
                    Thank you for registering. To complete your registration, you will receive an email to validate your email address. Click the link and then return to this page to continue.
                    <button type="button" className="btn register-btn" onClick={this.redirectToLoginPage}>Continue</button>
                  </div>
                </div>
              }
              {
                (this.props.registration?.emailValidationLoader || this.props.login?.accountsLoader) &&
                <div className="row no-margin">
                    <div className="col-sm-12 section-title email-validation-h3 text-center" style={{padding:'120px'}}>
                      Validating
                      <span>
                          <i className="fa fa-spinner fa-pulse fa-fw"></i>
                          <span className="sr-only">Loading...</span>
                        </span>
                    </div>
                </div>
              }
              {
                (this.props.checkoutLoader || this.props.contactsLoader) &&
                <div className="row no-margin">
                    <div className="col-sm-12 section-title email-validation-h3 text-center" style={{padding:'120px'}}>
                      Redirecting to checkout
                      <span>
                          <i className="fa fa-spinner fa-pulse fa-fw"></i>
                          <span className="sr-only">Loading...</span>
                        </span>
                    </div>
                </div>
              }
              {
                this.props.login?.loader &&
                <div className="row no-margin">
                    <div className="col-sm-12 section-title email-validation-h3 text-center" style={{padding:'120px'}}>
                      Redirecting to Log in..
                      <span>
                          <i className="fa fa-spinner fa-pulse fa-fw"></i>
                          <span className="sr-only">Loading...</span>
                        </span>
                    </div>
                </div>
              }

            </div>
            <div className="col-sm-3"></div>
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    registration: state.registration,
    login: state.login,
    cart: state.cart.cart,
    order: state.cart.order,
    checkoutLoader: state.cart.loading,
    contactInfo: state.checkout.contactInfo,
    contactsLoader: state.checkout.contactsLoader,
    contactUsernameDetails: state.checkout.contactUsernameDetails
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    validateEmail:() => dispatch(validateEmail()),
    redirectToLoginPage:()=> dispatch(redirectToLoginPage()),
    getAccounts:(code)=> dispatch(getAccounts(code)),
    checkout:(order) => dispatch(checkout(order)),
    resetLoaders:() => dispatch(resetLoaders()),
    getContactInfo:() => dispatch(getContactInfo()),
    getContactUsername:(accountGuid) => dispatch(getContactUsername(accountGuid))
  }
}

let EmailValidationPage = connect(mapStateToProps, mapDispatchToProps)(EmailValidation)

export default EmailValidationPage;
