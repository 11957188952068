export const FETCH_PRODUCT_REQUEST = 'FETCH_PRODUCT_REQUEST';
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';
export const FETCH_PRODUCT_FAILURE = 'FETCH_PRODUCT_FAILURE';

export const FETCH_PRODUCT_BY_ID_REQUEST = 'FETCH_PRODUCT_BY_ID_REQUEST';
export const FETCH_PRODUCT_BY_ID_SUCCESS = 'FETCH_PRODUCT_BY_ID_SUCCESS';
export const FETCH_PRODUCT_BY_ID_FAILURE = 'FETCH_PRODUCT_BY_ID_FAILURE';

export const SET_PACKAGE = 'SET_PACKAGE';

export const FETCH_QUOTE_REQUEST = 'FETCH_QUOTE_REQUEST';
export const FETCH_QUOTE_SUCCESS = 'FETCH_QUOTE_SUCCESS';
export const FETCH_QUOTE_FAILURE = 'FETCH_QUOTE_FAILURE';

export const SAVE_CART_ITEM_REQUEST = 'SAVE_CART_ITEM_REQUEST';
export const SAVE_CART_ITEM_SUCCESS = 'SAVE_CART_ITEM_SUCCESS';
export const SAVE_CART_ITEM_FAILURE = 'SAVE_CART_ITEM_FAILURE';

export const DELETE_CART_ITEM_REQUEST = 'DELETE_CART_ITEM_REQUEST';
export const DELETE_CART_ITEM_SUCCESS = 'DELETE_CART_ITEM_SUCCESS';
export const DELETE_CART_ITEM_FAILURE = 'DELETE_CART_ITEM_FAILURE';

export const CHECKOUT_REQUEST = 'CHECKOUT_REQUEST';
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS';
export const CHECKOUT_FAILURE = 'CHECKOUT_FAILURE';

export const SAVE_CUSTOMER_DETAILS_REQUEST = 'SAVE_CUSTOMER_DETAILS_REQUEST';
export const SAVE_CUSTOMER_DETAILS_SUCCESS = 'SAVE_CUSTOMER_DETAILS_SUCCESS';
export const SAVE_CUSTOMER_DETAILS_FAILURE = 'SAVE_CUSTOMER_DETAILS_FAILURE';

export const REDIRECT_TO_PAYMENT_PORTAL_REQUEST = 'REDIRECT_TO_PAYMENT_PORTAL_REQUEST';
export const REDIRECT_TO_PAYMENT_PORTAL_SUCCESS = 'REDIRECT_TO_PAYMENT_PORTAL_SUCCESS';
export const REDIRECT_TO_PAYMENT_PORTAL_FAILURE = 'REDIRECT_TO_PAYMENT_PORTAL_FAILURE';

export const FETCH_PAYMENT_DETAILS_REQUEST = 'FETCH_PAYMENT_DETAILS_REQUEST';
export const FETCH_PAYMENT_DETAILS_SUCCESS = 'FETCH_PAYMENT_DETAILS_SUCCESS';
export const FETCH_PAYMENT_DETAILS_FAILURE = 'FETCH_PAYMENT_DETAILS_FAILURE';

export const DIRECT_DEBIT_PAYMENT_REQUEST = 'DIRECT_DEBIT_PAYMENT_REQUEST';
export const DIRECT_DEBIT_PAYMENT_SUCCESS = 'DIRECT_DEBIT_PAYMENT_SUCCESS';
export const DIRECT_DEBIT_PAYMENT_FAILURE = 'DIRECT_DEBIT_PAYMENT_FAILURE';

export const TRIAL_PURCHASE_REQUEST = 'TRIAL_PURCHASE_REQUEST';
export const TRIAL_PURCHASE_SUCCESS = 'TRIAL_PURCHASE_SUCCESS';
export const TRIAL_PURCHASE_FAILURE = 'TRIAL_PURCHASE_FAILURE';

export const FETCH_ADDRESS_LINES_SUCCESS = 'FETCH_ADDRESS_LINES_SUCCESS';
export const FETCH_ADDRESS_LINES_REQUEST = 'FETCH_ADDRESS_LINES_REQUEST';
export const FETCH_ADDRESS_LINES_FAILURE = 'FETCH_ADDRESS_LINES_FAILURE';
export const RESET_ADDRESS_LOOKUP_PROPS = 'RESET_ADDRESS_LOOKUP_PROPS';

export const FETCH_ABN_DETAILS_REQUEST = 'FETCH_ABN_DETAILS_REQUEST';
export const FETCH_ABN_DETAILS_SUCCESS = 'FETCH_ABN_DETAILS_SUCCESS';
export const FETCH_ABN_DETAILS_FAILURE = 'FETCH_ABN_DETAILS_FAILURE';
export const RESET_ABN_LOOKUP_PROPS = 'RESET_ABN_LOOKUP_PROPS';

export const DELETE_ORDER_REQUEST = 'DELETE_ORDER_REQUEST';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_FAILURE = 'DELETE_ORDER_FAILURE';

export const APPLY_COUPON_REQUEST = 'APPLY_COUPON_REQUEST';
export const APPLY_COUPON_SUCCESS = 'APPLY_COUPON_SUCCESS';
export const APPLY_COUPON_FAILURE = 'APPLY_COUPON_FAILURE';

export const FETCH_CURRENT_ORDER_REQUEST = 'FETCH_CURRENT_ORDER_REQUEST';
export const FETCH_CURRENT_ORDER_SUCCESS = 'FETCH_CURRENT_ORDER_SUCCESS';
export const FETCH_CURRENT_ORDER_FAILURE = 'FETCH_CURRENT_ORDER_FAILURE';

export const FETCH_CART_REQUEST = 'FETCH_CART_REQUEST';
export const FETCH_CART_SUCCESS = 'FETCH_CART_SUCCESS';
export const FETCH_CART_FAILURE = 'FETCH_CART_FAILURE';

export const FETCH_AFFILIATES_DETAILS_REQUEST = 'FETCH_AFFILIATES_DETAILS_REQUEST';
export const FETCH_AFFILIATES_DETAILS_SUCCESS = 'FETCH_AFFILIATES_DETAILS_SUCCESS';
export const FETCH_AFFILIATES_DETAILS_FAILURE = 'FETCH_AFFILIATES_DETAILS_FAILURE';

export const CLEAR_ORDER = 'CLEAR_ORDER';
export const SET_ORDER = 'SET_ORDER';

export const CHANGE_INDUSTRY = 'CHANGE_INDUSTRY';

export const FETCH_FILTERED_AREAS_REQUEST = 'FETCH_FILTERED_AREAS_REQUEST';
export const FETCH_FILTERED_AREAS_SUCCESS = 'FETCH_FILTERED_AREAS_SUCCESS';
export const FETCH_FILTERED_AREAS_FAILURE = 'FETCH_FILTERED_AREAS_FAILURE';

export const FETCH_SUBURB_LIST_REQUEST = 'FETCH_SUBURB_LIST_REQUEST';
export const FETCH_SUBURB_LIST_SUCCESS = 'FETCH_SUBURB_LIST_SUCCESS';
export const FETCH_SUBURB_LIST_FAILURE = 'FETCH_SUBURB_LIST_FAILURE';

export const DELETE_COUPON_REQUEST = 'DELETE_COUPON_REQUEST';
export const DELETE_COUPON_SUCCESS = 'DELETE_COUPON_SUCCESS';
export const DELETE_COUPON_FAILURE = 'DELETE_COUPON_FAILURE';

export const REFRESH_COUPON = 'REFRESH_COUPON';
export const REMOVE_COUPON = 'REMOVE_COUPON';
export const REFRESH_CART_COUPON = 'REFRESH_CART_COUPON';

export const SET_COOKIE_NOTIFICATION = 'SET_COOKIE_NOTIFICATION';

export const PXPAY_REQUEST = 'PXPAY_REQUEST';
export const PXPAY_SUCCESS = 'PXPAY_SUCCESS';
export const PXPAY_FAILURE = 'PXPAY_FAILURE';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const SET_CUSTOMER_DETAILS = 'SET_CUSTOMER_DETAILS';

export const RESET_USER_DETAILS = 'RESET_USER_DETAILS';

export const EMAIL_VALIDATION_REQUEST = 'EMAIL_VALIDATION_REQUEST';
export const EMAIL_VALIDATION_SUCCESS = 'EMAIL_VALIDATION_SUCCESS';
export const EMAIL_VALIDATION_FAILURE = 'EMAIL_VALIDATION_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const GET_ACCOUNTS_REQUEST = 'GET_ACCOUNTS_REQUEST';
export const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS';
export const GET_ACCOUNTS_FAILURE = 'GET_ACCOUNTS_FAILURE';

export const GET_ROLES_REQUEST = 'GET_ROLES_REQUEST';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAILURE = 'GET_ROLES_FAILURE';

export const GET_CONTACT_INFO_REQUEST = 'GET_CONTACT_INFO_REQUEST';
export const GET_CONTACT_INFO_SUCCESS = 'GET_CONTACT_INFO_SUCCESS';
export const GET_CONTACT_INFO_FAILURE = 'GET_CONTACT_INFO_FAILURE';

export const GET_CONTACT_USERNAME_REQUEST = 'GET_CONTACT_USERNAME_REQUEST';
export const GET_CONTACT_USERNAME_SUCCESS = 'GET_CONTACT_USERNAME_SUCCESS';
export const GET_CONTACT_USERNAME_FAILURE = 'GET_CONTACT_USERNAME_FAILURE';

export const USER_SESSION_REQUEST = 'USER_SESSION_REQUEST';
export const USER_SESSION_SUCCESS = 'USER_SESSION_SUCCESS';
export const USER_SESSION_FAILURE = 'USER_SESSION_FAILURE';

export const RESET_LOADERS = 'RESET_LOADERS';

export const UPDATE_QUOTE_CONFIG = 'UPDATE_QUOTE_CONFIG';

export const SET_COUPON_CODE_FOR_CART = 'SET_COUPON_CODE_FOR_CART';

export const GET_ACCOUNT_INFO_REQUEST = 'GET_ACCOUNT_INFO_REQUEST';
export const GET_ACCOUNT_INFO_SUCCESS = 'GET_ACCOUNT_INFO_SUCCESS';
export const GET_ACCOUNT_INFO_FAILURE = 'GET_ACCOUNT_INFO_FAILURE';

export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE';

export const USER_EXPIRY_SESSION = 'USER_EXPIRY_SESSION';

export const RESET_COUPON = 'RESET_COUPON';

export const GET_PROPERTY_DETAILS_REQUEST = 'GET_PROPERTY_DETAILS_REQUEST';
export const GET_PROPERTY_DETAILS_SUCCESS = 'GET_PROPERTY_DETAILS_SUCCESS';
export const GET_PROPERTY_DETAILS_FAILURE = 'GET_PROPERTY_DETAILS_FAILURE';

export const RESET_CHECKOUT_LOADERS = 'RESET_CHECKOUT_LOADERS';

export const UPDATE_ORDER_DETAILS_SUCCESS = 'UPDATE_ORDER_DETAILS_SUCCESS';

export const VALIDATE_ACCOUNT_REFERENCE_NUMBER_REQUEST =
  'VALIDATE_ACCOUNT_REFERENCE_NUMBER_REQUEST';
export const VALIDATE_ACCOUNT_REFERENCE_NUMBER_SUCCESS =
  'VALIDATE_ACCOUNT_REFERENCE_NUMBER_SUCCESS';
export const VALIDATE_ACCOUNT_REFERENCE_NUMBER_FAILURE =
  'VALIDATE_ACCOUNT_REFERENCE_NUMBER_FAILURE';

export const PAYMENT_PXPAY_REQUEST = 'PAYMENT_PXPAY_REQUEST';
export const PAYMENT_PXPAY_SUCCESS = 'PAYMENT_PXPAY_SUCCESS';
export const PAYMENT_PXPAY_FAILURE = 'PAYMENT_PXPAY_FAILURE';

export const FLAG_PURCHASE_EVENT = 'FLAG_PURCHASE_EVENT';
export const RESET_PURCHASE_EVENT = 'RESET_PURCHASE_EVENT';

export const WINDCAVE_PROCESS_PAYMENT_REQUEST = 'WINDCAVE_PROCESS_PAYMENT_REQUEST';
export const WINDCAVE_PROCESS_PAYMENT_SUCCESS = 'WINDCAVE_PROCESS_PAYMENT_SUCCESS';
export const WINDCAVE_PROCESS_PAYMENT_FAILURE = 'WINDCAVE_PROCESS_PAYMENT_FAILURE';
