import axios from 'axios';

class Api {

  get(url, params) {
    return axios.get(url, params)
  }

  post(url, params, data) {
    return axios.post(url,params,data)
  }

  patch(url, params, data) {
    return axios.patch(url,params,data)
  }

  delete(url, params) {
    return axios.delete(url,params)
  }
}

export default Api;
