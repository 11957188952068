import { PXPAY_REQUEST, PXPAY_SUCCESS, PXPAY_FAILURE, FLAG_PURCHASE_EVENT, RESET_PURCHASE_EVENT } from '../constants';

const initialPxPayState = {  
  isPurchaseEventFlagged: false,
  pxpayLoader: false,
  failure: false
};

export default (state = initialPxPayState, action = {}) => {
  //This is to ensure immutability of state
  let newState = { ...state };

  switch (action.type) {
    case PXPAY_REQUEST:
      newState.pxpayLoader = true;
      newState.failure = false;
      return newState;
    case PXPAY_SUCCESS:
      newState.pxpayLoader = false;
      newState.failure = false;
      return newState;
    case PXPAY_FAILURE:
      newState.pxpayLoader = false;
      newState.failure = true;
      return newState;    
    case FLAG_PURCHASE_EVENT:
      newState.isPurchaseEventFlagged = true;
      return newState;
    case RESET_PURCHASE_EVENT:
      newState.isPurchaseEventFlagged = false;
      return newState;
    default:
      return state;
  }
};
