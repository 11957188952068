import React, { Component } from 'react';
import { connect } from 'react-redux'

class WindcavePaymentStatus extends Component {

  constructor(props){
    super(props);

    //SERVICES
    this.analyticsPageView = this.props.SegmentAnalytics.analyticsPageView.bind(this)
    this.trackWindcavePaymentStatus = this.props.SegmentAnalytics.trackWindcavePaymentStatus.bind(this)
  }

  triggerWindcavePaymentStatusEvent() {
    if (this.props.paymentToken.payment && this.props.paymentToken.payment.result) {
      this.trackWindcavePaymentStatus("Success", "Payment Successful", this.props.paymentToken.payment)
    } else {
      this.trackWindcavePaymentStatus("Fail", "Declined", this.props.paymentToken.payment)
    }
  }

  componentDidMount() {
    this.analyticsPageView("Windcave Status");
    this.triggerWindcavePaymentStatusEvent()
    window.scrollTo(0, 0);
  }

  render() {
    return(
      <div>
        {
          this.props.paymentToken.payment &&
          <div className="container">
            <div className="row margin-bottom-100">
              <div className="col-sm-12 text-white payment-status">
                {
                  this.props.paymentToken.payment.result &&
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="heading1 success-text padding-20">Success!</div>
                      <div>
                        <p>Thank you for your order.&nbsp;</p>
                      </div>
                    </div>
                  </div>
                }
                {
                  !this.props.paymentToken.payment.result &&
                  <div className="row">
                    <div>
                      <p>Unfortunately your payment cannot be processed as your transaction has been declined.</p>
                      <p>Please attempt your purchase again</p>
                      <a href={this.props.paymentToken.payment.retry_url}><button type="button" className="btn btn-outline padding-10">Retry Payment</button></a>
                    </div>
                  </div>
                }
            </div>
          </div>
        </div>
      }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    paymentToken: state.paymentToken
  }
}


let WindcavePaymentStatusPage = connect(mapStateToProps, null)(WindcavePaymentStatus)

export default WindcavePaymentStatusPage;
