import React from "react";
import $ from 'jquery'

export default class OrderSummary extends React.Component{

  constructor(props){
    super(props);
    this.state={
      isAgentVisbile: true
    }
  }

  /* istanbul ignore next */
  fixOrderSummary(mobile){
    if(mobile === false){
      $(window).scroll(function(){
        if( $(window).scrollTop() > 150 && $(window).width() > 999 ) {
          $('#order-summary').css({position: 'fixed',top:'5px','margin-top':'0px'});
        } else {
          $('#order-summary').css({position: 'relative','margin-top':'100px'});
        }
      });
    }else{
      $('#order-summary').css({position: 'relative','margin-top':'10px'});
    }
  }

  orderDiscount(){
    let discount = 0
    let quotes = this.props.quotes
    if(quotes!==null){
      for(let index = 0; index < quotes.length; index++){
        discount = discount + quotes[index].discount
      }
    }

    if(this.props.order.quotes && this.props.order.quotes.cartDiscount !== null){
      discount = discount + this.props.order.quotes.cartDiscount
    }
    return discount;
  }

  pricingFormat(value){
    var parts = value.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  componentDidMount() {
    var mobile = false
    if(/Android|webOS|iPhone|iPod|ipad|BlackBerry/i.test(navigator.userAgent) ){
      mobile = true
    }
    window.addEventListener('scroll',this.fixOrderSummary(mobile));

    if(this.props.coupon && Object.keys(this.props.coupon).length > 0 ){
      let coupon = this.props.coupon
      let isAgentVisbile = true

      if(coupon.discountServiceConfig !== null){
        let discountServiceConfig = coupon.discountServiceConfig
        let agentVisibility = discountServiceConfig.agentVisibility

        if(agentVisibility === "N"){
          isAgentVisbile = false
        }
      }

      this.setState({
        isAgentVisbile: isAgentVisbile
       });
    }
  }

  render(props){
    return (
      <div id="order-summary" className="margin-top-100 order-summary">
        <div className="order-header checkout-h6">ORDER SUMMARY</div>
        {
          this.props.quotes !== undefined && this.props.quotes.length > 0 &&
          <div>
            {
              this.props.quotes.map(function(item, index){
                return(
                  <div className="row margin-top-10" key={item.productId}>
                    <div className="col-xs-8">
                      <div className="item-header-checkout checkout-h6" data-testid="item-headline"> {item.headline} </div>
                      <div className="order-item footnote-text margin-top-5">
                        {
                          item.lineItems && item.lineItems.map(function(lineItem, subIndex){
                            return(
                              <span key={lineItem.description}>{lineItem.description}
                                {
                                  subIndex < item.lineItems.length-1 &&
                                  <span>,&nbsp;</span>
                                }
                              </span>
                            )
                          })
                        }
                      </div>
                      {
                        item.addons && item.addons.length > 0 &&
                        <div className="row no-margin">
                          <div className="item-header captions" style={{marginTop:"8px"}}>{item.addons.length} Add-ons </div>
                          <div className="order-item footnote-text">
                            {
                              item.addons.map(function(lineItem, subIndex){
                                return(
                                  <div key={lineItem.description}>{lineItem.description}</div>
                                )
                              })
                            }
                          </div>
                        </div>
                      }
                    </div>
                    <div className="col-xs-4 padding-5 text-right">
                      <div className="item-header-checkout checkout-h6"> ${this.pricingFormat(item.originalPrice.toFixed(2))}</div>
                      <div className="order-items footnote-text">
                        {
                          item.totalPerMonth === 0 &&
                          <small className=' text-right footnote-text checkout-small'>Min. cost $0 for 7 days.</small>
                        }
                        {
                          item.totalPerMonth > 0 &&
                          <small className=' text-right footnote-text checkout-small'>Min. cost ${this.pricingFormat((item.originalPrice * item.contractMonths).toFixed(2))} (incl. GST) over {item.userContractMonths} month(s).</small>
                        }
                      </div>
                    </div>
                  </div>
                )
              },this)
            }
          </div>
        }
        <hr/>
        {
          this.props.order &&
          <div className="row">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-xs-8">
                  <div className="text-left checkout-h6">Items incl. GST</div>
                </div>
                <div className="col-xs-4">
                  <div className="text-right checkout-h6">${this.pricingFormat(parseFloat(this.props.originalTotalPrice).toFixed(2))}</div>
                </div>
              </div>
              {
                this.orderDiscount() > 0 &&
                <div className="row">
                  <div className="col-xs-8">
                    <div className="text-left success-text checkout-h6">
                      Discount
                    </div>
                  </div>
                  <div className="col-xs-4">
                    <div className="text-right success-text checkout-h6">-${this.orderDiscount().toFixed(2)}</div>
                  </div>
                </div>
              }
              {
                this.props.totalPrice > 0 && this.props.cardType === 'credit' &&
                <div className="row">
                  <div className="col-xs-8">
                    <div className="text-left checkout-h6">
                      Credit Card Surcharge
                      {
                        this.props.paymentDetails.cardType !== null &&
                        <span className='font-12'> ({this.props.paymentDetails.surchargePercent}%) </span>
                      }
                    </div>
                  </div>
                  <div className="col-xs-4">
                    <div className="text-right checkout-h6">${this.props.creditCardSurcharge.toFixed(2)}</div>
                  </div>
                </div>
              }
              <div className="row">
                <div className="col-xs-8">
                  {
                    this.props.totalPrice > 0 &&
                    <div className="text-left checkout-h5">Total Monthly Cost</div>
                  }
                  {
                    this.props.totalPrice === 0 &&
                    <div className="text-right checkout-h5">Total</div>
                  }
                </div>
                <div className="col-xs-4">
                  <div className="text-right checkout-h5">${this.pricingFormat(this.props.totalPrice.toFixed(2))}

                    {
                      this.props.totalPrice === 0 &&
                      <p className="no-margin footnote-text checkout-small">for 7 days</p>
                    }
                  </div>
                  {
                    this.props.totalPrice > 0 &&
                    <div className="text-right footnote-text checkout-small"> <small className=' text-right footnote-text checkout-small'> Min. cost ${this.pricingFormat((this.props.minCost).toFixed(2))} over {this.props.userTotalMonths} month(s). </small></div>
                  }
                  {
                    this.props.totalPrice === 0 &&
                    <div className="text-right footnote-text checkout-small"> <small className=' text-right footnote-text checkout-small'> Min. cost $0 for 7 days.</small></div>
                  }
                </div>
              </div>
              <hr/>
            </div>
          </div>
        }
      </div>
    )
  }
}
