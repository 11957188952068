import Api from '../services/api.js';
import DataLayerService from '../services/dataLayerService'

import history from '../history';
import jstz from 'jstz';
const timezone = jstz.determine();


export function saveCustomerDetailsRequest() {
	return {
		type: 'SAVE_CUSTOMER_DETAILS_REQUEST'
	}
}

export function saveCustomerDetailsSuccess(body) {
	return {
		type: 'SAVE_CUSTOMER_DETAILS_SUCCESS',
		body: body
	}
}

export function updateOrderDetailsSuccess(body) {
	return {
		type: 'UPDATE_ORDER_DETAILS_SUCCESS',
		data: body
	}
}

export function saveCustomerDetailsFailure(exception) {
	return {
		type: 'SAVE_CUSTOMER_DETAILS_FAILURE',
		exception: exception
	}
}

export function updateContactInfoRequest() {
  return {
    type: 'UPDATE_CONTACT_INFO_REQUEST'
  };
}

export function updateContactInfoSuccess(body) {
  return {
    type: 'UPDATE_CONTACT_INFO_SUCCESS',
    body: body
  };
}

export function updateContactInfoFailure(exception) {
  return {
    type: 'UPDATE_CONTACT_INFO_FAILURE',
    exception: exception
  };
}

export function redirectToPaymentPortalRequest() {
	return {
		type: 'REDIRECT_TO_PAYMENT_PORTAL_REQUEST'
	}
}

export function redirectToPaymentPortalSuccess(body) {
	return {
		type: 'REDIRECT_TO_PAYMENT_PORTAL_SUCCESS',
		body: body
	}
}

export function redirectToPaymentPortalFailure(exception) {
	return {
		type: 'REDIRECT_TO_PAYMENT_PORTAL_FAILURE',
		exception: exception
	}
}

export function fetchPaymentDetailsRequest() {
	return {
		type: 'FETCH_PAYMENT_DETAILS_REQUEST'
	}
}

export function fetchPaymentDetailsSuccess(body) {
	return {
		type: 'FETCH_PAYMENT_DETAILS_SUCCESS',
		body: body
	}
}

export function fetchPaymentDetailsFailure(exception) {
	return {
		type: 'FETCH_PAYMENT_DETAILS_FAILURE',
		exception: exception
	}
}

export function directDebitPaymentRequest() {
	return {
		type: 'DIRECT_DEBIT_PAYMENT_REQUEST'
	}
}

export function directDebitPaymentSuccess(body) {
	return {
		type: 'DIRECT_DEBIT_PAYMENT_SUCCESS',
		body: body
	}
}

export function directDebitPaymentFailure(exception) {
	return {
		type: 'DIRECT_DEBIT_PAYMENT_FAILURE',
		exception: exception
	}
}

export function trialPurchaseRequest() {
	return {
		type: 'TRIAL_PURCHASE_REQUEST'
	}
}

export function trialPurchaseSuccess(body) {
	return {
		type: 'TRIAL_PURCHASE_SUCCESS',
		body: body
	}
}

export function trialPurchaseFailure(exception) {
	return {
		type: 'TRIAL_PURCHASE_FAILURE',
		exception: exception
	}
}

export function fetchAddressLinesRequest() {
	return {
		type: 'FETCH_ADDRESS_LINES_REQUEST'
	}
}

export function fetchAddressLinesSuccess(body) {
	return {
		type: 'FETCH_ADDRESS_LINES_SUCCESS',
		body: body
	}
}

export function fetchAddressLinesFailure(exception) {
	return {
		type: 'FETCH_ADDRESS_LINES_FAILURE',
		exception: exception
	}
}

export function fetchAbnDetailsRequest() {
	return {
		type: 'FETCH_ABN_DETAILS_REQUEST'
	}
}

export function fetchAbnDetailsSuccess(body) {
	return {
		type: 'FETCH_ABN_DETAILS_SUCCESS',
		body: body
	}
}

export function fetchAbnDetailsFailure(exception) {
	return {
		type: 'FETCH_ABN_DETAILS_FAILURE',
		exception: exception
	}
}

export function fetchCurrentOrderRequest() {
	return {
		type: 'FETCH_CURRENT_ORDER_REQUEST'
	}
}

export function fetchCurrentOrderSuccess(data) {
	return {
		type: 'FETCH_CURRENT_ORDER_SUCCESS',
		data: data
	}
}

export function fetchCurrentOrderFailure(exception) {
	return {
		type: 'FETCH_CURRENT_ORDER_FAILURE',
		exception: exception
	}
}

export function clearOrder() {
	return {
		type: 'CLEAR_ORDER'
	}
}

export function setOrder(data) {
	return {
		type: 'SET_ORDER',
		data: data
	}
}

export function getAccountsRequest() {
	return {
		type: 'GET_ACCOUNTS_REQUEST'
	}
}

export function getAccountsSuccess(data) {
	return {
		type: 'GET_ACCOUNTS_SUCCESS',
		data: data
	}
}

export function getAccountsFailure(exception) {
	return {
		type: 'GET_ACCOUNTS_FAILURE',
		exception: exception
	}
}

export function getRolesRequest() {
	return {
		type: 'GET_ROLES_REQUEST'
	}
}

export function getRolesSuccess(data) {
	return {
		type: 'GET_ROLES_SUCCESS',
		data: data
	}
}

export function getRolesFailure(exception) {
	return {
		type: 'GET_ROLES_FAILURE',
		exception: exception
	}
}

export function getContactInfoRequest() {
	return {
		type: 'GET_CONTACT_INFO_REQUEST'
	}
}

export function getContactInfoSuccess(data) {
	return {
		type: 'GET_CONTACT_INFO_SUCCESS',
		data: data
	}
}

export function getContactInfoFailure(exception) {
	return {
		type: 'GET_CONTACT_INFO_FAILURE',
		exception: exception
	}
}

export function getContactUsernameRequest() {
	return {
		type: 'GET_CONTACT_USERNAME_REQUEST'
	}
}

export function getContactUsernameSuccess(data) {
	return {
		type: 'GET_CONTACT_USERNAME_SUCCESS',
		data: data
	}
}

export function getContactUsernameFailure(exception) {
	return {
		type: 'GET_CONTACT_USERNAME_FAILURE',
		exception: exception
	}
}

export function getAccountInfoRequest() {
	return {
		type: 'GET_ACCOUNT_INFO_REQUEST'
	}
}

export function getAccountInfoSuccess(data) {
	return {
		type: 'GET_ACCOUNT_INFO_SUCCESS',
		data: data
	}
}

export function getAccountInfoFailure(exception) {
	return {
		type: 'GET_ACCOUNT_INFO_FAILURE',
		exception: exception
	}
}

export function getPropertyDetailsRequest() {
	return {
		type: 'GET_PROPERTY_DETAILS_REQUEST'
	}
}

export function getPropertyDetailsSuccess(body) {
	return {
		type: 'GET_PROPERTY_DETAILS_SUCCESS',
		body: body
	}
}

export function getPropertyDetailsFailure(exception) {
	return {
		type: 'GET_PROPERTY_DETAILS_FAILURE',
		exception: exception
	}
}

export function validateAccountReferenceNumberRequest() {
	return {
		type: 'VALIDATE_ACCOUNT_REFERENCE_NUMBER_REQUEST'
	}
}

export function validateAccountReferenceNumberSuccess(data) {
	return {
		type: 'VALIDATE_ACCOUNT_REFERENCE_NUMBER_SUCCESS',
		data: data
	}
}

export function validateAccountReferenceNumberFailure(exception) {
	return {
		type: 'VALIDATE_ACCOUNT_REFERENCE_NUMBER_FAILURE',
		exception: exception
	}
}

/* istanbul ignore next */
export function redirectToPaymentPortal(paymentDetails, order) {
	return (dispatch) => {
		dispatch(redirectToPaymentPortalRequest())
		const api = new Api();
		return api.post("/__api/payments", paymentDetails, {
				headers: {
					Region: "NZ"
				}
			})
			.then(response => {
				if (response.data.url) {
					const escape = require('escape-html');
          const payment_redirect = escape(response.data.url);
          window.location.href = payment_redirect;
				} else {
					history.push("/order_status")
					if (response.data.result) {
						const dataLayerService = new DataLayerService();
						dataLayerService.setCheckoutOptionDataLayer("Trial")
						dataLayerService.setDataLayer(order)
						dispatch(clearOrder())
					}
				}
				dispatch(redirectToPaymentPortalSuccess(response.data))
			})
			.catch(err => dispatch(redirectToPaymentPortalFailure(err)));
	};
}

/* istanbul ignore next */
export function updateContactInfo(contactInfo) {
  if ('mobileMfa' in contactInfo) delete contactInfo.mobileMfa;
  return (dispatch) => {
    dispatch(updateContactInfoRequest());
    const api = new Api();
    return api
      .post('/__api/user/update', contactInfo, { headers: { Region: 'AU' } })
      .then((response) => dispatch(updateContactInfoSuccess(response.data)))
      .catch((err) => dispatch(updateContactInfoFailure(err)));
  };
}

/* istanbul ignore next */
export function directDebitPayment(order, directDebitDetails) {
	order.bsb = directDebitDetails.bsbNumber
	order.accNumber = directDebitDetails.accountNumber
	order.accName = directDebitDetails.accountName
	return (dispatch) => {
		dispatch(directDebitPaymentRequest())
		const api = new Api();
		return api.patch("/__api/direct_debit_order", order, {
				headers: {
					Region: "NZ"
				}
			})
			.then(response => {
				Promise.all([
					dispatch(directDebitPaymentSuccess(response.data))
				]).then(() => {
					dispatch(clearOrder())
					history.push("/order_status")
					const dataLayerService = new DataLayerService();
					if (response.data.order) {
						dataLayerService.setCheckoutOptionDataLayer("Direct Debit")
						dataLayerService.setDataLayer(response.data)
					}
				})
			})
			.catch(err => dispatch(directDebitPaymentFailure(err)));
	};
}

export function saveCustomerDetails(order, type, paymentDetails) {
	let updatedOrder = null
	return (dispatch) => {
		dispatch(saveCustomerDetailsRequest())
		order.customer.ipAddress = null
		order.customer.timeZone = timezone.name();
		let country = order.customer.country;
		if(country == "Australia") {
			order.customer.country="AU"
		}
		if(country == "New Zealand") {
			order.customer.country="NZ"
		}
		const api = new Api();
		return api.patch("/__api/update_orders", order, {
				headers: {
					Region: "NZ"
				}
			})
			.then(response => {
				updatedOrder = response.data
				Promise.all([
					dispatch(saveCustomerDetailsSuccess(response.data))
				]).then(() => {
					dispatch(updateOrderDetailsSuccess(response.data))
					if (type === 'credit') {
						dispatch(redirectToPaymentPortal(paymentDetails, updatedOrder))
					} else if (type === 'trial') {
						// dispatch(trialPurchase())
						paymentDetails.paymentGateway = "DPS"
						paymentDetails.payCode = "TV"
						dispatch(redirectToPaymentPortal(paymentDetails, updatedOrder))
					}
				})
			})
			.catch(err => dispatch(saveCustomerDetailsFailure(err)));
	};
}

export function fetchPaymentDetails(payment_ref, order, isPurchaseEventFlagged = false) {
	return (dispatch) => {
		dispatch(fetchPaymentDetailsRequest())
		const api = new Api();
		return api.get("/__api/payment/" + payment_ref, {
				headers: {
					Region: "NZ"
				}
			})
			.then(response => {
				Promise.all([
					dispatch(fetchPaymentDetailsSuccess(response.data))
				]).then(() => {
					dispatch(clearOrder())
					const dataLayerService = new DataLayerService();
					if (order && (response.data.status === "PRE_AUTH") && !isPurchaseEventFlagged) {
						dataLayerService.setCheckoutOptionDataLayer("Credit Card")
						dataLayerService.setDataLayer({
							order: order
						})
					}
				})
			})
			.catch(err => dispatch(fetchPaymentDetailsFailure(err)));
	};
}

export function addressLines(line) {
	return (dispatch) => {
		dispatch(fetchAddressLinesRequest())
		const api = new Api();
		return api.get("/__api/address", {
				params: {
					q: line
				},
				headers: {
					Region: "NZ"
				}
			})
			.then(response => dispatch(fetchAddressLinesSuccess(response.data)))
			.catch(err => dispatch(fetchAddressLinesFailure(err)));
	};
}

export function fetchAbnDetails(abn) {
	return (dispatch) => {
		dispatch(fetchAbnDetailsRequest())
		const api = new Api();
		return api.get("/__api/abn", {
				params: {
					abn: abn
				},
				headers: {
					Region: "NZ"
				}
			})
			.then(response => dispatch(fetchAbnDetailsSuccess(response.data)))
			.catch((err) => {
				dispatch(fetchAbnDetailsFailure(err))
			  });
	};
}

export function fetchCurrentOrder() {
	return (dispatch) => {
		dispatch(fetchCurrentOrderRequest())
		const api = new Api();
		return api.get("/__api/order/current", {
				headers: {
					Region: "NZ"
				}
			})
			.then(response => {
				dispatch(fetchCurrentOrderSuccess(response.data))
			})
			.catch(err => dispatch(fetchCurrentOrderFailure(err)));
	};
}

export function setCustomerDetails(customerObject) {
	return {
		type: 'SET_CUSTOMER_DETAILS',
		data: customerObject
	}
}

export function getAccounts(code) {
	return (dispatch) => {
		dispatch(getAccountsRequest())
		const api = new Api();
		return api.get("/__api/user/accounts", {
				params: {
					code: code
				},
				headers: {
					'Content-Type': 'text/plain',
					Region: "NZ"
				}
			})
			.then(response => {
				dispatch(getAccountsSuccess(response.data))
			})
			.catch(err => dispatch(getAccountsFailure(err)));
	};
}

export function getRoles(accountGuid) {
	return (dispatch) => {
		dispatch(getRolesRequest())
		const api = new Api();
		return api.get("/__api/user/accounts/" + accountGuid + "/roles", {
				headers: {
					Region: "NZ"
				}
			})
			.then(response => {
				dispatch(getRolesSuccess(response.data))
			})
			.catch(err => dispatch(getRolesFailure(err)));
	};
}

export function getContactInfo() {
	return (dispatch) => {
		dispatch(getContactInfoRequest())
		const api = new Api();
		return api.get("/__api/user/contact", {
				headers: {
					Region: "NZ",
					Pragma: 'no-cache',
					'Cache-Control': 'no-store, no-cache, must-revalidate',
					Expires: -1
				}
			})
			.then(response => {
				dispatch(getContactInfoSuccess(response.data))
			})
			.catch(err => dispatch(getContactInfoFailure(err)));
	};
}

export function getContactUsername(accountGuid) {
	return (dispatch) => {
		dispatch(getContactUsernameRequest())
		const api = new Api();
		return api.get("/__api/user/" + accountGuid + "/username", {
				headers: {
					Region: "NZ"
				}
			})
			.then(response => {
				dispatch(getContactUsernameSuccess(response.data))
			})
			.catch(err => dispatch(getContactUsernameFailure(err)));
	};
}

export function getAccountInfo(accountNumber) {
	return (dispatch) => {
		dispatch(getAccountInfoRequest())
		const api = new Api();
		return api.get("/__api/user/accounts/" + accountNumber, {
				headers: {
					Region: "NZ"
				}
			})
			.then(response => {
				dispatch(getAccountInfoSuccess(response.data))
			})
			.catch(err => dispatch(getAccountInfoFailure(err)));
	};
}

export function getPropertyDetails(propertyId) {
	return (dispatch) => {
		dispatch(getPropertyDetailsRequest())
		const api = new Api();
		return api.get("/__api/address/" + propertyId, {
				headers: {
					Region: "NZ"
				}
			})
			.then(response => dispatch(getPropertyDetailsSuccess(response.data)))
			.catch(err => dispatch(getPropertyDetailsFailure(err)));
	};
}

export function resetCheckoutLoaders() {
	return {
		type: 'RESET_CHECKOUT_LOADERS'
	}
}

export function resetAbnLookupProps() {
	return {
	  type: 'RESET_ABN_LOOKUP_PROPS'
	};
  }
  
  export function resetAddressLookupProps() {
	return {
	  type: 'RESET_ADDRESS_LOOKUP_PROPS'
	};
  }
  

export function validateAccountReferenceNumber(accountReferenceNumber) {
	return (dispatch) => {
		dispatch(validateAccountReferenceNumberRequest())
		const api = new Api();
		return api.get("/__api/user/accounts/" + accountReferenceNumber + "/check", {
				headers: {
					Region: "NZ"
				}
			})
			.then(response => {
				dispatch(validateAccountReferenceNumberSuccess(response.data))
			})
			.catch(err => dispatch(validateAccountReferenceNumberFailure(err)));
	};
}
