import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components
import CordellEstimatorProductPackage from './components/products/cordellEstimator/cordellEstimatorProductPackage';
import RpnzProductPackage from './components/products/rpnz/rpnzProductPackage';
import GuruProductPackage from './components/products/guru/GuruProductPackage';

import {
  fetchProduct,
  setPackage,
  fetchAffiliatesDetails,
  setCouponCodeForCart
} from '../actions/packageSelection';
import { setCookieNotification } from '../actions/cookiesSettings';

import history from '../history';
import qs from 'query-string';

// Services
import {
  trackPackageSelection,
  analyticsPageView,
  trackError
} from '../services/v2/segmentAnalytics';

const PackageSelectionPage = (props) => {
  const product = useSelector((state) => state.packageSelection);
  const coupon = useSelector((state) => state.packageSelection.coupon);
  const loading = useSelector((state) => state.packageSelection.loading);
  const selectedPackage = useSelector((state) => state.packageSelection.selectedPckage);
  const dispatch = useDispatch();

  // CONSTANTS
  const FRIENDLY_NAME = props.match.params.friendly_name;
  const PRODUCTS_CONFIG = product.productsConfig[FRIENDLY_NAME];

  props.history.listen((location) => {
    if (/^\/product\/[a-zA-Z\-=?]+$/.test(location.pathname)) {
      window.location.reload();
    }
  });

  const trackPackageSelectionAnalytics = (industryKey, packageKey) => {
    let productName = product.productsConfig[FRIENDLY_NAME].productName;
    let configuration = product.productsConfig[FRIENDLY_NAME].configuration;

    let packageTitle = industryKey
      ? configuration.industries[industryKey].packages[packageKey].title
      : configuration.packages[packageKey].title;

    let industryTitle;
    if (configuration.industries) {
      industryTitle = configuration.industries[industryKey].title;
    }
    trackPackageSelection(productName, industryTitle, packageTitle);
  };

  const onSelectPackage = (industryKey, packageKey) => {
    trackPackageSelectionAnalytics(industryKey, packageKey);

    dispatch(setPackage(industryKey, packageKey, FRIENDLY_NAME));
    history.push(`/product_config/${product.productsConfig[FRIENDLY_NAME].id}`);
  };

  const selectPackageByFriendlyName = (packageParam) => {
    if (packageParam !== undefined) {
      if (FRIENDLY_NAME === 'rpnz' && packageParam === 'rpnz') {
        onSelectPackage(null, 'rpnz');
      } else if (FRIENDLY_NAME === 'cordell-costings-nz') {
        if (packageParam === 'cordell_estimator_lite') {
          onSelectPackage(null, 'cordell_estimator_lite');
        } else if (packageParam === 'cordell_estimator_platinum') {
          onSelectPackage(null, 'cordell_estimator_platinum');
        }
      } else if (FRIENDLY_NAME === 'guru' && (packageParam === 'base' || packageParam === 'lite' || packageParam === 'premium' || packageParam === 'pro')) {
        onSelectPackage(null, packageParam);
      }
    }
  };

  const handleFetchProduct = (packageParam) => {
    dispatch(fetchProduct(FRIENDLY_NAME)).then(function () {
      selectPackageByFriendlyName(packageParam);

      if (product.productFetchFailure && product.failureMessage !== null) {
        trackError('Fetch Product', null, product.failureMessage, '404');
      }
    });
  };

  useEffect(() => {
    analyticsPageView('Package Selection');

    window.scrollTo(0, 0);
    let couponParam = null;
    let packageParam = null;
    let cookiesAccepted = false;
    if (
      props.location.search.length > 0 &&
      props.location.search !== undefined &&
      props.location.search !== null
    ) {
      couponParam = qs.parse(props.location.search).coupon
        ? qs.parse(props.location.search).coupon
        : null;
      packageParam = qs.parse(props.location.search).package
        ? qs.parse(props.location.search).package
        : null;
      cookiesAccepted = qs.parse(props.location.search).cookiesAccepted
        ? qs.parse(props.location.search).cookiesAccepted
        : false;
    }

    if (cookiesAccepted) {
      dispatch(setCookieNotification());
    }

    if (!couponParam && coupon && Object.keys(coupon).length > 0) {
      couponParam = coupon.code;
    }

    dispatch(setCouponCodeForCart(couponParam));

    if (couponParam) {
      dispatch(fetchAffiliatesDetails(couponParam, packageParam)).then(function () {
        handleFetchProduct(packageParam);
      });
    } else {
      handleFetchProduct(packageParam);
    }
  }, []);

  return (
    <React.Fragment>
      {!loading && (
        <React.Fragment>
          {product.productFetchFailure && product.failureMessage !== null && (
            <div className='off-white-bg'>
              <div className='container'>
                <div className='row no-margin'>
                  <div className='col-sm-12'>
                    <div
                      className='row white-bg cart-container margin-bottom-100 margin-top-100'
                      style={{ fontWeight: '600' }}
                    >
                      <br />
                      <div className='cart-item-header text-center red-text'>
                        {' '}
                        {product.failureMessage}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {PRODUCTS_CONFIG?.quoteServiceType === 'RPNZ' && (
            <RpnzProductPackage
              coupon={coupon}
              onSelectPackage={onSelectPackage}
              productsConfig={PRODUCTS_CONFIG.configuration}
              productId={PRODUCTS_CONFIG.id}
            />
          )}
          {PRODUCTS_CONFIG?.quoteServiceType === 'CordellEstimatorNZ' && (
            <CordellEstimatorProductPackage
              coupon={coupon}
              onSelectPackage={onSelectPackage}
              productsConfig={PRODUCTS_CONFIG.configuration}
              productId={PRODUCTS_CONFIG.id}
            />
          )}
          {PRODUCTS_CONFIG?.quoteServiceType === 'Guru' && (
            <GuruProductPackage 
              coupon={coupon}
              method={PRODUCTS_CONFIG.method}
              onSelectPackage={onSelectPackage}
              productId={PRODUCTS_CONFIG.id}
              productsConfig={PRODUCTS_CONFIG.configuration}
              selectedPackage={selectedPackage}
              productName={PRODUCTS_CONFIG.productName}
            />
          )}
        </React.Fragment>
      )}

      {loading && (
        <div className='row spinner'>
          <i className='fa fa-spinner fa-pulse fa-3x fa-fw'></i>
          <span className='sr-only'>Loading...</span>
        </div>
      )}
    </React.Fragment>
  );
};

export default PackageSelectionPage;
