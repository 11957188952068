import {
  SAVE_CART_ITEM_REQUEST,
  SAVE_CART_ITEM_SUCCESS,
  SAVE_CART_ITEM_FAILURE,
  DELETE_CART_ITEM_REQUEST,
  DELETE_CART_ITEM_SUCCESS,
  DELETE_CART_ITEM_FAILURE,
  CHECKOUT_REQUEST,
  CHECKOUT_SUCCESS,
  CHECKOUT_FAILURE,
  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAILURE,
  FETCH_CURRENT_ORDER_REQUEST,
  FETCH_CURRENT_ORDER_SUCCESS,
  FETCH_CURRENT_ORDER_FAILURE,
  FETCH_CART_REQUEST,
  FETCH_CART_SUCCESS,
  FETCH_CART_FAILURE,
  CLEAR_ORDER,
  SET_ORDER,
  APPLY_COUPON_REQUEST,
  APPLY_COUPON_SUCCESS,
  APPLY_COUPON_FAILURE,
  DELETE_COUPON_REQUEST,
  DELETE_COUPON_SUCCESS,
  DELETE_COUPON_FAILURE,
  USER_SESSION_REQUEST,
  USER_SESSION_SUCCESS,
  USER_SESSION_FAILURE,
  UPDATE_ORDER_DETAILS_SUCCESS
} from '../constants';

const initialProductState = {
  cartQuotes: {},
  cart: {},
  order: {},
  saveCartItemFailure: false,
  saveCartItemSuccess: false,
  loading: false,
  orderCheckout: false,
  paidOrder: {},
  cartLoader: false,
  coupon: {},
  loggedIn: false,
  checkoutErrors: [],
};

export default (state = initialProductState, action = {}) => {
  //This is to ensure immutability of state
  let newState = { ...state };

  switch (action.type) {
    case SAVE_CART_ITEM_REQUEST:
      newState.loading = true;
      newState.saveCartItemFailure = false;
      newState.saveCartItemSuccess = false;
      return newState;
    case SAVE_CART_ITEM_SUCCESS:
      if (action.data) {
        newState.cartQuotes = action.data.quote;
        newState.cart = action.data.cart;
        newState.loading = false;
        newState.saveCartItemSuccess = true;
      }
      return newState;
    case SAVE_CART_ITEM_FAILURE:
      newState.loading = false;
      newState.saveCartItemFailure = true;
      return newState;
    case DELETE_CART_ITEM_SUCCESS:
      newState.loading = false;
      newState.cartQuotes = action.data.quote;
      newState.cart = action.data.cart;
      return newState;
    case CHECKOUT_SUCCESS:
      newState.loading = false;
      newState.orderCheckout = true;
      newState.order = action.data.order;
      newState.paidOrder = {};
      newState.loggedIn = true;
      return newState;
    case CHECKOUT_FAILURE:
      const responseData = action.exception.response.data;
      newState.checkoutErrors = responseData.errors;
      newState.loading = false;
      newState.loggedIn = false;
      return newState;
    case USER_SESSION_SUCCESS:
      newState.loggedIn = action.body;
      newState.loading = false;
      return newState;
    case DELETE_ORDER_SUCCESS:
      newState.order = {};
      newState.cartQuotes = action.data.quote;
      newState.cart = action.data.cart;
      return newState;
    case DELETE_ORDER_FAILURE:
      if (action.message === 'Request failed with status code 404') {
        newState.order = {};
      }
      return newState;
    case FETCH_CURRENT_ORDER_REQUEST:
      newState.order = {};
      return newState;
    case UPDATE_ORDER_DETAILS_SUCCESS:
    case FETCH_CURRENT_ORDER_SUCCESS:
    case SET_ORDER:
      newState.order = action.data.order;
      return newState;
    case DELETE_ORDER_REQUEST:
    case FETCH_CURRENT_ORDER_FAILURE:
      return newState;
    case FETCH_CART_REQUEST:
      newState.cartLoader = true;
      newState.order = {};
      return newState;
    case FETCH_CART_SUCCESS:
      newState.cartLoader = false;
      newState.cartQuotes = action.data.quote;
      newState.cart = action.data.cart;
      return newState;
    case CLEAR_ORDER:
      if (Object.keys(newState.paidOrder).length === 0) {
        let paidOrder = newState.order;
        newState = initialProductState;
        newState.paidOrder = paidOrder;
      }
      return newState;
    case APPLY_COUPON_SUCCESS:
      newState.coupon = action.body;
      return newState;
    case DELETE_COUPON_SUCCESS:
      newState.coupon = {};
      return newState;
    case CHECKOUT_REQUEST:
    case DELETE_CART_ITEM_REQUEST:
    case USER_SESSION_REQUEST:
      newState.loading = true;
      return newState;
    case DELETE_CART_ITEM_FAILURE:
    case USER_SESSION_FAILURE:
      newState.loading = false;
      return newState;
    case APPLY_COUPON_REQUEST:
    case APPLY_COUPON_FAILURE:
    case DELETE_COUPON_REQUEST:
    case DELETE_COUPON_FAILURE:
    case FETCH_CART_FAILURE:
      return newState;
    default:
      return state;
  }
};
