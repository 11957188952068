import React from "react";
import { connect } from 'react-redux'
import {resetState} from '../../../actions/cart'
import Avatar from '../../../image/avatar.svg';

class UserIcon extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      showOption: false
    }
  }

  resetState(){
    this.props.analyticsLogoutUser()
    this.props.resetState()
  }

  showUserOptions(){
    this.setState({
      showOption: !this.state.showOption
    })
  }

  render(){
    let renderUserIcon = null;
    if (!this.props.login?.userLoggedIn) 
      renderUserIcon = 
        <a id='register' href="/register">
          <img
            alt='Brand'
            id='avatar'
            src={Avatar}
          />
        </a>
    else
      renderUserIcon = <>
        {
          this.props.firstName && this.props.lastName ?
          <a id="avatar-initials" onClick={()=>{this.showUserOptions()}}>
            {this.props.firstName[0]}{this.props.lastName[0]}
          </a> :
          <a onClick={()=>{this.showUserOptions()}}>
            <img
              alt='Brand'
              id='avatar'
              src={Avatar}
            />
          </a>
        }
        { this.state.showOption &&
          <div className="user-options" id="user-logout-btn" onClick={()=>{this.resetState()}}>
            <div id='menuItem_Logout' className="font-16-nowrap">Log Out</div>
          </div>
        }
        </>
    return (<>{renderUserIcon}</>)
  }
}

const mapStateToProps = (state) => {
  return {
    cart: state.cart.cart,
    order: state.cart.order,
    login: state.login,
    firstName: state.registration?.emailValidation?.firstName,
    lastName: state.registration?.emailValidation?.lastName
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetState:() => dispatch(resetState())
  }
}

let UserIconComponent = connect(mapStateToProps, mapDispatchToProps)(UserIcon)

export default UserIconComponent
