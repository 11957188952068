import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchPaymentDetails } from '../actions/checkout';
import { resetCoupon } from '../actions/packageSelection';
import { flagPurchaseEvent } from '../actions/pxpay';

import classroom from './../image/training_classroom.png';
import training from './../image/training_onsite.png';
import online from './../image/training_online.png';

import step_one from './../image/Order_Step_1.svg'; 
import step_two from './../image/Order_Step_2.svg';
import step_three from './../image/Order_Step_3.svg';

import green_check from './../image/Icons/green_check.svg';

import './Payments.css'

import {
  analyticsPageView,
  trackAction,
  trackGenericPageInfo,
  trackOrderStatus
} from '../services/v2/segmentAnalytics';

const PaymentStatusPage = (props) => {
  const [condition1, setCondition1] = useState(false);
  const [condition2, setCondition2] = useState(false);
  const [productName, setProductName] = useState('RPNZ and Property Guru')

  const payment = useSelector((state) => state.checkout.payment);
  const paidOrder = useSelector((state) => state.cart.paidOrder);
  const order = useSelector((state) => state.cart.order);
  const isPurchaseEventFlagged = useSelector((state) => state.pxpay.isPurchaseEventFlagged);

  const dispatch = useDispatch();

  const closeCurrentWindowTab = () => {
    window.open("about:blank", "_self");
    window.close();

  };

  const redirectToURL = (link, pageStatus) => {
    trackAction('Return to corporate site', 'CoreLogic home', pageStatus);
    window.location.href = link;
  };

  const redirectToCheckout = (link, pageStatus) => {
    trackGenericPageInfo('Checkout', pageStatus);
    window.location.href = link;
  };

  const getPaidOrderItems = (newPaidOrder) => {
    let newCondition1 = false;
    let newCondition2 = false;
    let containsGuru = false;
    let containsRPNZ = false;

    if (newPaidOrder && Object.keys(newPaidOrder).length > 0) {
      let cartQuote = newPaidOrder.quotes.quotes;

      for (let index = 0; index < cartQuote.length; index++) {
        let quote = cartQuote[index];

        newCondition1 = true;

        if (quote.type !== null) {
          if (quote.type !== 'CordellEstimatorNZ') newCondition2 = true;
          if (quote.type === 'Guru') containsGuru = true;
          if (quote.type === 'RPNZ') containsRPNZ = true;
        }
      }
    }
    
    if (containsGuru && containsRPNZ) setProductName('RPNZ and Property Guru')
    else if (containsGuru) setProductName('Property Guru')
    else if (containsRPNZ) setProductName('RPNZ')

    setCondition1(newCondition1);
    setCondition2(newCondition2);
  };

  const triggerOrderStatusEvent = (payment_id, newOrder) => {
    if (payment.status !== 'PRE_AUTH') {
      trackOrderStatus(newOrder, 'Fail', 'Declined', payment_id);
    } else {
      trackOrderStatus(newOrder, 'Success', 'Payment Successful', payment_id);
    }
  };

  useEffect(() => {
    analyticsPageView('Order Status');

    const currentOrder = order && Object.keys(order).length > 0 ? order : paidOrder;

    dispatch(fetchPaymentDetails(props.match.params.payment_id, currentOrder, isPurchaseEventFlagged)).then(function () {
      getPaidOrderItems(currentOrder);
      //reset coupon value after successful transaction
      if (payment.status === 'PRE_AUTH') dispatch(resetCoupon());

      if (!isPurchaseEventFlagged) {
        triggerOrderStatusEvent(props.match.params.payment_id, currentOrder);
        dispatch(flagPurchaseEvent());
      }
    });

    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {Object.keys(payment).length > 0 && (
        <div className='container'>
          <div className='row margin-bottom-100'>
            <div className='col-sm-12 text-white payment-status'>
              {payment.status === 'PRE_AUTH' && (
                <div className='row'>
                  <div className='col-sm-12'>
                    <div className='order-success-header'>
                      <span>
                        <img src={green_check} alt='Success' />
                      </span>
                      <div className='heading1 padding-10 payments-h3' style={{color: "#48b295"}}>
                        Order #
                        {order && Object.keys(order).length > 0
                          ? order.orderNumber
                          : paidOrder.orderNumber}{' '}
                        request received
                      </div>
                      <div>
                        <p>Thank you for your order. We have just sent you an email confirming this request.</p>
                      </div>
                    </div>
                    <div className='padding-30-0-10 step-header payments-h6' style={{margin:"20px 0px"}}>Next Steps</div>
                    <div className='success-page-row'>
                      <div className='success-page-column'  style={{maxWidth:'350px'}}>
                        <span className='step-title payments-h4'>Step 1</span>
                        <img src={step_one} className='success-image' height='250' width='350' alt='Step 1' />
                        <p className='step-instructions payments-h4 heading3'>
                          1. Open the “Welcome to Property Guru“ invite email.
                        </p>
                        <p className='step-instructions-sub body'>
                          You should received the email within the next 48 hours.
                        </p>
                      </div>

                      <div className='success-page-column'  style={{maxWidth:'350px'}}>
                        <span className='step-title payments-h4'>Step 2</span>
                        <img src={step_two} className='success-image' height='250' width='350' alt='Step 2' />
                        <p className='step-instructions payments-h4 heading3'>
                          2. Click on the "Accept invite" link to continue setting up your account.
                        </p>
                      </div>

                      <div className='success-page-column' style={{maxWidth:'350px'}}>
                        <span className='step-title payments-h4'>Step 3</span>
                        <img src={step_three} className='success-image' height='250' width='350' alt='Step 3' style={{objectFit: 'cover'}} />
                        <p className='step-instructions payments-h4 heading3'>
                          3. Select ‘Yes’ and enter the username, email and password you just used when 
                          placing your order on our online store.
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div className='padding-10 body-semibold heading3'>
                      From there just follow the on-screen prompts to start using Property Guru.
                    </div>
                    <hr />
                    <p className='footnote-text' >
                      If you have any questions, please contact our Customer Care team on <b>0800 355 355</b>{' '}
                      and refer to order #
                      {order && Object.keys(order).length > 0
                        ? order.orderNumber
                        : paidOrder.orderNumber}
                      .
                    </p>

                      <p className='footnote-text' >
                        You may visit our&nbsp;
                        <a className='blue-link' href='https://nzhelpcentre.corelogic.co.nz/hc/en-nz/categories/360000568495-Property-Guru'>help center</a>
                        &nbsp;for additional support
                      </p>
                      <p className='footnote-text' >
                        Thank you for choosing property Guru
                      </p>
                    <p>
                      You may now{' '}
                      <a
                        className='blue-link'
                      >
                        close this window
                      </a>{' '}
                      or return to{' '}
                      <a
                        className='blue-link'
                        target='_blank'
                        rel='noopener noreferrer'
                        href='https://corelogic.co.nz'
                      >
                        corelogic.co.nz
                      </a>
                    </p>
                  </div>
                  </div>
              )}
              {payment.status !== 'PRE_AUTH' && (
                <div className='row'>
                  <div className='body'>
                    <p>
                      Unfortunately your order cannot be processed as your transaction has been
                      declined.
                    </p>
                    <p>Please attempt your purchase again</p>
                    <a
                      onClick={() => {
                        redirectToCheckout('/checkout', 'Failure');
                      }}
                    >
                      <button type='button' className='btn btn-outline padding-10'>
                        Return to Checkout
                      </button>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPaymentDetails: (payment_ref, order) => dispatch(fetchPaymentDetails(payment_ref, order)),
    resetCoupon: () => dispatch(resetCoupon())
  };
};

export default PaymentStatusPage;
