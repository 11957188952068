import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { YEARLY_DISCOUNT } from '../../../utils/constants';

/**
 * CartItems.
 * Displays the cart items for the Cart Page.
 *
 * @author  Manny
 * @param   {Object}      props.cartQuotes        Current Cart Quotes.
 * @param   {Object}      props.couponEntity      Coupon Object.
 * @param   {Boolean}     props.isCouponValidated Flag for confirming if coupon has been validated.
 * @param   {Function}    props.pricingFormat     Function from the Cart Item to format pricing.
 * @param   {Number}      props.totalMonths       Number of contract months for a cart quote.
 * @returns {JSX.Element}                         The component markup.
 */

const CartTotals = ({ cartQuotes, couponEntity, isCouponValidated, pricingFormat, totalMonths }) => {
  const [cartSubTotal, setCartSubTotal] = useState(0);
  const [cartSubTotalUpfront, setCartSubTotalUpfront] = useState(0);
  const [cartDiscount, setCartDiscount] = useState(0);
  const [cartTotal, setCartTotal] = useState(0);

  const { discountServiceType } = couponEntity || {};
  const { quotes } = cartQuotes || {};

  const isYearlyCouponApplied = () => {
    return couponEntity && discountServiceType === YEARLY_DISCOUNT;
  };

  const isMultipleYearlyCouponOrder = () => {
    return isYearlyCouponApplied() && quotes?.length > 1;
  };

  const calculateCartDiscount = () => {
    let discount = 0;
    const yearlyCoupon = isYearlyCouponApplied();
    if (quotes != null && quotes != {}) {
      for (let quote of quotes) {
        if (yearlyCoupon) discount += quote.yearlyDiscount;
        else discount += quote.discount;
      }
    }

    if (cartQuotes.cartDiscount !== null) discount = discount + cartQuotes.cartDiscount;
    setCartDiscount(discount.toFixed(2));
    return discount;
  };

  /* istanbul ignore next */
  const calculateCartSubTotalUpfront = () => {
    let originalYearlyPrice = 0;
    if (isMultipleYearlyCouponOrder() && quotes != null) {
      for (let quote of quotes) {
        if (quote.discount > 0) {
          originalYearlyPrice += quote.originalYearlyPrice;
        }
      }
    }
    setCartSubTotalUpfront(originalYearlyPrice.toFixed(2));
    return originalYearlyPrice;
  };

  /* istanbul ignore next */
  const calculateCartSubTotal = (discount) => {
    if (isMultipleYearlyCouponOrder() && quotes != null) {
      let originalPrice = 0;
      for (let quote of quotes) {
        if (quote.discount == 0) {
          originalPrice += quote.originalPrice;
        }
      }
      setCartSubTotal(originalPrice.toFixed(2));    
    } else {
      setCartSubTotal((cartQuotes.cartTotal + discount).toFixed(2));
    }
  };

  const calculateCartTotal = () => {
    setCartTotal(cartQuotes.cartTotal.toFixed(2));
  };

  useEffect(() => {
    if (isCouponValidated) {
      const discount = calculateCartDiscount();
      calculateCartSubTotal(discount);
      calculateCartTotal();
      calculateCartSubTotalUpfront();
    }
  }, [cartQuotes, couponEntity, isCouponValidated]);


  return (
    <React.Fragment>
      {cartSubTotalUpfront > 0 && (
        <div className='row cart-item-header cart-h5'>
          <div className='col-xs-6'>
            <div className='text-right'>Subtotal Upfront</div>
          </div>
          <div className='col-xs-6'>
            <div id='cart_subtotal_upfront' className='text-right'>
              ${pricingFormat(cartSubTotalUpfront)}
            </div>
          </div>
        </div>
      )}
      {cartSubTotal > 0 && (
        <div className='row cart-item-header cart-h5'>
          <div className='col-xs-6'>
            <div className='text-left'>
              {isMultipleYearlyCouponOrder() && <span>Monthly </span>}
              Subtotal
            </div>
          </div>
          <div className='col-xs-6'>
            <div id='cart_subtotal' className='text-right cart-h5'>
              ${pricingFormat(cartSubTotal)}
            </div>
          </div>
        </div>
      )}
      {cartDiscount > 0 && (
        <div className='row cart-item-header cart-h4'>
          <div className='col-xs-6'>
            <div className='text-left success-text'>Discount</div>
          </div>
          <div className='col-xs-6'>
            <div className='text-right success-text'>${pricingFormat(cartDiscount)}</div>
          </div>
        </div>
      )}
      {cartDiscount > 0 && <hr />}

      <div className='row cart-item-header cart-h4'>
        <div className='col-xs-6'>
          <div className='text-left'>Total</div>
        </div>
        <div className='col-xs-6'>
          <div id='cart_total' className='text-right'>
            ${pricingFormat(cartTotal)}
          </div>
          {!isYearlyCouponApplied() > 0 && cartTotal > 0 && totalMonths !== 1 && (
            <div className='text-right font-12 captions'>Per month</div>
          )}
          {isYearlyCouponApplied() > 0 && totalMonths !== 1 && (
            <div className='text-right'>Up Front</div>
          )}
          {cartTotal === 0 && <div className='text-right'>for 7 days</div>}
          <div>
            {cartTotal === 0 && (
              <div className='text-right footnote-text'>
                <small>Min. cost $0 for 7 days.</small>
              </div>
            )}
            {cartTotal > 0 && (
              <div className='text-right footnote-text'>
                <small id='cart_total_min_cost'>
                  Min. cost ${pricingFormat(cartQuotes.cartMinTotal.toFixed(2))} over {totalMonths}{' '}
                  month(s).
                </small>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

CartTotals.propTypes = {
  cartQuotes: PropTypes.object.isRequired,
  couponEntity: PropTypes.object,
  isCouponValidated: PropTypes.bool.isRequired,
  pricingFormat: PropTypes.func.isRequired,
  totalMonths: PropTypes.number.isRequired
};

export default CartTotals;
