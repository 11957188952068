import React from "react";
import tick from "../../image/Icons/tick-ef4400.svg"
import { Tooltip, OverlayTrigger } from "react-bootstrap";


import './iconComparisonTable.css'

export default class IconComparisonTable extends React.Component{
  render(){
    let data = this.props.data
    let packageData =  this.props.industryPackage
    let type= this.props.type

    if(data !== undefined){
      var dataContent = Object.keys(data).map(function (singlePackage) {
        var item = data[singlePackage]
        var tooltip = (
          <Tooltip id="tooltip">
            {item.description}
          </Tooltip>
        );
        return (
          <div className="row table-padding" key={item.key}>
            <div className="col-xs-3">
              <div className="semi-bold no-margin text-left">
              <div className='column title-column-iconComparisonTable'>
                  {item.title}
              </div>
              {item.description!=null && <OverlayTrigger placement="top" overlay={tooltip} trigger={['click', 'hover', 'focus']}>
                <div className="column tooltip-column-iconComparisonTabl text-right">
                <span>
                  <i
                    className='far fa-question-circle tooltip-icon-new tooltip-alignment'
                    aria-hidden='true'
                  ></i>
                </span>
              </div>
                </OverlayTrigger>}
              </div>
            </div>
            {
              Object.keys(packageData).map(function (singlePackageData) {
                var subItem = packageData[singlePackageData]
                return(
                  <div className="col-xs-3 text-center body" key={subItem.key}>
                    {subItem.features[type] && subItem.features[type][item.key] === true && <img src={tick} alt="yes"/>}
                    {subItem.features[type] && subItem.features[type][item.key] === "Except VIC" &&
                      <div><img src={tick} alt="yes"/><div style={{fontSize:"12px", marginBottom:"-10px"}}>Except VIC</div></div>}
                    {subItem.features[type] && typeof(subItem.features[type][item.key]) === "string" && subItem.features[type][item.key] !== "Except VIC" && <span>{subItem.features[type][item.key]}</span>}
                    {subItem.features[type] && !subItem.features[type][item.key] && <span><i className="fa fa-minus tan-text" aria-hidden="true"></i></span>}

                    {/* If the value is an object */}
                    {subItem.features[type] && typeof(subItem.features[type][item.key]) === "object" && subItem.features[type][item.key].default && <img src={tick} alt="yes"/>}
                    {subItem.features[type] && typeof(subItem.features[type][item.key]) === "object" && <span>{subItem.features[type][item.key].price}</span>}
                  </div>
                )
              })
            }
          </div>
        );
      });
    }

    return (
      <div>
        {dataContent}
      </div>
    );
  }
}
