export default class loginService {
  validateEmail() {
    this.props.validateEmail().then(
      function () {
        if (this.props.registration.emailValidation.isEmailValidated) {
          this.props.checkout();
        }
      }.bind(this)
    );
  }

  redirectToLoginPage() {
    this.trackSignUp('Sign-in');
    this.props.redirectToLoginPage();
  }
}
