import {
  PAYMENT_PXPAY_REQUEST,
  PAYMENT_PXPAY_SUCCESS,
  PAYMENT_PXPAY_FAILURE,
  WINDCAVE_PROCESS_PAYMENT_REQUEST,
  WINDCAVE_PROCESS_PAYMENT_SUCCESS,
  WINDCAVE_PROCESS_PAYMENT_FAILURE
} from '../constants';

const initialPxPayState = {
  loader: false,
  success: false,
  failed: false
};

export default (state = initialPxPayState, action = {}) => {
  //This is to ensure immutability of state
  let newState = { ...state };

  switch (action.type) {
    case PAYMENT_PXPAY_SUCCESS:
      window.location.href = action.data.redirectUrl;
      newState.loader = false;
      newState.success = true;
      newState.failed = false;
      return newState;
    case PAYMENT_PXPAY_REQUEST:
    case WINDCAVE_PROCESS_PAYMENT_REQUEST:
      newState.loader = true;
      newState.failed = false;
      newState.success = false;
      return newState;
    case WINDCAVE_PROCESS_PAYMENT_SUCCESS:
      newState.payment = action.data;
      newState.loader = false;
      newState.success = true;
      newState.failed = false;
      return newState;
    case PAYMENT_PXPAY_FAILURE:
    case WINDCAVE_PROCESS_PAYMENT_FAILURE:
      newState.loader = false;
      newState.failed = true;
      newState.success = false;
      return newState;
    default:
      return state;
  }
};
