/* eslint-disable no-console */
import { setCustomerDetails } from '../../../actions/checkout';
import { registerCustomer } from '../../../actions/registration';
import { trackCompanyInfo } from '../segmentAnalytics';

const getErrors = (customer, errors) => {
  for (let key in customer) {
    if (Object.prototype.hasOwnProperty.call(customer, key) && isValueEmpty(customer[key]))
      errors[key] = true;
  }
  return errors;
};

const isValueEmpty = (element) => {
  return [null, '', true].includes(element);
};

const handleCustomerRegistration = (dispatch, customer) => {
  return dispatch(registerCustomer(customer));
};

const setCustomerInfo = (dispatch, customerObject) => {
  return dispatch(setCustomerDetails(customerObject));
};

const trackAndRedirectToConditionOfUse = (link, pageName) => {
  trackCompanyInfo(pageName);
  window.open(link, '_blank', 'noopener noreferrer');
};

const registrationFormInputChange = (event, registrationForm, errors) => {
  setCustomerDetails(registrationForm);
  const value = event.target.value;

  switch (event.target.name) {
    case 'firstName':
    case 'lastName':
      errors[event.target.name] = validateName(value);
      break;
    case 'username':
      errors['invalidUsername'] = false;
      errors['username'] = validateUsername(value);
      break;
    case 'emailAddress':
      errors.duplicateEmail = false;
      errors[event.target.name] = validateEmail(value);
      break;
    case 'password':
      errors['password'] = validatePassword(value);
      break;
    case 'confirmPassword':
      errors['confirmPassword'] = validateConfirmPassword(registrationForm['password'], value);
      break;
    case 'title':
      errors['title'] = isInvalidTitle(value);
      break;
    default:
      break;
  }
  return errors;
};

const isInvalidTitle = (value) => {
  return !["Mr.", "Ms.", "Mrs.", "Dr.", "Prof.", "Mx."].includes(value);
}

const validatePassword = (value) => {
  const specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  const checkMessages = [
    'between 8 to 128 characters',
    'at least one digit',
    'at least one lower case character',
    'at least one upper case character',
    'at least one special case character'
  ];
  let checks = [];
  if (value) {
    // between 8 to 128 characters
    checks.push(/^.{8,128}$/.test(value) ? 'valid' : 'invalid');
    // at least one digit
    checks.push(/\d/.test(value) ? 'valid' : 'invalid');
    // at least one lower case letter
    checks.push(/[a-z]/.test(value) ? 'valid' : 'invalid');
    // at least one upper case letter
    checks.push(/[A-Z]/.test(value) ? 'valid' : 'invalid');
    // at least one special character
    checks.push(specialCharacters.test(value) ? 'valid' : 'invalid');
  }
  return {
    checks: checks,
    messages: checkMessages
  };
};

const validateName = (value) => {
  return value && !/^((\b[a-zA-Z'-]{2,40}\b)\s*){1,}$/.test(value);
};

const validateEmail = (value) => {
  return (
    value &&
    !/^(([^<>()[\]\\.,;:\s@"+]+(\.[^<>()[\]\\.,;:\s@"+]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    )
  );
};

const validateUsername = (value) => {
  return value && !/^(?=.*[A-Za-z])[A-Za-z\d.]{8,50}$/.test(value);
};

const validateConfirmPassword = (password, value) => {
  return value !== password;
};

const isInvalidEmailError = (errors) => {
  return errors.find(error => (error.msg === 'User email is not validated.' )) ? true : false
}

const verifyEmailValidated = (options) => {
  const { history, prevCheckoutErrors, checkoutErrors } = options;
  if (prevCheckoutErrors !== checkoutErrors && isInvalidEmailError(checkoutErrors) && checkoutErrors) {
    history.push('/email_validation');
  }
}


export {
  getErrors,
  handleCustomerRegistration,
  isValueEmpty,
  registrationFormInputChange,
  setCustomerInfo,
  trackAndRedirectToConditionOfUse,
  validatePassword,
  validateConfirmPassword,
  isInvalidEmailError,
  verifyEmailValidated,
  isInvalidTitle
};
