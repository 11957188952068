import React from "react";

export default class CheckoutErrorMessages extends React.Component{

  render(props){
    return (
      <div>
        {
          this.props.errorMessages.accountType &&
          <div className="red-text font-12 text-right">Please select your account type.</div>
        }
        {
          this.props.errorMessages.accountReferenceNumber &&
          <div className="red-text font-12 text-right">Please enter your account details.</div>
        }
        {
          this.props.errorMessages.accountReferenceNumberValid &&
          <div className="red-text font-12 text-right">Please enter a valid account number.</div>
        }
        {
          this.props.errorMessages.title && 
          <div className='red-text font-12 text-right'>Please select Title.</div>
        }
        {
          this.props.errorMessages.name &&
          <div className="red-text font-12 text-right">Please enter your first and last name.</div>
        }
        {
          this.props.errorMessages.mobileNumber &&
          <div className="red-text font-12 text-right">Please enter your phone number.</div>
        }
        {
          this.props.errorMessages.email &&
          <div className="red-text font-12 text-right">Please enter your email address.</div>
        }
        {
          this.props.errorMessages.singleAddressLine &&
          <div className="red-text font-12 text-right">Please select your address or click 'Enter address manually'.</div>
        }
        {
          this.props.errorMessages.streetAddress &&
          <div className="red-text font-12 text-right">Please enter your street address.</div>
        }
        {
          this.props.errorMessages.streetAddressFormat &&
          <div className="red-text font-12 text-right">Only special characters "," and "-" are accepted in Street Address.</div>
        }
        {
          this.props.errorMessages.suburbCity &&
          <div className="red-text font-12 text-right">Please enter your suburb.</div>
        }
        {
          this.props.errorMessages.state &&
          <div className="red-text font-12 text-right">Please enter your state.</div>
        }
        {
          this.props.errorMessages.postcode &&
          <div className="red-text font-12 text-right">Please enter your postcode.</div>
        }
        {
          this.props.errorMessages.physicalSingleAddressLine &&
          <div className="red-text font-12 text-right">Please select your physical address or click 'Enter physical address manually'.</div>
        }
        {
          this.props.errorMessages.physicalStreetAddress &&
          <div className="red-text font-12 text-right">Please enter your physical street address.</div>
        }
        {
          this.props.errorMessages.physicalStreetAddressFormat &&
          <div className="red-text font-12 text-right">Only special characters "," and "-" are accepted in Physical Street Address.</div>
        }
        {
          this.props.errorMessages.physicalSuburbCity &&
          <div className="red-text font-12 text-right">Please enter your suburb.</div>
        }
        {
          this.props.errorMessages.physicalState &&
          <div className="red-text font-12 text-right">Please enter your state.</div>
        }
        {
          this.props.errorMessages.physicalPostcode &&
          <div className="red-text font-12 text-right">Please enter your postcode.</div>
        }
        {
          this.props.errorMessages.role &&
          <div className="red-text font-12 text-right">Please select your position or role.</div>
        }
        {
          this.props.errorMessages.abn &&
          <div className="red-text font-12 text-right">Please enter a valid ABN / ACN or click 'Enter business details manually'</div>
        }
        {
          (this.props.errorMessages.abnFormat || this.props.errorMessages.abnManualFormat) &&
          <div className="red-text font-12 text-right">Company number format: 1234567890123</div>
        }
        {
          this.props.errorMessages.abnInvalid &&
          <div className="red-text font-12 text-right">Please enter a valid company number</div>
        }
        {
          this.props.errorMessages.abnManual &&
          <div className="red-text font-12 text-right">Please enter your company number/NZBN.</div>
        }
        {
          this.props.errorMessages.legalEntityName &&
          <div className="red-text font-12 text-right">Please enter your company name.</div>
        }
        {
          this.props.errorMessages.businessName &&
          <div className="red-text font-12 text-right">Please enter your trading name.</div>
        }
        {
          this.props.errorMessages.industry &&
          <div className="red-text font-12 text-right">Please enter the industry.</div>
        }
        {
          this.props.errorMessages.businessTerms &&
          <div className="red-text font-12 text-right">
            Please confirm that you are authorised to enter this contract on behalf of your business.
          </div>
        }
        {
          this.props.errorMessages.cardType &&
          <div className="red-text font-12 text-right">Please select card type.</div>
        }
        {
          this.props.errorMessages.bsbNumber &&
          <div className="red-text font-12 text-right">Please enter your 6 digit BSB.</div>
        }
        {
          this.props.errorMessages.accountNumber &&
          <div className="red-text font-12 text-right">Please enter your account number.</div>
        }
        {
          this.props.errorMessages.accountName &&
          <div className="red-text font-12 text-right">Please enter your name.</div>
        }
        {
          (this.props.errorMessages.terms || this.props.errorMessages.trialTerms) &&
          <div className="red-text font-12 text-right">
            Please confirm that you have read and accept the terms & conditions.
          </div>
        }

      </div>
    )
  }
}
