import Api from '../services/api.js';
import history from '../history';

export function fetchWindcavePaymentByTokenRequest() {
	return {
		type: 'PAYMENT_PXPAY_REQUEST'
	}
}

export function fetchWindcavePaymentByTokenSuccess(data) {
	return {
		type: 'PAYMENT_PXPAY_SUCCESS',
		data: data
	}
}

export function fetchWindcavePaymentByTokenFailure(exception) {
	return {
		type: 'PAYMENT_PXPAY_FAILURE',
		exception: exception
	}
}

export function windcaveProcessPaymentRequest() {
	return {
		type: 'WINDCAVE_PROCESS_PAYMENT_REQUEST'
	}
}

export function windcaveProcessPaymentSuccess(data) {
	return {
		type: 'WINDCAVE_PROCESS_PAYMENT_SUCCESS',
		data: data
	}
}

export function windcaveProcessPaymentFailure(exception) {
	return {
		type: 'WINDCAVE_PROCESS_PAYMENT_FAILURE',
		exception: exception
	}
}

export function fetchWindcavePaymentByToken(token) {
	return (dispatch) => {
		dispatch(fetchWindcavePaymentByTokenRequest())
		const api = new Api();
		let url = "/__api/payment_pxpay/" + token
		return api.get(url, {
				headers: {
					Region: "NZ"
				}
			})
			.then(response => dispatch(fetchWindcavePaymentByTokenSuccess(response.data)))
			.catch(err => dispatch(fetchWindcavePaymentByTokenFailure(err)));
	};
}

export function windcaveProcessPayment(region, result, userid) {
	let requestBody = {
		region: region,
		result: result,
		userid: userid
	}
	return (dispatch) => {
		dispatch(windcaveProcessPaymentRequest())
		const api = new Api();
		let url = "/__api/process_pxpay"
		return api.post(url, requestBody, {
				headers: {
					Region: "NZ"
				}
			})
			.then(response => {
				dispatch(windcaveProcessPaymentSuccess(response.data))
				history.push("/payment_pxpay_status")
			})
			.catch(err => dispatch(windcaveProcessPaymentFailure(err)));
	};
}