import React from "react";
import { Panel } from "react-bootstrap";
import IconComparisonTableMobile from "./../../iconComparisonTableMobile"
import DataComparisonTableMobile from "./../../dataComparisonTableMobile"
import usersIcon from "./../../../../image/Icons/Table-Users.svg"
import propertyDataIcon from  "./../../../../image/Icons/Table-House-Pricing.svg"
import mobilityIcon from  "./../../../../image/Icons/Table-Pcheck.svg"
import reportsIcon from  "./../../../../image/Icons/Table-Piechart.svg"
import modulesIcon from  "./../../../../image/Icons/Table-Appstore.svg"

export default class RpnzComparisonTableMobile extends React.Component{

  constructor(props){
    super(props)

    this.state = {
      open: [true, false, false, false, false]
    }
  }

  togglePanel = (index) => {
    let newOpen = [...this.state.open]
    newOpen[index] = !newOpen[index]
    this.setState( { open: newOpen })
  }

  render(props){
    return (
      <div>
          <Panel defaultExpanded>
            <Panel.Heading  className="off-white-bg">
              <Panel.Title toggle onClick={() => this.togglePanel(0)}>
                <div className="tab-header body-semibold">
                  <img className="panel-header-icon" src={usersIcon} alt=""/>
                  Users & Geography <span className={this.state.open[0] ? "panel-dropdown-icon fa carat-up" : "panel-dropdown-icon fa carat-down"} aria-hidden="true"></span>
                </div>
              </Panel.Title>
            </Panel.Heading>
            <Panel.Collapse>
              <Panel.Body>
                <DataComparisonTableMobile
                  industryPackage = { this.props.industryPackage }
                  data = { this.props.usersGeo }
                  type="users_geo"
                  packageKey = {this.props.packageKey}
                />
              </Panel.Body>
            </Panel.Collapse>
          </Panel>
          <Panel>
            <Panel.Heading  className="off-white-bg">
              <Panel.Title toggle onClick={() => this.togglePanel(1)}>
                <div className="tab-header body-semibold">
                  <img className="panel-header-icon" src={propertyDataIcon} alt=""/>
                  Property Data <span className={this.state.open[1] ? "panel-dropdown-icon fa carat-up" : "panel-dropdown-icon fa carat-down"} aria-hidden="true"></span>
                </div>
              </Panel.Title>
            </Panel.Heading>
            <Panel.Collapse>
              <Panel.Body>
                <IconComparisonTableMobile
                  industryPackage = { this.props.industryPackage }
                  data = { this.props.propertyData }
                  type="data"
                  packageKey = {this.props.packageKey}
                />
              </Panel.Body>
            </Panel.Collapse>
          </Panel>
          <Panel>
            <Panel.Heading className="off-white-bg">
              <Panel.Title toggle onClick={() => this.togglePanel(2)}>
                <div className="tab-header body-semibold">
                  <img className="panel-header-icon" src={mobilityIcon} alt=""/>
                  Mobility <span className={this.state.open[2] ? "panel-dropdown-icon fa carat-up" : "panel-dropdown-icon fa carat-down"} aria-hidden="true"></span>
                </div>
              </Panel.Title>
            </Panel.Heading>
            <Panel.Collapse>
              <Panel.Body>
                <IconComparisonTableMobile
                  industryPackage = { this.props.industryPackage }
                  data = { this.props.mobility }
                  type="mobility"
                  packageKey = {this.props.packageKey}
                />
              </Panel.Body>
            </Panel.Collapse>
          </Panel>
          <Panel>
            <Panel.Heading className="off-white-bg">
              <Panel.Title toggle onClick={() => this.togglePanel(3)}>
                <div className="tab-header body-semibold">
                  <img className="panel-header-icon" src={reportsIcon} alt=""/>
                  Reports <span className={this.state.open[3] ? "panel-dropdown-icon fa carat-up" : "panel-dropdown-icon fa carat-down"} aria-hidden="true"></span>
                </div>
              </Panel.Title>
            </Panel.Heading>
            <Panel.Collapse>
              <Panel.Body>
                <IconComparisonTableMobile
                  industryPackage = { this.props.industryPackage }
                  data = { this.props.reports }
                  type="reports"
                  packageKey = {this.props.packageKey}
                />
              </Panel.Body>
            </Panel.Collapse>
          </Panel>
          <Panel>
            <Panel.Heading className="off-white-bg">
              <Panel.Title toggle onClick={() => this.togglePanel(4)}>
                <div className="tab-header body-semibold">
                  <img className="panel-header-icon" src={modulesIcon} alt=""/>
                  Modules <span className={this.state.open[4] ? "panel-dropdown-icon fa carat-up" : "panel-dropdown-icon fa carat-down"} aria-hidden="true"></span>
                </div>
              </Panel.Title>
            </Panel.Heading>
            <Panel.Collapse>
              <Panel.Body>
                <IconComparisonTableMobile
                  industryPackage = { this.props.industryPackage }
                  data = { this.props.modules }
                  type="modules"
                  packageKey = {this.props.packageKey}
                />
              </Panel.Body>
            </Panel.Collapse>
          </Panel>
        </div>
    )
  }
}
