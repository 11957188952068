import $ from 'jquery';

const checkProductCoupon = (coupon, productId) => {
  let ids = coupon.productIds;

  if (ids !== null) {
    for (let id = 0; id < ids.length; id++) {
      if (parseInt(ids[id], 10) === productId) {
        return true;
      }
    }
    return false;
  } else {
    return true;
  }
};

export { checkProductCoupon };
