const DATA_SITE = 'NZ';

const getPageName = () => {
  const pathname = window.location.href;
  if (pathname.includes('/product/')) return 'Package Selection';
  if (pathname.includes('/product_config/')) return 'Customisation';
  if (pathname.includes('/register')) return 'Sign Up';
  if (pathname.includes('/email')) return 'Email Validation';
  if (pathname.includes('/order') || pathname.includes('/payment')) return 'Order Status';
  if (pathname.includes('/pxpay')) return 'DPS';
  if (pathname.includes('/checkout')) return 'Checkout';
  return 'Cart';
};

const analyticsAlias = (claudId) => {
  window.analytics.alias(claudId, {
    'previousId': window.mixpanel.get_distinct_id(),
    'userId': claudId
  });
};

const analyticsIdentity = (user) => {
  if (user) {
    window.analytics.identify(user.guid, {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      username: user.username,
      site: 'NZ'
    });
  };
};

const analyticsPageView = (page) => {
  window.analytics.page(page, {
    'site': DATA_SITE
  });
};

const analyticsTrack = (trackingObj) => {
  trackingObj.data.site = DATA_SITE;
  window.analytics.track(trackingObj.eventType, trackingObj.data);
};

const trackAction = (eventMessage, action, pageStatus) => {
  const pageName = pageStatus ? pageStatus : getPageName()
  const trackingObj = {
    eventType: eventMessage,
    data: {
      'Page': pageName,
      'Action': action,
      'name': pageName
    }
  };
  analyticsTrack(trackingObj);
};

// Add to Cart
const trackAddToCart = (cta, quote) => {
  const pageName = getPageName()
  const trackingObj = {
    eventType: 'Add to Cart',
    data: {
      'Page': pageName,
      'CTA': cta,
      'Product': quote.productGroup,
      'Package': quote.headLine,
      'Monthly Amount': quote.totalPerMonth,
      'name': pageName
    }
  };
  analyticsTrack(trackingObj);
};

//Apply / Remove coupon
const trackApplyOrRemoveCoupon = (eventMessage, coupon) => {
  const pageName = getPageName()
  if (coupon) {
    let discount = null;
    if (coupon.discountServiceConfig) {
      let discountConfig = coupon.discountServiceConfig;
      if (discountConfig.discountPercent && discountConfig.discountPercent > 0) {
        discount = `${discountConfig.discountPercent}%`;
      } else if (discountConfig.discountFixed && discountConfig.discountFixed > 0) {
        discount = `$${discountConfig.discountFixed}`;
      } else if (discountConfig.monthsDiscount && discountConfig.monthsDiscount > 0) {
        discount = `${discountConfig.monthsDiscount} months`;
      }
    }

    const trackingObj = {
      eventType: eventMessage,
      data: {
        'Page': pageName,
        'Coupon': coupon.code,
        'Discount': discount,
        'Referrer': coupon.agentName,
        'name': pageName
      }
    };
    analyticsTrack(trackingObj);
  }
};

//Information
const trackCompanyInfo = (info) => {
  const pageName = getPageName()
  const trackingObj = {
    eventType: 'Go to company information page',
    data: {
      'Page': pageName,
      'Information': info,
      'name': pageName
    }
  };
  analyticsTrack(trackingObj);
};

const trackContinueShoppingFromEmailValidation = () => {
  const pageName = getPageName()
  const trackingObj = {
    eventType: 'Continue Shopping',
    data: {
      'Page': pageName,
      'Entry Point': 'Expired / New Session',
      'name': pageName
    }
  }
  analyticsTrack(trackingObj)
}

//Delete package
const trackDeletePackage = (quote) => {
  const pageName = getPageName()
  const trackingObj = {
    eventType: 'Delete Package',
    data: {
      'Page': pageName,
      'Product': quote.headLine,
      'Industry': quote.productGroup,
      'Package': quote.headLine,
      'name': pageName
    }
  };
  analyticsTrack(trackingObj);
};

const trackEmailValidation = (validated) => {
  const pageName = getPageName()
  const trackingObj = {
    eventType: 'Email validation',
    data: {
      'Page': pageName,
      'Validated': validated ? 'Yes' : 'No',
      'name': pageName
    }
  }
  analyticsTrack(trackingObj)
}

const trackError = (action, page, errorMessage, errorCode) => {
  const pageName = page ? page : getPageName()
  const trackingObj = {
    eventType: 'Capture Error',
    data: {
      'site': DATA_SITE,
      'Page': pageName,
      'Action': action,
      'Error Message': errorMessage,
      'Error Code': errorCode,
      'name': pageName
    }
  };
  analyticsTrack(trackingObj);
};

const trackGenericPageInfo = (eventMessage, pageStatus) => {
  const pageName = pageStatus ? pageStatus : getPageName()
  const trackingObj = {
    eventType: eventMessage,
    data: {
      'Page': pageName,
      'name': pageName
    }
  };
  analyticsTrack(trackingObj);
};

const trackGotoCart = (itemsCount) => {
  const pageName = getPageName()
  const trackingObj = {
    eventType: 'Go to Cart',
    data: {
      'Page': pageName,
      'Number': itemsCount,
      'name': pageName
    }
  };
  analyticsTrack(trackingObj);
};

//Track Order Status
const trackOrderStatus = (order, result, reason, payment_id) => {
  const pageName = getPageName()
  let onceOffPrice = 0;
  let subscriptionsPrice = 0;

  const quotes = order.quotes.quotes;
  let orderType = quotes.length > 1 ? 'Combination' : null;

  for (let quoteIndex = 0; quoteIndex < quotes.length; quoteIndex++) {
    if (quotes[quoteIndex].minimumInitialTerm === 1) {
      onceOffPrice += quotes[quoteIndex].originalPrice;
      if (orderType === null) {
        orderType = 'Once off';
      }
    } else {
      subscriptionsPrice += quotes[quoteIndex].originalPrice;
      if (orderType === null) {
        orderType = 'Subscription';
      }
    }
  }

  const trackingObj = {
    eventType: 'Order Status',
    data: {
      Page: pageName,
      Result: result,
      'Order Type': orderType,
      'Order Number': order.orderNumber,
      'One off total': parseFloat((onceOffPrice / 1.1).toFixed(2)),
      'Subscription monthly amount': parseFloat((subscriptionsPrice / 1.1).toFixed(2)),
      ACV: parseFloat((order.quotes.cartMinTotal / 1.1).toFixed(2)),
      Reason: reason,
      'Customer Id': order.customer.id,
      'Payment Id': payment_id,
      'name': pageName
    }
  };
  analyticsTrack(trackingObj);
};

//Track package Selection
const trackPackageSelection = (product, industry, packageName) => {
  const pageName = getPageName()
  const trackingObj = {
    eventType: 'Select Package',
    data: {
      'Page': pageName,
      'Product Selected': product,
      'Industry': industry,
      'Package Selected': packageName,
      'name': pageName
    }
  };
  analyticsTrack(trackingObj);
};

// Sign up
const trackSignUp = (cta) => {
  const pageName = getPageName()
  const trackingObj = {
    eventType: 'Sign-in',
    data: {
      'Page': pageName,
      'CTA': cta,
      'name': pageName
    }
  };
  analyticsTrack(trackingObj);
};

export {
  analyticsAlias,
  analyticsIdentity,
  analyticsPageView,
  trackAction,
  trackAddToCart,
  trackApplyOrRemoveCoupon,
  trackCompanyInfo,
  trackContinueShoppingFromEmailValidation,
  trackDeletePackage,
  trackEmailValidation,
  trackError,
  trackGenericPageInfo,
  trackGotoCart,
  trackOrderStatus,
  trackPackageSelection,
  trackSignUp
};
