import React from "react";
import {FormGroup, ControlLabel, FormControl, Col, Tooltip, OverlayTrigger} from 'react-bootstrap';
export const BLACKLIST_ACCOUNTS = ["A0200848", "A0096340", "A0101117", "A0060336", "A0060335"]

export default class AccountSelector extends React.Component {

  constructor(props){
    super(props);
     this.state = {
       selector: true,
    }
  }

  /* istanbul ignore next */
  setOptions() {
    let accounts = []
    for(let accountIndex=0; accountIndex < this.props.accounts.length; accountIndex++) {
      // before pushing compare if account is in blacklist
      let blacklist_accs = BLACKLIST_ACCOUNTS.map(acc => acc.toLowerCase())
      if (!blacklist_accs.includes(this.props.accounts[accountIndex].accountNumber.toLowerCase())) {
        accounts.push(
          {
            value:this.props.accounts[accountIndex].accountNumber,
            label:this.props.accounts[accountIndex].accountName + " ("+this.props.accounts[accountIndex].accountNumber+")"
          }
        )
      }
    }
    return accounts
  }

  render(){
    var tooltip = (
      <Tooltip id="accountTooltip">
        Select "Create new account" if you would like to checkout under a new account.
        Select "Use my existing account" to select from an account number we identify against
        your user profile or select "Enter account manually" if you have an account number
        but we’re not presenting it to you under Use my existing account.
      </Tooltip>
    )

    return(
      <FormGroup controlId="accountReferenceNumber" className="row">
        <Col sm={4}>
          <ControlLabel>
            <span className="margin-right-5 body-semibold">
              I want to
              <i className="form-mandatory-fields">*</i>
            </span>
            <OverlayTrigger placement="top" overlay={tooltip} trigger={['click', 'hover', 'focus']}>
              <span>
                <i className="far fa-question-circle tooltip-icon" aria-hidden="true"></i>
              </span>
            </OverlayTrigger>
          </ControlLabel>
        </Col>
        <Col sm={6}>
          <FormControl
            className={ this.props.errorMessages.accountType ? 'form-error-border' : '' }
            name="accountType"
            componentClass="select"
            value={this.props.customerDetails.accountType===null?"":this.props.customerDetails.accountType}
            onChange={(e)=>{this.props.onAccountTypeSelectorChange(e)}}>
            <option value="">--Select Option--</option>
            <option value="NEW_ACCOUNT">Create new account</option>
            <option value="EXISTING_ACCOUNT">Use my existing account</option>
            <option value="MANUAL_ACCOUNT">Enter account manually</option>
          </FormControl>
          {
            this.props.errorMessages.accountType &&
            <div className="form-error-messages">Please select the account type.</div>
          }
          {
            this.props.customerDetails.accountType === "EXISTING_ACCOUNT" &&
            <FormControl
              data-testid="accountReferenceNumber" 
              style = {{'marginTop' : '10px'}}
              className={ this.props.errorMessages.accountReferenceNumber ? 'form-error-border' : '' }
              name="accountReferenceNumber"
              componentClass="select"
              value={this.props.customerDetails.accountReferenceNumber===null?"":this.props.customerDetails.accountReferenceNumber}
              onChange={(e)=>{this.props.onAccountSelectorChange(e)}}>
              <option value="">--Select Account--</option>
              {
                this.setOptions().map(function(option, index) {
                  return(
                    <option value={option.value} key={index}>{option.label}</option>
                  )
                })
              }
            </FormControl>
          }
          {
            this.props.customerDetails.accountType === "EXISTING_ACCOUNT" && this.props.errorMessages.accountReferenceNumber &&
            <div className="form-error-messages">Please select the account.</div>
          }
          {
            this.props.customerDetails.accountType === "MANUAL_ACCOUNT" &&
            <FormControl
              style = {{'marginTop' : '10px'}}
              type="text"
              name="accountReferenceNumber"
              placeholder="Enter account number here"
              className={this.props.errorMessages.accountReferenceNumber || this.props.errorMessages.accountReferenceNumberValid ? 'form-error-border' : ''}
              value={this.props.customerDetails.accountReferenceNumber===null ? "" : this.props.customerDetails.accountReferenceNumber}
              onChange={(e)=>{this.props.onInputChange(e)}}
              onBlur={(e)=>{this.props.onManualAccountInputChange(e)}}/>
          }
          {
            this.props.customerDetails.accountType === "MANUAL_ACCOUNT" && this.props.errorMessages.accountReferenceNumber &&
            <div className="form-error-messages">Please enter your account details.</div>
          }
          {
            this.props.customerDetails.accountType === "MANUAL_ACCOUNT" && this.props.errorMessages.accountReferenceNumberValid &&
            <div className="form-error-messages">Please enter a valid account number.</div>
          }
        </Col>
        {
          false &&
          <Col sm={12}>
            <div className="form-error-messages">Sorry, you’re not authorised to purchase on this account. Please seek assistance from your account administrator. Your account adminisrator or an authorised user can purchase on account by; logging in and subscribing online, calling our sales team on 1300 734 318 or emailing us at customercare@corelogic.com.au. </div>
          </Col>
        }
      </FormGroup>
    )
  }
}
