import { combineReducers } from 'redux'
import packageSelection from './packageSelection'
import packageConfiguration from './packageConfiguration'
import cart from './cart'
import checkout from './checkout'
import cookieSettings from './cookieSettings'
import pxpay from './pxpay'
import registration from './registration'
import login from './login'
import paymentToken from './paymentToken'

const coreStoreApp = combineReducers({
  packageSelection: packageSelection,
  packageConfiguration: packageConfiguration,
  cart: cart,
  checkout: checkout,
  cookieSettings: cookieSettings,
  pxpay: pxpay,
  registration: registration,
  login: login,
  paymentToken: paymentToken
})

export default coreStoreApp
