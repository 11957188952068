import Api from '../services/api.js';

export function fetchQuoteRequest(){
  return {
    type: 'FETCH_QUOTE_REQUEST'
  }
}

export function fetchQuoteSuccess(data, configuration, productId){
  return {
    type: 'FETCH_QUOTE_SUCCESS',
    data: data,
    configuration: configuration,
    productId: productId
  }
}

export function fetchQuoteFailure(exception){
  return {
    type: 'FETCH_QUOTE_FAILURE',
    exception: exception
  }
}

export function fetchFilteredAreasRequest(){
  return {
    type: 'FETCH_FILTERED_AREAS_REQUEST'
  }
}

export function fetchFilteredAreasSuccess(data){
  return {
    type: 'FETCH_FILTERED_AREAS_SUCCESS',
    data: data
  }
}

export function fetchFilteredAreasFailure(exception){
  return {
    type: 'FETCH_FILTERED_AREAS_FAILURE',
    exception: exception
  }
}

export function fetchSuburbListRequest(){
  return {
    type: 'FETCH_SUBURB_LIST_REQUEST'
  }
}

export function fetchSuburbListSuccess(data){
  return {
    type: 'FETCH_SUBURB_LIST_SUCCESS',
    data: data
  }
}

export function fetchSuburbListFailure(exception){
  return {
    type: 'FETCH_SUBURB_LIST_FAILURE',
    exception: exception
  }
}

export function fetchQuote(configuration, productId){
  return (dispatch) => {
    dispatch(fetchQuoteRequest())
    const api = new Api();
    configuration.configuration.product_id = parseInt(configuration.configuration.product_id, 10)
    return api.post("/__api/quote/"+productId, configuration, {headers: { Region: "NZ" }})

    .then(response => dispatch(fetchQuoteSuccess(response.data, configuration, productId)))
    .catch(err => dispatch(fetchQuoteFailure(err)));
  };
}

export function fetchFilteredAreas(searchText){
  return (dispatch) => {
    dispatch(fetchFilteredAreasRequest())
    const api = new Api();
    return api.get("/__api/zones_filter",{params:{searchText:searchText},headers: { Region: "NZ" }})
    .then(response => dispatch(fetchFilteredAreasSuccess(response.data)))
    .catch(err => dispatch(fetchFilteredAreasFailure(err)));
  }
}

export function fetchSuburbList(council){
  return (dispatch) => {
    dispatch(fetchSuburbListRequest())
    const api = new Api();
    return api.get("/__api/council_suburbs",{params:{council:council},headers: { Region: "NZ" }})
    .then(response => dispatch(fetchSuburbListSuccess(response.data)))
    .catch(err => dispatch(fetchSuburbListFailure(err)));
  }
}
