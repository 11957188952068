import React, { useState, useEffect } from 'react';
import Banner from '../../banner';
import BannerMobile from '../../bannerMobile';
import { Nav, NavItem } from 'react-bootstrap';
import PropTypes from 'prop-types';

import RpnzComparisonTable from './rpnzComparisonTable';
import RpnzComparisonTableMobile from './rpnzComparisonTableMobile';


import './rpnzProductPackage'

import { checkProductCoupon } from '../../../../services/v2/products/productService';

/**
 * RpnzProductPackage.
 * Displays the product and packages for the package selection page.
 *
 * @author  Miguel
 * @param   {Object}      props.coupon                      Product Coupon.
 * @param   {Function}    props.onSelectPackage             React onSelectPackage.
 * @param   {Object}      props.productsConfig              Product Config.
 * @param   {Number}      props.productId                   Product Id.
 * @returns {JSX.Element}                                   The component markup.
 */

const RpnzProductPackage = ({ coupon, onSelectPackage, productsConfig, productId }) => {
  const [mobile, setMobile] = useState(false);
  const [packageKey, setPackageKey] = useState(
    Object.keys(productsConfig.packages)[Object.keys(productsConfig.packages).length - 1]
  );
  const [activeKey, setActivekey] = useState(
    Object.keys(productsConfig.packages).length.toString()
  );

   /* istanbul ignore next */
  const changePackage = (newPackageKey, newActiveKey) => {
    setPackageKey(newPackageKey);
    setActivekey(newActiveKey);
  };
  useEffect(() => {
    if (/Android|webOS|iPhone|iPod|ipad|BlackBerry/i.test(navigator.userAgent)) {
      setMobile(true);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(productsConfig.packages).length === 1) {
      setPackageKey(
        Object.keys(productsConfig.packages)[Object.keys(productsConfig.packages).length - 1]
      );
    }
  }, [productsConfig]);

  let classNames = require('classnames');
  const containerClass = classNames({
    'col-sm-12': mobile === false,
    'col-sm-12 no-padding': mobile === true
  });

  return (
    <React.Fragment>
      {productsConfig && (
        <div>
          <div className='row off-white-bg no-margin'>
            <div className='col-sm-12'>
              <div className='row no-margin'>
                <div
                  className='col-sm-12 section-title package-selection-h3 text-center'
                  style={{ paddingBottom: '20px' }}
                >
                  RPNZ
                </div>
              </div>
              <div className='row no-margin'>
                <div className={containerClass}>
                  {coupon &&
                    Object.keys(coupon).length > 0 &&
                    checkProductCoupon(coupon, productId) &&
                    coupon.blueBoxText !== null && (
                      <div className='row no-margin page-dim'>
                        <div className='col-sm-12 discount-alert blue-bg no-margin'>
                          <span>{coupon.blueBoxText}</span>
                        </div>
                      </div>
                    )}

                  {mobile &&
                    coupon &&
                    Object.keys(coupon).length > 0 &&
                    checkProductCoupon(coupon, productId) &&
                    coupon.blueBoxText !== null && <br />}

                  {mobile === false && (
                    <div className='row no-margin margin-bottom-100 page-dim white-bg'>
                      <div className='col-xs-12 panel-container'>
                        <Banner
                          industryPackage={productsConfig.packages}
                          onSelectPackage={onSelectPackage}
                          product={productsConfig.quote_service_type}
                        />
                        {productsConfig?.features && (
                          <RpnzComparisonTable
                            industryPackage={productsConfig.packages}
                            usersGeo={productsConfig.users_geo}
                            propertyData={productsConfig.features.data}
                            mobility={productsConfig.features.mobility}
                            reports={productsConfig.features.reports}
                            modules={productsConfig.features.modules}
                            packageKey={packageKey}
                          />
                        )}
                      </div>
                    </div>
                  )}

                  {mobile === true && (
                    <div className='row no-margin margin-bottom-100'>
                      <div className='col-xs-12 white-bg padding-bottom-15'>
                        <div className='row off-white-bg'>
                          <div className='text-center body'>
                            {productsConfig.packages[packageKey].short_description}
                          </div>
                          <br />
                        </div>
                        <div className='row'>
                          <Nav bsStyle='tabs' id='product-nav-tabs' activeKey={activeKey}>
                            {Object.keys(productsConfig.packages).map(function (
                              singlePackage,
                              index
                            ) {
                              let item = productsConfig.packages[singlePackage];
                              let counter = index + 1;
                              counter = counter.toString();
                              return (
                                <NavItem
                                  eventKey={counter}
                                  onClick={() => {
                                    changePackage(item.key, counter);
                                  }}
                                  key={counter}
                                >
                                  {item.title}
                                </NavItem>
                              );
                            },
                            this)}
                          </Nav>
                          <BannerMobile
                            industryPackage={productsConfig.packages}
                            onSelectPackage={onSelectPackage}
                            packageKey={packageKey}
                            product={productsConfig.quote_service_type}
                          />
                        </div>
                        <div>
                          {productsConfig?.features && (
                            <RpnzComparisonTableMobile
                              industryPackage={productsConfig.packages}
                              usersGeo={productsConfig.users_geo}
                              propertyData={productsConfig.features.data}
                              mobility={productsConfig.features.mobility}
                              reports={productsConfig.features.reports}
                              modules={productsConfig.features.modules}
                              packageKey={packageKey}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {productsConfig == null && (
        <div className='row spinner'>
          <i className='fa fa-spinner fa-pulse fa-3x fa-fw'></i>
          <span className='sr-only'>Loading...</span>
        </div>
      )}
    </React.Fragment>
  );
};

RpnzProductPackage.propTypes = {
  coupon: PropTypes.object,
  onSelectPackage: PropTypes.func.isRequired,
  productsConfig: PropTypes.object.isRequired,
  productId: PropTypes.number.isRequired
};

export default RpnzProductPackage;
