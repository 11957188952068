import Api from '../services/api.js';
import history from '../history';

export function pxpayInitial() {
	return {
		type: 'PXPAY_REQUEST'
	}
}

export function pxpaySuccess(data) {
	return {
		type: 'PXPAY_SUCCESS',
		data: data
	}
}

export function pxpayFailure(exception) {
	return {
		type: 'PXPAY_FAILURE',
		exception: exception
	}
}

export function flagPurchaseEvent(){
  return {
    type: 'FLAG_PURCHASE_EVENT'
  }
}

export function resetPurchaseEvent(){
  return {
    type: 'RESET_PURCHASE_EVENT'
  }
}

export function pxpayRequest(region, result, userid) {
	let requestBody = {
		region: region,
		result: result,
		userid: userid
	}

	return (dispatch) => {
		dispatch(pxpayInitial())
		const api = new Api();
		let url = "/__api/pxpay"
		return api.post(url, requestBody, {
				headers: {
					Region: "NZ"
				}
			})
			.then(response => {
				history.push("/payment/" + response.data.paymentId)
			})
			.catch(err => dispatch(pxpayFailure(err)));
	};
}