import React from 'react';
import { validatePassword } from '../../../services/v2/customerRegistration/registrationService';

const PasswordValidations = ({ password }) => {
  const { checks, messages } = validatePassword(password);
  const icons = {
    'default': <i className='far fa-circle'></i>,
    'invalid': <i className='fas fa-exclamation-triangle'></i>,
    'valid': <i className='fas fa-check'></i>
  };
  if (password) {
    return messages.map((message, i) => {
      return (
        <p key={message} className={`password-validation-msg-${checks[i]}`}>
          {icons[checks[i]]} {message}
        </p>
      );
    });
  } else {
    return messages.map((message, i) => {
      return (
        <p key={message} className='password-validation-msg'>
          {icons['default']} {message}
        </p>
      );
    });
  }
};

export default PasswordValidations;
