import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  GET_ACCOUNTS_REQUEST,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNTS_FAILURE,
  RESET_LOADERS,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAILURE,
  USER_EXPIRY_SESSION
} from '../constants';

const initialUserLoginState = {
  accounts: [],
  loader: false,
  accountsLoader: false,
  relogin: false,
  userLoggedIn: false,
  expiryDateTime: null
};

export function setSessionExpiry(newState) {
  if (!newState.expiryDateTime) {
    let dt = new Date();
    dt.setHours(dt.getHours() + 1);
    newState.expiryDateTime = dt;
    newState.relogin = false;
  } else if (new Date() > new Date(newState.expiryDateTime)) {
    newState.relogin = true;
    newState.userLoggedIn = false;
    newState.expiryDateTime = null;
  }
  return newState;
}

export default (state = initialUserLoginState, action = {}) => {
  //This is to ensure immutability of state
  let newState = { ...state };

  switch (action.type) {
    case LOGIN_REQUEST:
      newState.loader = true;
      return newState;
    case LOGIN_SUCCESS:
      window.location.href = action.body;
      newState.loader = false;
      return newState;
    case LOGIN_FAILURE:
      newState.loader = false;
      return newState;
    case GET_ACCOUNTS_REQUEST:
      newState.accounts = [];
      newState.relogin = false;
      newState.accountsLoader = true;
      return newState;
    case GET_ACCOUNTS_SUCCESS:
      newState.accounts = action.data;
      newState.userLoggedIn = true;
      if (!newState.expiryDateTime) {
        let dt = new Date();
        dt.setHours(dt.getHours() + 1);
        newState.expiryDateTime = dt;
        newState.relogin = false;
      } else if (new Date() > new Date(newState.expiryDateTime)) {
        newState.relogin = true;
        newState.userLoggedIn = false;
        newState.expiryDateTime = null;
      }
      newState.accountsLoader = false;
      return newState;
    case GET_ACCOUNTS_FAILURE:
      if (
        action?.exception?.response?.data?.errors[0]?.msg === 'Code provided has expired or invalid'
      ) {
        newState.relogin = true;
        newState.accounts = [];
      }
      newState.userLoggedIn = false;
      newState.accountsLoader = false;
      return newState;
    case RESET_LOADERS:
      newState.accountsLoader = false;
      return newState;
    case USER_LOGOUT_SUCCESS:
      return initialUserLoginState;
    case USER_LOGOUT_REQUEST:
    case USER_LOGOUT_FAILURE:
      return newState;
    case USER_EXPIRY_SESSION:
      newState = setSessionExpiry(newState);
      return newState;
    default:
      return state;
  }
};
