import React, { Component } from 'react';
import { connect } from 'react-redux'
import {pxpayRequest} from '../actions/pxpay'
import qs from 'query-string';

class PxPay extends Component {

  constructor(props){
    super(props);

    this.state = {
      invalidParams:false
    }

    //SERVICES
    this.analyticsPageView = this.props.SegmentAnalytics.analyticsPageView.bind(this)

  }

  componentDidMount() {
    this.analyticsPageView("PxPay Validation");

    if(this.props.location.search.length > 0 && this.props.location.search !== undefined && this.props.location.search !== null){
      let urlStream = qs.parse(this.props.location.search)
      let result = urlStream.result ? urlStream.result : null
      let userid = urlStream.userid ? urlStream.userid : null
      let region = urlStream.Region ? urlStream.Region : null
      this.props.pxpayRequest(region, result, userid);
    }else{
      this.setState({
        invalidParams: true
      })
    }
  }

  render(){
    return(
      <div>
        <div className="row off-white-bg no-margin">
          <div className="col-sm-12">
            <div className="row no-margin">
              {
                this.state.invalidParams===false && this.props.pxpay.pxpayLoader &&
                <div className="col-sm-12 section-title heading1 text-center" style={{padding:'120px'}}>
                  Processing
                  <span>
                      <i className="fa fa-spinner fa-pulse fa-fw"></i>
                      <span className="sr-only">Loading...</span>
                    </span>
                </div>
              }
              {
                this.props.pxpay.failure === true &&
                <div className="col-sm-12 section-title heading1 text-center" style={{padding:'120px'}}>
                  Process failed
                </div>
              }
              {
                this.state.invalidParams === true &&
                <div className="col-sm-12 section-title heading1 text-center" style={{padding:'120px'}}>
                  404 - Page not found
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    pxpay: state.pxpay
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    pxpayRequest:(region, result, userid) => dispatch(pxpayRequest(region, result, userid))
  }
}

let PxPayPage = connect(mapStateToProps, mapDispatchToProps)(PxPay)

export default PxPayPage;
