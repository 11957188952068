import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import PackageHeader from './packageHeader';
import PackageSelection from './PackageSelection';

import { checkProductCoupon } from '../../../../services/v2/productsService';

/**
 * GuruProductPackage.
 * Page for product.
 *
 * @author  Manny
 * @param   {Object}      props.coupon            Coupon object.
 * @param   {Object}      props.method            Optional industry params.
 * @param   {Function}    props.onSelectPackage   Handles onClick for selecting a package.
 * @param   {Number}      props.productId         Id number of the product.
 * @param   {Object}      props.productsConfig    Product configurations.
 * @param   {String}      props.selectedIndustry  Name of selected industry.
 * @param   {String}      props.selectedPackage   Name of selected package.
 * @returns {JSX.Element}                         The component markup.
 */

const GuruProductPackage = ({
  coupon,
  method,
  onSelectPackage,
  productId,
  productsConfig,
  selectedPackage,
  productName
}) => {
  const [packageKey, setPackageKey] = useState('cityscope');

  /* istanbul ignore next */
  useEffect(() => {
    if (productsConfig) {
      let newPackageKey = packageKey;
      setPackageKey(newPackageKey);
    }
  }, []);

  const isMobile = /Android|webOS|iPhone|iPod|ipad|BlackBerry/i.test(navigator.userAgent);

  let classNames = require('classnames');

  let containerClass = classNames({
    'col-sm-12': isMobile === false,
    'col-sm-12 no-padding': isMobile === true
  });

  let couponClass = classNames({
    'row no-margin page-dim coupon-align-packages': isMobile === false,
    'row no-margin page-dim ': isMobile === true
  });

  /* istanbul ignore next */
  useEffect(() => {
    setPackageKey(selectedPackage);
  }, [selectedPackage]);

  if (productsConfig != null) {
    return (
      <div>
        <div className='row off-white-bg no-margin'>
          <div className='col-sm-12'>
            <PackageHeader title={productName}/>
            <div className='row no-margin'>
              <div className={containerClass}>
                {coupon &&
                  Object.keys(coupon).length > 0 &&
                  checkProductCoupon(productId, coupon.productIds) &&
                  coupon.blueBoxText !== null && (
                    <div className={couponClass} style={{paddingLeft:'0px'}}>
                      <div className='col-sm-12 captions-semibold discount-alert blue-bg no-margin' style={{paddingLeft:'0px'}}>
                        <span>{coupon.blueBoxText}</span>
                      </div>
                    </div>
                  )}

                {isMobile &&
                  coupon &&
                  Object.keys(coupon).length > 0 &&
                  checkProductCoupon(productId, coupon.productIds) &&
                  coupon.blueBoxText !== null && <br />
                }
                {productsConfig !== null && (
                  <PackageSelection
                    productPackages={productsConfig?.packages}
                    method={method}
                    selectedPackage={selectedPackage}
                    onSelectPackage={onSelectPackage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className='row spinner'>
        <i className='fa fa-spinner fa-pulse fa-3x fa-fw'></i>
        <span className='sr-only'>Loading...</span>
      </div>
    );
  }
};

GuruProductPackage.propTypes = {
  coupon: PropTypes.object,
  method: PropTypes.object,
  onSelectPackage: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  productId: PropTypes.number.isRequired,
  productsConfig: PropTypes.object,
  selectedPackage: PropTypes.string.isRequired
};

export default GuruProductPackage;
