import React from "react";

export default class TncTrial extends React.Component{
  render(){
    return (
      <div className="row padding-10">
        <div className="col-sm-12 terms-cond">
          <div>
            {
              this.props.conditionalTC === true &&
              <span className="text-uppercase">{this.props.headline} - </span>
            }
            TERMS & CONDITIONS</div>
          <ul className="list-padding">
            <li>I warrant I am authorised to enter this Contract on behalf of the business.</li>
            <li>I agree to be bound by this Contract with RP Data Pty Ltd t/as Corelogic Asia Pacific; and</li>
            <li>I acknowledge this Contract comprises of:
              <ul>
                <li><a className="terms-link" href="https://www.corelogic.com.au/about-us/terms-and-conditions" target="_blank" rel="noopener noreferrer">Full terms and conditions</a>; and</li>
                <li>Commercial terms on this page; and</li>
              </ul>
            </li>
            <li>I understand this is a trial which will automatically expire after 7 days.</li>
          </ul>
        </div>
      </div>
    )
  }
}
