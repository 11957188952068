import Api from '../services/api.js';

export function redirectToLoginPageRequest() {
  return {
    type: 'LOGIN_REQUEST'
  };
}

export function redirectToLoginPageSuccess(body) {
  return {
    type: 'LOGIN_SUCCESS',
    body: body
  };
}

export function redirectToLoginPageFailure(exception) {
  return {
    type: 'LOGIN_FAILURE',
    exception: exception
  };
}

export function redirectToLoginPage() {
  return (dispatch) => {
    dispatch(redirectToLoginPageRequest());
    const api = new Api();
    return api
      .get('/__api/user/login', { headers: { Region: 'NZ' } })
      .then((response) => dispatch(redirectToLoginPageSuccess(response.data)))
      .catch((err) => dispatch(redirectToLoginPageFailure(err)));
  };
}

export function resetLoaders() {
  return {
    type: 'RESET_LOADERS'
  };
}

export function userExpirySession() {
  return {
    type: 'USER_EXPIRY_SESSION'
  };
}
