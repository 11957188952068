import React from "react";

export default class PackageHeader extends React.Component{
  render(){
    return (
      <div className="row no-margin">
        <div id="package_header" className="col-sm-12 section-title package-selection-h3 text-center" style={{paddingBottom:'35px'}} >
          {this.props.title}
        </div>
      </div>
    )
  }
}
