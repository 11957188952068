import React, { Component } from 'react';
import { ToastContainer, ToastStore } from 'react-toasts';
import { connect } from 'react-redux'
import { Animate } from "react-show";
import history from './history'

import './fonts/open-sans.css'
import './fonts/open-sans-600.css'
import './fonts/silka.css'
import './fonts/fa/css/fontawesome-all.min.css'
import './index.css';
import { Router, Switch, Route } from 'react-router-dom'
import './include/bootstrap'

//COMPONENTS
import Header from './views/components/navigation/header'
import Footer from './views/components/navigation/footer'

//PAGES
import PackageSelectionPage from './views/PackageSelectionPage'
import PackageConfigurationPage from './views/PackageConfigurationPage'
import CartPage from './views/CartPage'
import CheckoutPage from './views/CheckoutPage'
import PaymentStatusPage from './views/PaymentStatusPage'
import OrderStatusPage from './views/OrderStatusPage'
import PxPayPage from './views/PxPayPage'
import RegistrationPage from './views/RegistrationPage'
import EmailValidationPage from './views/EmailValidationPage'
import PaymentPxPayPage from './views/PaymentPxPayPage'
import ProcessPxPayPage from './views/ProcessPxPayPage'
import WindcavePaymentStatusPage from './views/WindcavePaymentStatusPage'

import {setCookieNotification} from './actions/cookiesSettings'

import LoginService from './services/customerRegistration/loginService';
import ProductItemsService from './services/productItemsService';
import SegmentAnalytics from './services/segmentAnalytics';
import OrderService from './services/orderService';

//UTILS
import ScrollToTop from './utils/scrollToTop';


const email_validation_service = new LoginService();
const product_items_service = new ProductItemsService();
const segment_analytics_service = new SegmentAnalytics();
const order_service = new OrderService();

history.listen((location, action) => {

      if (window.ga !== undefined){
        window.ga('set', 'page', `${location.pathname}${location.search}${location.hash}`);
        window.ga('send', 'pageview');
      }

      if (window.dataLayer !== undefined){
        //Tag Manager PageView
        window.dataLayer.push({
            event: 'pageView',
            action: `${location.pathname}${location.search}${location.hash}`,
        });
      }

      if (window._mfq !== undefined){
        //Mouseflow route
        window._mfq.push(["newPageView", `${location.pathname}${location.search}${location.hash}`]);
      }

      if (window._elqQ !== undefined){
        //Eloqua
        window._elqQ.push(['elqTrackPageView',`${location.pathname}${location.search}${location.hash}`]);
      }
      console.log('page change event')
})

class App extends Component {

  constructor(props) {
    super(props);

    window.dataLayer.push({
    originalLocation: document.location.protocol + '//' +
                      document.location.hostname +
                      document.location.pathname
    });

    const escape = require('escape-html');
    const pageView = escape(`${window.location.pathname}${window.location.search}`);

    window.dataLayer.push({
      event: 'pageView',
      action: pageView
    });

    this.state = {
      isMobile: false,
      cookieNotificationSlider: !this.props.cookieSettings.cookieApproved
    };
  }

  toggleCookieNotification() {
    this.setState({
        cookieNotificationSlider: !this.state.cookieNotificationSlider
    })
  }

  componentDidMount() {
    if(/Android|webOS|iPhone|iPod|ipad|BlackBerry/i.test(navigator.userAgent) ){
      this.setState({
        isMobile: true
       });
    }
  }

  render() {

    if(new RegExp("/#!/").test(window.location.href)){
      window.location.href = window.location.href.replace(new RegExp("/#!/"), "/");
    }

    return (
      <div className="App">
        <Header
          isMobile = {this.state.isMobile}
          ProductItemsService = {product_items_service}
          SegmentAnalytics = {segment_analytics_service}
        />
        <Router history={history}>
          <div className="main-content">
          <ScrollToTop>
            <Switch>
              <Route exact path="/"
                render={
                  (props) =>
                  <CartPage
                    {...props}
                  />
              } />
              <Route exact path="/cart"
                render={
                  (props) =>
                  <CartPage
                    {...props}
                    SegmentAnalytics = {segment_analytics_service}
                    OrderService = {order_service}
                  />
              } />
              <Route
                exact
                path="/product/:friendly_name"
                render={
                    (props) =>
                    <PackageSelectionPage
                      {...props}
                      SegmentAnalytics = {segment_analytics_service}
                    />
                  }
              />
              <Route exact path="/product_config/:product_id"
                render={
                    (props) =>
                    <PackageConfigurationPage
                      {...props}
                      SegmentAnalytics = {segment_analytics_service}
                      OrderService = {order_service}
                    />
                  }/>
              <Route exact path="/checkout"
                render={
                  (props) =>
                  <CheckoutPage
                    {...props}
                    SegmentAnalytics = {segment_analytics_service}
                    ProductItemsService = {product_items_service}
                  />
              } />
              <Route exact path="/payment/:payment_id"
                render={
                  (props) =>
                  <PaymentStatusPage
                    {...props}
                    SegmentAnalytics = {segment_analytics_service}
                  />
              } />
              <Route exact path="/order_status"
                render={
                  (props) =>
                  <OrderStatusPage
                    {...props}
                    SegmentAnalytics = {segment_analytics_service}
                  />
              } />
              <Route exact path="/pxpay"
                render={
                  (props) =>
                  <PxPayPage
                    {...props}
                    SegmentAnalytics = {segment_analytics_service}
                  />
              } />
              <Route exact path="/register"
                render={
                  (props) =>
                  <RegistrationPage
                    {...props}
                    LoginService={email_validation_service}
                    SegmentAnalytics = {segment_analytics_service}
                  />
                }/>
              <Route exact path="/email_validation"
                  render={
                    (props) =>
                    <EmailValidationPage
                      {...props}
                      LoginService={email_validation_service}
                      ProductItemsService={product_items_service}
                      SegmentAnalytics = {segment_analytics_service}
                      OrderService = {order_service}
                    />
              }/>
              <Route exact path="/payment_pxpay/:payment_token"
                render={
                  (props) =>
                  <PaymentPxPayPage
                    {...props}
                    SegmentAnalytics = {segment_analytics_service}
                  />
              } />
              <Route exact path="/process_pxpay"
                render={
                  (props) =>
                  <ProcessPxPayPage
                    {...props}
                    SegmentAnalytics = {segment_analytics_service}
                  />
              } />
              <Route exact path="/payment_pxpay_status"
                render={
                  (props) =>
                  <WindcavePaymentStatusPage
                    {...props}
                    SegmentAnalytics = {segment_analytics_service}
                  />
              } />

              <ToastContainer store={ToastStore}/>
            </Switch>
          </ScrollToTop>
          </div>
        </Router>

        <Footer
          SegmentAnalytics = {segment_analytics_service}
        />

      {
        !this.props.cookieSettings.cookieApproved && !this.state.cookieNotificationSlider &&
          <div className="cookie-hidden-toggle-button" onClick={() => this.toggleCookieNotification()}>Privacy settings</div>
      }
      {
        !this.props.cookieSettings.cookieApproved &&
        <Animate
          show={this.state.cookieNotificationSlider} // Toggle true or false to show or hide the content!
          transitionOnMount // Will trigger the transition when the component is mounted and show === true
          enter={{
            // These styles will be applied when the component enters
            opacity: 1,
            visibility: 'visible',
            height: 'auto',
            transitionDuration: "1s"
          }}
          leave={{
            // these styles will be applied when the component leaves
            opacity: 0,
            visibility: 'hidden',
            height: 0,
            transitionDuration: "1s"
          }}
        >
        <div id="cookie-notification" className="cookie-notification">
          <div id="cookie-notification-id" className="row" style={{margin: this.state.isMobile ? '20px' : '20px 100px'}}>
            <div id="popup-text" className="col-sm-8 cookie-message">
              <h3>We use cookies on this site to enhance your user experience</h3>
              <p>
                Our website uses cookies and other similar technologies to improve our website
                and your online experience, and to tailor the adverts presented on our site. By clicking ”I Agree”,
                you consent to cookies being used. You can opt-out at any time by adjusting your browser settings.
                See our{' '}
                <a
                  className="cookie-link"
                  href="https://www.corelogic.co.nz/about-us/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
                {' '}to find out more.{' '}
                <a
                  className="cookie-link"
                  href="https://www.corelogic.co.nz/about-us/privacy-policy"
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  Cookie Settings
                </a>
              </p>
            </div>
            <div id="popup-buttons" className="col-sm-4">
              <button
                type="button"
                className="cookie-button"
                onClick={()=>{this.props.setCookieNotification()}}>
                  I Agree
              </button>
            </div>
          </div>
        </div>
        </Animate>
      }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cookieSettings: state.cookieSettings
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCookieNotification:() => dispatch(setCookieNotification())
  }
}

let AppPage = connect(mapStateToProps, mapDispatchToProps)(App)

export default AppPage;
