import { SET_COOKIE_NOTIFICATION } from '../constants';

const initialProductState = {
  cookieApproved: false
};

export default (state = initialProductState, action = {}) => {
  //This is to ensure immutability of state
  let newState = { ...state };
  if (action.type === SET_COOKIE_NOTIFICATION) {
    newState.cookieApproved = true;
    return newState;
  }
  return state;
};
