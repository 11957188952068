import React from "react";
import { useSelector } from 'react-redux';
import history from '../../../history';
import { getCartItemsCount } from '../../../services/v2/products/productItemsService';

//COMPONENTS
import CartIcon from './cartIcon';
import UserIcon from './userIcon';

import './header.css'

//IMAGES
import CorelogicLogo from '../../../image/CorelogicLogo-header.svg'
import HeaderLine from '../../../image/header-line.svg';

export default function Header(props) {
  const cart = useSelector((state) => state.cart);
  const order = useSelector((state) => state.order);

  function redirectToURL(link) {
    window.location.href = link;
  }
  
  /* istanbul ignore next */
  const goToCart = () => {
    props.SegmentAnalytics.trackGotoCart(getCartItemsCount(cart.cart, order));
    history.push('/cart');
  };

  return (
    <header>
      <nav className="navbar" id='navbar-header'>
        <div className="container-fluid">
          <a id='navbar-brand' className="navbar-brand" onClick={() => redirectToURL("https://www.corelogic.co.nz")}>
            <img
              alt='Brand'
              id='corelogic-logo'
              className='logo-brand'
              src={CorelogicLogo}
            />
          </a>
          <div className="pull-right">
            <CartIcon {...props} goToCart={goToCart} />
            <a id="view-cart-link" onClick={goToCart}>
              View Cart
            </a>
            <a>
              <img
                alt='|'
                id='header-line'
                src={HeaderLine}
              />
            </a>
            <UserIcon analyticsLogoutUser={props.SegmentAnalytics.analyticsLogoutUser}/>
          </div>
        </div>
      </nav>
    </header>

  )

}
