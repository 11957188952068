import React from 'react';
import PropTypes from 'prop-types';
import './objectRepeat.css';

/**
 * ObjectRepeat
 *
 * @author  Miguel
 * @param   {Object}      props.industryKey          Industry Key.
 * @param   {Function}    props.onSelectPackage      React onSelectPackage.
 * @param   {Object}      props.packages             Product Config Packages.
 * @param   {String}      props.product              Product Name as String.
 * @returns {JSX.Element}                                   The component markup.
 */

const ObjectRepeat = ({ industryKey, onSelectPackage, packages, product }) => {
  const allPackages = packages || {};
  const productPackages = Object.keys(allPackages).map(function (singlePackage) {
    let item = allPackages[singlePackage];

    let classNames = require('classnames');
    let packageBtnClass = classNames({
      'btn package-btn btn-width-190': product !== 'commercial',
      'btn package-btn ': product === 'commercial'
    });
    return (
      <div className='col-xs-3 margin-top-20 package-box-container' id={item.key} key={item.key}>
        {item.popular && (
          <div className='text-center'>
            <span className='popular-tag'>
              <i className='fa fa-star' aria-hidden='true'></i>
              <span> Popular</span>
            </span>
          </div>
        )}
        {item.starburst && (
          <div className='text-center'>
            <span className='popular-tag'>
              <i className='fa fa-star' aria-hidden='true'></i>
              <span> 20% Off</span>
            </span>
          </div>
        )}
        <p className='no-margin package-title'>{item.title}</p>
        {item.dollars > 0 && (
          <p style={{margin: '10px 0px'}}>
            <span className='superscript captions' style={{ fontWeight: 'normal', marginLeft: '0' }}>
              FROM
            </span>
            <br/>
            <span className='heading2'>
              <b style={{fontWeight: 700, fontSize: "28px", lineHeight: "28px"}}>${item.dollars}.{item.cents < 1 ? '00' : item.cents}</b>
            </span>
            {item.key !== 'one_month' && <span className='per-month captions'>/MTH</span>}
          </p>
        )}
        {item.dollars === 0 && (
          <p className='margin-top-neg-5'>
            <span className='package-selection-h4'>FREE</span>
          </p>
        )}
        <p className='sub-text footnote-text' style={{color: '#a5a4a7', margin: 0}}>{item.sub_text_1}</p>
        <p className='sub-text footnote-text' style={{color: '#a5a4a7', margin: 0}}>{item.sub_text_2}</p>
        {item.blurb && (
          <p className='packages-panel-body body'>
            <span className='white-space'>{item.blurb}</span>
          </p>
        )}
        {item.description && (
          <p
          className='packages-panel-body commercial-panel-body body'>
            <span className='white-space'>{item.description}</span>
          </p>
        )}
        {item.short_description && (
          <p
            className={
              product === 'CordellEstimatorNZ'
                ? 'packages-panel-body cordell-panel-body body'
                : 'packages-panel-body body'
            }
          >
            <span className='white-space'>{item.short_description}</span>
          </p>
        )}
        <button
          type='button'
          id={`btn_${item.key}`}
          className={packageBtnClass}
          onClick={() => {
            onSelectPackage(industryKey, item.key);
          }}
        >
          {product === 'commercial' ? 'Customise Package' : 'Select Package'}
        </button>
      </div>
    );
  });

  return (
    <React.Fragment>
      <div className='col-xs-3'></div>
      {productPackages}
    </React.Fragment>
  );
};

ObjectRepeat.propTypes = {
  industryKey: PropTypes.object,
  onSelectPackage: PropTypes.func.isRequired,
  packages: PropTypes.object.isRequired,
  product: PropTypes.string.isRequired
};

export default ObjectRepeat;
