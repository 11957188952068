/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import history from '../history';

import CartItems from './components/cart/cartItems';

import './CartPage.css';

import {
  applyCoupon,
  checkout,
  deleteCartItem,
  deleteCoupon,
  deleteOrder
} from '../actions/cart';
import { getContactInfo, getContactUsername } from '../actions/checkout';
import { updateQuoteConfig } from '../actions/packageSelection';

import {
  analyticsPageView,
  trackApplyOrRemoveCoupon,
  trackDeletePackage,
  trackGenericPageInfo
} from '../services/v2/segmentAnalytics';

import { verifyEmailValidated } from '../services/v2/customerRegistration/registrationService';

/**
 * CartPage.
 * Page for product cart.
 *
 * @author  Miguel
 * @param   {Object}      props.product    Current Cart Quotes.
 * @returns {JSX.Element} The component markup.
 */

const CartPage = ({ product }) => {
  const [componentCouponCode, setComponentCouponCode] = useState(null);
  const [componentCouponAdded, setComponentCouponAdded] = useState(null);
  const cart = useSelector((state) => state.cart.cart);
  const cartLoader = useSelector((state) => state.cart.cartLoader);
  const cartQuotes = useSelector((state) => state.cart.cartQuotes);
  const contactInfo = useSelector((state) => state.checkout.contactInfo);
  const contactUsernameDetails = useSelector((state) => state.checkout.contactUsernameDetails);
  const checkoutErrors = useSelector((state) => state.cart.checkoutErrors);
  const coupon = useSelector((state) => state.packageSelection.coupon);
  const couponCode = useSelector((state) => state.packageSelection.couponCode);
  const loading = useSelector((state) => state.cart.loading);
  const login = useSelector((state) => state.login);
  // const loggedIn = useSelector((state) => state.cart.loggedIn);
  const order = useSelector((state) => state.cart.order);
  const registration = useSelector((state) => state.registration);
  const [prevCheckoutErrors, setPrevCheckoutErrors] = useState(checkoutErrors);
  const [couponLoader, setCouponLoader] = useState(false);
  const [isCouponValidated, setIsCouponValidated] = useState(true);

  const dispatch = useDispatch();

  /* istanbul ignore next */
  const onCustomiseProduct = (productId, quoteConfig) => {
    trackGenericPageInfo('Re-customise Package');
    dispatch(updateQuoteConfig(productId, quoteConfig));
  };

  /* istanbul ignore next */
  const onDeleteProduct = (cartItemId, productId, item) => {
    trackDeletePackage(item);
    dispatch(deleteCartItem(cartItemId, productId, item));
  };

  /* istanbul ignore next */
  const onCheckout = () => {
    trackGenericPageInfo('Checkout');
    if (login.userLoggedIn) {
      dispatch(getContactInfo()).then(function () {
        let customer = {
          industry: 'NONE'
        };
        if (contactInfo) {
          if (contactInfo.emailAddress) customer.email = contactInfo.emailAddress;
          if (contactInfo.firstName) customer.name = `${contactInfo.firstName} `;
          if (contactInfo.lastName) customer.name += contactInfo.lastName;
          if (contactInfo.mobileNumber) customer.mobileNumber = contactInfo.mobileNumber;

          if (customer.email) {
            dispatch(getContactUsername(customer.email)).then(function () {
              if (contactUsernameDetails?.content?.length > 0) {
                customer.username = contactUsernameDetails.content[0].username
                  ? contactUsernameDetails.content[0].username
                  : '';
              }

              customer.claudId = registration.emailValidation.guid;

              const newOrder = {
                processStatus: 'AUTO',
                customer: customer,
                quoteConfiguration: {
                  products: cart.cartItems
                }
              };
              dispatch(checkout(newOrder));
            });
          }
        }
      });
    } else {
      history.push('/register');
    }
  };

  /* istanbul ignore next */
  const onCartLoad = () => {
    dispatch(deleteOrder());
  };

  /* istanbul ignore next */
  const onCouponApply = (newCouponCode) => {
    if (
      newCouponCode !== '' &&
      newCouponCode !== null &&
      /^[a-zA-Z0-9][a-z.\-\s'A-Z0-9]*$/.test(newCouponCode)
    ) {
      setCouponLoader(true)
      setIsCouponValidated(false)
      dispatch(applyCoupon(newCouponCode)).then(function () {
        trackApplyOrRemoveCoupon('Apply Coupon', coupon);
        setComponentCouponCode(null);
        setComponentCouponAdded(newCouponCode);
        setCouponLoader(false);
      });
    } else {
      setComponentCouponCode(null);
      return;
    }
  };

  /* istanbul ignore next */
  const onDeleteCoupon = () => {
    trackApplyOrRemoveCoupon('Remove Coupon', coupon);
    dispatch(deleteCoupon()).then(
      function () {
        setIsCouponValidated(true)
      }.bind(this)
    );
    setComponentCouponAdded(false);
  };

  /* istanbul ignore next */
  const onCouponInputChange = (event) => {
    setComponentCouponCode(event.target.value);
  };  /* istanbul ignore next */
  const validateCoupon = () => {
    if (!coupon || !cartQuotes?.quotes || !cartQuotes?.coupon) {
      setIsCouponValidated(true)
      return
    }
    for (const quote of cartQuotes.quotes) {
      if (cartQuotes?.coupon?.discountServiceConfig?.packages.includes(quote.packageName)) {
        setIsCouponValidated(true)
        return;
      }
    }
    onDeleteCoupon()
  }

  /* istanbul ignore next */
  useEffect(() => {
    analyticsPageView('Cart');
    window.scrollTo(0, 0);
    validateCoupon();
  }, []);

  useEffect(() => {
    validateCoupon();
  }, [couponLoader])

  useEffect(() => {
    setPrevCheckoutErrors(checkoutErrors);

    const options = {
      history: history,
      prevCheckoutErrors: prevCheckoutErrors,
      checkoutErrors: checkoutErrors
    }
    verifyEmailValidated(options);
  }, [checkoutErrors]);

  return (
    <React.Fragment>
      {cartLoader ? (
        <div className='row spinner'>
          <i className='fa fa-spinner fa-pulse fa-3x fa-fw'></i>
          <span className='sr-only'>Loading...</span>
        </div>
      ) : (
        <CartItems
          cart={cart}
          cartQuotes={cartQuotes}
          coupon={coupon}
          couponCode={componentCouponCode}
          couponAdded={componentCouponAdded}
          isCouponValidated={isCouponValidated}
          loading={loading}
          onCartLoad={onCartLoad}
          onCheckout={onCheckout}
          onCouponApply={onCouponApply}
          onCouponInputChange={onCouponInputChange}
          onCustomiseProduct={onCustomiseProduct}
          onDeleteCoupon={onDeleteCoupon}
          onDeleteProduct={onDeleteProduct}
          order={order}
          trackGenericPageInfo={trackGenericPageInfo}
        />
      )}
    </React.Fragment>
  );
};

CartPage.propTypes = {
  product: PropTypes.object
};

export default CartPage;
