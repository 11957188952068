import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Tooltip, OverlayTrigger } from 'react-bootstrap';

import './productConfigSummary.css';

/**
 * ProductConfigSummary
 * Displays the config summary for the product configuration page.
 *
 * @author  Miguel
 * @param   {Object}      props.cart                    Cart Object.
 * @param   {Function}    props.changePackage           Change Package.
 * @param   {Function}    props.onAddToCart             Function to add to cart.
 * @param   {Function}    props.onRemoveAddon           Function to remove addon.
 * @param   {Object}      props.coupon                  Coupon Object.
 * @param   {Object}      props.loading                 Loading prop for spinner and disable.
 * @param   {Object}      props.packageKey              Package Key Object.
 * @param   {Object}      props.productQuote            Product Quote Object.
 * @param   {Object}      props.quoteLoading            Loading prop for spinner and disable.
 * @returns {JSX.Element}                               The component markup.
 */

const ProductConfigSummary = ({
  cart,
  changePackage,
  coupon,
  loading,
  onAddToCart,
  onRemoveAddon,
  packageKey,
  productQuote,
  quoteLoading
}) => {
  const [mobile, setMobile] = useState(false);
  const [isAgentVisible, setIsAgentVisible] = useState(true);
  const [showAdditionalFeatures, setShowAdditionalFeatures] = useState(false);

  const CONTRACT_MONTHS = 12;
  const CORDELL_KEYS = ['cordell_estimator_platinum_lite', 'cordell_estimator_platinum_premium']
  const PROPERTY_GURU_KEYS = ['lite', 'base', 'premium', 'pro']
  const VALID_PACKAGE_KEYS = ['rpnz', ...PROPERTY_GURU_KEYS, ...CORDELL_KEYS]

  useEffect(() => {
    if (/Android|webOS|iPhone|iPod|ipad|BlackBerry/i.test(navigator.userAgent)) {
      setMobile(true);
    }

    if (coupon && Object.keys(coupon).length > 0) {
      if (coupon.discountServiceConfig !== null) {
        let discountServiceConfig = coupon.discountServiceConfig;
        let agentVisibility = discountServiceConfig.agentVisibility;

        if (agentVisibility === 'N') {
          setIsAgentVisible(false);
        }
      }
    }
  }, []);

  const getDiscountedValue = () => {
    let discount = 0;
    if (coupon) {
      if (coupon.applicableTo === 'cart') {
        if (coupon.discountType === 'percentage') {
          discount = (productQuote.totalPerMonth * coupon.sizePercentage) / 100;
        } else if (coupon.discountType === 'fixed') {
          if (cart != null && Object.keys(cart).length > 0 && cart.cartItems.length > 0) {
            discount = coupon.sizeFixed / cart.cartItems.length;
          } else {
            discount = coupon.sizeFixed;
          }
        }
      } else if (coupon.applicableTo === 'product') {
        if (productQuote.discount > 0) {
          discount = productQuote.discount;
        }
      }
    }

    return discount;
  };

  const getDiscountedTotalPrice = () => {
    let price = 0;
    if (coupon && coupon.applicableTo === 'cart') {
      let discount = getDiscountedValue();
      price = productQuote.totalPerMonth - discount;
    } else {
      price = productQuote.totalPerMonth;
    }
    return price;
  };
  
  /* istanbul ignore next */
  const pricingFormat = (value) => {
    let parts = value.toString().split('.');
    parts[0] = parts[0].split('').reverse().join('').replace(/(\d{3})/g, '$1,').split('').reverse().join('');
    if (parts[0].startsWith(',')) {
      parts[0] = parts[0].slice(1);
    }
    return parts.join('.');
  };

  let classNames = require('classnames');
  const orderSummaryClass = classNames({
    'row margin-bottom-60 checkout-content margin-top-160': mobile === false,
    'row margin-bottom-60 checkout-content': mobile === true
  });

  return (
    <React.Fragment>
      {quoteLoading && (
        <div className='row spinner'>
          <i className='fa fa-spinner fa-pulse fa-3x fa-fw'></i>
          <span className='sr-only'>Loading...</span>
        </div>
      )}
      {!quoteLoading && (
        <div className={orderSummaryClass}>
          {coupon && coupon.agentName && isAgentVisible === true && (
            <div className='row coupon-agent margin-bottom-20 captions-semibold'>
              Your Consultant is {coupon.agentName}.
            </div>
          )}
          <div className='row' style={{width:'100%',marginRight:'0px'}}>
            <div className='col-xs-8' style={{paddingLeft:'0px'}}>
              {productQuote !== undefined &&
                productQuote !== '' &&
                Object.keys(productQuote).length > 0 && (
                  <div className='checkout-header' style={{marginLeft:'15px'}}>
                    {packageKey === 'rpnz' && <span className='text-uppercase'> RPNZ</span>}
                    {CORDELL_KEYS.includes(packageKey) && (
                      <span>
                        Cordell Estimator
                        <br />
                        {packageKey === 'cordell_estimator_platinum_lite' && (
                          <div>
                            <span className='text-uppercase'>Platinum Lite</span> Package
                          </div>
                        )}
                        {packageKey === 'cordell_estimator_platinum_premium' && (
                          <div>
                            <span className='text-uppercase'>Platinum Premium</span> Package
                          </div>
                        )}
                      </span>
                    )}
                    {PROPERTY_GURU_KEYS.includes(packageKey) && (
                      <span>
                        Property Guru
                        <br />
                        {packageKey === 'lite' && (
                          <div>
                            <span className='text-uppercase'>Lite</span> Package
                          </div>
                        )}
                        {packageKey === 'base' && (
                          <div>
                            <span className='text-uppercase'>Base</span> Package
                          </div>
                        )}
                        {packageKey === 'premium' && (
                          <div>
                            <span className='text-uppercase'>Premium</span> Package
                          </div>
                        )}
                        {packageKey === 'pro' && (
                          <div>
                            <span className='text-uppercase'>Pro</span> Package
                          </div>
                        )}
                      </span>
                    )}
                  </div>
                )}
            </div>
            {VALID_PACKAGE_KEYS.includes(packageKey) && (
              <div className='col-xs-4 text-right' style={{padding:'0px',marginRight:'-15px',float:'right'}}>
                <button
                  type='button'
                  className='btn btn-outline product-config-change-btn off-white-bg'
                  onClick={() => {
                    changePackage();
                  }}
                >
                  Change
                </button>
              </div>
            )}
          </div>
          {productQuote !== undefined &&
            productQuote !== '' &&
            Object.keys(productQuote).length > 0 && (
              <div>
                <p className='price-tag'>
                  {parseFloat(getDiscountedTotalPrice()) > 0.0 && (
                    <span className='checkout-price'>
                      ${parseFloat(getDiscountedTotalPrice()).toFixed(2)}
                    </span>
                  )}
                  {parseFloat(getDiscountedTotalPrice()) <= 0.0 && (
                    <span className='checkout-price'>$0.00</span>
                  )}
                  &nbsp;
                  {productQuote.totalPerMonth > 0 && (
                    <span className='font-12 text-right captions'>
                      Per month
                    </span>
                  )}
                </p>
                {productQuote.totalPerMonth > 0 && (
                  <div className='footnote-text padding-left-0'>
                    Min. cost is $
                    {pricingFormat(
                      parseFloat(getDiscountedTotalPrice() * CONTRACT_MONTHS).toFixed(2)
                    )}{' '}
                    over 12 month(s).
                  </div>
                )}
                {getDiscountedValue() > 0 && productQuote.totalPerMonth > 0 && (
                  <div className='row'>
                    <div className='col-xs-12'>
                      <p className='checkout-discount success-text margin-top-10 captions'>
                        You save ${getDiscountedValue().toFixed(2)} per month
                      </p>
                    </div>
                  </div>
                )}
                {getDiscountedValue() === 0 && <br />}
                {productQuote.lineItems && productQuote.lineItems.length > 0 && (
                  <p className='no-margin wcag-grey-text captions'>Inclusions</p>
                )}
                {productQuote.lineItems &&
                  productQuote.lineItems.map(function (lineItem, index) {
                    return (
                      // Check if category is added
                      <div className='row' key={lineItem.description} style={{paddingTop: "5px"}}>
                        <div className='col-sm-12'>
                          <p className='body no-margin'>{lineItem.description}</p>
                        </div>
                      </div>
                    );
                  })}
                {productQuote.features && <>
                  {productQuote.features.map(function (line, index) {
                    let tooltip = <Tooltip id='tooltip '>{line.tooltip}</Tooltip>;
                    if(index < 3 && !showAdditionalFeatures || showAdditionalFeatures )
                      return (
                        <div className={'row'} key={line.description}>
                          <div className='col-xs-10' style={{paddingTop: "5px"}}>
                            <p className='no-margin text-capitalize body'>
                              <span className='margin-right-5 title-column' style={{paddingTop: "5px"}}>{line.description} </span>
                              {line.tooltip !== null && line.tooltip !== '' && (
                                <OverlayTrigger
                                  placement='top'
                                  overlay={tooltip}
                                  trigger={['click', 'hover', 'focus']}
                                >
                                  <span>
                                    <i
                                      className='far fa-question-circle tooltip-icon tooltip-column'
                                      aria-hidden='true'
                                      style={{top: "0.1em"}}
                                    ></i>
                                  </span>
                                </OverlayTrigger>
                              )}
                            </p>
                          </div>
                        </div>
                      ); 
                  })}
                  {productQuote.features.length > 3 &&
                    <div className='row body no-margin grey-text pointer'>
                      <span onClick={ () => setShowAdditionalFeatures(s => !s) }>
                        Show {showAdditionalFeatures ? 'less' : 'more'}
                      </span>
                    </div>
                  }
                  </>
                  }
                  
                <br />
                <div className='row no-margin'>
                  {productQuote.addons && productQuote.addons.length > 0 && (
                    <p className='grey-text no-margin captions'>
                      <b> Add Ons</b>{' '}
                    </p>
                  )}
                  {productQuote.addons &&
                    productQuote.addons.map(function (addon, index) {
                      return (
                        <div className='row' key={`product-quote-addon-${index}`}>
                          <hr className='margin-5-15' />
                          <div className='col-xs-10'>
                            <p className='font-16 no-margin'>{addon.description}</p>
                          </div>
                          <div className='col-xs-2 text-right'>
                            <i
                              className='fa fa-times-circle close-icon'
                              aria-hidden='true'
                              onClick={() => {
                                onRemoveAddon(addon.description);
                              }}
                            ></i>
                          </div>
                        </div>
                      );
                    }, this)}
                </div>
                {productQuote.lineItems && productQuote.lineItems.length > 0 && !loading && (
                  <div className='row no-margin'>
                    <button
                      type='button'
                      className='btn btn-primary add-to-cart no-margin'
                      onClick={() => {
                        onAddToCart('buy');
                      }}
                    >
                      Buy it now
                    </button>
                    <a
                      type='button'
                      className='btn add-to-cart btn-outline-20 off-white-bg'
                      onClick={() => {
                        onAddToCart('add');
                      }}
                    >
                      Add to cart
                    </a>
                  </div>
                )}
                {loading && (
                  <div className='row spinner' style={{ marginTop: '0px' }}>
                    <i className='fa fa-spinner fa-pulse fa-3x fa-fw'></i>
                    <span className='sr-only'>Loading...</span>
                  </div>
                )}
              </div>
            )}
        </div>
      )}
    </React.Fragment>
  );
};

ProductConfigSummary.propTypes = {
  cart: PropTypes.object.isRequired,
  changePackage: PropTypes.func.isRequired,
  coupon: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  onAddToCart: PropTypes.func.isRequired,
  onRemoveAddon: PropTypes.func.isRequired,
  packageKey: PropTypes.string.isRequired,
  productQuote: PropTypes.object,
  quoteLoading: PropTypes.bool.isRequired
};

export default ProductConfigSummary;
