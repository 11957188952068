import React, { useState } from 'react';
import PropTypes from 'prop-types';

import history from '../../../../history';

import { PanelGroup, Panel } from 'react-bootstrap';

import PackageUsers from '../../products/packageUsers';

import UsersActive from '../../../../image/Icons/Users-active.svg';
import CategoryIcon from  "../../../../image/Icons/Table-House-Pricing.svg"

/**
 * CordellEstimatorProductPackageConfiguration.
 *
 * @author  Miguel
 * @param   {Function}    props.onSelectingUser              Function to call on selecting users.
 * @param   {Object}      props.packageKey                   Product Package Key Object.
 * @param   {Object}      props.productPackageConfig         Product Package Object.
 * @param   {Object}      props.productPackageDefaultConfig  Pruduct Package Default Object.
 * @param   {Function}    props.setCategory                  Function to call on set category.
 * @returns {JSX.Element}                                    The component markup.
 */

const CordellEstimatorProductPackageConfiguration = ({
  onSelectingUser,
  packageKey,
  productPackageConfig,
  productPackageDefaultConfig,
  setCategory
}) => {
  const [activeKey, setActiveKey] = useState('1');

  const handleSelect = (newActiveKey) => {
    setActiveKey(newActiveKey);
  };

  const onCategoryClickAction = () => {
    setActiveKey('2');
  };

  const onUserClickAction = () => {
    setActiveKey('3');
  };

  const selectCategory = (categoryKey) => {
    if (categoryKey) {
      setCategory(categoryKey);
    }
  };

  return (
    <div>
      <div className='row  no-margin'>
        <div className='col-sm-12'>
          <div className='row'>
            <div className='back-btn' onClick={history.goBack}>
              {' '}
              &lt; Back{' '}
            </div>
            <hr />
          </div>
          <div className='row'>
            <div className='section-no-pad-title text-center'>Customise Your Subscription</div>
          </div>
        </div>
      </div>
      <div className='row white-bg padding-20'>
        <div className='col-sm-12 no-padding'>
          <PanelGroup
            accordion
            id='rpp-config-accordion'
            activeKey={activeKey}
            onSelect={handleSelect}
          >
            <Panel eventKey='1'>
              <Panel.Heading className='white-bg'>
                <Panel.Title toggle>
                  <div type='button' className='row panel-title panel-button no-margin'>
                    <div className='col-xs-7 text-left text-success no-padding'>
                      <img src={CategoryIcon} alt='' className='margin-right-5' />
                      <span className='text-success body'>Category</span>
                      
                    </div>
                    <div className='col-xs-5 text-right text-success no-padding'>
                      {!productPackageDefaultConfig.configuration.category.residential && (
                        <span className='body'>Trade Business and Builder &nbsp;</span>
                      )}
                      {productPackageDefaultConfig.configuration.category.residential && (
                        <span>Trade Business and Builder, Residential &nbsp;</span>
                      )}
                      {activeKey !== '1' && (
                        <span className='fa carat-down' aria-hidden='true'></span>
                      )}
                      {activeKey === '1' && (
                        <span className='fa carat-up' aria-hidden='true'></span>
                      )}
                    </div>
                  </div>
                </Panel.Title>
              </Panel.Heading>
              <Panel.Body collapsible>
                <div className='panel-body-spacing'>
                  <div className='alert alert-success'>
                    <div className='row body'>
                      <div className='col-xs-12'>
                        Trade Business and Builder
                        <div className='row no-margin footnote-text'>
                          {
                            productPackageConfig.packages[packageKey].features.categories
                              .trade_business_and_builder.sub_text_1
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      productPackageDefaultConfig.configuration.category.residential
                        ? 'alert alert-success'
                        : 'alert'
                    }
                    onClick={() => {
                      selectCategory('residential');
                    }}
                  >
                    <div className='row body'>
                      <div className='col-xs-12'>
                        Residential
                        <div className='row no-margin footnote-text'>
                          {
                            productPackageConfig.packages[packageKey].features.categories
                              .residential.sub_text_1
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type='submit'
                  className='btn btn-primary float-right btn-continue margin-bottom-10'
                  onClick={() => {
                    onCategoryClickAction();
                  }}
                >
                  Next
                </button>
              </Panel.Body>
            </Panel>
            <Panel eventKey='2'>
              <Panel.Heading className='white-bg'>
                <Panel.Title toggle>
                  <div type='button' className='row panel-title panel-button no-margin'>
                    <div className='col-xs-7 text-left text-success no-padding'>
                      <img src={UsersActive} alt='' className='margin-right-5' />
                      <span className='body'>Users</span>
                    </div>
                    <div className='col-xs-5 text-right text-success no-padding body'>
                      {productPackageDefaultConfig.configuration.numberOfUsers !== null && (
                        <span>
                          {productPackageDefaultConfig.configuration.numberOfUsers}{' '}
                          {productPackageDefaultConfig.configuration.numberOfUsers === 1
                            ? 'User'
                            : 'Users'}{' '}
                        </span>
                      )}
                      {activeKey !== '2' && (
                        <span className='fa carat-down' aria-hidden='true'></span>
                      )}
                      {activeKey === '2' && (
                        <span className='fa carat-up' aria-hidden='true'></span>
                      )}
                    </div>
                  </div>
                </Panel.Title>
              </Panel.Heading>
              <Panel.Body collapsible>
                <PackageUsers
                  users={productPackageConfig.packages[packageKey].users}
                  selectedUser={productPackageDefaultConfig.configuration.numberOfUsers}
                  onSelectingUser={onSelectingUser}
                  onUserClickAction={onUserClickAction}
                />
              </Panel.Body>
            </Panel>
          </PanelGroup>
        </div>
      </div>
    </div>
  );
};

CordellEstimatorProductPackageConfiguration.propTypes = {
  onSelectingUser: PropTypes.func.isRequired,
  packageKey: PropTypes.string.isRequired,
  productPackageConfig: PropTypes.object.isRequired,
  productPackageDefaultConfig: PropTypes.object.isRequired,
  setCategory: PropTypes.func.isRequired
};

export default CordellEstimatorProductPackageConfiguration;
