import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const PackageUsers = ({ onSelectingUser, onUserClickAction, selectedUser, users }) => {
  const [size, setSize] = useState(1);
  const [usersPackage, setUsersPackage] = useState([]);
  const [showMoreUsersOption, setShowMoreUsersOption] = useState(true);
  const [newSelectedUser, setNewSelectedUser] = useState(1);

  const showMoreUsers = () => {
    if (showMoreUsersOption) {
      setShowMoreUsersOption(false);
      setSize(usersPackage.length);
    } else {
      setShowMoreUsersOption(true);
      setSize(3);
    }
  };

  // Functions for various actions within selectUser
  const handleOnClickAction = (clickAction) => {
    if (clickAction) onUserClickAction();
  }

  /* istanbul ignore next */
  const computeUserPricing = (newUsersPackage, user) => {
    return newUsersPackage.map((obj) => {
      let userCount = parseInt(obj.number, 10);
      if (userCount > user) {
        return updateHigherUserPricing(obj, user, userCount);
      } else if (userCount < user) {
        return updateLowerUserPricing(obj, user, userCount);
      } else {
        return {...obj, value: 'Selected'};
      }
    });
  }

  /* istanbul ignore next */
  const updateHigherUserPricing = (obj, user, userCount) => {
    let selectedUserPricing = users.user_price[parseInt(user, 10)]; // value selected
    let higherUserPricing = users.user_price[userCount];
    let addedPrice = selectedUserPricing
      ? higherUserPricing - selectedUserPricing
      : higherUserPricing;
    return {...obj, value: `+$${addedPrice}`};
  }

  /* istanbul ignore next */
  const updateLowerUserPricing = (obj, user, userCount) => {
    let selectedUserPricing = users.user_price[user]; // value selected
    let lowerUserPricing = users.user_price[userCount];
    let reducedPrice = lowerUserPricing
      ? selectedUserPricing - lowerUserPricing
      : selectedUserPricing;
    return {...obj, value: `-$${reducedPrice}`};
  }

  const computePricingForAllUsers = (newUsersPackage, user) => {
    let pricePerUser = users.price_per_additional_user || users.additionalPricePerUser || 0;
    return newUsersPackage.map((obj, index) => {
      return updateIndividualUserPricing(obj, user, pricePerUser, index);
    });
  }

  const updateIndividualUserPricing = (obj, user, pricePerUser, index) => {
    let userIndex = parseInt(obj.number, 10) - user;
    let userPrice = Math.abs(pricePerUser * userIndex).toFixed(2);
    if (userIndex === 0) {
      return {...obj, value: 'Selected'};
    } else if (obj.number > user) {
      return {...obj, value: `+$${userPrice}`};
    } else if (obj.number < user) {
      return {...obj, value: `-$${userPrice}`};
    }
  }

  // Main function
  const selectUser = (user, newUsersPackage, clickAction) => {
    onSelectingUser(user);

    handleOnClickAction(clickAction);

    if (users.user_price) {
      newUsersPackage = computeUserPricing(newUsersPackage, user);
    } else {
      newUsersPackage = computePricingForAllUsers(newUsersPackage, user);
    }

    //set the updated users value
    setUsersPackage(newUsersPackage);
    setNewSelectedUser(user);
  };

  useEffect(() => {
    const currentUsersPackage = users.user_price
      ? createPackageFromUserPrice()
      : createPackageFromUserCount();
    
    const newSize = currentUsersPackage.length > 3 ? 3 : currentUsersPackage.length;
    const minUsers = users.min_users || users.minimumUsers || 0;
    
    setSize(newSize);
    setNewSelectedUser(selectedUser != null ? selectedUser : parseInt(minUsers, 10));
  
    if (selectedUser != null) {
      selectUser(selectedUser, currentUsersPackage, false);
    } else {
      setUsersPackage(currentUsersPackage);
    }
  }, []);
  
  const createPackageFromUserPrice = () => {
    let currentUsersPackage = [];
    let userPricing = users.user_price;
    
    if (users.default_users) {
      currentUsersPackage.push({ number: parseInt(users.default_users, 10), value: 'Included' });
    }
    
    for (const [key, value] of Object.entries(userPricing)) {
      currentUsersPackage.push({ number: key, value: `+$${value}` });
    }
    
    return currentUsersPackage;
  }
  
  const createPackageFromUserCount = () => {
    let currentUsersPackage = [];
    let pricePerUser = users.price_per_additional_user || users.additionalPricePerUser || 0;
    let minUsers = users.min_users || users.minimumUsers || 0;
    let counter = 0;
  
    if (users.max_users === 1) {
      currentUsersPackage.push({ number: 1, value: 'Included' });
    } else {
      for (let user = parseInt(minUsers, 10); user <= users.max_users; user++) {
        if (user === parseInt(users.default_users, 10)) {
          currentUsersPackage.push({ number: user, value: 'Included' });
        } else {
          counter++;
          let userPrice = (pricePerUser * counter).toFixed(2);
          currentUsersPackage.push({ number: user, value: `+$${userPrice}` });
        }
      }
    }
    
    return currentUsersPackage;
  }  

  const usersList = usersPackage.slice(0, size).map(function (singleUser, index) {
    let classNames = require('classnames');
    const userTileClass = classNames({
      'alert': newSelectedUser !== singleUser.number,
      'alert alert-success': newSelectedUser === singleUser.number
    });
    const userValueClass = classNames({
      'col-xs-4 text-right grey-text': newSelectedUser !== singleUser.number,
      'col-xs-4 text-right success-text': newSelectedUser === singleUser.number
    });
    return (
      <div
        className={userTileClass}
        key={index}
        onClick={() => {
          selectUser(singleUser.number, usersPackage, true);
        }}
      >
        <div className='row body'>
          <div className='col-xs-8'>
            {singleUser.number} {singleUser.number === 1 ? 'User' : 'Users'}
          </div>
          <div className={userValueClass}>{singleUser.value}</div>
        </div>
      </div>
    );
  });

  return (
    <React.Fragment>
      <div className='panel-body-spacing'>
        {usersList}
        <div className='row body no-margin grey-text pointer'>
          <div className='col-xs-8'>
            {usersPackage.length > 3 && showMoreUsersOption && (
              <span
                onClick={() => {
                  showMoreUsers();
                }}
              >
                Show more
              </span>
            )}
            {usersPackage.length > 3 && !showMoreUsersOption && (
              <span
                onClick={() => {
                  showMoreUsers();
                }}
              >
                Show less
              </span>
            )}
          </div>
          <div className='col-xs-4 text-right'>
            {usersPackage.length > 3 && showMoreUsersOption && (
              <span
                onClick={() => {
                  showMoreUsers();
                }}
                aria-hidden='true'
              ></span>
            )}
            {usersPackage.length > 3 && !showMoreUsersOption && (
              <span
                onClick={() => {
                  showMoreUsers();
                }}
                aria-hidden='true'
              ></span>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

PackageUsers.propTypes = {
  onSelectingUser: PropTypes.func.isRequired,
  onUserClickAction: PropTypes.func.isRequired,
  selectedUser: PropTypes.number,
  users: PropTypes.object.isRequired
};

export default PackageUsers;
