import Api from '../services/api.js';

export function fetchProductRequest(){
  return {
    type: 'FETCH_PRODUCT_REQUEST'
  }
}

export function fetchProductSuccess(body){
  return {
    type: 'FETCH_PRODUCT_SUCCESS',
    body: body
  }
}

export function fetchProductFailure(exception){
  return {
    type: 'FETCH_PRODUCT_FAILURE',
    exception: exception
  }
}

export function fetchProductByIdRequest(){
  return {
    type: 'FETCH_PRODUCT_BY_ID_REQUEST'
  }
}

export function fetchProductByIdSuccess(body, productId){
  return {
    type: 'FETCH_PRODUCT_BY_ID_SUCCESS',
    body: body,
    productId: productId
  }
}

export function fetchProductByIdFailure(exception){
  return {
    type: 'FETCH_PRODUCT_BY_ID_FAILURE',
    exception: exception
  }
}

export function fetchAffiliatesDetailsRequest(){
  return {
    type: 'FETCH_AFFILIATES_DETAILS_REQUEST'
  }
}

export function fetchAffiliatesDetailsSuccess(body, productId){
  return {
    type: 'FETCH_AFFILIATES_DETAILS_SUCCESS',
    body: body,
    productId: productId
  }
}

export function fetchAffiliatesDetailsFailure(exception){
  return {
    type: 'FETCH_AFFILIATES_DETAILS_FAILURE',
    exception: exception
  }
}

export function setPackage(industryKey, packageKey, friendlyName){
  return {
    type: 'SET_PACKAGE',
    industryKey: industryKey,
    packageKey: packageKey,
    friendlyName: friendlyName
  }
}

export function changeIndustry(industryKey, packageKey){
  return {
    type: 'CHANGE_INDUSTRY',
    industryKey: industryKey,
    packageKey: packageKey
  }
}

export function updateQuoteConfig(productId, quoteConfig){
  return {
    type: 'UPDATE_QUOTE_CONFIG',
    productId: productId,
    quoteConfig: quoteConfig
  }
}

export function fetchProduct(friendlyName){
  return (dispatch) => {
    dispatch(fetchProductRequest())
    const api = new Api();
    return api.get("/__api/product/"+friendlyName, {headers: { Region: "NZ" }})
    .then(response => dispatch(fetchProductSuccess(response.data)))
    .catch(err => dispatch(fetchProductFailure(err)));
  };
}

export function fetchAffiliatesDetails(couponCode, packageName) {
  const requestBody = {
    code: couponCode,
    packageName: packageName
  }
  return (dispatch) => {
    dispatch(fetchAffiliatesDetailsRequest());
    const api = new Api();
    return api
      .post("/__api/affiliate/coupon", requestBody, { headers: { Region: 'NZ' } })
      .then((response) => dispatch(fetchAffiliatesDetailsSuccess(response.data)))
      .catch((err) => dispatch(fetchAffiliatesDetailsFailure(err)));
  };
}

export function setCouponCodeForCart(code) {
  return {
    type: 'SET_COUPON_CODE_FOR_CART',
    code: code
  }
}

export function resetCoupon() {
  return {
    type: 'RESET_COUPON'
  }
}
