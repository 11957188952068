export default class SegmentAnalytics {

  getPageName = () => {
    const pathname = window.location.href
    if (pathname.includes('/product/')) return 'Package Selection';
    if (pathname.includes('/product_config/')) return 'Customisation';
    if (pathname.includes('/register')) return 'Sign Up';
    if (pathname.includes('/email')) return 'Email Validation';
    if (pathname.includes('/order') || pathname.includes('/payment')) return 'Order Status';
    if (pathname.includes('/pxpay')) return 'DPS';
    if (pathname.includes('/checkout')) return 'Checkout';
    return 'Cart';
  }

  trackReturnToCorelogic = (product) => {
    const pageName = this.getPageName()
    let productName = null;

    if(pageName === 'Package Selection') {
      const path = window.location.pathname.split('/');
      //path[2] will return the friendly name for the product
      productName = (product && product.productsConfig && product.productsConfig[path[2]]) ? product.productsConfig[path[2]].productName : null;
    }
    const trackingObj = {
      eventType: 'Return to corporate site',
      data: {
        'Page': pageName,
        'Product': productName,
        'name': pageName
      }
    }
    this.analyticsTrack(trackingObj)
  }

  trackGenericPageInfo = (eventMessage, pageStatus) => {
    const pageName = pageStatus ? pageStatus : this.getPageName()
    const trackingObj = {
      eventType: eventMessage,
      data: {
        'Page': pageName,
        'name': pageName
      }
    }
    this.analyticsTrack(trackingObj)
  }

  trackGotoCart = (itemsCount) => {
    const pageName = this.getPageName()
    const trackingObj = {
      eventType: 'Go to Cart',
      data: {
        'Page': pageName,
        'Number': itemsCount,
        'name': pageName
      }
    }
    this.analyticsTrack(trackingObj)
  }

   // Sign up
  trackSignUp = (cta) => {
    const pageName = this.getPageName()
    const trackingObj = {
      eventType: 'Sign-in',
      data: {
        'Page': pageName,
        'CTA': cta,
        'name': pageName
      }
    }
    this.analyticsTrack(trackingObj)
  }

  // Add to Cart
  trackAddToCart = (cta, quote) => {
    const pageName = this.getPageName()
    const trackingObj = {
      eventType: 'Add to Cart',
      data: {
        'Page': pageName,
        'CTA': cta,
        'Product': quote.productGroup,
        'Package': quote.headLine,
        'Monthly Amount': quote.totalPerMonth,
        'name': pageName
      }
    }
    this.analyticsTrack(trackingObj)
  }

  //Information
  trackCompanyInfo = (info) => {
    const pageName = this.getPageName()
    const trackingObj = {
      eventType: 'Go to company information page',
      data: {
        'Page': pageName,
        'Information': info,
        'name': pageName
      }
    }
    this.analyticsTrack(trackingObj)
  }

  //Track package Selection
  trackPackageSelection = (product, industry, packageName) => {
    const pageName = this.getPageName()
    const trackingObj = {
      eventType: 'Select Package',
      data: {
        'Page': pageName,
        'Product Selected': product,
        'Industry': industry,
        'Package Selected': packageName,
        'name': pageName
      }
    }
    this.analyticsTrack(trackingObj)
  }

  //Delete package
  trackDeletePackage = (quote) => {
    const pageName = this.getPageName()
    const trackingObj = {
      eventType: 'Delete Package',
      data: {
        'Page': pageName,
        'Product': quote.headLine,
        'Industry': quote.productGroup,
        'Package': quote.headLine,
        'name': pageName
      }
    }
    this.analyticsTrack(trackingObj)
  }

  //Apply / Remove coupon
  trackApplyOrRemoveCoupon = (eventMessage, coupon) => {
    const pageName = this.getPageName()
    if (coupon) {
      let discount = null;
      if (coupon.discountServiceConfig) {
        let discountConfig = coupon.discountServiceConfig
        if(discountConfig.discountPercent && discountConfig.discountPercent > 0) {
          discount = discountConfig.discountPercent + '%'
        } else if(discountConfig.discountFixed && discountConfig.discountFixed > 0) {
          discount = '$' + discountConfig.discountFixed
        } else if (discountConfig.monthsDiscount && discountConfig.monthsDiscount > 0) {
          discount = discountConfig.monthsDiscount + ' months'
        }
      }

      const trackingObj = {
        eventType: eventMessage,
        data: {
          'Page': pageName,
          'Coupon': coupon.code,
          'Discount': discount,
          'Referrer': coupon.agentName,
          'name': pageName
        }
      }
      this.analyticsTrack(trackingObj)
    }
  }

  //Track if email was validated
  trackEmailValidation = (validated) => {
    const pageName = this.getPageName()
    const trackingObj = {
      eventType: 'Email validation',
      data: {
        'Page': pageName,
        'Validated': validated ? 'Yes' : 'No',
        'name': pageName
      }
    }
    this.analyticsTrack(trackingObj)
  }

  //Continue shopping from email validation
  trackContinueShoppingFromEmailValidation = () => {
    const pageName = this.getPageName()
    const trackingObj = {
      eventType: 'Continue Shopping',
      data: {
        'Page': pageName,
        'Entry Point': 'Expired / New Session',
        'name': pageName
      }
    }
    this.analyticsTrack(trackingObj)
  }

  //payment method
  trackPaymentMethod = (paymentMethod) => {
    const pageName = this.getPageName()
    const trackingObj = {
      eventType: 'Payment Method',
      data: {
        'Page': pageName,
        'Payment Method Selected': paymentMethod === 'credit' ? 'Credit Card' : 'Direct Debit',
        'name': pageName
      }
    }
    this.analyticsTrack(trackingObj)
  }

  //track paid order
  trackPaidOrder = (order) => {
    const pageName = this.getPageName()
    let onceOffPrice = 0
    let onceOffDiscount = 0
    let subscriptionsPrice = 0
    let subscriptionsDiscount = 0

    const quotes = order.quotes.quotes
    let orderType = quotes.length > 1 ? 'Combination' : null

    const productList = quotes.map(item => item.headLine);
    const productGroupList = quotes.map(item => item.productGroup);

    for (let quoteIndex = 0; quoteIndex < quotes.length; quoteIndex++) {
      if (quotes[quoteIndex].minimumInitialTerm === 1) {
        onceOffPrice += quotes[quoteIndex].originalPrice
        onceOffDiscount += quotes[quoteIndex].discount
        if(orderType === null) { orderType = 'Once off' }
      }else{
        subscriptionsPrice += quotes[quoteIndex].originalPrice
        subscriptionsDiscount += quotes[quoteIndex].discount
        if(orderType === null) { orderType = 'Subscription' }
      }
    }

    const trackingObj = {
      eventType: 'Submit Order',
      data: {
        'Page': pageName,
        'Order Type': orderType,
        'Order Number': order.orderNumber,
        'One off total': parseFloat((onceOffPrice/1.1).toFixed(2)),
        'One off discount': parseFloat((onceOffDiscount/1.1).toFixed(2)),
        'Subscription monthly amount': parseFloat((subscriptionsPrice/1.1).toFixed(2)),
        'Subscription monthly discount': parseFloat((subscriptionsDiscount/1.1).toFixed(2)),
        'ACV': parseFloat((order.quotes.cartMinTotal/1.1).toFixed(2)),
        'Cart discount': parseFloat((order.quotes.cartDiscount/1.1).toFixed(2)),
        'Product': productList.join(', '),
        'Group': productGroupList.join(', '),
        'name': pageName
      }
    }
    this.analyticsTrack(trackingObj)
  }

  //track trial order
  trackTrialOrder = (order) => {
    const pageName = this.getPageName()
    const quotes = order.quotes.quotes

    const productList = quotes.map(item => item.headLine);
    const productGroupList = quotes.map(item => item.productGroup);

    const trackingObj = {
      eventType: 'Submit Order',
      data: {
        'Page': pageName,
        'Order Type': 'Trial Request',
        'Order Number': order.orderNumber,
        'Product': productList.join(', '),
        'Group': productGroupList.join(', '),
        'name': pageName
      }
    }
    this.analyticsTrack(trackingObj)
  }

  //Track order
  trackOrder = (order, type) => {
    type === 'trial' ? this.trackTrialOrder(order) : this.trackPaidOrder(order)
  }

  //Track Order Status
  trackOrderStatus = (order, result, reason, payment_id) => {
    const pageName = this.getPageName()
    let onceOffPrice = 0
    let subscriptionsPrice = 0

    const quotes = order.quotes.quotes
    let orderType = quotes.length > 1 ? 'Combination' : null

    const productList = quotes.map(item => item.headLine);
    const productGroupList = quotes.map(item => item.productGroup);

    for (let quoteIndex = 0; quoteIndex < quotes.length; quoteIndex++) {
      if (quotes[quoteIndex].minimumInitialTerm === 1) {
        onceOffPrice += quotes[quoteIndex].originalPrice
        if(orderType === null) { orderType = 'Once off' }
      }else{
        subscriptionsPrice += quotes[quoteIndex].originalPrice
        if(orderType === null) { orderType = 'Subscription' }
      }
    }

    const trackingObj = {
      eventType: 'Order Status',
      data: {
        'Page': pageName,
        'Result': result,
        'Order Type': orderType,
        'Order Number': order.orderNumber,
        'One off total': parseFloat((onceOffPrice/1.1).toFixed(2)),
        'Subscription monthly amount': parseFloat((subscriptionsPrice/1.1).toFixed(2)),
        'ACV': parseFloat((order.quotes.cartMinTotal/1.1).toFixed(2)),
        'Reason': reason,
        'Customer Id': order.customer.id,
        'Payment Id': payment_id,
        'Product': productList.join(', '),
        'Group': productGroupList.join(', '),
        'name': pageName
      }
    }
    this.analyticsTrack(trackingObj)
  }

  trackWindcavePaymentStatus(result, reason, payment) {
    const pageName = this.getPageName()
    const trackingObj = {
      eventType: 'Sales assisted tokenisation',
      data: {
        'Page': pageName,
        'Result': result,
        'Opportunity Number': payment.opportunityNumber,
        'Amount': payment.amount,
        'Currency': payment.currency,
        'Region': payment.region,
        'Reason': reason,
        'name': pageName
      }
    }
    this.analyticsTrack(trackingObj)
  }

  trackAction = (eventMessage, action, pageStatus) => {
    const pageName = pageStatus ? pageStatus : this.getPageName()
    const trackingObj = {
      eventType: eventMessage,
      data: {
        'Page': pageName,
        'Action': action,
        'name': pageName
      }
    }
    this.analyticsTrack(trackingObj)
  }

  trackError = (action, page, errorMessage, errorCode) => {
    const pageName = page ? page : this.getPageName()
    let trackingObj = {
      eventType: 'Capture Error',
      data: {
        'site': 'NZ',
        'Page': pageName,
        'Action': action,
        'Error Message': errorMessage,
        'Error Code': errorCode,
        'name': pageName
      }
    }
    this.analyticsTrack(trackingObj)
  }

  analyticsCheckout = (page, orderNumber) => {
    window.analytics.page(page,{
      'site': 'NZ',
      'Order Number': orderNumber
    });
  }

  //PAGE
  analyticsPageView = (page) => {
    window.analytics.page(page, {
      'site': 'NZ'
    });
  }

  // TRACK
  analyticsTrack = (trackingObj) => {
    trackingObj.data.site = 'NZ'
    window.analytics.track(trackingObj.eventType, trackingObj.data)
  }

  analyticsAlias = (claudId) => {
    window.analytics.alias(claudId, { previousId: window.mixpanel.get_distinct_id(), userId: claudId});
  }

  analyticsIdentity = (user) => {
    if (user) {
      window.analytics.identify(user.guid, {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        username: user.username,
        site: 'NZ'
      });
    }
  }

  analyticsIdentifyIndustry = (userId, industry) => {
    window.analytics.identify(userId, {
      industry: industry
    });
  }

  analyticsLogoutUser = () => {
    window.analytics.reset();
    window.mixpanel.reset();
  }
}
