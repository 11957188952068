class DataLayerService {

  //Calculate the values
  productRevenueDetails(selectProduct, quote, serviceType){
    let metric3 = 0
    let metric4 = 0
    let revenue = 0
    let products = []

    let productPackage = {
      "item_id": undefined,
      "item_name": undefined,
      "price": undefined,
      "quantity": 12,
      "item_category":undefined,
      "item_variant": undefined,
      "item_category2": undefined,
      "item_category3": undefined
    }

    //for cordell estimator
    if(serviceType !== null && serviceType === 'CordellEstimatorNZ'){

      let addonsDetails = [{
        "name": "Planswift",
        "price": 219.54
      }]
      selectProduct = selectProduct.configuration
      productPackage.item_brand = productPackage.item_id = productPackage.item_name = "CEP - Estimator Platinum"
      let addonsPrice = 0
      if(selectProduct.addons && selectProduct.addons.length > 0){
        for(let addonIndex = 0; addonIndex < selectProduct.addons.length;addonIndex++){
          let addon =  selectProduct.addons[addonIndex]
          for(let itemIndex = 0; itemIndex < addonsDetails.length;itemIndex++){
            let addonitem = addonsDetails[itemIndex]
            if(addon === addonitem.name){
              addonsPrice = addonsPrice + addonitem.price
            }
          }
        }
        if(addonsPrice > 0){
          productPackage.metric1 = parseFloat(((quote.totalPerMonth - addonsPrice)/1.1).toFixed(2))
          productPackage.metric2 = parseFloat((((quote.totalPerMonth - addonsPrice)/1.1) * 12).toFixed(2))
        }
      }else{
        productPackage.metric1 = parseFloat((quote.totalPerMonth/1.1).toFixed(2))
        productPackage.metric2 = parseFloat(((quote.totalPerMonth/1.1) * 12).toFixed(2))
      }

      productPackage.item_variant = "Platinum"
      productPackage.price = quote.totalPerMonth

      productPackage.item_category2 = selectProduct.numberOfUsers.toString()
      productPackage.item_category3 = '0'

      revenue = revenue + productPackage.price
      products.push(productPackage);


      if(selectProduct.addons && selectProduct.addons.length > 0){
        for(let addonIndx=0; addonIndx<selectProduct.addons.length; addonIndx++){
          let selectedAddon = selectProduct.addons[addonIndx]
          productPackage = {
            'item_name': selectedAddon,
            'item_id': selectedAddon,
            'price': 0,
            'quantity': 1,
          }

          if(selectedAddon === "Planswift"){
            productPackage.item_id = "Addon-Planswift"
          }

          let addonItems = addonsDetails
          for(let addonItemIndex=0; addonItemIndex<addonItems.length;addonItemIndex++){
            let addonItem = addonItems[addonItemIndex]
            if(selectedAddon === addonItem.name){
              productPackage.metric1 = parseFloat((addonItem.price/1.1).toFixed(2))
              productPackage.metric2 = parseFloat(((addonItem.price/1.1) * 12).toFixed(2))
            }
          }
          productPackage.price = quote.totalPerMonth

          products.push(productPackage);
        }
      }
    }

    //Guru
     else if(serviceType !== null && serviceType === 'Guru'){
      selectProduct = selectProduct.configuration
      productPackage.item_brand = productPackage.item_id = productPackage.item_name = "Guru"
      productPackage.price = quote.totalPerMonth
      productPackage.metric1 = parseFloat((quote.totalPerMonth/1.1).toFixed(2))
      productPackage.metric2 = parseFloat(((quote.totalPerMonth/1.1) * 12).toFixed(2))
      productPackage.item_category2 = selectProduct.number_of_users.toString()
      productPackage.item_category3 = '0'
      productPackage.item_variant = "Guru"
      metric3 = metric3 + productPackage.metric1
      metric4 = metric4 + productPackage.metric2
      revenue = revenue + productPackage.price
      products.push(productPackage);
    }

    //RPNZ
    else if(serviceType !== null && serviceType === 'RPNZ'){
      selectProduct = selectProduct.configuration
      productPackage.item_brand = productPackage.item_id = productPackage.item_name = "RPNZ"
      productPackage.price = quote.totalPerMonth
      productPackage.item_category2 = selectProduct.number_of_users.toString()
      productPackage.item_category3 = '0'
      productPackage.item_variant = "RPNZ"
  
      revenue = revenue + productPackage.price
      products.push(productPackage);
    }

    let productValues = {
      'products': products,
      'revenue': revenue,
    }

    return productValues;
  }

  // Script 1
  notifyOnNewCartItem(item, configuration){
    item.quote.quotes.map(function(subItem, subIndex){
      if(subItem.productId === configuration.product_id){
        let productDetails = this.productRevenueDetails(configuration, subItem, subItem.type)
        window.dataLayer.push({
          'event': 'addToCart',
          'ecommerce': {
            'add': {
              'products': productDetails.products
            }
          }
        });
      }
    }.bind(this))

  }

  // Script 2
  notifyOnDeleteCartItem(item){
    if(item !== undefined) {
      let productDetails = this.productRevenueDetails(item.configuration, item, item.type)
      for(let index=0;index<productDetails.products.length;index++){
        let product = productDetails.products[index]
        window.dataLayer.push({
          'event': 'removeFromCart',
          'ecommerce': {
            'remove': {
              'products': [{
                'name': product.item_name,
                'id': product.item_id,
                'price':  product.price,
                'item_category': product.item_category,
                'item_variant': product.item_variant,
                'quantity': product.quantity,
                'dimension1': product.item_category2,
                'dimension2': product.item_category3
              }]
            }
          }
        });
      }
    }
  }

  //Script 3
  notifyOnCheckout(data){
    if(data.order && data.order.quotes && data.order.quotes.quotes && data.order.quotes.quotes.length > 0){
      let serviceType = undefined
      let productDetails = {}
      let products = []

      data.order.quotes.quotes.map(function(quote, index){
        data.order.quoteConfiguration.products.map(function(quoteConfig, quoteConfigIndex){
          if(quoteConfig.product_id === quote.productId){

            serviceType = quote.type
            productDetails = this.productRevenueDetails(quoteConfig, quote, serviceType)
            for(let subIndex=0;subIndex<productDetails.products.length;subIndex++){
              let product = productDetails.products[subIndex]
              products.push(product);
            }

          }
        }.bind(this))
      }.bind(this))

      window.dataLayer.push({
        'event': 'checkout',
        'ecommerce': {
          'checkout': {
            'actionField': {'step': 1},
            'products': products
          }
        }
      });
    }
  }

  //Script 4
  setCheckoutOptionDataLayer(option){
    window.dataLayer.push({
      'event': 'checkoutOption',
      'ecommerce': {
        'checkout': {
          'actionField': {'step': 1, 'option': option }
        }
      }
    })
  }

  //Script 5
  setDataLayer(data){
    let serviceType = undefined
    let coupon = null
    let productDetails = {}
    let products = []
    let revenue = 0

    if(data.order.quotes.coupon !== null) {
      coupon = data.order.quotes.coupon.code
    }

    data.order.quotes.quotes.map(function(quote, index){
      data.order.quoteConfiguration.products.map(function(quoteConfig, quoteConfigIndex){
        if(quoteConfig.product_id === quote.productId){

          serviceType = quote.type
          productDetails = this.productRevenueDetails(quoteConfig, quote, serviceType)
          for(let subIndex=0;subIndex<productDetails.products.length;subIndex++){
            let product = productDetails.products[subIndex]
            if(data.order?.customer?.industry) {
              product.item_category = data.order.customer.industry;
            }
            product.coupon = coupon
            delete product['metric1']
            delete product['metric2']
            products.push(product);
          }
          revenue = revenue + productDetails.revenue*12
        }
      }.bind(this))
    }.bind(this))
    const tax = (revenue / 1.1) * 0.1;
    const event_revenue = Number(Math.abs(revenue).toFixed(2));
    const event_tax = Number(Math.abs(tax).toFixed(2));
    const transaction_id = data.order.orderNumber.toString();

    const purchaseEvent = {
      "event": "purchase",
      "ecommerce": {
        "currency": "NZD",
        "items": products,
        "transaction_id": transaction_id,
        "affiliation": "Corestore NZ",
        "value": event_revenue,
        "tax": event_tax,
        "coupon": coupon,
        "shipping": 0.00
      }
    };

    if (revenue > 0) {
      try {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push(purchaseEvent);
      } catch (error) {
          console.error("An error occurred: ", error);
      }
    }
  }
}

export default DataLayerService;
