import React, { useState } from 'react';
import PropTypes from 'prop-types';

import history from '../../../../history';

import { PanelGroup, Panel } from 'react-bootstrap';

import UsersActive from '../../../../image/Icons/Users-active.svg';
import Users from '../../users';

/**
 * RPNZProductPackageConfiguration.
 *
 * @author  Miguel
 * @param   {Function}    props.onSelectingUser              Function to call on selecting users.
 * @param   {Object}      props.productPackageConfig         Product Package Object.
 * @param   {Object}      props.productPackageDefaultConfig  Pruduct Package Default Object.
 * @returns {JSX.Element}                                    The component markup.
 */

const RPNZProductPackageConfiguration = ({
  onSelectingUser,
  productPackageConfig,
  productPackageDefaultConfig
}) => {
  const [activeKey, setActiveKey] = useState('1');
  const number_of_users = 1;

  /* istanbul ignore next */
  const handleSelect = (newActiveKey) => {
    setActiveKey(newActiveKey);
  };
  /* istanbul ignore next */
  const onUserClickAction = () => {
    setActiveKey('2');
  };

  return (
    <div>
      <div className='row  no-margin'>
        <div className='col-sm-12'>
          <div className='row'>
            <div className='back-btn' onClick={history.goBack}>
              {' '}
              &lt; Back{' '}
            </div>
            <hr />
          </div>
          <div className='row'>
            <div className='section-no-pad-title text-center'>Customise Your Subscription</div>
          </div>
        </div>
      </div>
      <div className='row white-bg padding-20 margin-10'>
        <div className='col-sm-12 no-padding'>
          <PanelGroup
            accordion
            id='report-bundle-config-accordion'
            activeKey={activeKey}
            onSelect={handleSelect}
          >
            <Panel eventKey='1'>
              <Panel.Heading className='white-bg'>
                <Panel.Title toggle>
                  <div type='button' className='row panel-title panel-button no-margin'>
                    <div className='col-xs-8 text-left font-16 no-padding'>
                      <span>
                        <img src={UsersActive} alt='' className='margin-right-5' />
                        <span className='text-success'>Users</span>
                      </span>
                    </div>
                    <div className='col-xs-4 text-right no-padding font-16'>
                      {number_of_users !== '' && (
                        <span className='margin-right-5'>
                          {productPackageDefaultConfig.configuration.number_of_users === null
                            ? 1
                            : productPackageDefaultConfig.configuration.number_of_users}
                          {productPackageDefaultConfig.configuration.number_of_users === 1 ||
                          productPackageDefaultConfig.configuration.number_of_users === null
                            ? ' User'
                            : ' Users'}
                        </span>
                      )}
                      {activeKey !== '1' && (
                        <span className='fa carat-down text-success' aria-hidden='true'></span>
                      )}
                      {activeKey === '1' && (
                        <span className='fa carat-up text-success' aria-hidden='true'></span>
                      )}
                    </div>
                  </div>
                </Panel.Title>
              </Panel.Heading>
              <Panel.Body collapsible>
                {productPackageConfig.packages.rpnz && (
                  <Users
                    productPackageConfig={productPackageConfig}
                    productPackageDefaultConfig={productPackageDefaultConfig}
                    users={productPackageConfig.packages.rpnz.users}
                    onSelectingUser={onSelectingUser}
                    selectedUser={productPackageDefaultConfig.configuration.number_of_users}
                    onUserClickAction={onUserClickAction}
                    usersRange={[1, 5, 10, 20]}
                  />
                )}
              </Panel.Body>
            </Panel>
          </PanelGroup>
        </div>
      </div>
    </div>
  );
};

RPNZProductPackageConfiguration.propTypes = {
  productPackageConfig: PropTypes.object.isRequired,
  productPackageDefaultConfig: PropTypes.object.isRequired,
  onSelectingUser: PropTypes.func.isRequired
};

export default RPNZProductPackageConfiguration;
