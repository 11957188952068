import React, { Component } from 'react';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import CustomerForm from './components/checkout/customerForm';
import OrderSummary from './components/orderSummary';
import {
  saveCustomerDetails,
  updateContactInfo,
  addressLines,
  getPropertyDetails,
  fetchAbnDetails,
  fetchCurrentOrder,
  getRoles,
  getAccounts,
  directDebitPayment,
  getAccountInfo,
  resetCheckoutLoaders,
  validateAccountReferenceNumber,
  resetAbnLookupProps,
  resetAddressLookupProps
} from '../actions/checkout';
import history from '../history';
import { Modal } from 'react-bootstrap';
import { redirectToLoginPage } from '../actions/login';
import { resetPurchaseEvent } from '../actions/pxpay';
import qs from 'query-string';

import './CheckoutPage.css';

export const BLACKLIST_ACCOUNTS = ['A0200848', 'A0096340', 'A0101117', 'A0060336', 'A0060335'];

const ADDRESS_LOOKUP_AUTO_MANUAL_ENTRY_TRIGGER_LENGTH = '5';
const businessOnlyKeys = ['role', 'businessName', 'legalEntityName', 'abn', 'abnManual', 'businessTermsClass']

const STATE_ABBREVIATIONS = {
  // AU
  "new south wales": "NSW",
  "victoria": "VIC",
  "queensland": "QLD",
  "south australia": "SA",
  "western australia": "WA",
  "tasmania": "TAS",
  "northern territory": "NT",
  "australian capital territory": "ACT",

  // NZ
  "northland": "NTL",
  "auckland": "AUK",
  "waikato": "WKO",
  "bay of plenty": "BOP",
  "gisborne": "GIS",
  "hawke's bay": "HKB",
  "taranaki": "TKI",
  "manawatu-wanganui": "MWT",
  "wellington": "WGN",
  "tasman": "TAS",
  "nelson": "NSN",
  "marlborough": "MBH",
  "west coast": "WTC",
  "canterbury": "CAN",
  "otago": "OTA",
  "southland": "STL"
};

function abbreviateState(address) {
  if (!address) return address;

  const parts = address.split(" ");

  for (let i = parts.length - 2; i >= 0; i--) {
    const potentialStateName = parts.slice(i, parts.length - 1).join(" ");
    // Convert both the potential state name and dictionary keys to lowercase for comparison
    const matchedState = Object.keys(STATE_ABBREVIATIONS).find(
      state => state.toLowerCase() === potentialStateName.toLowerCase()
    );
    if (matchedState) {
      parts.splice(i, parts.length - i - 1, STATE_ABBREVIATIONS[matchedState]);
      return parts.join(" ");
    }
  }

  return address;
}

class Checkout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cardType: 'credit',
      totalDays: 30,
      totalMonths: 0,
      userTotalMonths: 0,
      minCost: 0,
      conditionalTC: false,
      quotes: [],
      order: {},
      totalPrice: 0,
      creditCardSurcharge: 0,
      customer: {},
      directDebit: {
        bsbNumber: null,
        accountNumber: null,
        accountName: null
      },
      showBusinessOption: true,
      business: true,
      addressOption: false,
      mailingAddressAutocomplete: true,
      errorMessages: {
        abn: false,
        abnManual: false,
        abnFormat: false,
        abnManualFormat: false,
        abnInvalid: false,
        businessName: false,
        name: false,
        mobileNumber: false,
        email: false,
        preferredContact: false,
        singleAddressLine: false,
        physicalSingleAddressLine: false,
        legalEntityName: false,
        postcode: false,
        role: false,
        physicalState: false,
        physicalStreetAddress: false,
        physicalStreetAddressFormat: false,
        physicalSuburbCity: false,
        physicalPostcode: false,
        state: false,
        streetAddress: false,
        streetAddressFormat: false,
        suburbCity: false,
        bsbNumber: false,
        accountNumber: false,
        accountName: false,
        businessTerms: false,
        physicalAddress: false,
        title: false,
        trialTerms: false,
        terms: false,
        cardType: false,
        industry: false,
        accountReferenceNumber: true,
        accountReferenceNumberValid: false,
        accountType: false
      },
      required: {
        abn: false,
        abnManual: true,
        accountReferenceNumber: false,
        accountType: true,
        businessName: true,
        name: true,
        mobileNumber: true,
        email: true,
        industry: true,
        singleAddressLine: true,
        legalEntityName: true,
        postcode: false,
        role: true,
        state: false,
        streetAddress: false,
        suburbCity: false,
        physicalSingleAddressLine: false,
        physicalPostcode: false,
        physicalState: false,
        physicalStreetAddress: false,
        physicalSuburbCity: false,
        businessTerms: true,
        title: true,
        trialTerms: false,
        terms: false
      },
      disabled: {
        abn: false,
        abnManual: false,
        businessName: false,
        name: true,
        mobileNumber: false,
        email: true,
        singleAddressLine: false,
        legalEntityName: false,
        postcode: false,
        role: false,
        state: false,
        streetAddress: false,
        suburbCity: false,
        title: false,
        industry: false
      },
      requiredDebit: {
        bsbNumber: false,
        accountNumber: false,
        accountName: false
      },
      manualEntry: false,
      abnManualEntry: true,
      physicalManualEntry: false,
      physicalAddress: false,
      singleAddressLineSelected: false,
      physicalSingleAddressLineSelected: false,
      errors: false,
      paymentDetails: {
        cardType: null,
        amountSurcharge: 0,
        amount: 0,
        channel: 'WEB',
        orderId: null,
        originatingPlatform: 'CORESTORE',
        payOnAccount: false,
        paymentGateway: 'DPS',
        accountNumber: null,
        region: 'NZ',
        transactionType: 'PRE_AUTH',
        accountGuid: null,
        payCode: null,
        currency: 'NZD'
      },
      cardTypeRequired: false,
      diners: [4.4, 'TD'],
      amex: [2.75, 'TA'],
      visaDebit: [0.65, 'T2'],
      visaCredit: [1.5, 'TV'],
      mastercardDebit: [1.5, 'T1'],
      mastercardCredit: [1.25, 'TM'],
      originalTotalPrice: 0,
      isAgentVisbile: true,
      monthsDiscount: 0,
      vicDeedFormPopup: false,
      show: false,
      physicalAddressOption: false,
      physicalAddressSelector: false,
      coupon: null,
      isValidAccountReferenceNumber: false,
      isDirectDebit: false
    };

    this.onCardChange = this.onCardChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onSubmitCustomerDetails = this.onSubmitCustomerDetails.bind(this);
    this.onDirectDebitInputChange = this.onDirectDebitInputChange.bind(this);
    this.onChangeBusinessOption = this.onChangeBusinessOption.bind(this);
    this.onAddressInputChange = this.onAddressInputChange.bind(this);
    this.onAddressFocus = this.onAddressFocus.bind(this);
    this.onAddressSelection = this.onAddressSelection.bind(this);
    this.onAbnChange = this.onAbnChange.bind(this);
    this.onAddressManualEntry = this.onAddressManualEntry.bind(this);
    this.onAddressCancelManualEntry = this.onAddressCancelManualEntry.bind(this);
    this.onPhysicalAddressManualEntry = this.onPhysicalAddressManualEntry.bind(this);
    this.onPhysicalAddressCancelManualEntry = this.onPhysicalAddressCancelManualEntry.bind(this);
    this.onAbnManualEntry = this.onAbnManualEntry.bind(this);
    this.onAbnCancelManualEntry = this.onAbnCancelManualEntry.bind(this);
    this.onPaymentDetailsChange = this.onPaymentDetailsChange.bind(this);
    this.onPhysicalAddressFocus = this.onPhysicalAddressFocus.bind(this);
    this.onAccountSelectorChange = this.onAccountSelectorChange.bind(this);
    this.onManualAccountInputChange = this.onManualAccountInputChange.bind(this);
    this.onAccountTypeSelectorChange = this.onAccountTypeSelectorChange.bind(this);
    this.backToCart = this.backToCart.bind(this);
    this.forceAddressManualEntry = this.forceAddressManualEntry.bind(this);
    this.forcePhysicalAddressManualEntry = this.forcePhysicalAddressManualEntry.bind(this)

    //SERVICES
    this.analyticsCheckout = this.props.SegmentAnalytics.analyticsCheckout.bind(this);
    this.trackPaymentMethod = this.props.SegmentAnalytics.trackPaymentMethod.bind(this);
    this.getItemsCount = this.props.ProductItemsService.getItemsCount.bind(this);
    this.trackGotoCart = this.props.SegmentAnalytics.trackGotoCart.bind(this);
    this.trackOrder = this.props.SegmentAnalytics.trackOrder.bind(this);
    this.analyticsIdentifyIndustry =
      this.props.SegmentAnalytics.analyticsIdentifyIndustry.bind(this);
    this.trackError = this.props.SegmentAnalytics.trackError.bind(this);
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  backToCart() {
    this.trackGotoCart(this.getItemsCount());
    history.push('/cart');
  }

  onCardChange(cardType) {
    this.trackPaymentMethod(cardType);

    let totalPrice = this.props.order.quotes ? this.props.order.quotes.cartTotal : 0;
    let minCost = this.props.order.quotes ? this.props.order.quotes.cartMinTotal : 0;

    let required = cloneDeep(this.state.requiredDebit);
    let paymentDetails = cloneDeep(this.state.paymentDetails);
    let errorMessages = cloneDeep(this.state.errorMessages);
    let totalMonths = this.state.totalMonths;
    let creditCardSurcharge = 0;

    if (cardType === 'credit') {
      required.bsbNumber = false;
      required.accountNumber = false;
      required.accountName = false;
      creditCardSurcharge = paymentDetails.amountSurcharge;
      totalPrice = totalPrice + creditCardSurcharge;
      minCost = minCost + creditCardSurcharge * totalMonths;
      errorMessages.bsbNumber = false;
      errorMessages.accountNumber = false;
      errorMessages.accountName = false;
    } else if (cardType === 'debit') {
      required.bsbNumber = false;
      required.accountNumber = true;
      required.accountName = true;
      paymentDetails.cardType = null;
      paymentDetails.amountSurcharge = 0;
      errorMessages.cardType = false;
    }

    this.setState({
      cardType: cardType,
      totalPrice: totalPrice,
      requiredDebit: required,
      cardTypeRequired: false,
      paymentDetails: paymentDetails,
      creditCardSurcharge: creditCardSurcharge,
      minCost: minCost,
      errorMessages: errorMessages
    });
  }

  onSubmitCustomerDetails(type) {
    const requiredFields = this.state.required;
    let requiredDebit = cloneDeep(this.state.requiredDebit);
    let customer = cloneDeep(this.state.customer);
    const directDebit = this.state.directDebit;
    let errorMessages = cloneDeep(this.state.errorMessages);
    let paymentDetails = cloneDeep(this.state.paymentDetails);
    let errors = null;

    if (type === 'debit') {
      for (let field in requiredDebit) {
        if (
          requiredDebit[field] === true &&
          directDebit[field] !== undefined &&
          (directDebit[field] === null || directDebit[field] === '' || errorMessages[field])
        ) {
          errorMessages[field] = true;
          errors = true;
        }
      }
    }

    if (
      type === 'credit' &&
      this.state.cardTypeRequired &&
      this.state.paymentDetails.cardType === null
    ) {
      errors = true;
      errorMessages.cardType = true;
    }

    if (this.state.business && (
      errorMessages.abnFormat ||
      errorMessages.abnInvalid ||
      errorMessages.abnManualFormat
    )) {
      errors = true;
    }

    if (
      errorMessages.streetAddressFormat ||
      errorMessages.physicalStreetAddressFormat ||
      errorMessages.accountReferenceNumberValid)
      errors = true;

    for (let requiredField in requiredFields) {
      if (customer[requiredField] === undefined) {
        customer[requiredField] = null;
      }
      if (
        requiredFields[requiredField] === true &&
        (customer[requiredField] === null ||
          customer[requiredField] === '' ||
          errorMessages[requiredField] ||
          customer[requiredField] === undefined ||
          customer[requiredField] === 'null')
      ) {
        errorMessages[requiredField] = true;
        if (
          this.state.business &&
          requiredField === 'abnManual' &&
          customer.abn !== null &&
          customer.abn !== '' &&
          !/^\s*$/.test(customer.abn)
        ) {
          errorMessages[requiredField] = false;
        }
        if (
          !errors &&
          requiredField === 'abnManual' &&
          customer.abn) {
            errors = false;
        } else {
          errors = true;
        }
      }
    }

    if (customer.industry === 'NONE' || !customer.industry) {
      errorMessages.industry = true;
      errors = true;
    }

    if (!customer.preferredContact || customer.preferredContact === '') {
      customer.preferredContact = 'email';
    }

    if (customer.abn !== null && customer.abn !== '') {
      customer.abn = customer.abn.toString().replace(/\s/g, '');
    }

    if (customer.businessTerms === true) {
      customer.purpose = 'Business';
    } else {
      customer.purpose = 'Personal';
    }

    if (this.state.mailingAddressAutocomplete === false && this.state.manualEntry === false) {
      errors = true;
      errorMessages.singleAddressLine = true;
    }

    if (
      this.state.mailingAddressAutocomplete === false &&
      this.state.physicalManualEntry === false
    ) {
      errors = true;
      errorMessages.physicalSingleAddressLine = true;
    }

    if (this.state.physicalManualEntry === false || errorMessages.physicalSingleAddressLine) {
      errorMessages.physicalState = false;
    }

    if (!customer.country) {
      customer.country = 'NZ';
    }

    if (!this.state.business) {
      businessOnlyKeys.map((businessKey) => {
        errorMessages[businessKey] = false;
      })
    }

    if (errors == null) errors = false

    this.setState({
      errorMessages: errorMessages,
      customer: customer,
      errors: errors
    });
    if (!errors && this.state.vicDeedFormPopup === false) {
      this.analyticsIdentifyIndustry(
        this.props.registration.emailValidation.guid,
        customer.industry
      );
      this.trackOrder(this.props.order, type);

      if (this.state.physicalAddress === false && !this.state.manualEntry) {
        customer.physicalSingleAddressLine = customer.singleAddressLine;
      } else if (this.state.physicalAddress === false && this.state.manualEntry) {
        customer.physicalStreetAddress = customer.streetAddress;
        customer.physicalSuburbCity = customer.suburbCity;
        customer.physicalState = customer.state;
        customer.physicalPostcode = customer.postcode;
      }

      let accountGuid = null;
      for (let accountIndex = 0; accountIndex < this.props.login.accounts.length; accountIndex++) {
        if (
          customer.accountReferenceNumber === this.props.login.accounts[accountIndex].accountNumber
        ) {
          accountGuid = this.props.login.accounts[accountIndex].crmAccountGuid;
        }
      }

      paymentDetails.orderId = this.props.order.id;
      paymentDetails.amount =
        parseFloat(this.props.order.quotes.cartTotal) +
        parseFloat(this.state.creditCardSurcharge.toFixed(2));
      paymentDetails.accountGuid = accountGuid;
      paymentDetails.accountNumber = customer.accountReferenceNumber;

      let order = {
        processStatus: 'AUTO',
        currency: 'NZD',
        accountNumber: customer.accountReferenceNumber,
        accountGuid: accountGuid,
        customer: customer,
        quoteConfiguration: this.props.order.quoteConfiguration,
        creditCardSurcharge: parseFloat(this.state.creditCardSurcharge.toFixed(2)),
        transactionType: 'PRE_AUTH'
      };
      if (type === 'debit') {
        this.props.directDebitPayment(order, this.state.directDebit);
      } else {
        if (this.props.contactInfo.title !== customer.title) {
          this.props.updateContactInfo({ ...this.props.contactInfo, title: customer.title });
        }
      
        this.props.saveCustomerDetails(order, type, paymentDetails);
      }
    } else if (!errors && this.state.vicDeedFormPopup === true) {
      this.handleShow();
      this.setState({
        vicDeedFormPopup: false
      });
    } else if (errors) {
      let trackErrorMessages = {};
      Object.keys(errorMessages).forEach(function (error) {
        if (errorMessages[error]) {
          trackErrorMessages[error] = errorMessages[error];
        }
      });
      this.trackError('Proceed to Payment', null, trackErrorMessages, null);
    }
  }

  onPaymentDetailsChange(event) {
    let errorMessages = cloneDeep(this.state.errorMessages);
    let paymentDetails = cloneDeep(this.state.paymentDetails);
    let totalPrice = this.props.order.quotes ? this.props.order.quotes.cartTotal : 0;
    let minCost = this.props.order.quotes ? this.props.order.quotes.cartMinTotal : 0;
    const totalMonths = this.state.totalMonths;
    const key = event.target.name;
    const value = event.target.value;
    let creditCardSurcharge = 0;

    if (value !== null && value !== '' && value !== 'null') {
      errorMessages[key] = false;
      paymentDetails['cardType'] = value;
      paymentDetails.surchargePercent = this.state[value][0];
      paymentDetails.payCode = this.state[value][1];

      creditCardSurcharge = totalPrice * (this.state[value][0] / 100);
      creditCardSurcharge = parseFloat(creditCardSurcharge.toFixed(2));
      paymentDetails.amountSurcharge = creditCardSurcharge;

      totalPrice = totalPrice + creditCardSurcharge;
      minCost = minCost + creditCardSurcharge * totalMonths;
    } else {
      errorMessages[key] = true;
      paymentDetails['cardType'] = null;
      paymentDetails.amountSurcharge = 0;
    }

    this.setState({
      paymentDetails: paymentDetails,
      errorMessages: errorMessages,
      totalPrice: totalPrice,
      creditCardSurcharge: creditCardSurcharge,
      minCost: minCost
    });
  }

  addressManualEntryValidation(key, value, errorMessages) {
    if (key === 'streetAddress' || key === 'physicalStreetAddress') {
      let formatKey = `${key}Format`;
      if (value === null && value === '' && value === 'null') {
        errorMessages[key] = true;
      } else if (!/^[a-z,\-\sA-Z0-9]*$/.test(value)) {
        errorMessages[formatKey] = true;
        errorMessages[key] = false;
      } else {
        errorMessages[formatKey] = false;
        errorMessages[key] = false;
      }
    }
    if (key === 'suburbCity' || key === 'physicalSuburbCity') {
      if (value !== null && value !== '' && value !== 'null' && !/^[a-zA-Z\s]*$/.test(value)) {
        errorMessages[key] = true;
      } else {
        errorMessages[key] = false;
      }
    }
    if (key === 'postcode' || key === 'physicalPostcode') {
      if (value !== null && value !== '' && value !== 'null' && !/^[0-9]{4,}$/.test(value)) {
        errorMessages[key] = true;
      } else {
        errorMessages[key] = false;
      }
    }
  }

  onInputChange(event, type) {
    let customerDetails = cloneDeep(this.state.customer);
    let requiredFields = cloneDeep(this.state.required);
    let errorMessages = cloneDeep(this.state.errorMessages);

    let key, value;
    if (type === 'franchise') {
      key = 'franchiseGroup';
      value = event.length > 0 ? event[0] : '';
    } else {
      key = event.target.name;
      value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
      if (requiredFields[key]) {
        if (key === 'title') {
          if (!["Mr.", "Ms.", "Mrs.", "Dr.", "Prof.", "Mx."].includes(value)) {
            errorMessages[key] = true;
          } else {
            errorMessages[key] = false;
          }
        }
        if (key === 'name') {
          if (!/^((\b[a-zA-Z'-]{2,40}\b)\s*){2,}$/.test(value)) {
            errorMessages[key] = true;
          } else {
            errorMessages[key] = false;
          }
        }
        if (key === 'mobileNumber') {
          if (
            !/^(\d{9}|\d{10}|(\d{4} \d{3} \d{3})|(\d{3} \d{3} \d{4})|(\d{2} \d{3} \d{4}))$/.test(
              value
            )
          ) {
            errorMessages[key] = true;
          } else {
            errorMessages[key] = false;
          }
        }
        if (key === 'email') {
          if (
            !/^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              value
            )
          ) {
            errorMessages[key] = true;
          } else {
            errorMessages[key] = false;
          }
        }
        if (key === 'abn') {
          if (
            !/^(\d{11} *|(\d{2} \d{3} \d{3} \d{3} *)|(\d{3} \d{3} \d{3} *)|\d{9} *)$/.test(value) ||
            /^\s*$/.test(value)
          ) {
            errorMessages['abnFormat'] = true;
          } else {
            errorMessages['abnFormat'] = false;
            errorMessages['abn'] = false;
          }
        }
        if (key === 'abnManual') {
          if (!/^(\d*)$/.test(value) || /^\s*$/.test(value)) {
            errorMessages['abnManualFormat'] = true;
          } else {
            errorMessages['abnManualFormat'] = false;
            errorMessages['abnManual'] = false;
          }
        }

        if (key === 'accountReferenceNumber') {
          if (
            !/^(\d{1,8})$|^([aAxX]{1}\d{7})$|^([inqstvwzINQSTVWZ]{1}\d{5})$/.test(
              value.toLowerCase()
            )
          ) {
            errorMessages[key] = true;
          } else {
            errorMessages[key] = false;
          }

          //check blacklist
          let blacklist_accs = BLACKLIST_ACCOUNTS.map((acc) => acc.toLowerCase());
          if (blacklist_accs.includes(value.toLowerCase())) {
            errorMessages[key] = true;
          }
        }

        if (key === 'role' || key === 'businessName' || key === 'legalEntityName') {
          if (
            value !== null &&
            value !== '' &&
            value !== 'null' &&
            /^[a-zA-Z0-9][a-z.\-\s'A-Z0-9_]*$/.test(value)
          ) {
            errorMessages[key] = false;
          } else {
            errorMessages[key] = true;
          }
        }

        this.addressManualEntryValidation(key, value, errorMessages);

        if (key === 'industry') {
          if (value !== 'NONE') {
            errorMessages[key] = false;
          } else {
            errorMessages[key] = true;
          }
        }

        if (key === 'businessTerms') {
          if (value) {
            errorMessages.businessTerms = false;
          } else {
            errorMessages.businessTerms = true;
          }
        }
        if (key === 'terms') {
          if (value) {
            errorMessages.terms = false;
          } else {
            errorMessages.terms = true;
          }
        }
        if (key === 'trialTerms') {
          if (value) {
            errorMessages.trialTerms = false;
          } else {
            errorMessages.trialTerms = true;
          }
        }
      }

      if (key === 'physicalAddress') {
        if (value) {
          requiredFields.physicalSingleAddressLine = true;
          this.setState({
            physicalAddress: true,
            required: requiredFields,
            physicalAddressSelector: true
          });
        } else {
          errorMessages.physicalSingleAddressLine = false;
          errorMessages.physicalStreetAddress = false;
          errorMessages.physicalSuburbCity = false;
          errorMessages.physicalPostcode = false;
          requiredFields.physicalSingleAddressLine = false;
          requiredFields.physicalStreetAddress = false;
          requiredFields.physicalSuburbCity = false;
          requiredFields.physicalPostcode = false;
          this.setState({
            physicalAddress: false,
            required: requiredFields,
            physicalAddressSelector: false
          });
        }
      }
    }
    if (key === 'abnManual') {
      customerDetails.abn = value;
    } else {
      customerDetails[key] = value;
    }

    this.setState({
      customer: customerDetails,
      errorMessages: errorMessages
    });
  }

  onChangeBusinessOption(event) {
    let required = cloneDeep(this.state.required);
    let customer = cloneDeep(this.state.customer);
    let errorMessages = cloneDeep(this.state.errorMessages);
    if (event.target.value === 'Business') {
      required.role = true;
      required.abn = false;
      required.abnManual = true;
      required.businessName = true;
      required.legalEntityName = true;
      required.businessTerms = true;
      customer.legalEntityName = null;
      customer.businessName = null;
      this.setState({
        business: true,
        required: required,
        customer: customer
      });
    } else if (event.target.value === 'Personal') {
      required.role = false;
      required.abn = false;
      required.abnManual = false;
      required.businessName = false;
      required.legalEntityName = false;
      required.businessTerms = false;
      errorMessages.abnFormat = false;
      errorMessages.abnManual = false;
      errorMessages.abnManualFormat = false;
      errorMessages.abnInvalid = false;
      errorMessages.abn = false;
      errorMessages.abnManual = false;
      errorMessages.businessName = false;
      errorMessages.legalEntityName = false;
      errorMessages.businessTerms = false;
      errorMessages.role = false;
      customer.abn - null;
      customer.abnManual = null;
      customer.businessName = null;
      customer.businessTerms = null;
      customer.industry = null;
      customer.role = null;
      customer.legalEntityName = null;
      this.setState({
        business: false,
        required: required,
        errorMessages: errorMessages,
        customer: customer
      });
    }
  }

  onAddressInputChange(event) {
    let customerDetails = cloneDeep(this.state.customer);
    let errorMessages = cloneDeep(this.state.errorMessages);
    this.props.addressLines(event.target.value);
    if (event.target.value !== null && event.target.value !== '') {
      errorMessages[event.target.name] = false;
    }
    customerDetails[event.target.name] = event.target.value;

    if (event.target.name === 'singleAddressLine') {
      this.setState({
        singleAddressLineSelected: true
      })
    } else if (event.target.name === 'physicalSingleAddressLine') {
      this.setState({
        physicalSingleAddressLineSelected: true
      })
    }
    this.setState({
      customer: customerDetails,
      errorMessages: errorMessages,
      mailingAddressAutocomplete: false
    });
  }

  onDirectDebitInputChange(event) {
    let directDebitDetails = cloneDeep(this.state.directDebit);
    const required = this.state.requiredDebit;
    let errorMessages = cloneDeep(this.state.errorMessages);
    let key, value;
    key = event.target.name;
    value = event.target.value;

    if (required[key]) {
      if (key === 'bsbNumber') {
        if (!/^\d{6}$/.test(value)) {
          errorMessages[key] = true;
        } else {
          errorMessages[key] = false;
        }
      }
      if (key === 'accountNumber') {
        if (!/^[0-9\-0-9]*$/.test(value)) {
          errorMessages[key] = true;
        } else {
          errorMessages[key] = false;
        }
      }
      if (key === 'accountName') {
        if (!/^((\b[a-zA-Z'-]{2,40}\b)\s*){1,}$/.test(value)) {
          errorMessages[key] = true;
        } else {
          errorMessages[key] = false;
        }
      }
    }
    directDebitDetails[key] = value;
    this.setState({
      directDebit: directDebitDetails,
      errorMessages: errorMessages
    });
  }

  onAddressFocus() {
    this.setState({
      addressOption: true,
      physicalAddressOption: false
    });
  }

  onPhysicalAddressFocus() {
    this.setState({
      physicalAddressOption: true,
      addressOption: false
    });
  }

  onAddressSelection(line, key) {
    let customerDetails = cloneDeep(this.state.customer);
    let errorMessages = cloneDeep(this.state.errorMessages);
    customerDetails[key] = line.suggestion;
    errorMessages[key] = false;

    //Fetch property details
    this.props.getPropertyDetails(line.propertyId).then(
      function () {
        if (key === 'singleAddressLine') {
          customerDetails.streetAddress = this.props.propertyDetails?.streetAddress?.replace(
            /\//g,
            ', '
          );
          customerDetails.suburbCity = this.props.propertyDetails?.suburbCity;
          customerDetails.state = this.props.propertyDetails?.state;
          customerDetails.postcode = this.props.propertyDetails?.code;
          this.state.singleAddressLineSelected = true;
        } else if (key === 'physicalSingleAddressLine') {
          customerDetails.physicalStreetAddress = this.props.propertyDetails?.streetAddress?.replace(
            /\//g,
            ', '
          );
          customerDetails.physicalSuburbCity = this.props.propertyDetails?.suburbCity;
          customerDetails.physicalState = this.props.propertyDetails?.state;
          customerDetails.physicalPostcode = this.props.propertyDetails?.code;
          this.state.physicalSingleAddressLineSelected = true;
        }
      }.bind(this)
    );

    if (key === 'singleAddressLine') {
      customerDetails.singleAddressLine = line.suggestion.replace(/\//g, ', ');
    } else if (key === 'physicalSingleAddressLine') {
      customerDetails.physicalSingleAddressLine = line.suggestion.replace(/\//g, ', ');
    }

    this.setState({
      customer: customerDetails,
      addressOption: false,
      physicalAddressOption: false,
      mailingAddressAutocomplete: true,
      errorMessages: errorMessages
    });
  }

  onAbnChange(event) {
    let customerDetails = cloneDeep(this.state.customer);
    let errorMessages = cloneDeep(this.state.errorMessages);
    let abn = event.target.value;
    if (abn !== null && abn !== '') {
      abn = abn.toString().replace(/\s/g, '');
      this.props.fetchAbnDetails(abn);
      if (!/^(\d{11} *|(\d{2} \d{3} \d{3} \d{3} *)|(\d{3} \d{3} \d{3} *)|\d{9} *)$/.test(abn)) {
        errorMessages['abnFormat'] = true;
      } else {
        errorMessages['abnFormat'] = false;
        errorMessages['abn'] = false;
      }
    } else {
      customerDetails.businessName = '';
      customerDetails.legalEntityName = '';
    }
    this.setState({
      customer: customerDetails,
      errorMessages: errorMessages
    });
  }

  onAddressManualEntry() {
    let required = cloneDeep(this.state.required);
    let errorMessages = cloneDeep(this.state.errorMessages);
    let customer = cloneDeep(this.state.customer);
    required.singleAddressLine = false;
    required.streetAddress = true;
    required.suburbCity = true;
    required.postcode = true;
    customer.singleAddressLine = null;
    errorMessages.singleAddressLine = false;

    if (customer.streetAddress != null) {
      customer.streetAddress.trim();
    }
    this.addressManualEntryValidation('streetAddress', customer.streetAddress, errorMessages);
    this.addressManualEntryValidation('suburbCity', customer.suburbCity, errorMessages);

    this.setState({
      required: required,
      manualEntry: true,
      errorMessages: errorMessages,
      customer: customer
    });
  }

  onAddressCancelManualEntry() {
    let required = cloneDeep(this.state.required);
    let errorMessages = cloneDeep(this.state.errorMessages);
    let customer = cloneDeep(this.state.customer);
    required.singleAddressLine = true;
    required.streetAddress = false;
    required.suburbCity = false;
    required.state = false;
    required.postcode = false;
    errorMessages.state = false;
    errorMessages.streetAddress = false;
    errorMessages.streetAddressFormat = false;
    errorMessages.suburbCity = false;
    errorMessages.postcode = false;
    customer.streetAddress = null;
    customer.suburbCity = null;
    customer.state = null;
    customer.postcode = null;
    this.setState({
      required: required,
      manualEntry: false,
      errorMessages: errorMessages,
      customer: customer
    });
  }

  onPhysicalAddressManualEntry() {
    let required = cloneDeep(this.state.required);
    let errorMessages = cloneDeep(this.state.errorMessages);
    let customer = cloneDeep(this.state.customer);
    required.physicalSingleAddressLine = false;
    required.physicalStreetAddress = true;
    required.physicalSuburbCity = true;
    required.physicalPostcode = true;
    customer.physicalSingleAddressLine = null;
    errorMessages.physicalSingleAddressLine = false;

    if (customer.physicalStreetAddress != null) {
      customer.physicalStreetAddress.trim();
    }
    this.addressManualEntryValidation(
      'physicalStreetAddress',
      customer.physicalStreetAddress,
      errorMessages
    );
    this.addressManualEntryValidation(
      'physicalSuburbCity',
      customer.physicalSuburbCity,
      errorMessages
    );

    this.setState({
      required: required,
      physicalManualEntry: true,
      errorMessages: errorMessages,
      customer: customer
    });
  }

  onPhysicalAddressCancelManualEntry() {
    let required = cloneDeep(this.state.required);
    let errorMessages = cloneDeep(this.state.errorMessages);
    let customer = cloneDeep(this.state.customer);
    required.physicalSingleAddressLine = true;
    required.physicalStreetAddress = false;
    required.physicalSuburbCity = false;
    required.physicalState = false;
    required.physicalPostcode = false;
    errorMessages.physicalState = false;
    errorMessages.physicalStreetAddress = false;
    errorMessages.physicalStreetAddressFormat = false;
    errorMessages.physicalSuburbCity = false;
    errorMessages.physicalPostcode = false;
    customer.physicalSingleAddressLine = null;
    customer.physicalStreetAddress = null;
    customer.physicalSuburbCity = null;
    customer.physicalState = null;
    customer.physicalPostcode = null;
    this.setState({
      required: required,
      physicalManualEntry: false,
      errorMessages: errorMessages,
      customer: customer
    });
  }

  onAbnManualEntry() {
    let required = cloneDeep(this.state.required);
    let customer = cloneDeep(this.state.customer);
    let errorMessages = cloneDeep(this.state.errorMessages);
    required.abn = false;
    errorMessages.abn = false;
    errorMessages.abnFormat = false;
    errorMessages.abnInvalid = false;
    required.abnManual = true;
    required.legalEntityName = true;
    required.businessName = true;
    customer.abn = null;
    customer.legalEntityName = null;
    customer.businessName = null;
    this.setState({
      required: required,
      abnManualEntry: true,
      customer: customer,
      errorMessages: errorMessages
    });
  }

  onAbnCancelManualEntry() {
    let required = cloneDeep(this.state.required);
    let customer = cloneDeep(this.state.customer);
    let errorMessages = cloneDeep(this.state.errorMessages);
    required.abn = true;
    required.abnManual = false;
    required.legalEntityName = false;
    required.businessName = false;
    customer.abn = null;
    customer.legalEntityName = null;
    customer.businessName = null;
    errorMessages.abn = false;
    errorMessages.abnManual = false;
    errorMessages.abnFormat = false;
    errorMessages.abnInvalid = false;
    errorMessages.businessName = false;
    errorMessages.legalEntityName = false;
    this.setState({
      required: required,
      abnManualEntry: false,
      customer: customer,
      errorMessages: errorMessages
    });
  }

  standardizeTitle(title) {
    // Convert the title to lowercase and then capitalize the first letter
    let standardizedTitle = title.toLowerCase();
    standardizedTitle = standardizedTitle.charAt(0).toUpperCase() + standardizedTitle.slice(1);

    // Add a period at the end if it's not there already
    if (standardizedTitle.charAt(standardizedTitle.length - 1) !== '.') {
        standardizedTitle += '.';
    }

    return standardizedTitle;
  }

  resetOrder(order) {
    let disabled = this.state.disabled;
    let errorMessages = cloneDeep(this.state.errorMessages);
    let quotes = order.quotes ? order.quotes.quotes : [];
    let orderItems = order.quoteConfiguration ? order.quoteConfiguration.products : [];
    let totalMonths = 0;
    let userTotalMonths = 0;
    let totalDays = this.state.totalDays;
    let showBusinessOption = this.state.showBusinessOption;
    let required = cloneDeep(this.state.required);
    let customer = order.customer;
    let totalPrice = order.quotes ? order.quotes.cartTotal : 0;
    let minCost = order.quotes ? order.quotes.cartMinTotal : 0;
    let conditionalTC = false;
    let cardTypeRequired = this.state.cardTypeRequired;
    let discountApplied = false;
    let monthsDiscount = 0;
    let isAgentVisbile = true;
    let vicDeedFormPopup = false;
    let business = this.state.business;

    if (totalPrice === 0) {
      cardTypeRequired = false;
    }
    
    if (this.props.contactInfo.title) {
      customer.title = this.standardizeTitle(this.props.contactInfo.title)
      disabled.title = true
    }

    if (this.state.creditCardSurcharge > 0) {
      totalPrice = totalPrice + this.state.creditCardSurcharge;
    }

    let originalTotalPrice = 0;
    for (let quoteIndex = 0; quoteIndex < quotes.length; quoteIndex++) {
      quotes[quoteIndex].totalPerMonth = parseFloat(quotes[quoteIndex].totalPerMonth).toFixed(2);
      originalTotalPrice = originalTotalPrice + quotes[quoteIndex].originalPrice;
      quotes[quoteIndex].headline =
        quotes[quoteIndex].headLine.charAt(0).toUpperCase() + quotes[quoteIndex].headLine.slice(1);
      if (parseFloat(quotes[quoteIndex].totalPerMonth) > 0) {
        quotes[quoteIndex].contractMonths = 12;
      } else if (parseFloat(quotes[quoteIndex].totalPerMonth) < 1) {
        quotes[quoteIndex].contractMonths = 0;
      }
    }

    for (let index = 0; index < orderItems.length; index++) {
      quotes[index].userContractMonths =
        quotes[index].minimumInitialTerm +
        (quotes[index].provisioningInfo && quotes[index].provisioningInfo.discountPeriods
          ? quotes[index].provisioningInfo.discountPeriods
          : 0);

      if (userTotalMonths === 0 || userTotalMonths < quotes[index].userContractMonths) {
        userTotalMonths = quotes[index].userContractMonths;
      }

      if (quotes[index].userContractMonths >= 12) {
        totalMonths = 12;
        totalDays = 90;
      }
    }

    if (totalPrice > 0) {
      required.trialTerms = false;
      required.terms = true;
    } else {
      required.trialTerms = true;
      required.terms = false;
    }

    if (customer.abn) {
      errorMessages.abnInvalid = false;
    }
    if (customer.businessName){
      errorMessages.businessName = false;
    }
    if(customer.legalEntityName){
      errorMessages.legalEntityName === false;
    }

    if (customer.accountType === 'NEW_ACCOUNT') {
      required.accountReferenceNumber = false;
    }

    if (customer.purpose !== null && customer.purpose === 'Personal') {
      business = false;
      required.businessTerms = false;
      required.role = false;
      required.abnManual = false;
      required.businessName = false;
      required.legalEntityName = false;
    }

    if (customer.physicalSingleAddressLine !== null || customer.physicalStreetAddress !== null ) {
      required.physicalSingleAddressLine = true;
      this.setState({
        physicalAddress: true,
        required: required,
        physicalAddressSelector: true
      });
    }

    conditionalTC = orderItems.length > 1 ? true : false;

    this.setState({
      totalDays: totalDays,
      totalMonths: totalMonths,
      userTotalMonths: userTotalMonths,
      quotes: quotes,
      order: order,
      totalPrice: totalPrice,
      originalTotalPrice: originalTotalPrice,
      customer: customer,
      showBusinessOption: showBusinessOption,
      errorMessages: errorMessages,
      minCost: minCost,
      conditionalTC: conditionalTC,
      cardTypeRequired: cardTypeRequired,
      isAgentVisbile: isAgentVisbile,
      monthsDiscount: monthsDiscount,
      vicDeedFormPopup: vicDeedFormPopup,
      discountApplied: discountApplied,
      business: business,
      coupon: order.quotes.coupon,
      required: required,
      disabled: disabled
    });
  }

  componentDidMount() {
    this.analyticsCheckout('Checkout', this.props.order?.orderNumber);

    let directDebit = null;

    if (
      window.location.href.includes('host') ||
      window.location.href.includes('uat') ||
      window.location.href.includes('dev')
    ) {
      if (this.props.location?.search?.length > 0) {
        directDebit = qs.parse(this.props.location.search).directDebit;
      }

      if (directDebit === 'y') {
        this.setState({
          isDirectDebit: true
        });
      }
    }

    //Reset all form LOADERS
    this.props.resetCheckoutLoaders();

    //Reset ABN Lookup props
    this.props.resetAbnLookupProps();

    //Reset Address Lookup props
    this.props.resetAddressLookupProps();

    if (this.props.login?.userLoggedIn) {
      this.props.fetchCurrentOrder().then(
        function () {
          if (
            this.props.order &&
            this.props.order.quotes &&
            this.props.order.quotes.quotes &&
            this.props.order.quotes.quotes.length > 0
          ) {
            this.resetOrder(this.props.order);
            this.props.getAccounts(null).then(
              function () {
                if (this.props.login.relogin) {
                  this.props.redirectToLoginPage();
                }
              }.bind(this)
            );
          } else {
            history.push('/cart');
          }
        }.bind(this)
      );
    } else {
      history.push('/cart');
    }
  
    this.props.resetPurchaseEvent();

    window.scrollTo(0, 0);
  }

  componentWillReceiveProps(nextProps) {
      // check only if abn is auto fill
      if (!this.state.abnManualEntry && this.state.customer.abn && nextProps.abn && this.state.customer.purpose != 'Personal') {
        this.checkAndFillIncompleteAbnDetails(nextProps.abn);
      }

      // Validation check for ABN success response
      this.validateAbnResponse(nextProps.abn);

      // Handle ABN failure response
      this.enterBusinessDetailsManuallyIfAbnLookupIsDown(nextProps.abnError);

      // Handle Address failure response
      this.enterAddressDetailsManuallyIfAddressLookupIsDown(nextProps.addressError);

      // Handle Physical address Failure Response
      this.enterPhysicalAddressDetailsManuallyIfPhysicalAddressLookupIsDown(nextProps.addressError);
    }

    forceAddressManualEntry() {
      return (this.props.addressError && !this.state.manualEntry && this.state.customer.singleAddressLineSelected?.length> ADDRESS_LOOKUP_AUTO_MANUAL_ENTRY_TRIGGER_LENGTH);
    }
    forcePhysicalAddressManualEntry() {
      return (this.props.addressError && !this.state.physicalManualEntry && this.state.customer.singleAddressLineSelected?.length> ADDRESS_LOOKUP_AUTO_MANUAL_ENTRY_TRIGGER_LENGTH);
    }

    enterAddressDetailsManuallyIfAddressLookupIsDown(addressLookupError) {
      if (
        addressLookupError  && // if error
        !this.state.manualEntry && // if no manual entry
        this.state.singleAddressLineSelected === true &&
        this.state.customer.singleAddressLine?.length > ADDRESS_LOOKUP_AUTO_MANUAL_ENTRY_TRIGGER_LENGTH
      ) {

        let customer = cloneDeep(this.state.customer);
        customer.streetAddress = null;
        customer.suburbCity = null;
        customer.state = null;
        customer.postcode = null;
        this.setState({customer: customer}, () => this.onAddressManualEntry());
      }
    }


    enterPhysicalAddressDetailsManuallyIfPhysicalAddressLookupIsDown(addressLookupError) {
      if (
          addressLookupError &&
          !this.state.physicalManualEntry &&
          this.state.physicalSingleAddressLineSelected === true &&
          this.state.customer.physicalSingleAddressLine?.length > ADDRESS_LOOKUP_AUTO_MANUAL_ENTRY_TRIGGER_LENGTH
      ) {
        let customer = cloneDeep(this.state.customer);
        customer.physicalStreetAddress = null;
        customer.physicalSuburbCity = null;
        customer.physicalState = null;
        customer.physicalPostcode = null;
        this.setState({customer: customer}, () => this.onPhysicalAddressManualEntry());
      }
    }

    enterBusinessDetailsManuallyIfAbnLookupIsDown(abnLookupIsDown) {
      if (abnLookupIsDown && !this.state.abnManualEntry) this.onAbnManualEntry();
    }

    validateAbnResponse(abn) {
      if (
        this.state.customer.abn &&
        'businessName' in abn &&
        'organisationName' in abn
      ) {
        let customerDetails = cloneDeep(this.state.customer);
        let errorMessages = cloneDeep(this.state.errorMessages);
        const { businessName, organisationName } = abn;

        errorMessages['abn'] = (businessName.length === 0 && organisationName.length === 0);
        if (errorMessages['abn']) {
          customerDetails.businessName = '';
          customerDetails.legalEntityName = '';
          this.setState({customer: customerDetails});
        }

        this.setState({errorMessages: errorMessages});
      }
    }

  onAccountTypeSelectorChange(event) {
    let customerDetails = cloneDeep(this.state.customer);
    let requiredFields = cloneDeep(this.state.required);
    let errorMessages = cloneDeep(this.state.errorMessages);
    let disabled = cloneDeep(this.state.disabled);

    let key, value;
    key = event.target.name;
    value = event.target.value;

    if (value === 'NEW_ACCOUNT') {
      requiredFields.accountReferenceNumber = false;
    } else {
      requiredFields.accountReferenceNumber = true;
    }
    if (value !== 'EXISTING_ACCOUNT'){
      customerDetails.abnManual = null;
      customerDetails.abn = null;
      customerDetails.legalEntityName = null;
      customerDetails.businessName = null;
      disabled.abn = false;
      disabled.abnManual = false;
      disabled.legalEntityName = false;
      disabled.businessName = false;
    }
    disabled.singleAddressLine = false;
    errorMessages.accountReferenceNumber = false;
    errorMessages.accountReferenceNumberValid = false;
    customerDetails.accountReferenceNumber = null;
    errorMessages[key] = false;
    customerDetails[key] = value;

    this.setState({
      customer: customerDetails,
      errorMessages: errorMessages,
      required: requiredFields,
      disabled: disabled
    });
  }

  onAccountSelectorChange(event) {
    if (event.target.value !== '') {
      this.props.getAccountInfo(event.target.value).then(
        function () {
          let errorMessages = cloneDeep(this.state.errorMessages);
          let disabled = cloneDeep(this.state.disabled);
          let customer = this.state.customer;
          let accountDetails = this.props.accountDetails;
          if (accountDetails) {
            if (accountDetails.addressLine1) {
              customer.singleAddressLine = `${accountDetails.addressLine1} `;
              customer.streetAddress = `${accountDetails.addressLine1} `;
              disabled.singleAddressLine = true;
            }
            if (accountDetails.addressLine2) {
              customer.singleAddressLine += `${accountDetails.addressLine2} `;
              customer.streetAddress += accountDetails.addressLine2;
              disabled.singleAddressLine = true;
            }
            if (accountDetails.city) {
              customer.singleAddressLine += `${accountDetails.city} `;
              customer.suburbCity = accountDetails.city;
              disabled.singleAddressLine = true;
            }
            if (accountDetails.state) {
              customer.singleAddressLine += `${accountDetails.state} `;
              customer.state = STATE_ABBREVIATIONS[accountDetails.state.toLowerCase()];
              disabled.singleAddressLine = true;
            }
            if (accountDetails.postalCode) {
              customer.singleAddressLine += accountDetails.postalCode;
              customer.postcode = accountDetails.postalCode;
              disabled.singleAddressLine = true;
            }
            if (accountDetails.country) {
              customer.country = accountDetails.country;
            }
            if (accountDetails.businessNumber) {
              customer.abn = accountDetails.businessNumber;
              errorMessages.businessName = false;
              disabled.abn = true;
              disabled.abnManual = true;
              errorMessages.abnFormat = false;
              errorMessages.abn = false;
              errorMessages.abnManual = false;
              errorMessages.abnInvalid = false;
            }
            if (accountDetails.tradingName) {
              customer.legalEntityName = accountDetails.tradingName;
              errorMessages.legalEntityName = false;
              disabled.legalEntityName = true;
            }
            if (accountDetails.organizationName) {
              customer.businessName = accountDetails.organizationName;
              errorMessages.businessName = false;
              disabled.businessName = true;
            }
            if (customer.singleAddressLine) {
              errorMessages.singleAddressLine = false;
            }
            if (customer.title) {
              disabled.title = true;
            }

            this.setState({
              customer: customer,
              errorMessages: errorMessages,
              disabled: disabled,
              mailingAddressAutocomplete: true
            });
          }
        }.bind(this)
      );
    }

    this.onInputChange(event);
  }

  onManualAccountInputChange(event) {
    let errorMessages = cloneDeep(this.state.errorMessages);
    if (event.target.value !== '') {
      this.props.validateAccountReferenceNumber(event.target.value).then(
        function () {
          let isValidAccountReferenceNumber = this.props.isValidAccountReferenceNumber;

          errorMessages.accountReferenceNumberValid = !isValidAccountReferenceNumber;

          this.setState({
            errorMessages: errorMessages
          });
        }.bind(this)
      );
    }
  }

  render() {
    return (
      <div>
        <div className='container-fluid'>
          {this.props.order && Object.keys(this.props.order).length > 0 && (
            <div className='row page-dim margin-bottom-100'>
              <div className='col-md-8'>
                <div className='row no-margin'>
                  <div className='col-sm-12'>
                    <div className='row'>
                      <div className='checkout-section-title checkout-h3 text-center'>Checkout</div>
                    </div>
                  </div>
                </div>
                <CustomerForm
                  customerDetails={this.state.customer}
                  onCardChange={this.onCardChange}
                  totalDays={this.state.totalDays}
                  totalMonths={this.state.totalMonths}
                  userTotalMonths={this.state.userTotalMonths}
                  cardType={this.state.cardType}
                  onInputChange={this.onInputChange}
                  onSubmitCustomerDetails={this.onSubmitCustomerDetails}
                  onDirectDebitInputChange={this.onDirectDebitInputChange}
                  totalPrice={this.state.totalPrice}
                  showBusinessOption={this.state.showBusinessOption}
                  business={this.state.business}
                  onChangeBusinessOption={this.onChangeBusinessOption}
                  address={this.props.address}
                  onAddressInputChange={this.onAddressInputChange}
                  addressOption={this.state.addressOption}
                  onAddressFocus={this.onAddressFocus}
                  onAddressSelection={this.onAddressSelection}
                  onAbnChange={this.onAbnChange}
                  abn={this.props.abn}
                  abnError={this.props.abnError}
                  errorMessages={this.state.errorMessages}
                  onAddressManualEntry={this.onAddressManualEntry}
                  manualEntry={this.state.manualEntry}
                  forceAddressManualEntry={this.forceAddressManualEntry}
                  forcePhysicalAddressManualEntry={this.forcePhysicalAddressManualEntry}
                  onAddressCancelManualEntry={this.onAddressCancelManualEntry}
                  onAbnManualEntry={this.onAbnManualEntry}
                  onAbnCancelManualEntry={this.onAbnCancelManualEntry}
                  abnManualEntry={this.state.abnManualEntry}
                  errors={this.state.errors}
                  paymentLoader={this.props.paymentLoader}
                  quotes={this.state.quotes}
                  conditionalTC={this.state.conditionalTC}
                  onPaymentDetailsChange={this.onPaymentDetailsChange}
                  paymentDetails={this.state.paymentDetails}
                  physicalAddress={this.state.physicalAddress}
                  physicalManualEntry={this.state.physicalManualEntry}
                  onPhysicalAddressFocus={this.onPhysicalAddressFocus}
                  physicalAddressOption={this.state.physicalAddressOption}
                  onPhysicalAddressManualEntry={this.onPhysicalAddressManualEntry}
                  onPhysicalAddressCancelManualEntry={this.onPhysicalAddressCancelManualEntry}
                  physicalAddressSelector={this.state.physicalAddressSelector}
                  login={this.props.login}
                  onAccountSelectorChange={this.onAccountSelectorChange}
                  onManualAccountInputChange={this.onManualAccountInputChange}
                  onAccountTypeSelectorChange={this.onAccountTypeSelectorChange}
                  disabled={this.state.disabled}
                  contactsLoader={this.props.contactsLoader}
                  submitLoader={this.props.submitLoader}
                  backToCart={this.backToCart}
                  isDirectDebit={this.state.isDirectDebit}
                />
              </div>
              <div className='col-md-4'>
                <OrderSummary
                  totalDays={this.state.totalDays}
                  totalMonths={this.state.totalMonths}
                  userTotalMonths={this.state.userTotalMonths}
                  quotes={this.state.quotes}
                  order={this.state.order}
                  totalPrice={this.state.totalPrice}
                  creditCardSurcharge={this.state.creditCardSurcharge}
                  cardType={this.state.cardType}
                  agent={this.props.agent}
                  monthsDiscount={this.state.monthsDiscount}
                  minCost={this.state.minCost}
                  paymentDetails={this.state.paymentDetails}
                  originalTotalPrice={this.state.originalTotalPrice}
                  isAgentVisbile={this.state.isAgentVisbile}
                  coupon={this.state.coupon}
                />
              </div>
              <Modal show={this.state.show}>
                <Modal.Header>
                  <Modal.Title>
                    <p className='text-center no-margin font-16 semi-bold'>
                      Owner Details - Victoria
                    </p>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className='text-center no-margin font-16 padding-20'>
                    Victorian Legislation requires Real Estate Agents and Valuers to fill out a Deed
                    4 to be able to access Owners Details. You will receive the form and
                    instructions attached to your confirmation email.
                  </div>
                  <button
                    type='button'
                    className={
                      this.props.submitLoader
                        ? 'btn btn-primary btn-20 margin-bottom-20 disabled'
                        : 'btn btn-primary btn-20 margin-bottom-20'
                    }
                    onClick={() => {
                      this.onSubmitCustomerDetails(this.state.cardType);
                    }}
                  >
                    Continue
                    {this.props.submitLoader && (
                      <span>
                        <i className='fa fa-spinner fa-pulse fa-fw'></i>
                        <span className='sr-only'>Loading...</span>
                      </span>
                    )}
                  </button>
                </Modal.Body>
              </Modal>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    order: state.cart.order,
    cart: state.cart.cart,
    address: state.checkout.address,
    abn: state.checkout.abn,
    abnError: state.checkout.abnError,
    addressError: state.checkout.addressError,
    agent: state.packageSelection.agent,
    paymentLoader: state.checkout.paymentLoader,
    contactsLoader: state.checkout.contactsLoader,
    submitLoader: state.checkout.submitLoader,
    login: state.login,
    accountDetails: state.checkout.accountDetails,
    propertyDetails: state.checkout.propertyDetails,
    registration: state.registration,
    isValidAccountReferenceNumber: state.checkout.isValidAccountReferenceNumber,
    contactInfo: state.checkout.contactInfo
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveCustomerDetails: (customerDetails, type, paymentDetails) =>
      dispatch(saveCustomerDetails(customerDetails, type, paymentDetails)),
    updateContactInfo: (contactInfo) =>
      dispatch(updateContactInfo(contactInfo)),
    directDebitPayment: (order, directDebitDetails) =>
      dispatch(directDebitPayment(order, directDebitDetails)),
    addressLines: (address) => dispatch(addressLines(address)),
    fetchAbnDetails: (abn) => dispatch(fetchAbnDetails(abn)),
    fetchCurrentOrder: () => dispatch(fetchCurrentOrder()),
    getRoles: (accountGuid) => dispatch(getRoles(accountGuid)),
    getAccounts: (code) => dispatch(getAccounts(code)),
    redirectToLoginPage: () => dispatch(redirectToLoginPage()),
    getAccountInfo: (accountNumber) => dispatch(getAccountInfo(accountNumber)),
    getPropertyDetails: (propertyId) => dispatch(getPropertyDetails(propertyId)),
    resetCheckoutLoaders: () => dispatch(resetCheckoutLoaders()),
    resetAbnLookupProps: () => dispatch(resetAbnLookupProps()),
    resetAddressLookupProps: () => dispatch(resetAddressLookupProps()),
    validateAccountReferenceNumber: (accountReferenceNumber) =>
      dispatch(validateAccountReferenceNumber(accountReferenceNumber)),
    resetPurchaseEvent: () => dispatch(resetPurchaseEvent())  
  };
};

let CheckoutPage = connect(mapStateToProps, mapDispatchToProps)(Checkout);

export default CheckoutPage;
