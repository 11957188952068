import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  ControlLabel,
  FormControl,
  Col,
  Tooltip,
  OverlayTrigger,
  InputGroup
} from 'react-bootstrap';

import ErrorMessage from '../errorMessage';
import PasswordValidations from './PasswordValidations';
import {
  trackAndRedirectToConditionOfUse,
  validateConfirmPassword
} from '../../../services/v2/customerRegistration/registrationService';

const tooltip = (
  <Tooltip id='usernameTooltip'>
    We suggest creating a username based on your first name and last name eg Bob.Brown. Using your
    email as a username is not supported.
  </Tooltip>
);

/**
 * RegistrationForm.
 * Displays the form for customer registration.
 *
 * @author  Manny
 * @param   {Object}      props.customer                    Customer Object.
 * @param   {Function}    props.dispatch                    Dispatch object from react-redux .
 * @param   {Object}      props.formErrors                  FormError Object containing form error properties in boolean.
 * @param   {Boolean}     props.loader                      Loader boolean to hide/show spinner.
 * @param   {Function}    props.onInputChange               React onInputChange.
 * @param   {Function}    props.submitCustomerRegistration  Function to call the register service action.
 * @returns {JSX.Element}                                   The component markup.
 */

const RegistrationForm = ({
  customer,
  dispatch,
  formErrors,
  loader,
  onInputChange,
  submitCustomerRegistration
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);

  return (
    <div className='white-bg' style={{paddingTop:'20px',paddingBottom:'20px'}}>
      <FormGroup controlId='title' className='row captions'>
        <Col sm={4}>
          <ControlLabel className='body-semibold'>Title</ControlLabel>
        </Col>
        <Col sm={7}>
          <FormControl componentClass="select" name="title" value={customer.title} onChange={(e)=>{onInputChange(e)}}>
            <option value="null"></option>
            <option value="Mr.">Mr.</option>
            <option value="Ms.">Ms.</option>
            <option value="Mrs.">Mrs.</option>
            <option value="Dr.">Dr.</option>
            <option value="Prof.">Prof.</option>
            <option value="Mx.">Mx.</option>
          </FormControl>
          {formErrors.title && (
            <ErrorMessage message="Please select a Title." />
          )}
        </Col>
      </FormGroup>
      <FormGroup controlId='firstName' className='row captions'>
        <Col sm={4}>
          <ControlLabel className='body-semibold'>First Name</ControlLabel>
        </Col>
        <Col sm={7}>
          <FormControl
            type='text'
            name='firstName'
            value={customer.firstName}
            onChange={(e) => {
              onInputChange(e);
            }}
          />
          {formErrors.firstName && (
            <ErrorMessage message='First Name must be minimum of 2 characters, letters only.' />
          )}
        </Col>
      </FormGroup>
      <FormGroup controlId='lastName' className='row captions'>
        <Col sm={4}>
          <ControlLabel className='body-semibold'>Last Name</ControlLabel>
        </Col>
        <Col sm={7}>
          <FormControl
            type='text'
            name='lastName'
            value={customer.lastName}
            onChange={(e) => {
              onInputChange(e);
            }}
          />
          {formErrors.lastName && (
            <ErrorMessage message='Last Name must be minimum 2 characters, letters only.' />
          )}
        </Col>
      </FormGroup>
      <FormGroup controlId='email' className='row captions'>
        <Col sm={4}>
          <ControlLabel className='body-semibold'>Email Address</ControlLabel>
        </Col>
        <Col sm={7}>
          <FormControl
            type='email'
            name='emailAddress'
            value={customer.emailAddress}
            onChange={(e) => {
              onInputChange(e);
            }}
          />
          {formErrors.emailAddress && (
            <ErrorMessage message='Please enter your valid email address.' />
          )}
          {formErrors.duplicateEmail && (
            <ErrorMessage message='Email is already registered. Please enter another email or sign in using the email registered.' />
          )}
        </Col>
      </FormGroup>
      <FormGroup controlId='username' className='row captions'>
        <Col sm={4}>
          <ControlLabel className='body-semibold'>
            <span className='margin-right-5'>Username</span>
            <OverlayTrigger placement='top' overlay={tooltip} trigger={['click', 'hover', 'focus']}>
              <span>
                <i className='far fa-question-circle tooltip-icon' aria-hidden='true'></i>
              </span>
            </OverlayTrigger>
          </ControlLabel>
        </Col>
        <Col sm={7}>
          <FormControl
            type='text'
            name='username'
            placeholder='eg Bob.Brown'
            value={customer.username}
            onChange={(e) => {
              onInputChange(e);
            }}
          />
          {formErrors.invalidUsername && (
            <ErrorMessage message='Username already exists, please create another.' />
          )}
          {formErrors.username && (<>
              <ErrorMessage message="Between 8 and 50 characters"/>
              <ErrorMessage message="'.' dot is optional"/>
              <ErrorMessage message="no spaces or special characters eg “!#$%&*+,-/':][;<=>?@^_`(){|}~"/>
            </>
          )}
        </Col>
      </FormGroup>
      <FormGroup controlId='password' className='row captions'>
        <Col sm={4}>
          <ControlLabel className='body-semibold'>Password</ControlLabel>
        </Col>
        <Col sm={7}>
          <InputGroup>
            <FormControl
              id='password'
              type={isPasswordVisible ? 'text' : 'password'}
              name='password'
              value={customer.password}
              onChange={(e) => {
                onInputChange(e);
              }}
              className='password-field'
            />
            <InputGroup.Addon>
              <span
                onClick={() => {
                  setIsPasswordVisible(!isPasswordVisible);
                }}
              >
                <i
                  className={isPasswordVisible ? 'fa fa-eye' : 'fa fa-eye-slash'}
                  aria-hidden='true'
                ></i>
              </span>
            </InputGroup.Addon>
          </InputGroup>
          <PasswordValidations password={customer.password} />
        </Col>
      </FormGroup>
      <FormGroup controlId='confirmPassword' className='row captions'>
        <Col sm={4}>
          <ControlLabel className='body-semibold'>Confirm Password</ControlLabel>
        </Col>
        <Col sm={7}>
          <InputGroup>
            <FormControl
              id='confirmPassword'
              type={isConfirmPasswordVisible ? 'text' : 'password'}
              name='confirmPassword'
              value={customer.confirmPassword}
              onChange={(e) => {
                onInputChange(e);
              }}
              className='password-field'
            />
            <InputGroup.Addon>
              <span
                onClick={() => {
                  setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
                }}
              >
                <i
                  className={isConfirmPasswordVisible ? 'fa fa-eye' : 'fa fa-eye-slash'}
                  aria-hidden='true'
                ></i>
              </span>
            </InputGroup.Addon>
          </InputGroup>
          {!customer.confirmPassword && (
            <p className='password-validation-msg'>
              <i className='far fa-circle'></i> password matches
            </p>
          )}
          {customer.confirmPassword &&
            (validateConfirmPassword(customer.password, customer.confirmPassword) ? (
              <p className='password-validation-msg-invalid'>
                <i className='fas fa-exclamation-triangle'></i> password matches
              </p>
            ) : (
              <p className='password-validation-msg-valid'>
                <i className='fas fa-check'></i> password matches
              </p>
            ))}
        </Col>
      </FormGroup>
      <FormGroup className='row body no-margin'>
        <Col sm={12} style={{ textAlign: 'center' }}>
          <div>
            By continuing, you agree to CoreLogic RP Data&#39;s&nbsp;
            <a
              id='lnk_conditions'
              className='text-link'
              onClick={() => {
                trackAndRedirectToConditionOfUse(
                  'https://www.corelogic.co.nz/about-us/product-terms-and-conditions',
                  'Conditions of Use'
                );
              }}
            >
              Conditions of Use
            </a>{' '}
            and&nbsp;
            <a
              id='lnk_privacy'
              className='text-link'
              onClick={() => {
                trackAndRedirectToConditionOfUse(
                  'https://www.corelogic.com.au/about-us/privacy-policy',
                  'Privacy Policy'
                );
              }}
            >
              Privacy Policy
            </a>
            .
          </div>
        </Col>
        <div className='row no-margin'style={{textAlign:'center'}}>
            <button
                id='btn_register'
                type='button'
                className='btn register-btn'
                style={{width:'100%'}}
                onClick={() => {
                  /*istanbul ignore next*/
                  submitCustomerRegistration(customer);
                }}
              >
              Register
              {loader && (
                <span>
                  <i className='fa fa-spinner fa-pulse fa-fw'></i>
                  <span className='sr-only'>Loading...</span>
                </span>
              )}
            </button>
        </div>
      </FormGroup>
    </div>
  );
};

RegistrationForm.propTypes = {
  customer: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  formErrors: PropTypes.object.isRequired,
  loader: PropTypes.bool.isRequired,
  onInputChange: PropTypes.func.isRequired,
  submitCustomerRegistration: PropTypes.func
};

export default RegistrationForm;
