import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { resetCoupon } from '../actions/packageSelection';
import { flagPurchaseEvent } from '../actions/pxpay';

import classroom from './../image/training_classroom.png';
import training from './../image/training_onsite.png';
import online from './../image/training_online.png';

import { analyticsPageView, trackAction, trackOrderStatus } from '../services/v2/segmentAnalytics';

const OrderStatusPage = () => {
  //condition1 refers to order items other than report bundles
  //condition2 refers to order item report bundle
  //condition3 refers to multiple report bundle packages

  const [condition1, setCondition1] = useState(false);
  const [condition2, setCondition2] = useState(false);
  const [condition3, setCondition3] = useState(false);
  const [condition4, setCondition4] = useState(false);

  const paidOrder = useSelector((state) => state.cart.paidOrder);
  const order = useSelector((state) => state.cart.order);
  const isPurchaseEventFlagged = useSelector((state) => state.pxpay.isPurchaseEventFlagged);

  const dispatch = useDispatch();

  const redirectToURL = (link, pageStatus) => {
    trackAction('Return to corporate site', 'CoreLogic home', pageStatus);
    window.location.href = link;
  };

  const getPaidOrderItems = (newPaidOrder) => {
    let newCondition1 = false;
    let newCondition2 = false;
    let newCondition3 = false;
    let newCondition4 = false;

    if (newPaidOrder && Object.keys(newPaidOrder).length > 0) {
      let cartQuote = newPaidOrder.quotes.quotes;

      for (let index = 0; index < cartQuote.length; index++) {
        let quote = cartQuote[index];

        if (quote.type !== null && quote.type === 'ReportBundle') {
          if (!condition2) {
            newCondition2 = true;
          } else {
            newCondition3 = true;
          }
        }
        if (quote.type !== null && quote.type !== 'ReportBundle') {
          newCondition1 = true;
        }
        if (
          quote.type !== null &&
          quote.type !== 'ReportBundle' &&
          quote.type !== 'CordellEstimatorNZ'
        ) {
          newCondition4 = true;
        }
      }
    }

    setCondition1(newCondition1);
    setCondition2(newCondition2);
    setCondition3(newCondition3);
    setCondition4(newCondition4);
  };

  useEffect(() => {
    analyticsPageView('Order Status');

    const currentOrder = order && Object.keys(order).length > 0 ? order : paidOrder;

    getPaidOrderItems(currentOrder);

    if (!isPurchaseEventFlagged) {
      //Track Order Status
      trackOrderStatus(currentOrder, 'Success', 'Order Successful');
      dispatch(flagPurchaseEvent());
    }

    //reset coupon value after successful transaction
    dispatch(resetCoupon());

    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {paidOrder && (
        <div className='container'>
          <div className='row margin-bottom-60'>
            <div className='col-sm-12 text-white payment-status'>
              {paidOrder.total > 0 && (
                <div className='row'>
                  <div className='col-sm-12'>
                    <div className='heading1 success-text padding-20'>Success!</div>
                    <div>
                      <p>
                        Thank you for your order. You will receive
                        <span>
                          {condition1 && <span> your login credentials </span>}
                          {condition1 && (condition2 || condition3) && <span>and </span>}
                          {condition2 && <span> report </span>}
                          {condition3 && <span> reports </span>}
                        </span>
                        via email as soon as possible.
                        <br /> If you have any questions, please contact our Customer Care team on{' '}
                        <b>0800 355 355</b> and refer to order #{paidOrder.orderNumber}.
                      </p>
                      <p>
                        When you join Corelogic, you get free access to a network of professionals
                        who look after your interests long-term. Our friendly team will be in touch.
                      </p>
                      <p>
                        You may now close this window or return to&nbsp;
                        <a
                          className='red-link'
                          onClick={() => {
                            redirectToURL('https://corelogic.co.nz', 'Success');
                          }}
                        >
                          corelogic.co.nz
                        </a>
                      </p>
                    </div>
                    {condition4 && (
                      <div className='row'>
                        <div className='col-sm-12'>
                          <div className='row'>
                            <div className='col-sm-4'>
                              <div className='tile-container'>
                                <div className='tile-top'>
                                  <img src={classroom} height='250' width='250' alt='Classroom' />
                                </div>
                                <div className='tile-content'>
                                  <div className='heading3 padding-top-10 padding-bottom-10'>
                                    Classroom Training
                                  </div>
                                  <div className='padding-10 text-left'>
                                    <p>
                                      Comprehensive in-house training in our training room
                                      facilities. Training facilities are located in Wellington and
                                      Auckland
                                    </p>
                                    <p>
                                      <a className='red-text'>Coming Soon</a>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='col-sm-4'>
                              <div className='tile-container'>
                                <div className='tile-top'>
                                  <img src={online} height='250' width='250' alt='Online' />
                                </div>
                                <div className='tile-content'>
                                  <div className='heading3 padding-top-10 padding-bottom-10'>
                                    Online Training
                                  </div>
                                  <div className='padding-10 text-left'>
                                    <p>
                                      Sign up to our free interactive online training with one of
                                      our training experts. We offer beginner and advanced training
                                      for RPNZ and Propery Guru with multiple sessions daily. Our
                                      online courses are ideal for refresher training and regional
                                      subscribers.
                                    </p>
                                    <p>
                                      <a
                                        className='red-text'
                                        href='https://events-apac1.adobeconnect.com/content/connect/c1/1347210301/en/events/catalog.html?OWASP_CSRFTOKEN=a5e258a0107ae867af84ce3028c1c25e8c5adb777a277a9a3d7dce408c47d7ed&utm_source=Website%20Training%20Page&utm_medium=Website&utm_campaign=Adobe%20Connect%20Training#=undefinedcurrentSearchTag=1389640976'
                                      >
                                        Book Online Training
                                      </a>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='col-sm-4'>
                              <div className='tile-container'>
                                <div className='tile-top'>
                                  <img src={training} height='250' width='250' alt='Onsite' />
                                </div>
                                <div className='tile-content'>
                                  <div className='heading3 padding-top-10 padding-bottom-10'>
                                    Onsite Training
                                  </div>
                                  <div className='padding-10 text-left'>
                                    <p>
                                      Have one of our expert trainers attend your workplace and
                                      conduct specialised training.
                                    </p>
                                    <p>
                                      For more information on pricing and availability call 0800 355
                                      355 or email{' '}
                                      <a
                                        className='red-text'
                                        href='mailto:training@corelogic.com.nz'
                                      >
                                        training@corelogic.co.nz
                                      </a>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderStatusPage;
