import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, ControlLabel, FormControl, Col } from 'react-bootstrap';

/**
 * IndustrySelector.
 * Displays a select field containing customer industry options.
 *
 * @author  Manny
 * @param   {Object}      props.customerDetails  Customer Object.
 * @param   {Object}      props.errorMessages    FormError Object containing form error properties in boolean.
 * @param   {Function}    props.onInputChange    React onInputChange.
 * @param   {Boolean}     props.containsRPPTrial Boolean flag for RPP Trial specific condition.
 * @returns {JSX.Element}                        The component markup.
 */

const IndustrySelector = ({ customerDetails, errorMessages, onInputChange, containsRPPTrial }) => {
  const [isMobile, setIsMobile] = useState(false);
  /* istanbul ignore next */
  function setOptions() {
    let industryOptions = {
      'Real Estate': [
        { value: 'RESIDENTIAL_REAL_ESTATE', label: 'Residential Real Estate' },
        { value: 'COMMERCIAL_REAL_ESTATE', label: 'Commercial Real Estate' }
      ],
      'Construction': [
        { value: 'BUILDERS', label: 'Builders' },
        { value: 'DEVELOPERS', label: 'Developers' },
        { value: 'TRADES_AND_SERVICES', label: 'Trades & Services' },
        { value: 'SUPPLIERS_AND_MANUFACTURERS', label: 'Suppliers & Manufacturers' }
      ],
      'Insurance': [
        { value: 'INSURANCE', label: 'Insurance'}
      ],
      'Valuers': [
        { value: 'VALUERS', label: 'Valuers'}
      ],
      'Banking & Finance': [
        { value: 'MORTGAGE_BROKERS', label: 'Mortgage Brokers'},
        { value: 'BANKING_AND_LENDING', label: 'Banking & Lending'}
      ],
      'Wealth': [
        { value: 'PROPERTY_INVESTORS', label: 'Property Investors' },
      ],
      'Government': [
        { value: 'LOCAL_GOV_AUTHORITY', label: 'Local Govt Authority' },
        { value: 'STATE_GOV_AGENCY_DEPT', label: 'State Govt Agency/Dept' },
        { value: 'FEDERAL_GOV_AGENCY_DEPT', label: 'Federal Govt Agency/Dept' },
        { value: 'TERTIARY_EDUCATION', label: 'Tertiary Education' }
      ]
    };
    if (containsRPPTrial) delete industryOptions['Wealth']

    return industryOptions;
  }

  useEffect(()=> {
    if(/Android|webOS|iPhone|iPod|ipad|BlackBerry/i.test(navigator.userAgent) ){
      setIsMobile(true)
    }  
  }, [])


  return (
    <FormGroup controlId='industry' className='row'>
      <Col sm={4}>
        <ControlLabel>
          <span className='margin-right-5 body-semibold'>Industry</span>
          <i className="form-mandatory-fields">*</i>
        </ControlLabel>
      </Col>
      <Col sm={6}>
        <FormControl
          className={errorMessages.industry ? 'form-error-border' : 'body'}
          name='industry'
          componentClass='select'
          value={customerDetails.industry === null ? '' : customerDetails.industry}
          onChange={(e) => {
            onInputChange(e);
          }}
        >
          <option value='NONE'>--Select Industry--</option>
          {Object.entries(setOptions()).map(([group, options]) => {
            return (
              <optgroup label={group} key={group} className={isMobile ? 'mobile-industry-group' : 'industry-group'}>
                {options.map((option, index) => (
                  <option value={option.value} key={index} className={isMobile ? 'mobile-industry-option' : 'industry-option'}>
                    {isMobile && '\u00A0\u00A0'}
                    {option.label}
                  </option>
                ))}
              </optgroup>
            );
          })}
        </FormControl>
        {errorMessages.industry && (
          <div className='form-error-messages captions'>Please select the industry.</div>
        )}
      </Col>
    </FormGroup>
  );
};

IndustrySelector.propTypes = {
  customerDetails: PropTypes.object.isRequired,
  errorMessages: PropTypes.object.isRequired,
  onInputChange: PropTypes.func.isRequired,
  containsRPPTrial: PropTypes.bool.isRequired,
};

export default IndustrySelector;
