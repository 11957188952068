/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import CouponField from './couponField';
import CartTotals from './cartTotals';

import './cartItems.css';

/**
 * CartItems.
 * Displays the cart items for the Cart Page.
 *
 * @author  Miguel
 * @param   {Object}      props.cart                  Cart Object.
 * @param   {Object}      props.cartQuotes            CartQuotes Object.
 * @param   {Object}      props.coupon                Coupon Object.
 * @param   {String}      props.couponAdded           CouponAdded Object.
 * @param   {String}      props.couponCode            CouponCode Object.
 * @param   {Boolean}     props.isCouponValidated     Flag for confirming if coupon has been validated.
 * @param   {Boolean}     props.loading               Loader Object.
 * @param   {Function}    props.onCartLoad            Function from the Cart Page to refresh the cart, delete a past order.
 * @param   {Function}    props.onCheckout            Function from the Cart Page to set Customer details.
 * @param   {Function}    props.onCouponApply         Function from the Cart Page to to apply a coupon code.
 * @param   {Function}    props.onCouponInputChange   Function from the Cart Page to handle coupon code onInputChange.
 * @param   {Function}    props.onCustomiseProduct    Function from the Cart Page, calls updateQuoteConfig.
 * @param   {Function}    props.onDeleteCoupon        Function from the Cart Page to to remove a coupon code.
 * @param   {Function}    props.onDeleteProduct       Function from the Cart Page to delete a cart item.
 * @param   {Object}      props.order                 Order Object from redux store.
 * @param   {Function}    props.trackGenericPageInfo  Function from the segmentAnalytics service.
 * @returns {JSX.Element}                             The component markup.
 */

const CartItems = ({
  cart,
  cartQuotes,
  coupon,
  couponAdded,
  couponCode,
  isCouponValidated,
  loading,
  onCartLoad,
  onCheckout,
  onCouponApply,
  onCouponInputChange,
  onCustomiseProduct,
  onDeleteCoupon,
  onDeleteProduct,
  order,
  trackGenericPageInfo
}) => {
  const [currentCart, setCurrentCart] = useState(null);
  const [currentCartQuotes, setCurrentCartQuotes] = useState(null);
  const [totalMonths, setTotalMonths] = useState(0);
  const [showInclusions, setShowInclusions] = useState(false);
  const [showAdditionalFeatures, setShowAdditionalFeatures] = useState(false);

  /* istanbul ignore next */
  const continueShopping = (link) => {
    trackGenericPageInfo('Continue Shopping');
    window.location.assign(link);
  };

  /* istanbul ignore next */
  const pricingFormat = (value) => {
    let parts = value.toString().split('.');
    parts[0] = parts[0].split('').reverse().join('').replace(/(\d{3})/g, '$1,').split('').reverse().join('');
    if (parts[0].startsWith(',')) {
      parts[0] = parts[0].slice(1);
    }
    return parts.join('.');
  };

  /* istanbul ignore next */
  const getQuoteContractDays = (quoteTotalPerMonth) => {
    if (quoteTotalPerMonth > 0) {
      return 90;
    } else if (quoteTotalPerMonth === 0) {
      return 0;
    }
  }

  const getQuoteNewTotalMonths = (newTotalMonths, quoteContractMonths) => {
    if (newTotalMonths === 0 || newTotalMonths < quoteContractMonths) {
      return quoteContractMonths;
    }
  }

  const isCartNotNull = (newCart, newCartQuotes) => {
    return Boolean(newCart && Object.keys(newCart).length > 0 && newCart?.cartItems && newCartQuotes?.quotes != null);
  }

  /* istanbul ignore next */
  const setCartItems = (newCart, newCartQuotes) => {
    let newTotalMonths = 0;

    if (!isCartNotNull(newCart, newCartQuotes)) {
      setCurrentCart(newCart);
      setCurrentCartQuotes(newCartQuotes);
      setTotalMonths(newTotalMonths);
      return;
    }
    for (let quote of newCartQuotes.quotes) {
      quote.deedMessage = false;
      quote.contractDays = getQuoteContractDays(quote.totalPerMonth);
      for (let cartItem of newCart.cartItems) {
        if (cartItem.productId === quote.productId) {
          quote.cartItemId = cartItem.cartItemId;
          quote.configuration = JSON.parse(cartItem.configuration);

          quote.contractMonths =
            quote.minimumInitialTerm +
            (quote.provisioningInfo &&
                quote.provisioningInfo.discountPeriods
                ? quote.provisioningInfo.discountPeriods
                : 0);
          newTotalMonths = getQuoteNewTotalMonths(newTotalMonths, quote.contractMonths);
        }
      }
    }

    setCurrentCart(newCart);
    setCurrentCartQuotes(newCartQuotes);
    setTotalMonths(newTotalMonths);
  };

  /* istanbul ignore next */
  useEffect(() => {
    if (cart && order && Object.keys(order).length > 0) {
      onCartLoad();
    } else {
      setCartItems(cart, cartQuotes);
    }
  }, []);

  useEffect(() => {
    setCartItems(cart, cartQuotes);
  }, [cart, cartQuotes]);

  return (
    <div className='off-white-bg'>
      <div className='container'>
        <div className='row no-margin' style={{padding: '0px'}}>
          <div className='col-sm-12'  style={{padding: '0px'}}>
            <div className='row'>
              <div className='cart-header cart-h3'>Your Cart</div>
            </div>
            {currentCartQuotes === null && (
              <div className='row white-bg cart-container margin-bottom-100'>
                <br />
                <div className='row spinner'>
                  <i className='fa fa-spinner fa-pulse fa-3x fa-fw'></i>
                  <span className='sr-only'>Loading...</span>
                </div>
              </div>
            )}
            {currentCartQuotes &&
              (Object.keys(currentCartQuotes).length === 0 ||
                currentCartQuotes.quotes.length === 0) && (
                <div className='row white-bg cart-container margin-bottom-100 empty-cart-alignment'>
                  <br />
                  <div className='cart-item-header cart-h5'> You have no items in your cart</div>
                  <div className='body'>Click the button below to start shopping</div>
                  <a
                  type='button' className='btn cont-btn cart-items-btn'
                    onClick={() => {
                      continueShopping('https://corelogic.co.nz');
                    }}
                  >
                    Continue Shopping
                  </a>
                </div>
              )}
            {currentCartQuotes &&
              Object.keys(currentCartQuotes).length > 0 &&
              currentCartQuotes.quotes.length > 0 && (
                <div className='row white-bg cart-container margin-bottom-100'>
                  {coupon && isCouponValidated && Object.keys(coupon).length > 0 && coupon.blueBoxText !== null && (
                    <div className='row' style={{ marginRight: '-20px', marginLeft: '-20px' }}>
                      <div className='col-sm-12 discount-alert blue-bg captions-semibold'>
                        <span>{coupon.blueBoxText}</span>
                      </div>
                    </div>
                  )}
                  {(!coupon || !isCouponValidated || Object.keys(coupon).length === 0 || coupon.blueBoxText === null) && (
                    <br />
                  )}
                  <div className='col-sm-12 no-padding'>
                    {currentCartQuotes.quotes.map(function (item, index) {
                      return (
                        <div key={item.productId}>
                          <div className='row no-margin'>
                            <div className='col-sm-12 no-padding'>
                              <div className='row no-margin'>
                                <div className='col-xs-8 no-padding'>
                                  <div className='cart-item-header cart-h5'>{item?.headLine}</div>
                                  <div>
                                    <p className='no-margin wcag-grey-text captions'>
                                      <b>Inclusions</b>
                                    </p>
                                    {item?.lineItems?.length < 3 && (
                                      <div className='checkout-item'>
                                      <>
                                        {item?.lineItems?.map(function (lineItem, subIndex) {
                                           if(subIndex < 3 && !showInclusions || showInclusions )
                                            return (
                                              <span>
                                                <span>{lineItem.description}</span>
                                                {subIndex < item.lineItems.length - 1 && (
                                                  <span>,&nbsp;</span>
                                                )}
                                              </span>
                                            );
                                        })}
                                        {item?.lineItems?.length > 3 &&
                                          <div className='row body no-margin grey-text pointer'>
                                            <span onClick={ () => setShowInclusions(s => !s) }>
                                              Show {showInclusions ? 'less' : 'more'}
                                            </span>
                                          </div>
                                        }
                                      </>
                                    </div>
                                    )}
                                  </div>
                                  {item?.lineItems?.length < 3 && (
                                  <div className='checkout-item'>
                                    {item?.features?.map(function (line, subIndex) {
                                      if(subIndex < 3 && !showAdditionalFeatures || showAdditionalFeatures ) 
                                      return (
                                        <span>
                                          <span>{line.description}</span>
                                          {subIndex < item.features.length - 1 && (
                                            <span>,&nbsp;</span>
                                          )}
                                        </span>
                                      );
                                    })}
                                    {item?.features?.length > 3 &&
                                          <div className='row body no-margin grey-text pointer'>
                                            <span onClick={ () => setShowAdditionalFeatures(s => !s) }>
                                              Show {showAdditionalFeatures ? 'less' : 'more'}
                                            </span>
                                          </div>
                                        }
                                  </div>
                                  )}
                                  {item?.addons?.length > 0 && (
                                    <div>
                                      <br />
                                      <p className='no-margin grey-text'>
                                        <b>{item.addons.length} Add Ons </b>
                                      </p>
                                      <div className='checkout-item'>
                                        {item.addons.map(function (lineItem, subIndex) {
                                          return <div key={subindex}>{lineItem.description}</div>;
                                        })}
                                      </div>
                                    </div>
                                  )}
                                  <br />
                                </div>
                                <div className='col-xs-4 no-padding'>
                                  <div className='cart-item-header text-right cart-h5'>
                                    <span className=''> $NZD </span>
                                    {pricingFormat(item.originalPrice.toFixed(2))}
                                    <div className='font-12 text-right captions'>Per month</div>
                                  </div>
                                  <div className='cart-items text-right footnote-text'>
                                    {item?.originalPrice === 0 && (
                                      <span>
                                        <small>Min. cost $0 for 7 days.</small>
                                      </span>
                                    )}
                                    {item?.originalPrice > 0 && (
                                      <span>
                                        <small>
                                          Min. cost $
                                          {pricingFormat(
                                            (item.originalPrice * item.minimumInitialTerm).toFixed(
                                              2
                                            )
                                          )}{' '}
                                          for&nbsp;
                                          {item.contractMonths} month(s).
                                        </small>
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className='row no-margin'>
                                <div className='col-xs-12 no-padding'>
                                  <a
                                    type='text'
                                    className='btn cart-btn btn-outline margin-right-5'
                                    onClick={() => {
                                      onCustomiseProduct(item.productId, item.configuration);
                                    }}
                                  >
                                    Customise
                                  </a>
                                  <a
                                    type='text'
                                    className='btn cart-btn btn-outline'
                                    onClick={() => {
                                      onDeleteProduct(item.cartItemId, item.productId, item);
                                    }}
                                  >
                                    Delete
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </div>
                      );
                    }, this)}
                    {currentCart !== null &&
                      Object.keys(currentCart).length > 0 &&
                      currentCart.cartItems.length > 0 && (
                        <div className='row no-margin'>
                          <div className='col-sm-8 terms-cond'></div>
                          <div className='col-sm-4 no-padding'>
                            <CouponField
                              coupon={couponCode}
                              couponAdded={couponAdded}
                              couponEntity={isCouponValidated ? coupon : null}
                              monthsDiscount={
                                coupon && coupon.discountServiceConfig
                                  ? coupon.discountServiceConfig.months_discount
                                  : 0
                              }
                              onCouponApply={onCouponApply}
                              onCouponInputChange={onCouponInputChange}
                              onDeleteCoupon={onDeleteCoupon}
                            />
                            <hr />

                            <CartTotals
                              cartQuotes={currentCartQuotes}
                              couponEntity={coupon}
                              isCouponValidated={isCouponValidated}
                              pricingFormat={pricingFormat}
                              totalMonths={totalMonths}
                            />
                          </div>
                        </div>
                      )}
                    <div className='row no-margin'>
                      <hr />
                    </div>
                    {currentCart !== null &&
                      Object.keys(currentCart).length > 0 &&
                      currentCart.cartItems.length > 0 && (
                        <div className='row no-margin'>
                          <div className='col-xs-12 no-padding'>
                            <button
                              type='button'
                              className='btn checkout-btn cart-items-btn'
                              onClick={() => {
                                onCheckout();
                              }}
                            >
                              Checkout
                              {loading && (
                                <span>
                                  <i className='fa fa-spinner fa-pulse fa-fw'></i>
                                  <span className='sr-only'>Loading...</span>
                                </span>
                              )}
                            </button>
                            <a
                              type='button' className='btn cont-btn cart-items-btn'
                              onClick={() => {
                                continueShopping('https://corelogic.co.nz');
                              }}
                            >
                                Continue Shopping
                            </a>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

CartItems.propTypes = {
  cart: PropTypes.object.isRequired,
  cartQuotes: PropTypes.object.isRequired,
  coupon: PropTypes.object,
  couponCode: PropTypes.string,
  couponAdded: PropTypes.bool,
  isCouponValidated: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onCartLoad: PropTypes.func.isRequired,
  onCheckout: PropTypes.func.isRequired,
  onCouponApply: PropTypes.func.isRequired,
  onCouponInputChange: PropTypes.func.isRequired,
  onCustomiseProduct: PropTypes.func.isRequired,
  onDeleteCoupon: PropTypes.func.isRequired,
  onDeleteProduct: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  trackGenericPageInfo: PropTypes.func.isRequired
};

export default CartItems;
