import React, { useState, useEffect } from 'react';
import Banner from '../../banner';
import BannerMobile from '../../bannerMobile';
import { Nav, NavItem } from 'react-bootstrap';
import PropTypes from 'prop-types';

import CordellEstimatorComparisonTable from './cordellEstimatorComparisonTable';
import CordellEstimatorComparisonTableMobile from './cordellEstimatorComparisonTableMobile';

import { checkProductCoupon } from '../../../../services/v2/products/productService';


import './cordellEstimatorProductPackage.css'

/**
 * CordellEstimatorProductPackage.
 * Displays the product and packages for the package selection page.
 *
 * @author  Miguel
 * @param   {Object}      props.coupon                      Product Coupon.
 * @param   {Function}    props.onSelectPackage             React onSelectPackage.
 * @param   {Object}      props.productsConfig              Product Config.
 * @param   {Number}      props.productId                   Product Id.
 * @returns {JSX.Element}                                   The component markup.
 */

const CordellEstimatorProductPackage = ({ coupon, onSelectPackage, productsConfig, productId }) => {
  const [mobile, setMobile] = useState(false);
  const [packageKey, setPackageKey] = useState(
    Object.keys(productsConfig.packages)[Object.keys(productsConfig.packages).length - 1]
  );
  const [activeKey, setActivekey] = useState(
    Object.keys(productsConfig.packages).length.toString()
  );

  const changePackage = (newPackageKey, newActiveKey) => {
    setPackageKey(newPackageKey);
    setActivekey(newActiveKey);
  };

  useEffect(() => {
    if (/Android|webOS|iPhone|iPod|ipad|BlackBerry/i.test(navigator.userAgent)) {
      setMobile(true);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(productsConfig.packages).length === 1) {
      setPackageKey(
        Object.keys(productsConfig.packages)[Object.keys(productsConfig.packages).length - 1]
      );
    }
  }, [productsConfig]);

  let classNames = require('classnames');
  let containerClass = classNames({
    'col-sm-12': !mobile,
    'col-sm-12 no-padding': mobile
  });

  return (
    <React.Fragment>
      {productsConfig && (
        <div>
          <div className='row off-white-bg no-margin'>
            <div className='col-sm-12'>
              <div className='row no-margin'>
                <div
                  className='col-sm-12 section-title package-selection-h3 text-center'
                  id='package_header'
                  style={{ paddingBottom: '20px' }}
                >
                  Cordell Estimator - NZ Costings Data
                </div>
              </div>
              <div className='row no-margin'>
                <div className={containerClass}>
                  {coupon &&
                    Object.keys(coupon).length > 0 &&
                    checkProductCoupon(coupon, productId) &&
                    coupon.blueBoxText !== null && (
                      <div className='row no-margin page-dim'>
                        <div className='col-sm-12 captions-semibold discount-alert blue-bg no-margin'>
                          <span>{coupon.blueBoxText}</span>
                        </div>
                      </div>
                    )}

                  {mobile &&
                    coupon &&
                    Object.keys(coupon).length > 0 &&
                    checkProductCoupon(coupon, productId) &&
                    coupon.blueBoxText !== null && <br />}

                  {mobile === false && (
                    <div className='row no-margin margin-bottom-100 page-dim white-bg'>
                      <div className='col-xs-12 panel-container'>
                        <Banner
                          industryPackage={productsConfig.packages}
                          onSelectPackage={onSelectPackage}
                          product={productsConfig.quote_service_type}
                        />
                        <div
                          className='row padding-15 light-blue-bg'
                          style={{ margin: '15px 0px 0px 0px',
                          display: 'flex',
                          alignItems: 'center' }}
                        >
                          <div
                            className='col-sm-2'
                          />
                          <div
                            className='col-sm-6'
                            style={{marginLeft: '60px'}}
                          >
                            <div id='package_title' className='package-table-title'>
                              Customise Cordell Estimator
                            </div>
                            <p className='product-adv-text'>
                              For more tailored pricing to suit please call us.
                            </p>
                          </div>
                          <div x-ms-format-detection='none' className='col-sm-4 contact-text'>
                            <button
                              id='contact-btn'
                              type='button'
                              className='btn package-btn'
                            >
                              0800 355 355
                            </button>
                          </div>
                        </div>
                        <CordellEstimatorComparisonTable
                          industryPackage={productsConfig.packages}
                          usersGeo={productsConfig.features.users_geo}
                          addons={productsConfig.features.add_ons}
                          categories={productsConfig.features.categories}
                          training={productsConfig.features.training}
                        />
                      </div>
                    </div>
                  )}

                  {mobile === true && (
                    <div className='row no-margin margin-bottom-100'>
                      <div className='col-xs-12 white-bg padding-bottom-15'>
                        <div className='row off-white-bg'>
                          <div className='body font-16 text-center'>
                            {productsConfig.packages[packageKey].short_description}
                          </div>
                          <br />
                        </div>
                        <div className='row'>
                          <Nav bsStyle='tabs' id='product-nav-tabs' activeKey={activeKey}>
                            {Object.keys(productsConfig.packages).map(function (
                              singlePackage,
                              index
                            ) {
                              let item = productsConfig.packages[singlePackage];
                              let counter = index + 1;
                              counter = counter.toString();
                              return (
                                <NavItem
                                  eventKey={counter}
                                  onClick={() => {
                                    changePackage(item.key, counter);
                                  }}
                                  key={counter}
                                >
                                  {item.title}
                                </NavItem>
                              );
                            },
                            this)}
                          </Nav>
                          <BannerMobile
                            industryPackage={productsConfig.packages}
                            onSelectPackage={onSelectPackage}
                            packageKey={packageKey}
                            product={productsConfig.quote_service_type}
                          />
                        </div>
                        <div>
                          <CordellEstimatorComparisonTableMobile
                            industryPackage={productsConfig.packages}
                            usersGeo={productsConfig.features.users_geo}
                            categories={productsConfig.features.categories}
                            training={productsConfig.features.training}
                            packageKey={packageKey}
                            addons={productsConfig.features.add_ons}
                          />
                        </div>
                        <div
                          className='row light-blue-bg padding-15'
                          style={{ margin: '15px 0px 0px 0px' }}
                        >
                          <div
                            className='col-sm-8 text-left'
                            style={{ borderBottom: '1px solid #ccc', paddingBottom: '10px' }}
                          >
                            <div id='package_title' className='package-title'>Customise Cordell Costings</div>
                            <p className='body-semibold'>
                              For more tailored pricing to suit please call us.
                            </p>
                          </div>
                          <div
                            x-ms-format-detection='none'
                            className='col-sm-4 body-text margin-top-10 text-center'
                          >
                            <button
                              id='contact-btn'
                              type='button'
                              className='btn package-btn'
                            >
                              0800 355 355
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {productsConfig == null && (
        <div className='row spinner'>
          <i className='fa fa-spinner fa-pulse fa-3x fa-fw'></i>
          <span className='sr-only'>Loading...</span>
        </div>
      )}
    </React.Fragment>
  );
};

CordellEstimatorProductPackage.propTypes = {
  coupon: PropTypes.object,
  onSelectPackage: PropTypes.func.isRequired,
  productsConfig: PropTypes.object.isRequired,
  productId: PropTypes.number.isRequired
};

export default CordellEstimatorProductPackage;
