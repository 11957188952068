import {
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_FAILURE,
  FETCH_AFFILIATES_DETAILS_REQUEST,
  FETCH_AFFILIATES_DETAILS_SUCCESS,
  FETCH_AFFILIATES_DETAILS_FAILURE,
  SET_PACKAGE,
  CHANGE_INDUSTRY,
  REFRESH_COUPON,
  REMOVE_COUPON,
  REFRESH_CART_COUPON,
  UPDATE_QUOTE_CONFIG,
  SET_COUPON_CODE_FOR_CART,
  RESET_COUPON
} from '../constants';
import history from '../history';

const initialProductState = {
  productsByFriendlyName: {},
  productsById: {},
  productsConfig: {},
  productFetchFailure: false,
  productFetchSuccess: false,
  loading: false,
  coupon: null,
  failureMessage: null,
  couponCode: null
};

const INACTIVE_PRODUCTS = ['RPNZ']
const FAILURE_MESSAGE =
  'Sorry! The product you are looking for is unavailable for the New Zealand market.';

export default (state = initialProductState, action = {}) => {
  //This is to ensure immutability of state
  let newState = { ...state };

  switch (action.type) {
    case FETCH_PRODUCT_REQUEST:
      newState.productFetchFailure = false;
      newState.productFetchSuccess = false;
      newState.loading = true;
      newState.failureMessage = null;
      return newState;
    case FETCH_PRODUCT_SUCCESS:
      newState.loading = false;
      if (action.body.status === 'INACTIVE' 
        || INACTIVE_PRODUCTS.includes(action.body.productName)) {
        newState.failureMessage = FAILURE_MESSAGE;
        newState.productFetchFailure = true;
        return newState;
      }
      newState.productFetchSuccess = true;
      newState.productFetchFailure = false;
      newState.productsConfig[action.body.productUrl] = action.body;
      newState.productsConfig[action.body.productUrl].method = action.method;
      return newState;
    case FETCH_PRODUCT_FAILURE:
      newState.failureMessage = FAILURE_MESSAGE;
      newState.productFetchFailure = true;
      newState.loading = false;
      return newState;
    case SET_PACKAGE:
      if (
        newState.productsConfig[action.friendlyName] &&
        (action.friendlyName === 'cordell-costings-nz' || action.friendlyName === 'rpnz')
      ) {
        newState.productsConfig[
          action.friendlyName
        ].quoteConfigurationTemplate.configuration.packageName = action.packageKey;
      } else {
        newState.productsConfig[
          action.friendlyName
        ].quoteConfigurationTemplate.configuration.package = action.packageKey;
      }
      return newState;
    case FETCH_AFFILIATES_DETAILS_REQUEST:
      newState.productFetchFailure = false;
      return newState;
    case FETCH_AFFILIATES_DETAILS_SUCCESS:
      newState.productFetchSuccess = true;
      newState.coupon = action.body;
      return newState;
    case FETCH_AFFILIATES_DETAILS_FAILURE:
      newState.failureMessage = FAILURE_MESSAGE;
      newState.productFetchFailure = true;
      return newState;
    case CHANGE_INDUSTRY:
      newState.industrySelected = true;
      newState.selectedIndustry = action.industryKey;
      newState.selectedPackage = action.packageKey;
      return newState;
    case REFRESH_COUPON:
      newState.coupon = action.data.order.couponEntity;
      return newState;
    case REFRESH_CART_COUPON:
    case REMOVE_COUPON:
      newState.coupon = action.data.quote.coupon;
      return newState;
    case UPDATE_QUOTE_CONFIG:
      let productUrl = null;
      for (let config in newState.productsConfig) {
        if (newState.productsConfig[config].id === action.productId) {
          productUrl = newState.productsConfig[config].productUrl;
          break;
        }
      }
      newState.productsConfig[productUrl].quoteConfigurationTemplate = action.quoteConfig;
      history.push(`/product_config/${action.productId}`);
      return newState;
    case SET_COUPON_CODE_FOR_CART:
      newState.couponCode = action.code;
      return newState;
    case RESET_COUPON:
      newState.couponCode = null;
      newState.coupon = null;
      return newState;
    default:
      return state;
  }
};
