import React from "react";

export default class TncOneMonth extends React.Component{
  render(){
    return (
      <div className="row padding-10">
        <div className="col-sm-12 terms-cond">
          <div>
            {
              this.props.conditionalTC === true &&
              <span className="text-uppercase">{this.props.headline} - </span>
            }
            TERMS & CONDITIONS</div>
          <ul className="list-padding">
            <li>This is a binding contract with RP Data Pty Ltd t/as CoreLogic Asia Pacific. It combines the commercial terms on this webpage together with our <a className="terms-link" href="https://www.corelogic.com.au/about-us/terms-and-conditions" target="_blank" rel="noopener noreferrer">full terms & conditions</a>;</li>
            <li>You have paid upfront for a {this.props.totalMonths} {this.props.totalMonths > 1 ? "months" : "month"} subscription which will expire {this.props.totalDays} days after activation. At this time your access will be disconnected unless you enter into a renewing contract, or purchase another 1 month access.</li>
            <li>When accessing your subscription, you agree and comply to the commercial terms on this webpage together with the full terms & conditions of RP Data Pty Ltd t/as CoreLogic Asia Pacific, excluding clauses A1.2, A1.3 and A18.6 to the extent of any inconsistency.</li>
          </ul>
        </div>
      </div>
    )
  }
}
