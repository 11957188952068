import {
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  RESET_USER_DETAILS,
  EMAIL_VALIDATION_REQUEST,
  EMAIL_VALIDATION_SUCCESS,
  EMAIL_VALIDATION_FAILURE
} from '../constants';

const initialLoginState = {
  user: {},
  userError: {},
  userSuccess: true,
  emailValidation: {},
  loader: false
};

const umErrorMessage = (key) => {
  let errorKey = '';
  switch (key) {
    case 'validpassword.password':
      errorKey = 'password';
      break;
    case 'username.already.exists':
      errorKey = 'username';
      break;
    case 'user.email.already.exists':
      errorKey = 'duplicateEmail';
      break;
    default:
      break;
  }
  return errorKey;
};

export default (state = initialLoginState, action = {}) => {
  //This is to ensure immutability of state
  let newState = { ...state };

  switch (action.type) {
    case REGISTER_REQUEST:
      newState.user = {};
      newState.userError = {};
      newState.userSuccess = false;
      newState.loader = true;
      return newState;
    case REGISTER_SUCCESS:
      newState.user = action.body;
      newState.userSuccess = true;
      newState.userError = {};
      newState.loader = false;
      return newState;
    case REGISTER_FAILURE:
      JSON.parse(action.exception).errors.forEach((error) => {
        let errorKey = umErrorMessage(error.key);
        if (errorKey)
          newState.userError[errorKey] = true;
      });
      newState.userSuccess = false;
      newState.loader = false;
      return newState;
    case RESET_USER_DETAILS:
      newState.user = {};
      newState.userError = {};
      newState.userSuccess = false;
      return newState;
    case EMAIL_VALIDATION_REQUEST:
      newState.emailValidationLoader = true;
      return newState;
    case EMAIL_VALIDATION_SUCCESS:
      newState.emailValidation = action.data;
      newState.emailValidationLoader = false;
      return newState;
    case EMAIL_VALIDATION_FAILURE:
      const emailValidation = { isEmailValidated: false, }
      newState.emailValidation = emailValidation;
      newState.emailValidationLoader = false;
      return newState;
    default:
      return state;
  }
};
