import React from 'react';
import PropTypes from 'prop-types';

/**
 * Banner Mobile
 *
 * @author  Miguel
 * @param   {Object}      props.industryKey          Industry Key.
 * @param   {Object}      props.industryPackage      Product Config Packages.
 * @param   {Function}    props.onSelectPackage      React onSelectPackage.
 * @param   {Object}      props.packageKey           Package Key.
 * @param   {String}      props.product              Product Name as String.
 * @returns {JSX.Element}                                   The component markup.
 */

const BannerMobile = ({ industryKey, industryPackage, onSelectPackage, packageKey, product }) => {
  const packages = industryPackage || {};

  let classNames = require('classnames');
  const selectBtnClass = classNames({
    'btn btn-primary  margin-top-40':
      (packages[packageKey]?.popular || packages[packageKey]?.starburst) &&
      product !== 'commercial',
    'btn btn-primary  margin-top-25':
      !packages[packageKey]?.popular &&
      !packages[packageKey]?.starburst &&
      product !== 'commercial',
    'btn btn-primary ': product === 'commercial'
  });

  return (
    <React.Fragment>
      {packages && packages[packageKey] && (
        <div className='row no-margin mobile-package-selection'>
          <div className='col-xs-8 text-left'>
            {packages[packageKey].popular === true && (
              <div className='margin-bottom-5'>
                <span className='popular-tag-mobile'>
                  <i className='fa fa-star' aria-hidden='true'></i>
                  <span>Popular</span>
                </span>
              </div>
            )}
            {packages[packageKey].starburst === true && (
              <div className='margin-bottom-5'>
                <span className='popular-tag-mobile'>
                  <i className='fa fa-star' aria-hidden='true'></i>
                  <span> 20% Off</span>
                </span>
              </div>
            )}
            <p className='red-text text-uppercase no-margin package-selection-h3 mobile-package-title'>
              {packages[packageKey].title}
            </p>
            {packages[packageKey].dollars > 0 && (
              <p className='no-margin'>
                <span className='body-text'>
                  {product === 'cordellEstimator' && <span>From </span>}$
                  <b>{packages[packageKey].dollars}.{packages[packageKey].cents}</b>
                </span>
                <span className='per-month captions'>/MTH</span>
              </p>
            )}
            {packages[packageKey].dollars === 0 && (
              <p className='no-margin'>
                <span className='free-text'>FREE</span>
              </p>
            )}
            <p className='footnote-text'>{packages[packageKey].sub_text_1}</p>
            {packages[packageKey].sub_text_2 !== '' && (
              <p className='footnote-text'>{packages[packageKey].sub_text_2}</p>
            )}
          </div>
          <div className='col-xs-4 text-right'>
            <button
              type='button'
              className={selectBtnClass}
              onClick={() => {
                onSelectPackage(industryKey, packageKey);
              }}
            >
              Select
            </button>
          </div>
        </div>
      )}
      {!packages && (
        <div className='row spinner'>
          <i className='fa fa-spinner fa-pulse fa-3x fa-fw'></i>
          <span className='sr-only'>Loading...</span>
        </div>
      )}
    </React.Fragment>
  );
};

BannerMobile.propTypes = {
  industryKey: PropTypes.object,
  industryPackage: PropTypes.object.isRequired,
  onSelectPackage: PropTypes.func.isRequired,
  packageKey: PropTypes.object,
  product: PropTypes.string.isRequired
};

export default BannerMobile;
