import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import PackageCard from './PackageCard';

/**
 * PackageSelection.
 * Component for handling product package selection.
 *
 * @author  Manny
 * @param   {String}      props.method           Optional industry params.
 * @param   {Function}    props.onSelectPackage  Handles onClick for selecting a package.
 * @param   {Object}      props.productPackages  List of packages for the product.
 * @returns {JSX.Element} The component markup.
 */

const PackageSelection = ({
  method,
  onSelectPackage,
  productPackages
}) => {
  const [currentProductPackages, setCurrentProductPackages] = useState(productPackages);

  const isMobile = /Android|webOS|iPhone|iPod|ipad|BlackBerry/i.test(navigator.userAgent);

  let classNames = require('classnames');

  const packageContainer = classNames({
    'row no-margin page-dim-package-selection package-container-center': isMobile === false,
    'row no-margin page-dim-package-selection package-container-mobile': isMobile === true
  });
  const noteContainer = classNames({
    'row no-margin page-dim package-container-center': isMobile === false,
    'row no-margin page-dim package-container-mobile': isMobile === true
  });
  const noteClass = classNames({
    'padding-left-15 note-text note-text-align footnote-text margin-bottom-100 page-dim': isMobile === false,
    'padding-left-15 note-text footnote-text margin-bottom-100 padding-right-10 page-dim': isMobile === true
  });

  useEffect(() => {
    getFilteredDisplayFeatures();
  }, []);

  const getFilteredDisplayFeatures = () => {
    let newProductPackages = currentProductPackages;
    let packageKeys = Object.keys(productPackages);
    for (let index = 0; index < packageKeys.length; index++) {
      let currentPackage = currentProductPackages[packageKeys[index]];

      if (index == 0) {
        currentPackage.features.filteredFeatures = currentPackage.features.display;
        currentPackage.featuresText = 'Subscription Features Include:';
      }

      if (index > 0) {
        let previousPackage = currentProductPackages[packageKeys[index - 1]];
        if (
          previousPackage.features &&
          previousPackage.features.display &&
          currentPackage.features &&
          currentPackage.features.display
        ) {
          let currentDisplay = currentPackage.features.display;
          let previousDisplay = previousPackage.features.display;
          currentPackage.features.filteredFeatures = currentDisplay.filter(function (obj) {
            return !previousDisplay.some(function (obj2) {
              return obj.title === obj2.title;
            });
          });
          if (index > 2) {
            let firstPackage = currentProductPackages[packageKeys[index - 2]];
            currentPackage.featuresText = `Subscription Features Include \n Everything in ${firstPackage.title} & ${previousPackage.title} plus:`;
          } else {
            currentPackage.featuresText = `Subscription Features Include \n Everything in ${previousPackage.title} plus:`;
          }
        }
      }
      newProductPackages[packageKeys[index]] = currentPackage;
    }
    setCurrentProductPackages(newProductPackages);
  };

  return (
    <React.Fragment>
      <br/>
      <div className='package-container'>
        <div className={packageContainer}>
          {Object.keys(currentProductPackages).map((packageKey, packageIndex) => {
            let singlePackage = currentProductPackages[packageKey];
            if (singlePackage.key !== 'trial' && singlePackage.enabled) {
              return (
                <React.Fragment key={`package_card_${packageIndex}`}>
                  <PackageCard
                    singlePackage={singlePackage}
                    packageIndex={packageIndex}
                    onSelectPackage={onSelectPackage}
                    method={method}
                    isMobile={isMobile}
                  />
                </React.Fragment>
              );
            }
            return '';
          })}
        </div>
      </div>
      <div className='note-container'>
        <div className={noteContainer}>
          <div className={noteClass}>
            <br/>
            <b>Terms and Conditions</b>
            <ul>
              <li style={{lineHeight: "16px;"}}>
                All packages come with 1 user included. Additional user packages are tiered and start from an extra: $40.25 for the Lite Package with 3 users, $51.75 for the Base Package with 3 users, $69.00 for the Premium Package with 3 users and $109.25 for the Pro Package with 3 users. Package tiers include: 3 users, 5 users, 10 users, 15 users, and 25 users.
              </li>
              <li style={{lineHeight: "16px;"}}>
                Please note some features are currently available in RPNZ only, and will be transitioned to the new platform in due course. CoreLogic intends that users will continue to have access to these features (if they are included in the user’s chosen package) during and after this transition.             </li>
            </ul>
          </div>
        </div>
      </div>
      
    </React.Fragment>
  );
};

PackageSelection.propTypes = {
  method: PropTypes.string,
  onSelectPackage: PropTypes.func.isRequired,
  productPackages: PropTypes.object.isRequired,
  selectedIndustry: PropTypes.string.isRequired
};

export default PackageSelection;
