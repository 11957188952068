import {
  FETCH_QUOTE_REQUEST,
  FETCH_QUOTE_SUCCESS,
  FETCH_QUOTE_FAILURE,
  FETCH_FILTERED_AREAS_REQUEST,
  FETCH_FILTERED_AREAS_SUCCESS,
  FETCH_FILTERED_AREAS_FAILURE,
  FETCH_SUBURB_LIST_REQUEST,
  FETCH_SUBURB_LIST_SUCCESS,
  FETCH_SUBURB_LIST_FAILURE
} from '../constants';

const initialProductState = {
  quoteById: {},
  quotesConfigById: {},
  quoteFetchFailure: false,
  quoteFetchSuccess: false,
  loading: false,
  areaLoading: false,
  filteredAreas: [],
  suburbList: [],
  suburbLoading: false
};

export default (state = initialProductState, action = {}) => {
  //This is to ensure immutability of state
  let newState = { ...state };

  switch (action.type) {
    case FETCH_QUOTE_REQUEST:
      newState.loading = true;
      newState.quoteFetchFailure = false;
      newState.quoteFetchSuccess = false;
      return newState;
    case FETCH_QUOTE_SUCCESS:
      newState.loading = false;
      newState.quoteFetchSuccess = true;
      newState.quoteById[action.productId] = action.data;
      newState.quotesConfigById[action.productId] = action.configuration;
      return newState;
    case FETCH_QUOTE_FAILURE:
      newState.loading = false;
      newState.quoteFetchFailure = true;
      return newState;
    case FETCH_FILTERED_AREAS_REQUEST:
      newState.areaLoading = true;
      return newState;
    case FETCH_FILTERED_AREAS_SUCCESS:
      newState.areaLoading = false;
      newState.filteredAreas = action.data;
      return newState;
    case FETCH_FILTERED_AREAS_FAILURE:
      newState.areaLoading = false;
      return newState;
    case FETCH_SUBURB_LIST_REQUEST:
      newState.suburbLoading = true;
      return newState;
    case FETCH_SUBURB_LIST_SUCCESS:
      newState.suburbLoading = false;
      newState.suburbList = action.data;
      return newState;
    case FETCH_SUBURB_LIST_FAILURE:
      newState.suburbLoading = false;
      return newState;

    default:
      return state;
  }
};
