import React from 'react';

export default class Users extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      size: 1,
      usersPackage: [],
      selectedUser: 1
    };
  }

  /* istanbul ignore next */
  selectUser(user, usersPackage, clickAction) {
    this.props.onSelectingUser(user);

    if (clickAction) {
      this.props.onUserClickAction();
    }

    //update the pricing for users
    let userPrice, userIndex;
    let pricePerUser = this.props.users.price_per_user;
    let usersRange = this.props.usersRange;
    for (let eachUser = 0; eachUser <= usersPackage.length - 1; eachUser++) {
      if (pricePerUser) {
        userIndex =
          usersRange.indexOf(parseInt(usersPackage[eachUser].number, 10)) - usersRange.indexOf(user);
        userPrice = Math.abs(pricePerUser * userIndex).toFixed(2);
      } else {// if user price for all is provided
        let option_user = parseInt(usersPackage[eachUser].number, 10)
        let selected_user_price = this.props.users?.user_price[user]
        let option_user_price = this.props.users?.user_price[option_user]
        userIndex =  option_user - user
        userPrice = Math.abs(option_user_price - selected_user_price).toFixed(2);
      }
      
      if (userIndex === 0) {
        usersPackage[eachUser].value = 'Selected';
      } else if (parseInt(usersPackage[eachUser].number, 10) > user) {
        usersPackage[eachUser].value = ` +$${userPrice}`;
      } else if (parseInt(usersPackage[eachUser].number, 10) < user) {
        usersPackage[eachUser].value = `-$${userPrice}`;
      }
    }

    //set the updated users value
    this.setState({
      usersPackage: usersPackage,
      selectedUser: user
    });
  }

  componentDidMount() {
    let userPrice;
    let usersRange = this.props.usersRange;
    let usersPackage = [];
    let pricePerUser = this.props.users.price_per_user;
    // if user price for all is provided
    if (!usersRange) {
      let user_price = this.props.users?.user_price;
      usersPackage.push({ number: Object.keys(user_price)[0], value: 'Included' });
      for (let price_key of Object.keys(user_price)) {
        if (price_key != Object.keys(user_price)[0])
          usersPackage.push({ number: price_key, value: `+$${user_price[price_key].toFixed(2)}` });
      }
    } else {
      usersPackage.push({ number: 1, value: 'Included' });
      for (let index = 1; index < usersRange.length; index++) {
        userPrice = pricePerUser * index;
        usersPackage.push({ number: usersRange[index], value: `+$${userPrice.toFixed(2)}` });
      }
  
    }
    
    this.setState({
      selectedUser: this.props.selectedUser !== null ? this.props.selectedUser : 1,
      usersPackage: usersPackage
    });

    if (this.props.selectedUser != null) {
      this.selectUser(this.props.selectedUser, usersPackage, false);
    } else {
      this.setState({
        usersPackage: usersPackage
      });
    }
  }

  render(props) {
    let usersList = this.state.usersPackage.map(function (singleUser, index) {
      let classNames = require('classnames');
      let userTileClass = classNames({
        'alert': this.state.selectedUser !== singleUser.number,
        'alert alert-success': this.state.selectedUser === singleUser.number
      });
      const userValueClass = classNames({
        'col-xs-4 text-right grey-text': this.state.selectedUser !== singleUser.number,
        'col-xs-4 text-right success-text': this.state.selectedUser === singleUser.number
      });
      return (
        <div
          className={userTileClass}
          key={singleUser.number}
          data-testid="user-tile"
          onClick={() => {
            this.selectUser(singleUser.number, this.state.usersPackage, true);
          }}
        >
          <div className='row'>
            <div className='col-xs-8'>
              {singleUser.number} {singleUser.number === 1 ? 'User' : 'Users'}
            </div>
            <div className={userValueClass}>{singleUser.value}</div>
          </div>
        </div>
      );
    }, this);
    return <div className='panel-body-spacing'>{usersList}</div>;
  }
}
