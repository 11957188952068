/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import RegistrationForm from './components/customerRegistration/RegistrationForm';

import {
  getErrors,
  handleCustomerRegistration,
  isValueEmpty,
  registrationFormInputChange,
  setCustomerInfo
} from '../services/v2/customerRegistration/registrationService';
import {
  analyticsAlias,
  analyticsPageView,
  trackError,
  trackSignUp
} from '../services/v2/segmentAnalytics';

import { userExpirySession } from '../actions/login';
import { handleLogin } from '../services/v2/customerRegistration/loginService';
import './RegistrationPage.css'

/**
 * RegistrationPage.
 * Page for customer registration.
 *
 * @author  Manny
 * @returns {JSX.Element} The component markup.
 */

const RegistrationPage = () => {
  const defaultErrors = {
    confirmPassword: false,
    duplicateEmail: false,
    emailAddress: false,
    firstName: false,
    invalidUsername: false,
    incompleteFields: false,
    lastName: false,
    password: false,
    title: false,
    username: false
  };

  const [errors, setErrors] = useState(defaultErrors);
  const [submitCount, setSubmitCount] = useState(0);
  const [isFormValid, setIsFormValid] = useState(false);
  const registration = useSelector((state) => state.registration);
  const customer = useSelector((state) => state.checkout.customer);
  const [customerState, setCustomerState] = useState(customer);

  const dispatch = useDispatch();

  const resetState = () => {
    setErrors(defaultErrors);

    const blankForm = {
      confirmPassword: '',
      emailAddress: '',
      firstName: '',
      lastName: '',
      password: '',
      title: '',
      username: ''
    };
    setCustomerInfo(dispatch, blankForm);
  };

  /* istanbul ignore next */
  const submitCustomerRegistration = (newCustomer) => {
    setSubmitCount(submitCount + 1);
    setErrors(getErrors(newCustomer, { ...errors }));
  };

  // Hook runs when `errors` changes after handleRegistrationFormChange runs
  // Sets the form to be either valid for submission or invalid
  /* istanbul ignore next */
  useEffect(() => {
    let trackErrorMessages = [];
    Object.keys(errors).forEach(function (error) {
      if (errors[error]) trackErrorMessages[error] = errors[error];
    });
    trackError('Register', null, trackErrorMessages, null);
    const values = Object.values(errors);
    if (submitCount > 0 && !values.some(isValueEmpty)) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [errors]);

  // Hook runs when `customer` in redux changes after setCustomerInfo runs
  /* istanbul ignore next */
  useEffect(() => setCustomerState(customer), [customer]);

  // Hook runs when `userError` in redux changes after registration runs
  /* istanbul ignore next */
  useEffect(() => {
    for (const key in registration.userError) {
      switch (key) {
        case 'username':
          setErrors({...errors, invalidUsername: true})
          break;
        case 'duplicateEmail':
          setErrors({...errors, duplicateEmail: true})
          break;
        default:
          break;
      }
    }
    if (registration.userSuccess  && registration.user?.guid) {
      analyticsAlias(registration.user.guid);
    }
  }, [dispatch, registration])

  // Hook runs when `isFormValid` changes after errors are checked and tracked
  // The hook triggers the calls to the redux actions
  /* istanbul ignore next */
  useEffect(() => {
    trackSignUp('Register');
    if (!isFormValid) return;
    handleCustomerRegistration(dispatch, customer);
  }, [isFormValid]);

  /* istanbul ignore next */
  useEffect(() => {
    analyticsPageView('Sign Up');
    resetState();
    dispatch(userExpirySession());
  }, []);

  // onChange hook for RegistrationForm
  const handleRegistrationFormChange = (event) => {
    let registrationForm = customer;
    registrationForm[event.target.name] = event.target.value;
    setErrors(registrationFormInputChange(event, registrationForm, errors));
    setCustomerState((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
  };

  return (
    <div className='row off-white-bg no-margin' style={{paddingTop: '20px'}}>
      <form componentclass='fieldset'>
        <div className='row no-margin'>
          <div className='col-sm-3'></div>
          <div className='col-sm-6'>
            <div className='row no-margin'>
              <div className='col-xs-12 no-padding'>
              <div className='section-header registration-h3' style={{ textAlign: 'center' }}>
                Already registered?
              </div>
              <div className='body-semibold text-center'  style={{paddingTop:"10px"}}>
                If you were a user of the old version of Property Guru (where your email address is your 
                username), your current credentials will not allow you to sign in on this Store.
              </div>
              <div className='body-semibold text-center'  style={{paddingTop:"10px"}}>
               Please register as a new user to continue to the Checkout page.
              </div>
              <button
                style={{marginTop:"30px !important"}}
                id='btn_login'
                type='button'
                className='btn register-btn'
                onClick={() => handleLogin(dispatch)}
              >
                Sign-in
              </button>
              <hr/>
              </div>
            </div>
            <div className='section-header registration-h3' style={{ textAlign: 'center' }}>
              Or register here to create a new account.
            </div>
            {customer && (
              <RegistrationForm
                customer={customerState}
                dispatch={dispatch}
                formErrors={errors}
                loader={registration.loader}
                onInputChange={handleRegistrationFormChange}
                submitCustomerRegistration={submitCustomerRegistration}
              />
            )}
          </div>
          <div className='col-sm-3'></div>
        </div>
      </form>
    </div>
  );
};

export default RegistrationPage;
