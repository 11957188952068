import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import history from '../../../history';
import { trackGotoCart } from '../../../services/v2/segmentAnalytics';
import { getCartItemsCount } from '../../../services/v2/products/productItemsService';

import CartImage from '../../../image/cart-iframe.svg';

const CartIcon = (props) => {
  const cart = useSelector((state) => state.cart);
  const order = useSelector((state) => state.order);
  const [cartItemsCount, setCartItemsCount] = useState(0);

  const goToCart = () => {
    trackGotoCart(getCartItemsCount(cart.cart, order));
    history.push('/cart');
  };

  useEffect(() => {
    setCartItemsCount(getCartItemsCount(cart.cart, order));
  }, [cart, order]);

  return (
    <a
      id='cart-iframe' onClick={goToCart}
    >
      <img
        alt='Brand'
        id='cart-logo'
        src={CartImage}
      />   
        <div className='cart-counter'>{cartItemsCount}</div>
    </a>
  );
};

export default CartIcon;
