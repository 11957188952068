import React from "react";

export default class TncGeneral extends React.Component{
  render(){
    const productType = this.props.productType;
    const termsAndCondsLink = productType === 'Guru' ? 'https://www.property-guru.co.nz/gurux/help/CLNZ_Standard_Terms_and_Conditions_Jan_2015.pdf' : 'https://www.corelogic.co.nz/sites/default/files/2018-06/2018-06--v150201_CLNZ%20Standard%20Terms%20and%20Conditions.pdf';
    return (
      <div className="row padding-10">
        <div className="col-sm-12 terms-cond">
          <div>
            {
              this.props.conditionalTC === true &&
              <span className="text-uppercase">{this.props.headline} - </span>
            }
            TERMS & CONDITIONS
          </div>
          <ul className="list-padding">
            <li>This is a binding contract with CoreLogic NZ Limited. It combines the commercial terms on this webpage together with our <a className="terms-link" href={termsAndCondsLink} target="_blank" rel="noopener noreferrer">full terms & conditions</a>;</li>
            <li>Your minimum contract period (aka Initial Period) is {this.props.totalMonths} months; after this time you will continue to have access to our system at your contracted monthly price; </li>
            <li>To discontinue your access at the end of the Initial Period, you need to give us {this.props.noticeDays} days’ advance notice. After the Initial Period we will also need 30 days advance notice to terminate your access (your notice will take effect from the first day of the month following the date on which we receive the notice).</li>
            {productType === 'Guru' &&
              <li>Please note some features are currently available in RPNZ only, and will be transitioned to the new platform in due course. CoreLogic intends that users will continue to have access to these features (if they are included in the user’s chosen package) during and after this transition.</li>
            }
          </ul>
        </div>
      </div>
    )
  }
}
