import Api from '../services/api.js';
import history from '../history';

export function registerRequest() {
	return {
		type: 'REGISTER_REQUEST'
	}
}

export function registerSuccess(body) {
	return {
		type: 'REGISTER_SUCCESS',
		body: body
	}
}

export function registerFailure(exception) {
	return {
		type: 'REGISTER_FAILURE',
		exception: exception
	}
}

export function resetUserDetails() {
	return {
		type: 'RESET_USER_DETAILS'
	}
}

export function emailValidationRequest() {
	return {
		type: 'EMAIL_VALIDATION_REQUEST'
	}
}

export function emailValidationSuccess(body) {
	return {
		type: 'EMAIL_VALIDATION_SUCCESS',
		data: body
	}
}

export function emailValidationFailure(exception) {
	return {
		type: 'EMAIL_VALIDATION_FAILURE',
		exception: exception
	}
}

export function registerCustomer(userObject) {
	return (dispatch) => {
		dispatch(registerRequest())
		const api = new Api();
		return api.post("/__api/user/create", userObject, {
			headers: {
				Region: "NZ"
			}
		})
		.then((response) => {
      if(response.data.errors?.length > 0)
        dispatch(registerFailure(response.data.errors))
      else {
        dispatch(registerSuccess(response.data))
        history.push('/email_validation')
      }
    })
		.catch(err => dispatch(registerFailure(err)));
	};
}

export function validateEmail() {
	return (dispatch) => {
		dispatch(emailValidationRequest())
		const api = new Api();
		return api.get("/__api/user/validate_email", {
				headers: {
					Region: "NZ"
				}
			})
			.then(response => dispatch(emailValidationSuccess(response.data)))
			.catch(err => dispatch(emailValidationFailure(err)));
	};
}
